import ParentChildOtherDevice from 'src/components/Onboarding/ParentChildOtherDevice';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { usePaywall } from 'src/contexts/PaywallContext';
import useEndOnboardingParent from 'src/hooks/useEndOnboardingParent';
import Spinner from 'src/components/common/designSystem/Spinner';
import { DeviceType } from 'src/components/Onboarding/ParentChildDevice';

const OnboardingParentChildOtherDevice = () => {
  const { childId } = useParams();
  const navigate = useNavigate();
  const { isPremium } = usePaywall();
  const { endOnboarding, isEnding } = useEndOnboardingParent();
  const [searchParams] = useSearchParams();

  const onFinish = async () => {
    if (isPremium) {
      await endOnboarding();
      return;
    }
    return navigate(
      `/onboarding/parent/child/${childId}/school_level/device/paywall?deviceType=${DeviceType.other}&${searchParams.toString()}`,
    );
  };

  if (isEnding) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return (
    <>
      <ParentChildOtherDevice childId={Number(childId)} onFinish={onFinish} />
      <Outlet />
    </>
  );
};

export default OnboardingParentChildOtherDevice;
