import { Answer, Question, QuestionType } from 'src/types/quiz.types';

export default function LessonImprovement({
  question,
}: {
  answer?: Answer;
  question: Question;
}) {
  if (question.type === QuestionType.mcq) {
    return (
      <div>
        <div>{question.statement}</div>
        <div className={'font-bold'}>
          {question.correct.map(item => (
            <div key={item}>{item}</div>
          ))}
        </div>
      </div>
    );
  }
  if (question.type === QuestionType.matching) {
    return (
      <div>
        <div className={'font-bold'}>
          {question.correct.map(([a, b]) => (
            <div key={a}>
              {a} - {b}
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (question.type === QuestionType.fill_blank) {
    const splitStatement = question.statement.split('___');
    return (
      <div>
        <div>
          {splitStatement.map((part, index) => {
            return (
              <span key={index}>
                {part}
                {index !== splitStatement.length - 1 && (
                  <span className={'font-bold'}>{question.correct[index]}</span>
                )}
              </span>
            );
          })}
        </div>
      </div>
    );
  }
  if (question.type === QuestionType.complete_sentence) {
    const [startStatement, endStatement] = question.statement.split('___');
    return (
      <div>
        <div>
          {startStatement}
          <span className={'font-bold'}>{question.correct.join(' ')}</span>
          {endStatement}
        </div>
      </div>
    );
  }

  return null;
}
