export default {
  continue: 'Continuer',
  streak: {
    description1: 'Bien joué ! Reviens chaque jour pour cumuler des flammes !',
    description2:
      "Deuxième jour de révision d'affilée !\nTu es sur la bonne voie !",
    description3:
      "Tu es sur une bonne série !\nTroisième jour de révision d'affilée !",
    description4: 'Tu y es presque !\nLa constance est la clé du succès !',
    description5:
      'Cinquième jour consécutif !\nTu fais des progrès impressionnants !',
    description6: 'Plus qu’un jour pour\ncompléter ta semaine ! ',
    description7:
      'Une semaine complète de révision !\nTa détermination est admirable !',
    description: 'Bien joué ! Reviens chaque jour pour cumuler des flammes !',
    continue: 'Continuer ma série',
    descriptionDetails: 'Reviens chaque jour pour cumuler des flammes !',
  },
  quizzes: {
    quiz1: 'Tu as terminé\nton 1er quiz !',
    quiz10: 'Tu as terminé\n10 quiz !',
    quiz20: 'Tu as terminé\n20 quiz !',
  },
  lessons: {
    lesson5: 'Tu as créé\n5 leçons !',
    lesson10: 'Tu as créé\n10 leçons !',
    lesson20: 'Tu as créé\n20 leçons !',
    lesson30: 'Tu as créé\n30 leçons !',
    lesson40: 'Tu as créé\n40 leçons !',
    lesson50: 'Tu as créé\n50 leçons !',
    history: '1ère leçon\nd’histoire créée !',
    geography: '1ère leçon\nde géographie créée !',
    mathematics: '1ère leçon\nde mathématiques créée !',
    sciences: '1ère leçon\nde SVT créée !',
  },
  quiz_answers: {
    answers10: 'Tu as répondu\nà 10 questions !',
    answers50: 'Tu as répondu\nà 50 questions !',
    answers100: 'Tu as répondu\nà 100 questions !',
    answers150: 'Tu as répondu\nà 150 questions !',
    answers200: 'Tu as répondu\nà 200 questions !',
  },
};
