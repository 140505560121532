export enum MilestoneType {
  daily_streaks = 'daily_streaks',
  lessons = 'lessons',
  quizzes = 'quizzes',
  quiz_answers = 'quiz_answers',
  geography = 'geography',
  history = 'history',
  mathematics = 'mathematics',
  sciences = 'sciences',
}
