import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { useGetLesson } from 'src/queries/lessons';
import Spinner from 'src/components/common/designSystem/Spinner';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import QuizProvider from 'src/components/Quiz/QuizContext';
import Quiz from 'src/components/Quiz/Quiz';
import QuizHeader from 'src/components/Quiz/QuizHeader';
import { useNotification } from 'src/contexts/NotificationContext';
import QuizBackButton from 'src/components/Quiz/QuizBackButton';

import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import {
  useFinishQuiz,
  useGetQuizQuestion,
  useStartQuiz,
} from 'src/queries/quiz';
import dayjs from 'dayjs';
import { reportError } from 'src/modules/logs/Sentry';

export default function LessonQuiz() {
  const navigate = useNavigate();
  const { lessonId, quizId } = useParams();
  const { showError } = useNotification();
  const { t } = useTranslation();
  const [quizRunId, setQuizRunId] = useState<number>();

  const { data: questions, isLoading: isLoadingQuestions } = useGetQuizQuestion(
    Number(quizId),
  );
  const { data: lesson, isLoading: isLoadingLesson } = useGetLesson(
    Number(lessonId),
  );

  const { mutateAsync: finishQuizQuery, isLoading: isFinishingQuiz } =
    useFinishQuiz();
  const {
    mutateAsync: startQuizQuery,
    isLoading: isStartingQuiz,
    isIdle,
  } = useStartQuiz();

  const onQuizEnd = async () => {
    if (!lessonId || !quizRunId) {
      return;
    }
    try {
      await finishQuizQuery({
        quizId: Number(quizId),
        quizRunId: quizRunId,
        lessonId: Number(lessonId),
      });
      logAnalyticsEvent('complete_quiz', {
        lessonId,
        quizRunId: quizRunId,
        nbQuestions: questions?.length,
      });
      navigate(
        `/lessons/${lessonId}/quizzes/${quizId}/runs/${quizRunId}/result`,
        {
          replace: true,
        },
      );
    } catch (error) {
      reportError('Fail to finish quiz', error);
      showError({
        error,
        message: t('quiz.failToUpdate'),
      });
    }
  };

  const startQuiz = useCallback(async () => {
    if (isStartingQuiz || !lessonId || !quizId || quizRunId) {
      return;
    }

    const quizRun = await startQuizQuery({
      lessonId: Number(lessonId),
      quizId: Number(quizId),
    });
    setQuizRunId(quizRun.id);
    logAnalyticsEvent('start_quiz', {
      lessonId,
      quizId,
      quizRunId: quizRun.id,
      duration: dayjs(quizRun.created_at).diff(dayjs(), 'second'),
    });
  }, [isStartingQuiz, lessonId, quizId, quizRunId, startQuizQuery]);

  useEffect(() => {
    startQuiz();
  }, [startQuiz]);

  useEffect(() => {
    if (questions && !questions.length) {
      showError({
        message: t('quiz.noQuestions'),
        error: undefined,
      });
    }
  }, [questions, showError, t]);

  if (
    isFinishingQuiz ||
    isStartingQuiz ||
    isLoadingQuestions ||
    isLoadingLesson ||
    isIdle
  )
    return (
      <BlankPrimaryLayout appBackgroundFooter={'bg-white'}>
        <Spinner className="w-full h-full" type={'white'} />
      </BlankPrimaryLayout>
    );

  if (!lessonId || !questions || !quizRunId || !lesson) {
    return <Navigate to="404" />;
  }

  if (questions.length === 0) {
    return <Navigate to={`/lessons/${lessonId}`} replace />;
  }

  return (
    <QuizProvider
      lesson={lesson}
      quizID={Number(quizId)}
      quizRunID={quizRunId}
      onQuizEnd={onQuizEnd}
      questionsList={questions}
    >
      <BlankPrimaryLayout appBackgroundFooter={'bg-white'}>
        <div
          className={
            'w-full flex-1 flex flex-col justify-between overflow-hidden'
          }
        >
          <QuizHeader back={<QuizBackButton />} />
          <Quiz />
        </div>
      </BlankPrimaryLayout>
    </QuizProvider>
  );
}
