import { useTranslation } from 'react-i18next';
import ChildLessons from 'src/components/UserManagement/ChildLessons';
import StreakParentCard from 'src/components/Streak/modal/StreakParentCard';
import { User } from 'src/types/user.types';

const ChildTab = ({ child }: { child: User }) => {
  const { t } = useTranslation();

  return (
    <div className={'px-4 py-6 gap-6 flex flex-col'}>
      <StreakParentCard child={child} />
      <div className={'gap-4 flex flex-col'}>
        <div className={'font-bold text-xl'}>
          {t('settings.users.lessons.title')}
        </div>
        <ChildLessons child={child} />
      </div>
    </div>
  );
};

export default ChildTab;
