import { useCallback, useMemo } from 'react';
import { useGetStreaks } from 'src/queries/streak';
import dayjs, { type Dayjs } from 'dayjs';
import { getDaysCenterOnDay } from 'src/utils/date';
import { isStreakCompleted as isStreakCompletedUtil } from 'src/utils/streak';

const useStreakCard = () => {
  const { data: streaks } = useGetStreaks();

  const days = useMemo(() => getDaysCenterOnDay(dayjs()), []);

  const isStreakCompleted = useCallback(
    (day: Dayjs) => {
      if (!streaks) return false;
      return isStreakCompletedUtil(day, streaks);
    },
    [streaks],
  );

  return {
    days,
    isStreakCompleted,
    streaks,
  };
};

export default useStreakCard;
