export const projectColors = {
  backGrey: '#D9D9D9',
  textBlue: '#35A9EA',
  orange: '#FFC43D',
  blue: '#67F1E8',
  purple: '#9138D3',
  barGreen: '#26D037',
  flashyGreen: '#38D363',
  wrongRed: '#CF0808',
  nameOrange: '#FFBF1A',
  geography: '#dbfdf2',
  history: '#ffe3eb',
  mathematics: '#fff3dc',
  english: '#d8f6fc',
  spanish: '#edeaff',
  sciences: '#ffebe2',
  lightGrey: '#BDBDBD',
  darkGrey: '#37474F',
  whiteish: '#F9F8F4',
  backGround: '#F4F9F9',
  primary: {
    darkest: '#8C4AF8',
    darker: '#A36FF9',
    dark: '#B991FB',
    neutral: '#CFB4FC',
    light: '#E6D8FE',
    lighter: '#F6F1FE',
    lightest: '#FDFBFF',
  },
  red: {
    darkest: '#f10d1e',
    neutral: '#F87F4A',
    light: '#f8c9cb',
    lightest: '#fff4f5',
  },
  green: {
    darkest: '#01be80',
    neutral: '#06D6A0',
    light: '#a5fde0',
    lightest: '#f2fffb',
  },
  secondary: {
    darker: '#39395B',
    neutral: '#5438DC',
    lighter: '#E8F0FF',
  },
  structural: {
    darkest: '#070732',
    darker: '#39395B',
    dark: '#6A6A84',
    neutral: '#9C9CAD',
    light: '#CDCDD6',
    lighter: '#EBEBEF',
    lightest: '#FAFAFB',
  },
  paywall: {
    lightest: {
      from: '#F4EEFC',
      via: '#FBECF6',
      to: '#FEF9F2',
    },
    darkest: {
      from: '#9B5FFF',
      to: '#B358DC',
    },
  },
};
