import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useGetChallenge } from 'src/queries/challenge';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useNotification } from 'src/contexts/NotificationContext';
import { useStartQuiz } from 'src/queries/quiz';
import Button from 'src/components/common/designSystem/Button';
import CloseIcon from 'src/images/close.svg?react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useCurrentUser } from 'src/queries/user';
import { reportError } from 'src/modules/logs/Sentry';

const ChallengePage = () => {
  const { challengeId } = useParams();
  const { data: challenge, isLoading: isLoadingChallenge } = useGetChallenge(
    Number(challengeId),
  );
  const navigate = useNavigate();
  const { showError } = useNotification();
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();

  const { mutateAsync: startQuizQuery, isLoading: isStartingQuiz } =
    useStartQuiz();

  const startQuiz = useCallback(async () => {
    if (!challenge || !challenge.quiz.lesson.id || !challenge.quiz.id) {
      return;
    }
    try {
      const quizRun = await startQuizQuery({
        lessonId: challenge.quiz.lesson.id,
        quizId: challenge.quiz.id,
        fromChallengeId: challenge.id,
      });
      logAnalyticsEvent('challenge_accepted');
      navigate(
        `/challenges/${challenge.id}/lessons/${challenge.quiz.lesson.id}/quizzes/${challenge.quiz.id}/runs/${quizRun.id}`,
      );
    } catch (error) {
      reportError('Fail to start quiz challenge', error);
      showError({
        message: t('lessons.photo.error'),
        error: error,
      });
    }
  }, [challenge, navigate, showError, startQuizQuery, t]);

  const isChallengingHimself =
    challenge && user && challenge.created_by.id === user.id;

  useEffect(() => {
    if (isChallengingHimself) {
      showError({
        message: t('challenge.error.challengingHimself'),
        props: {
          'data-testid': `toast-challenge-himself`,
        },
      });
      navigate('/');
    }
  }, [isChallengingHimself]);

  if (!challengeId) {
    return <Navigate to="404" />;
  }

  if (isLoadingChallenge || isChallengingHimself) {
    return (
      <BlankPrimaryLayout>
        <div
          className={'w-full h-full flex flex-col justify-center items-center'}
        >
          <Spinner className="w-full h-full" />
        </div>
      </BlankPrimaryLayout>
    );
  }

  if (!challenge) {
    return <Navigate to="404" />;
  }

  return (
    <BlankPrimaryLayout>
      <div
        className={
          'flex flex-1 flex-col gap-5 w-full text-white justify-between items-center px-4 py-8'
        }
      >
        <div className={'flex flex-row justify-between items-center w-full'}>
          <div className={'p-3 text-white'} onClick={() => navigate('/')}>
            <CloseIcon
              className={'fill-white w-4 h-4'}
              data-testid={'btn-back'}
            />
          </div>
          <div
            className={
              'bg-white bg-opacity-30 rounded-2xl font-bold text-base px-4 py-2'
            }
          >
            {challenge.quiz.lesson.title}
          </div>
          <div className={'w-10'} />
        </div>
        <div className={'font-bold text-3xl text-balance text-center'}>
          {t('challenge.title', { firstName: challenge.created_by.firstname })}
        </div>
        <Button
          data-testid={'btn-start-quiz'}
          loading={isStartingQuiz}
          onClick={startQuiz}
        >
          {t('challenge.start')}
        </Button>
      </div>
    </BlankPrimaryLayout>
  );
};

export default ChallengePage;
