import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { type AxiosError } from 'axios';

import { useGetLesson } from 'src/queries/lessons';
import { useCurrentUser } from 'src/queries/user';

import Spinner from 'src/components/common/designSystem/Spinner';
import Button from 'src/components/common/designSystem/Button';
import QuizResultHeader from 'src/components/Quiz/Result/QuizResultHeader';
import QuizShareButton from 'src/components/Quiz/Result/QuizShareButton';
import { useGetQuizRun } from 'src/queries/quiz';
import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';
import QuizScore from 'src/components/Quiz/QuizScore';
import QuizXP from 'src/components/Quiz/QuizXP';
import Star from 'src/images/quiz/star.png';
import QuizNewRecord from 'src/components/Quiz/QuizNewRecord';
import { MouseEvent, useEffect } from 'react';
import { blast } from 'src/components/common/animations/Blast';
import { addLocalStorageXPWon } from 'src/utils/usersUtils';

export default function LessonQuizResult() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lessonId, quizId, runId } = useParams();

  const { data: currentUser } = useCurrentUser();
  const { data: lesson, isLoading, isError, error } = useGetLesson(lessonId);
  const { data: quizRun, isLoading: isLoadingQuizRun } = useGetQuizRun({
    quizId: Number(quizId),
    quizRunId: Number(runId),
  });

  useEffect(() => {
    if (quizRun?.is_record) {
      setTimeout(() => {
        blast('👑');
      }, 300);
    }
  }, [quizRun?.is_record]);

  useEffect(() => {
    if (quizRun?.xp_won) {
      addLocalStorageXPWon(quizRun.xp_won);
    }
  }, [quizRun?.xp_won]);

  const onClick = (event: MouseEvent<HTMLDivElement>) => {
    blast('👑', {
      x: event.clientX,
      y: event.clientY,
    });
  };

  if (isLoading || isLoadingQuizRun) {
    return (
      <BlankWhiteLayout>
        <div className={'w-full h-full flex flex-col'}>
          <Spinner className="w-full h-full" />
        </div>
      </BlankWhiteLayout>
    );
  }

  if (
    !lessonId ||
    (isError && (error as AxiosError).status === 404) ||
    !lesson ||
    !quizRun
  ) {
    return <Navigate to="404" />;
  }

  const score = quizRun.grade;

  const handleRestart = async () => {
    navigate(`/lessons/${lessonId}/quizzes/${quizId}`, { replace: true });
  };

  return (
    <BlankWhiteLayout>
      <div className={'w-full h-full flex flex-col justify-between'}>
        <QuizResultHeader title={lesson.title} />
        <div
          className={
            'text-center flex flex-1 flex-col items-center justify-between w-full px-8 py-4'
          }
          onClick={onClick}
        >
          <div className={'relative'}>
            {quizRun.is_record ? (
              <div className={'absolute top-0 left-9 text-6xl'}>👑</div>
            ) : null}
            <img src={Star} className={'w-32 h-32'} alt={'Star'} />
          </div>
          <div className={'flex flex-col justify-center items-center'}>
            <div className={'text-3xl font-bold text-center'}>
              {t('quiz.result.greatWork', { name: currentUser?.firstname })}
            </div>
            {quizRun.is_record ? (
              <div
                className={
                  'text-base text-center text-structural-darkest opacity-70 text-balance font-semibold'
                }
              >
                {t('quiz.result.newRecordDescription')}
              </div>
            ) : null}
          </div>
          <div className={'flex flex-col gap-4 w-full'}>
            <div
              className={
                'w-full relative items-center bg-gradient-to-r from-paywall-darkest-from/40 to-paywall-darkest-to/40 rounded-xl p-1'
              }
            >
              {quizRun.is_record ? (
                <div className={'absolute -right-4 -top-4'}>
                  <QuizNewRecord />
                </div>
              ) : null}
              <div
                className={
                  'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-6'
                }
              >
                <div className={'text-base font-bold uppercase'}>
                  {t('quiz.result.score')}
                </div>
                <QuizScore score={score} />
              </div>
            </div>
            <div
              className={
                'w-full items-center bg-gradient-to-r from-paywall-darkest-from/40 to-paywall-darkest-to/40 rounded-xl p-1'
              }
            >
              <div
                className={
                  'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-6'
                }
              >
                <div className={'text-base font-bold uppercase'}>
                  {t('quiz.result.xp')}
                </div>
                <QuizXP xp={quizRun.xp_won} />
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-col gap-2 w-full py-4 px-8'}>
          <Button
            type={'default'}
            data-testid="btn-restart-quiz"
            onClick={handleRestart}
          >
            {t('quiz.result.restart')}
          </Button>
          <QuizShareButton quizId={Number(quizId)} lesson={lesson} />
        </div>
      </div>
    </BlankWhiteLayout>
  );
}
