import { NativeAudio } from '@capacitor-community/native-audio';
import { Capacitor } from '@capacitor/core';
import { reportError } from 'src/modules/logs/Sentry';

export const preloadSoundAsset = async ({
  filename,
  assetId,
}: {
  filename: string;
  assetId: string;
}) => {
  try {
    if (Capacitor.isNativePlatform()) {
      return await NativeAudio.preload({
        assetId,
        audioChannelNum: 1,
        assetPath: `public/sounds/${filename}`,
        isUrl: false,
      });
    }
    return await NativeAudio.preload({
      assetId,
      audioChannelNum: 1,
      assetPath: '/public/sounds/success.mp3',
      isUrl: true,
    });
  } catch (error) {
    if (!error) {
      reportError('Error while preloading sound asset: unknown error');
      return;
    }
    if (typeof error === 'string' && error.includes('AssetId already exists')) {
      return;
    }
    if (
      typeof error === 'object' &&
      'message' in error &&
      error.message === 'Audio Asset already exists'
    ) {
      return;
    }
    reportError('Error while preloading sound asset', error);
  }
};
