import { ReactNode } from 'react';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import BlankLayout from 'src/layouts/BlankLayout';

const BlankWhiteLayout = ({ children }: { children: ReactNode }) => {
  return (
    <BlankLayout
      appBackground={'bg-white'}
      statusBar={{
        color: '#FFFFFF',
        style: Style.Light,
      }}
    >
      {children}
    </BlankLayout>
  );
};

export default BlankWhiteLayout;
