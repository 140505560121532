import i18n from 'src/modules/i18n';
import History from 'src/images/subjects/history.svg?react';
import HistorySmall from 'src/images/subjects/small/history.svg?react';
import SVT from 'src/images/subjects/svt.svg?react';
import SVTSmall from 'src/images/subjects/small/svt.svg?react';
import Physics from 'src/images/subjects/physics.svg?react';
import PhysicsSmall from 'src/images/subjects/small/physics.svg?react';
import Mathematics from 'src/images/subjects/mathematics.svg?react';
import MathematicsSmall from 'src/images/subjects/small/mathematics.svg?react';
import Geography from 'src/images/subjects/geography.svg?react';
import GeographySmall from 'src/images/subjects/small/geography.svg?react';
import Spanish from 'src/images/subjects/spanish.svg?react';
import SpanishSmall from 'src/images/subjects/small/spanish.svg?react';
import English from 'src/images/subjects/english.svg?react';
import EnglishSmall from 'src/images/subjects/small/english.svg?react';
import French from 'src/images/subjects/french.svg?react';
import FrenchSmall from 'src/images/subjects/small/french.svg?react';
import { LessonSubject, LessonSubjectType } from 'src/types/lesson.types';

export const subjects: {
  [key in LessonSubject]: LessonSubjectType;
} = {
  [LessonSubject.geography]: {
    key: LessonSubject.geography,
    labelKey: 'subjects.geography.label',
    IconComponent: Geography,
    IconSmallComponent: GeographySmall,
  },
  [LessonSubject.history]: {
    key: LessonSubject.history,
    labelKey: 'subjects.history.label',
    IconComponent: History,
    IconSmallComponent: HistorySmall,
  },
  [LessonSubject.mathematics]: {
    key: LessonSubject.mathematics,
    labelKey: 'subjects.mathematics.label',
    IconComponent: Mathematics,
    IconSmallComponent: MathematicsSmall,
  },
  [LessonSubject.sciences]: {
    key: LessonSubject.sciences,
    labelKey: 'subjects.sciences.label',
    IconComponent: SVT,
    IconSmallComponent: SVTSmall,
  },
  [LessonSubject.physics]: {
    key: LessonSubject.physics,
    labelKey: 'subjects.physics.label',
    IconComponent: Physics,
    IconSmallComponent: PhysicsSmall,
  },
  [LessonSubject.english]: {
    key: LessonSubject.english,
    labelKey: 'subjects.english.label',
    IconComponent: English,
    IconSmallComponent: EnglishSmall,
    isBeta: true,
  },
  [LessonSubject.spanish]: {
    key: LessonSubject.spanish,
    labelKey: 'subjects.spanish.label',
    IconComponent: Spanish,
    IconSmallComponent: SpanishSmall,
    isBeta: true,
  },
  [LessonSubject.french]: {
    key: LessonSubject.french,
    labelKey: 'subjects.french.label',
    IconComponent: French,
    IconSmallComponent: FrenchSmall,
    isBeta: true,
  },
};

export const allSubjects = Object.values(subjects);

export const quizQuestionFlagOptions = (): Record<
  string,
  { label: string; value: string }
> => ({
  wrong_question: {
    label: i18n.t('quiz.questionFlagOptions.wrongQuestion'),
    value: 'wrong_question',
  },
  no_correct_answer: {
    label: i18n.t('quiz.questionFlagOptions.noCorrectAnswer'),
    value: 'no_correct_answer',
  },
  not_in_the_lesson: {
    label: i18n.t('quiz.questionFlagOptions.notInTheLesson'),
    value: 'not_in_the_lesson',
  },
  other: {
    label: i18n.t('quiz.questionFlagOptions.other'),
    value: 'other',
  },
});

export enum LocalStorageKey {
  STREAKS_COUNT = 'STREAKS_COUNT',
  FAMILY_USER_ID = 'familyUserId',
  LESSON_PIECE = 'LESSON_PIECE',
  XP_WON = 'XP_WON',
  HAS_DONE_ONBOARDING_FIRST_LESSON = 'HAS_DONE_ONBOARDING_FIRST_LESSON',
  HAS_SEEN_CONTACT_SUPPORT_PANEL = 'HAS_SEEN_CONTACT_SUPPORT_PANEL',
}
