import StreakDay from './StreakDay';
import { useTranslation } from 'react-i18next';
import FlameIcon from 'src/images/Flame.svg?react';
import useStreakCard from 'src/components/Streak/modal/useStreakCard';
import { useGetMilestones } from 'src/queries/milestones';

const StreakChildCard = () => {
  const { t } = useTranslation();
  const { days, isStreakCompleted } = useStreakCard();
  const { data: milestones } = useGetMilestones();

  return (
    <div
      className={
        'flex flex-col justify-center items-center rounded-xl bg-structural-lightest p-4 pb-5 gap-4'
      }
      data-testid={'card-streak'}
    >
      <div className={'flex flex-row justify-between items-center w-full'}>
        <div className={'font-bold text-base text-structural-darkest'}>
          {t('profile.child.streaks.title')}
        </div>
        <div className={'flex flex-row items-center gap-2'}>
          <FlameIcon className={'w-5 h-auto'} />
          <div className={'font-extrabold text-base text-structural-darkest'}>
            {milestones?.daily_streaks ?? 0}
          </div>
        </div>
      </div>
      <div className={'flex flex-row gap-4 justify-between flex-1 w-full'}>
        {days.map((day, index) => (
          <StreakDay
            key={index}
            day={day}
            isCompleted={isStreakCompleted(day)}
          />
        ))}
      </div>
    </div>
  );
};

export default StreakChildCard;
