import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUsers } from 'src/queries/users';
import Spinner from 'src/components/common/designSystem/Spinner';
import RadioList from 'src/components/common/designSystem/RadioList';

export enum DeviceType {
  same = 'same',
  other = 'other',
}

const ParentChildDevice = ({
  childId,
  onSubmit,
}: {
  childId: number;
  onSubmit: (deviceType: DeviceType) => void;
}) => {
  const { t } = useTranslation();
  const { data: users, isLoading } = useGetUsers();
  const child = users?.find(user => user.id === Number(childId));
  const [deviceType, setDeviceType] = useState<DeviceType>();

  const onNext = async (deviceType: DeviceType) => {
    setDeviceType(deviceType);
    onSubmit(deviceType);
  };

  if (isLoading) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return (
    <div
      className={'flex flex-col gap-5 p-6 text-center'}
      data-testid={'radio-device'}
    >
      <div className={'font-bold text-balance text-base'}>
        {t('onboarding.parent.child.device.title', {
          firstname: child?.firstname,
        })}
      </div>
      <RadioList
        options={[
          {
            type: 'value',
            label: t('onboarding.parent.child.device.same'),
            value: DeviceType.same,
          },
          {
            type: 'value',
            label: t('onboarding.parent.child.device.other'),
            value: DeviceType.other,
          },
        ]}
        onSelect={onNext}
        selected={deviceType}
      />
    </div>
  );
};

export default ParentChildDevice;
