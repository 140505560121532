import BgPatternSVG from 'src/images/bg-pattern.svg';

const BackgroundPattern = () => {
  return (
    <div
      className="absolute inset-0 z-0"
      style={{
        backgroundImage: `url(${BgPatternSVG})`,
        backgroundRepeat: 'repeat',
      }}
    />
  );
};

export default BackgroundPattern;
