import axios from 'axios';
import { useMutation } from 'react-query';

import { Answer } from 'src/types/quiz.types';

export type AnswerQuestionData = {
  quizId: number;
  runId: number;
  questionId: number;
  answer?: Answer;
  timeTaken: number;
};

export const useAnswerQuestion = () => {
  return useMutation<unknown, unknown, AnswerQuestionData>({
    mutationFn: async data => {
      await axios.post(
        `/api/quizzes/${data.quizId}/runs/${data.runId}/questions/${data.questionId}/answer`,
        {
          answer: data.answer,
          time_to_answer_ms: data.timeTaken,
        },
      );
    },
  });
};
