import CloseIcon from 'src/images/close.svg?react';

const QuizAnswerWrongIcon = () => {
  return (
    <div
      className={
        'w-5 h-5 flex items-center justify-center rounded-full bg-red-darkest'
      }
    >
      <CloseIcon className={'fill-white w-2'} />
    </div>
  );
};

export default QuizAnswerWrongIcon;
