import { ReactNode } from 'react';

const ProfileItem = ({
  title,
  onClick,
  'data-testid': dataTestId,
  Icon,
  color = 'structural-darkest',
}: {
  title: string;
  onClick: () => void;
  'data-testid'?: string;
  Icon: ReactNode;
  color?: string;
}) => {
  return (
    <div
      className={`font-semibold flex text-${color} flex-row gap-5 items-center cursor-pointer px-1 py-1`}
      data-testid={dataTestId}
      onClick={onClick}
    >
      {Icon}
      <span>{title}</span>
    </div>
  );
};

export default ProfileItem;
