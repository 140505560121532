import { LocalStorageKey } from 'src/utils/constants';
import {
  getInLocalStorageByKey,
  setInLocalStorage,
} from 'src/utils/storageManager';

export const shouldShowLessonPieceError = (lessonPieceId: number) => {
  const localStorageLessonPiece = getInLocalStorageByKey(
    LocalStorageKey.LESSON_PIECE,
  );
  if (!localStorageLessonPiece || !localStorageLessonPiece.errors) {
    return true;
  }
  return !localStorageLessonPiece.errors.includes(lessonPieceId);
};

export const addLocalStorageLessonPieceError = (lessonPieceId: number) => {
  const localStorageLessonPiece = getInLocalStorageByKey(
    LocalStorageKey.LESSON_PIECE,
  );
  if (!localStorageLessonPiece) {
    setInLocalStorage(LocalStorageKey.LESSON_PIECE, {
      errors: [lessonPieceId],
    });
    return;
  }
  const newLocalStorageLessonPiece = {
    ...localStorageLessonPiece,
    errors: [...(localStorageLessonPiece?.errors ?? []), lessonPieceId],
  };
  setInLocalStorage(LocalStorageKey.LESSON_PIECE, newLocalStorageLessonPiece);
};
