import { User } from 'src/types/user.types';
import { AppLauncher } from '@capacitor/app-launcher';
import { reportError } from 'src/modules/logs/Sentry';
import { initCrisp, initUserToCrispSupport } from 'src/modules/support/Crisp';
import {
  initIntercomSupport,
  initUserToIntercomSupport,
  openIntercomMessenger,
} from 'src/modules/support/Intercom';
import { Capacitor } from '@capacitor/core';

export const WHATSAPP_URL = 'https://wa.me/message/GLLWOED5VJMMG1';

export const initSupport = async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    await Promise.all([initCrisp(), initIntercomSupport()]);
  } catch (error) {
    reportError('Error while initializing support', error);
  }
};

export const openSupportMessenger = async () => {
  try {
    await openIntercomMessenger();
  } catch (error) {
    reportError('Error while opening support', error);
  }
};

export const openWhatsAppSupport = async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    await AppLauncher.openUrl({ url: WHATSAPP_URL });
  } catch (error) {
    reportError('Error while opening WhatsApp', error);
  }
};

export const initUserToSupport = async (user?: User) => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    await Promise.all([
      initUserToCrispSupport(user),
      initUserToIntercomSupport(user),
    ]);
  } catch (error) {
    reportError('Error while init user to support', error);
  }
};
