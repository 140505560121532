import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Button from 'src/components/common/designSystem/Button';
import dayjs from 'dayjs';
import PayButton from 'src/components/Paywall/PayButton';
import MonthlyOffer from 'src/components/Paywall/MonthlyOffer';
import { usePaywall } from 'src/contexts/PaywallContext';
import { useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import AnnualOffer from 'src/components/Paywall/AnnualOffer';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { openWhatsAppSupport } from 'src/modules/support/support';
import ParentSellArguments from 'src/components/Paywall/ParentSellArguments';
import { useCurrentUser } from 'src/queries/user';
import AskParentButton from 'src/components/Paywall/AskParentButton';
import { UserType } from 'src/types/user.types';

const PaywallMenuPanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { isPremium } = usePaywall();

  useEffect(() => {
    if (isOpen) {
      logAnalyticsEvent('paywall_viewed', {
        type: 'profile_my_subscription',
      });
    }
  }, [isOpen]);

  return (
    <AutoFloatingPanel
      data-testid={'panel-paywall'}
      isOpen={isOpen}
      onClose={onClose}
    >
      {isPremium ? (
        <SubscribedPanel />
      ) : (
        <NotSubscribedPanel onClose={onClose} />
      )}
    </AutoFloatingPanel>
  );
};

const NotSubscribedPanel = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();

  return (
    <div className={'flex flex-col items-start justify-center p-6 gap-8'}>
      <div className={'font-bold text-xl text-structural-darker text-balance'}>
        {t('paywall.panel.notSubscribed.title')}
      </div>

      <ParentSellArguments />
      <AnnualOffer />

      <div className={'flex flex-col items-center justify-center gap-2 w-full'}>
        {user?.user_type === UserType.child ? <AskParentButton /> : null}
        <PayButton onPay={onClose}>{t('global.continue')}</PayButton>
        <Button
          data-testid="btn-contact-us"
          type={'link'}
          onClick={openWhatsAppSupport}
        >
          {t('paywall.pay.contactUs')}
        </Button>

        <PaywallCGU />
      </div>
    </div>
  );
};

const SubscribedPanel = () => {
  const { t } = useTranslation();
  const { activeSubscription, managementURL } = usePaywall();

  const isMonthly =
    activeSubscription?.identifier === 'premium' ||
    activeSubscription?.identifier === 'premium_without_trial';

  const manage = async () => {
    window.open(managementURL, '_self');
  };

  return (
    <div className={'flex flex-col items-start justify-center p-6 gap-8'}>
      <div className={'font-bold text-xl text-structural-darker'}>
        {t('paywall.panel.subscribed.title')}
      </div>

      <div className={'flex flex-col w-full items-start gap-2'}>
        {isMonthly ? <MonthlyOffer /> : <AnnualOffer />}
        {activeSubscription?.originalPurchaseDate ? (
          <div className={'text-structural-dark'}>
            {t('paywall.panel.since', {
              date: dayjs(activeSubscription.originalPurchaseDate).format('LL'),
            })}
          </div>
        ) : null}
      </div>

      <div className={'flex flex-col gap-1 w-full items-center justify-center'}>
        <Button type={'primary'} onClick={manage}>
          {t('paywall.panel.manage')}
        </Button>
        <div
          className={'text-structural-dark text-xs text-center text-balance'}
        >
          {activeSubscription?.expirationDate
            ? t('paywall.panel.nextBilling', {
                date: dayjs(activeSubscription.expirationDate).format('LL'),
              })
            : null}
        </div>

        <Button
          data-testid="btn-contact-us"
          type={'link'}
          onClick={openWhatsAppSupport}
        >
          {t('paywall.pay.contactUs')}
        </Button>

        <PaywallCGU />
      </div>
    </div>
  );
};

export default PaywallMenuPanel;
