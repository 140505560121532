import QuizMCQQuestion from 'src/components/Quiz/MCQQuestion/QuizMCQQuestion';
import { useQuiz } from 'src/components/Quiz/QuizContext';
import QuizMatchingQuestion from 'src/components/Quiz/MatchingQuestion/QuizMatchingQuestion';
import QuizFillBlankQuestion from 'src/components/Quiz/FillBlankQuestion/QuizFillBlankQuestion';
import QuizCompleteSentenceQuestion from 'src/components/Quiz/CompleteSentenceQuestion/QuizCompleteSentenceQuestion';
import { QuestionType } from 'src/types/quiz.types';

const Quiz = () => {
  const { currentQuestion } = useQuiz();

  if (!currentQuestion) {
    return null;
  }

  if (currentQuestion.type === QuestionType.matching) {
    return <QuizMatchingQuestion question={currentQuestion} />;
  }
  if (currentQuestion.type === QuestionType.mcq) {
    return <QuizMCQQuestion question={currentQuestion} />;
  }
  if (currentQuestion.type === QuestionType.fill_blank) {
    return <QuizFillBlankQuestion question={currentQuestion} />;
  }
  if (currentQuestion.type === QuestionType.complete_sentence) {
    return <QuizCompleteSentenceQuestion question={currentQuestion} />;
  }
  return null;
};

export default Quiz;
