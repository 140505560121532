import { useTranslation } from 'react-i18next';
import { LeftOutline } from 'antd-mobile-icons';
import useNavigateBack from 'src/hooks/useNavigateBack';

const PhotoHeader = () => {
  const { t } = useTranslation();
  const goBack = useNavigateBack();

  return (
    <div
      className={
        'flex flex-row items-center justify-between p-4 bg-primary-darkest'
      }
    >
      <LeftOutline fontSize={24} data-testid={'btn-back'} onClick={goBack} />
      <div className={'font-extrabold text-base'}>
        {t('lessons.photo.title')}
      </div>
      <div className={'w-6'} />
    </div>
  );
};

export default PhotoHeader;
