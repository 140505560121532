const QuizCompleteSentenceAnswer = ({
  completions,
  answer,
  onClick,
}: {
  completions: string[];
  answer: string;
  onClick?: () => void;
}) => {
  const isSelected = completions.includes(answer);
  if (isSelected) {
    return (
      <div
        className={
          'px-4 py-2 rounded-lg bg-structural-lighter border-2 border-structural-lighter text-structural-neutral'
        }
      >
        {answer}
      </div>
    );
  }
  return (
    <div
      className={
        'px-4 py-2 rounded-lg bg-white border-2 border-structural-lighter text-structural-darker cursor-pointer'
      }
      onClick={onClick}
    >
      {answer}
    </div>
  );
};

export default QuizCompleteSentenceAnswer;
