const QuizFillBlankAnswer = ({
  answer,
  onClick,
  isCorrect,
  questionEnded,
  isSelected,
}: {
  answer: {
    value?: string;
  };
  isCorrect?: boolean;
  questionEnded?: boolean;
  onClick: () => void;
  isSelected: boolean;
}) => {
  const getClassname = () => {
    let classnames = 'border-red-light text-red-light';
    if (!questionEnded) {
      classnames = 'border-white text-white';
    }
    if (answer.value && isCorrect) {
      classnames = 'border-green-light text-green-light';
    }
    return classnames;
  };

  return (
    <span
      onClick={onClick}
      className={`min-w-16 w-auto mx-2 my-1 px-1 rounded inline-block align-middle h-8 min-h-8 ${isSelected ? 'border-2' : 'border'} ${getClassname()}`}
    >
      <span className={'font-bold text-xl flex items-center justify-center'}>
        {answer.value}
      </span>
    </span>
  );
};

export default QuizFillBlankAnswer;
