import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ParentChildDevice, {
  DeviceType,
} from 'src/components/Onboarding/ParentChildDevice';
import { usePaywall } from 'src/contexts/PaywallContext';
import useEndOnboardingParentAndSwitchToChildOnboarding from 'src/hooks/useEndOnboardingParentAndSwitchToChildOnboarding';
import Spinner from 'src/components/common/designSystem/Spinner';

const OnboardingParentChildDevice = () => {
  const { childId } = useParams();
  const navigate = useNavigate();
  const { isPremium } = usePaywall();
  const [searchParams] = useSearchParams();
  const { endOnboarding, isEnding } =
    useEndOnboardingParentAndSwitchToChildOnboarding({
      childId: Number(childId),
    });

  const onSubmit = async (deviceType: DeviceType) => {
    if (deviceType === DeviceType.other) {
      return navigate(
        `/onboarding/parent/child/${childId}/school_level/device/other?${searchParams.toString()}`,
      );
    }
    if (!isPremium) {
      return navigate(
        `/onboarding/parent/child/${childId}/school_level/device/paywall?${searchParams.toString()}`,
      );
    }
    await endOnboarding();
  };

  if (isEnding) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return <ParentChildDevice childId={Number(childId)} onSubmit={onSubmit} />;
};

export default OnboardingParentChildDevice;
