import NotificationsIconSVG from 'src/images/notifications.svg?react';
import { useGetUnreadNotifications } from 'src/queries/notifications';

const NotificationsIcon = (props: { className: string }) => {
  const { data: unreadNotifications } = useGetUnreadNotifications();
  return (
    <div className={'relative'}>
      <NotificationsIconSVG className={props.className} />
      {unreadNotifications &&
      unreadNotifications.pages.length &&
      unreadNotifications.pages[0].items.length ? (
        <div
          data-testid={'unread-notifications'}
          className={
            'absolute top-0 right-0 rounded-full w-2 h-2 bg-red-darkest'
          }
        />
      ) : null}
    </div>
  );
};

export default NotificationsIcon;
