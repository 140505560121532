import { PropsWithChildren, useEffect, useState } from 'react';
import { SafeArea } from 'antd-mobile';

export default function Footer({ children }: PropsWithChildren) {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    document.body.style.setProperty('--footer-height', `${height}px`);
  }, [height]);

  return (
    <>
      <div style={{ marginBottom: height }} />
      <div
        style={{
          bottom: 0,
        }}
        className={
          'flex flex-col items-center bg-white absolute w-full z-[1002] max-w-screen-lg left-1/2 transform -translate-x-1/2'
        }
      >
        <div
          className={'flex flex-col items-center gap-1 px-2 py-3 w-full'}
          ref={el => {
            if (el) setHeight(el.clientHeight);
          }}
        >
          {children}
        </div>
        <SafeArea position={'bottom'} />
      </div>
    </>
  );
}
