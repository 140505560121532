import { PropsWithChildren } from 'react';
const Title = ({
  children,
  className,
}: PropsWithChildren & { className?: string }) => {
  return (
    <div
      className={
        'h2 whitespace-pre-line text-balance text-center py-6 ' + className
      }
    >
      {children}
    </div>
  );
};

export default Title;
