import { Lesson } from 'src/types/lesson.types';

export const lessonIsProcessing = (lesson?: Lesson) => {
  if (!lesson) return false;

  return lesson.lesson_pieces?.some(
    piece =>
      !piece.post_process_completed &&
      (piece.transcript_job_id || piece.transcript_job_id),
  );
};

export const getAddLessonPieceURL = (lessonId: number): string => {
  return `/lessons/${lessonId}/pieces/add`;
};
