import { useState } from 'react';
import CloseIcon from 'src/images/close.svg?react';
import QuizConfirmQuit from 'src/components/Quiz/QuizConfirmQuit';

const QuizBackButton = () => {
  const [openCancelQuiz, setOpenCancelQuiz] = useState(false);

  const onBackClick = () => {
    setOpenCancelQuiz(true);
  };

  return (
    <>
      <div className={'p-3 text-white'} onClick={onBackClick}>
        <CloseIcon className={'fill-white w-4 h-4'} data-testid={'btn-back'} />
      </div>
      <QuizConfirmQuit
        open={openCancelQuiz}
        onClose={() => {
          setOpenCancelQuiz(false);
        }}
      />
    </>
  );
};

export default QuizBackButton;
