import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { Poll } from 'src/types/poll.types';

export const useGetPoll = () => {
  return useQuery<Poll | null>({
    queryKey: ['poll'],
    queryFn: async () => {
      const { data } = await axios.get<Poll>('/api/polls/latest');
      return data;
    },
  });
};

export const usePollVote = ({ id }: { id: number }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ answerId }: { answerId: number }) => {
      const { data } = await axios.post(`/api/polls/${id}/vote`, {
        poll_answer_id: answerId,
      });
      return data as Poll;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['poll']);
    },
  });
};
