import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import Button from 'src/components/common/designSystem/Button';
import Input from 'src/components/common/designSystem/Input';
import { useNotification } from 'src/contexts/NotificationContext';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import Footer from 'src/components/common/designSystem/Footer';

import { Child, UserType } from 'src/types/user.types';
import { reportError } from 'src/modules/logs/Sentry';
import useUpsertUser from 'src/components/UserManagement/ChildForm/useUpsertUser.hook';

const ChildFirstnameStep = ({
  child,
  onFinish,
  isMe,
}: {
  child?: Child;
  onFinish: (child: Child) => void;
  isMe?: boolean;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const tNamespace = isMe
    ? 'onboarding.child.firstname'
    : 'onboarding.parent.child.firstname';

  const { upsertUser, isUpserting } = useUpsertUser();

  const saveFirstname = async ({ firstname }: { firstname: string }) => {
    const childId = child?.id;
    try {
      const createdChild = (await upsertUser({
        id: childId,
        firstname,
        user_type: UserType.child,
      })) as Child;
      onFinish(createdChild);
      return;
    } catch (error) {
      reportError('Fail to upsert child with firstname', error);
      showError({
        error,
        message: t(`${tNamespace}.fail`),
      });
    }
  };

  return (
    <>
      <Form
        name="childFirstnameForm"
        onFinish={saveFirstname}
        className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3"
        disabled={isUpserting}
        initialValues={{ firstname: child?.firstname }}
      >
        <OnboardingTitle title={t(`${tNamespace}.title`)} />
        <Form.Item
          name="firstname"
          className="w-full text-center"
          rules={[
            {
              required: true,
              message: t('global.validation.fieldRequired'),
            },
          ]}
        >
          <Input
            data-testid="inp-firstname"
            className="!border-none !bg-transparent !shadow-none text-center !text-2xl font-bold"
            placeholder={t(`${tNamespace}.label`)}
          />
        </Form.Item>
        <div />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="childFirstnameForm"
          type="primary"
          htmlType="submit"
          disabled={isUpserting}
          loading={isUpserting}
          className="w-full"
        >
          {t('onboarding.next')}
        </Button>
      </Footer>
    </>
  );
};

export default ChildFirstnameStep;
