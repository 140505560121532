import { LessonSmartbitQuestion } from 'src/types/lesson.types';

export interface Quiz {
  id: number;
  lesson_id: number;
  created_at: string;
  updated_at: string;
}

export interface QuizWithQuestions extends Quiz {
  questions: { question: LessonSmartbitQuestion }[];
}

export interface LessonQuiz extends QuizWithQuestions {
  runs: QuizRun[];
}

export interface QuizRun {
  id: number;
  user_id: number;
  quiz_id: number;
  grade: number;
  xp_won: number;
  is_record: boolean;
  started_at?: string;
  finished_at?: string;
  created_at?: string;
  updated_at?: string;
}

export interface QuizRunAnswer {
  id: number;
  run_id: number;
  quiz_question_id: number;
  answer: {
    given_answer?: Answer;
    correct_answer: Question['correct'];
  };
  time_to_answer_ms: number;
  is_correct: boolean;
  question: {
    question: LessonSmartbitQuestion;
  };
  created_at: string;
  updated_at: string;
}

export interface FinishedQuizRun {
  id: number;
  date: Date;
  grade: number;
}

export enum QuestionType {
  mcq = 'mcq',
  matching = 'matching',
  fill_blank = 'fill_blank',
  complete_sentence = 'complete_sentence',
}

export type MCQQuestion = {
  type: QuestionType.mcq;
  id: number;
  guideline: string;
  statement: string;
  correct: string[];
  incorrect: string[];
};
export type MatchingQuestion = {
  type: QuestionType.matching;
  id: number;
  guideline: string;
  correct: [string, string][];
};
export type FillBlankQuestion = {
  type: QuestionType.fill_blank;
  id: number;
  guideline: string;
  statement: string;
  correct: string[];
  incorrect: string[][];
};
export type CompleteSentenceQuestion = {
  type: QuestionType.complete_sentence;
  id: number;
  guideline: string;
  statement: string;
  correct: string[];
  incorrect: string[];
};
export type Question =
  | MCQQuestion
  | MatchingQuestion
  | FillBlankQuestion
  | CompleteSentenceQuestion;
export type Answer = string | string[] | [string, string][];
