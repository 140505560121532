import { Form } from 'antd';
import { InputPassword } from 'src/components/common/designSystem/Input';
import { useTranslation } from 'react-i18next';

const SetPasswordInputs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="password"
        validateTrigger="onBlur"
        className="w-full text-center"
        rules={[
          {
            required: true,
            message: t('global.validation.fieldRequired'),
          },
        ]}
      >
        <InputPassword
          autoComplete="current-password"
          data-testid="inp-password"
          prefix="🔒"
          placeholder={t('login.passwordLabel')}
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        validateTrigger="onBlur"
        dependencies={['password']}
        className="w-full text-center"
        rules={[
          {
            required: true,
            message: t('global.validation.fieldRequired'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t('login.signUp.confirmPasswordInvalid')),
              );
            },
          }),
        ]}
      >
        <InputPassword
          data-testid="inp-confirm-password"
          placeholder={t('login.signUp.confirmPasswordLabel')}
          prefix="🔒"
        />
      </Form.Item>
    </>
  );
};

export default SetPasswordInputs;
