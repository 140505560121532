import { useState } from 'react';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { List } from 'antd-mobile';
import QuizQuestionFlagMenuItem from 'src/components/Quiz/QuizQuestionFlagMenuItem';
import TroubleIcon from 'src/images/trouble.svg?react';

const QuizHeaderMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <div>
        <div className={'w-12 h-12 p-3 flex items-center justify-center'}>
          <TroubleIcon
            className={'w-full h-full fill-white'}
            data-testid="quiz-header-menu"
            onClick={() => {
              setIsMenuOpen(true);
            }}
          />
        </div>
      </div>
      <AutoFloatingPanel isOpen={isMenuOpen} onClose={onClose}>
        <List>
          <QuizQuestionFlagMenuItem onClick={onClose} />
        </List>
      </AutoFloatingPanel>
    </>
  );
};

export default QuizHeaderMenu;
