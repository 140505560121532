import { useCallback, useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { List } from 'antd-mobile';

import { quizQuestionFlagOptions } from 'src/utils/constants';
import { useUpdateQuestion } from 'src/queries/lessons';
import Button from 'src/components/common/designSystem/Button';
import { useQuiz } from 'src/components/Quiz/QuizContext';

export default function QuizQuestionFlagMenuItem({
  onClick,
}: {
  onClick: () => void;
}) {
  const {
    lesson,
    currentQuestion,
    onPause,
    onResume,
    onFlagQuestion,
    canFlagQuestions,
  } = useQuiz();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const closeTimeout = useRef<number | null>();
  const [flagSent, setFlagSent] = useState(false);

  const { mutateAsync, isLoading } = useUpdateQuestion();
  const handleSendFlag = async (flagOptionValue: string) => {
    if (!currentQuestion) {
      return;
    }
    await mutateAsync({
      lessonId: lesson.id!,
      question: {
        id: currentQuestion.id,
        user_flag: flagOptionValue,
      },
    });
    setFlagSent(true);
  };

  const handleBackToQuiz = useCallback(() => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    onFlagQuestion();
    setFlagSent(false);
    setOpen(false);
  }, [onFlagQuestion]);

  useEffect(() => {
    if (flagSent)
      closeTimeout.current = window.setTimeout(() => {
        handleBackToQuiz();
      }, 2000);
  }, [flagSent, handleBackToQuiz]);

  const handleClickOpen = () => {
    onClick();
    onPause();
    setOpen(true);
  };

  const handleClose = () => {
    onResume();
    setOpen(false);
  };

  if (!canFlagQuestions) {
    return null;
  }

  return (
    <>
      <List.Item onClick={handleClickOpen} data-testid={'item-flag-question'}>
        {t('quiz.flagQuestion.buttonLabel')}
      </List.Item>
      <Modal
        title={t('quiz.flagQuestion.title')}
        open={open}
        onCancel={handleClose}
        cancelButtonProps={{ disabled: isLoading }}
        footer={null}
      >
        {flagSent ? (
          <div className="flex flex-col items-center justify-center h-full w-full gap-4">
            <div className="text-xl font-semibold">
              {t('quiz.flagQuestion.flagSent')}
            </div>
            <Button
              type="primary"
              onClick={handleBackToQuiz}
              data-testid={'btn-back-quiz'}
            >
              {t('quiz.flagQuestion.backToQuiz')}
            </Button>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {Object.values(quizQuestionFlagOptions()).map(flagOption => (
              <Button
                key={flagOption.value}
                onClick={async () => {
                  await handleSendFlag(flagOption.value);
                }}
                data-testid={`flag-${flagOption.value}`}
                disabled={isLoading}
              >
                {flagOption.label}
              </Button>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
}
