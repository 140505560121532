import { getSubjectInfo } from 'src/utils/subject';
import { Lesson } from 'src/types/lesson.types';

const LessonCardIcon = ({ lesson }: { lesson: Lesson }) => {
  const subject = getSubjectInfo(lesson.subject_matter);

  return (
    <div className={'h-44 '}>
      <div
        className={
          'w-64 h-64 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
        }
      >
        <subject.IconComponent className={'w-full h-full -mt-6'} />
      </div>
    </div>
  );
};

export default LessonCardIcon;
