import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { User } from 'src/types/user.types';

export const useFetchSubscriptions = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.post<User>(`/api/user/subscriptions/fetch`);
      return data;
    },
    onSuccess: data => {
      queryClient.setQueryData(['user'], data);
    },
  });
};
