import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Capacitor } from '@capacitor/core';
import { isTestFlight } from 'src/modules/ios';
import Button from 'src/components/common/designSystem/Button';
import { useCurrentUser } from 'src/queries/user';

const BuildInfoPanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();
  const [currentBuild, setCurrentBuild] = useState<{
    version: string;
    versionNative: string;
    platform: string;
    isTestFlight: boolean;
  }>();

  const init = async () => {
    const currentBuild = await CapacitorUpdater.current();
    setCurrentBuild({
      version: currentBuild.bundle.version,
      versionNative: currentBuild.native,
      platform: Capacitor.getPlatform(),
      isTestFlight: await isTestFlight(),
    });
  };

  useEffect(() => {
    init();
  }, []);

  if (!currentBuild) {
    return null;
  }

  return (
    <AutoFloatingPanel isOpen={isOpen} onClose={onClose}>
      <div className={'p-4 whitespace-pre-line text-sm text-left'}>
        <div className={'mb-2'}>
          <div className={'font-bold text-base'}>
            {t('navbar.menu.buildInfo')}
          </div>
          <div>
            {t('build.info.userID')}: {user?.id}
          </div>
          <div>
            {t('build.info.familyID')}: {user?.family_id}
          </div>
          <div>
            {t('build.info.webVersion')}: {currentBuild.version}
          </div>
          <div>
            {t('build.info.appVersion')}: {currentBuild.versionNative}
          </div>
          <div>
            {t('build.info.platform')}: {currentBuild.platform}
          </div>
          <div>
            Is TestFlight: {currentBuild.isTestFlight ? 'true' : 'false'}
          </div>
          <div>Base URL: {String(import.meta.env.VITE_BACKEND_URL)}</div>
        </div>
        <Button type={'default'} onClick={onClose}>
          {t('global.back')}
        </Button>
      </div>
    </AutoFloatingPanel>
  );
};

export default BuildInfoPanel;
