import {
  CustomerInfo,
  LOG_LEVEL,
  Purchases,
  PurchasesEntitlementInfo,
} from '@revenuecat/purchases-capacitor';
import { Capacitor } from '@capacitor/core';
import { getAnalyticsDeviceId } from 'src/modules/analytics/Amplitude';
import {
  fakeSubscribePremiumWithoutTrial,
  fakeSubscribePremiumWithTrial,
} from 'src/modules/purchase/purchase.fake';
import {
  ANNUAL_ENTITLEMENT_ID,
  ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL,
  ANNUAL_OFFERING_ID,
  ANNUAL_OFFERING_ID_WITHOUT_TRIAL,
  MONTHLY_ENTITLEMENT_ID,
  MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL,
} from 'src/modules/purchase/purchase.constants';

export const initInAppPurchase = async () => {
  if (Capacitor.getPlatform() === 'web') {
    await Purchases.setMockWebResults({ shouldMockWebResults: true });
  }

  await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
  if (Capacitor.getPlatform() === 'ios') {
    await Purchases.configure({
      apiKey: 'appl_gfNrmAOtFfJjCadVHLxaSfxjzOj',
    });
  }
  if (Capacitor.getPlatform() === 'android') {
    await Purchases.configure({
      apiKey: 'goog_QXvQYkNfgDgpdvAieZIVpYgbRhz',
    });
  }
};

export const subscribePremiumWithTrial = async () => {
  if (import.meta.env.VITE_CI) {
    return fakeSubscribePremiumWithTrial();
  }

  const { all } = await Purchases.getOfferings();
  if (!all || !all[ANNUAL_OFFERING_ID]) {
    throw new Error('No offerings available');
  }

  return Purchases.purchasePackage({
    aPackage: all[ANNUAL_OFFERING_ID].availablePackages[0],
  });
};

export const subscribePremiumWithoutTrial = async () => {
  if (import.meta.env.VITE_CI) {
    return fakeSubscribePremiumWithoutTrial();
  }

  const { all } = await Purchases.getOfferings();
  if (!all || !all[ANNUAL_OFFERING_ID_WITHOUT_TRIAL]) {
    throw new Error('No offerings available');
  }
  return Purchases.purchasePackage({
    aPackage: all[ANNUAL_OFFERING_ID_WITHOUT_TRIAL].availablePackages[0],
  });
};

export const logInAppPurchase = async (user: {
  id: number;
  family_id: number;
}): Promise<CustomerInfo | undefined> => {
  if (!user.family_id || !user.id) {
    return undefined;
  }
  const { customerInfo } = await Purchases.logIn({
    appUserID: user.family_id.toString(),
  });
  await Purchases.setAttributes({
    userID: user.id.toString(),
    familyID: user.family_id.toString(),
    $amplitudeDeviceId: getAnalyticsDeviceId() ?? '',
    $amplitudeUserId: user.id.toString(),
    $branchId: user.id.toString(),
    isFakeUser: Boolean(import.meta.env.VITE_CI).toString(),
    isStaging: (!Boolean(import.meta.env.PROD)).toString(),
    isProd: Boolean(import.meta.env.PROD).toString(),
  });
  await Purchases.invalidateCustomerInfoCache();

  return customerInfo;
};

export const logOutInAppPurchase = async () => {
  try {
    const { isAnonymous } = await Purchases.isAnonymous();
    if (isAnonymous) {
      return;
    }
    await Purchases.logOut();
  } catch (e) {}
};

export const getActiveSubscription = (
  customerInfo?: CustomerInfo,
): PurchasesEntitlementInfo | undefined => {
  if (!customerInfo) {
    return undefined;
  }
  if (customerInfo.entitlements.active[MONTHLY_ENTITLEMENT_ID]) {
    return customerInfo.entitlements.active[MONTHLY_ENTITLEMENT_ID];
  }
  if (customerInfo.entitlements.active[MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL]) {
    return customerInfo.entitlements.active[
      MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL
    ];
  }
  if (customerInfo.entitlements.active[ANNUAL_ENTITLEMENT_ID]) {
    return customerInfo.entitlements.active[ANNUAL_ENTITLEMENT_ID];
  }
  if (customerInfo.entitlements.active[ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL]) {
    return customerInfo.entitlements.active[
      ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL
    ];
  }
  return undefined;
};

export const getPurchaseUserId = async () => {
  const { appUserID } = await Purchases.getAppUserID();
  return appUserID;
};
