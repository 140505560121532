import { useEffect, useState } from 'react';
import QuizStarsIcon from 'src/components/Quiz/QuizStarsIcon';

const QuizMatchingAnswer = ({
  isSelected,
  isCorrect,
  showCorrection,
  onClick,
  id,
}: {
  showCorrection: boolean;
  onClick?: () => void;
  isSelected: boolean;
  isCorrect: boolean;
  id: string;
}) => {
  const [showStars, setShowStars] = useState(false);

  const getAnswerColor = () => {
    if (showCorrection) {
      if (isCorrect) {
        return 'bg-secondary-lighter border-secondary-lighter';
      } else if (isSelected) {
        return 'bg-red-lightest border-red-lightest';
      }
      return 'bg-white border-secondary-lighter';
    }
    if (isSelected) {
      return 'bg-secondary-lighter border-secondary-lighter';
    }
    return 'bg-white border-secondary-lighter';
  };

  useEffect(() => {
    if (isCorrect && showCorrection) {
      setShowStars(true);
      setTimeout(() => {
        setShowStars(false);
      }, 2000);
    }
  }, [isCorrect, showCorrection]);

  return (
    <div
      data-testid={`answer-${id}`}
      onClick={onClick}
      className={`flex items-center relative text-base text-balance gap-4 text-structural-darker font-extrabold text-center justify-between cursor-pointer px-4 min-h-16 h-16 rounded-lg border ${getAnswerColor()}`}
    >
      <div className={'overflow-ellipsis line-clamp-2 w-full overflow-hidden'}>
        {id}
      </div>
      {showStars ? <QuizStarsIcon /> : null}
    </div>
  );
};

export default QuizMatchingAnswer;
