import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import ParentChildOtherDevice from 'src/components/Onboarding/ParentChildOtherDevice';

const InvitationPanel = ({
  childId,
  isOpen,
  onClose,
}: {
  childId: number;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <AutoFloatingPanel isOpen={isOpen} onClose={onClose}>
      {isOpen ? (
        <ParentChildOtherDevice childId={childId} onFinish={onClose} />
      ) : null}
    </AutoFloatingPanel>
  );
};

export default InvitationPanel;
