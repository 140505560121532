import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from '../../common/AutoFloatingPanel';
import StreakDay from '../modal/StreakDay';
import useStreaksDetails from './useStreaksDetails';

const StreaksDetails = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const {
    days,
    isStreakCompleted,
    previousWeek,
    nextWeek,
    nextWeekAvailable,
    currentLastDay,
  } = useStreaksDetails();

  const middleDay = currentLastDay.subtract(3, 'day');

  return (
    <AutoFloatingPanel
      data-testid={'panel-streaks-details'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div
        className={
          'flex flex-col justify-center text-center gap-3 py-5 px-2 normal-case'
        }
      >
        <div className={'capitalize'}>
          {middleDay.locale('fr').format('dddd DD MMMM YYYY')}
        </div>
        <div
          className={
            'flex flex-row gap-4 justify-center items-center border-b-primary-lighter border-b-2 pb-3'
          }
        >
          <div
            className={'flex-1 flex justify-center items-center'}
            onClick={previousWeek}
          >
            <IconMdiChevronLeft />
          </div>
          {days.map((day, index) => (
            <StreakDay
              key={index}
              day={day}
              size={'small'}
              withFlame
              isCompleted={isStreakCompleted(day)}
            />
          ))}
          {nextWeekAvailable ? (
            <div
              className={'flex-1 flex justify-center items-center'}
              onClick={nextWeek}
            >
              <IconMdiChevronRight />
            </div>
          ) : (
            <div className={'flex-1'} />
          )}
        </div>
        <div className={'text-xs'}>{t('home.streak.descriptionDetails')}</div>
      </div>
    </AutoFloatingPanel>
  );
};

export default StreaksDetails;
