import { useTranslation } from 'react-i18next';
import { getSubjectInfo } from 'src/utils/subject';
import { LessonSubject } from 'src/types/lesson.types';

export default function SubjectListItem({
  subject,
  className,
  onClick,
}: {
  subject: LessonSubject;
  className?: string;
  onClick?: () => void;
}) {
  const { t } = useTranslation();
  const selectedSubject = getSubjectInfo(subject);

  if (!selectedSubject) return null;

  return (
    <div
      data-testid={`item-${subject}`}
      onClick={onClick}
      className={
        'rounded-lg p-3 bg-secondary-lighter flex items-center justify-space ' +
        className
      }
    >
      <div className={'w-full flex flex-row gap-3 justify-start items-center'}>
        <div className={'w-10 h-10 flex justify-center items-center'}>
          <selectedSubject.IconSmallComponent className={'w-full h-full'} />
        </div>
        <div className="font-extrabold text-secondary-darker text-base capitalize">
          {t(selectedSubject.labelKey)}
        </div>
      </div>
      {selectedSubject.isBeta && (
        <div className="rounded-lg bg-primary-darkest top-2 left-2 text-white text-xs font-bold p-1">
          Beta
        </div>
      )}
    </div>
  );
}
