import { useTranslation } from 'react-i18next';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { subjects } from 'src/utils/constants';
import TrophyIcon from 'src/images/trophy.svg?react';
import QuizXP from 'src/components/Quiz/QuizXP';

const ChallengeFinishedNotificationCard = ({
  notification,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.CHALLENGE_FINISHED;
  };
}) => {
  const { t } = useTranslation();
  const params = notification.params;
  const subject = subjects[params.lesson_subject];
  const win = params.my_score > params.opponent_score;

  return (
    <div
      data-testid={`notification-challenge-finished-${params.lesson_id}`}
      className={'flex flex-row bg-white rounded-xl p-4 gap-4'}
    >
      <div>
        <div
          className={
            'w-12 h-12 rounded-full flex justify-center items-center bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to'
          }
        >
          {params.my_score === params.opponent_score ? (
            <div className={'text-2xl leading-none pt-1'}>🤷</div>
          ) : win ? (
            <TrophyIcon className={'w-6 h-6'} />
          ) : (
            <div className={'text-2xl leading-none pt-1'}>🙁</div>
          )}
        </div>
      </div>
      <div className={'flex flex-col w-full'}>
        <div className={'text-base font-bold text-structural-darkest'}>
          {t(
            params.my_score === params.opponent_score
              ? 'notifications.challenge_finished.equal'
              : win
                ? 'notifications.challenge_finished.win'
                : 'notifications.challenge_finished.lose',
            {
              opponentFirstName: params.opponent_firstname,
            },
          )}
        </div>
        <div className={'flex flex-row justify-between items-center'}>
          <div
            className={
              'font-semibold line-clamp-1 text-ellipsis overflow-hidden text-balance text-structural-neutral'
            }
          >
            {t(subject.labelKey)} • {notification.params.lesson_title}
          </div>
        </div>
      </div>
      {win ? <QuizXP xp={10} /> : null}
    </div>
  );
};

export default ChallengeFinishedNotificationCard;
