import { Mute } from '@capgo/capacitor-mute';
import { Capacitor } from '@capacitor/core';
import { NativeAudio } from '@capacitor-community/native-audio';

export const isMuted = async () => {
  if (!Capacitor.isNativePlatform()) {
    return false;
  }
  const { value: isMutedValue } = await Mute.isMuted();
  return isMutedValue;
};

export const playSound = async (assetId: string) => {
  if (await isMuted()) {
    return;
  }
  return NativeAudio.play({ assetId });
};
