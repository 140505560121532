import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';
import Revision from 'src/images/revision.svg?react';
import Improvements from 'src/images/improvements.svg?react';
import Progress from 'src/images/progress.svg?react';
import LessonLink from 'src/components/LessonDetail/LessonLink';
import LessonPieceError from 'src/components/LessonDetail/LessonPieceError';
import LessonBestScore from 'src/components/LessonDetail/LessonBestScore';
import LessonHeader from 'src/components/LessonDetail/LessonHeader';
import { LessonProvider } from 'src/components/LessonDetail/LessonContext';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import LessonFooter from 'src/components/LessonDetail/LessonFooter';

export default function Lesson() {
  const { t } = useTranslation();
  const { lessonId } = useParams();
  if (!lessonId) return <Navigate to="404" />;

  const lessonIdNumber = Number(lessonId);
  return (
    <LessonProvider lessonId={lessonIdNumber}>
      <LayoutWithHeader header={<LessonHeader />}>
        <div className={'flex flex-col gap-4'}>
          <LessonBestScore />
          <div className={'flex flex-col gap-2'}>
            <LessonLink
              title={t('lessons.details.revision')}
              link={`/lessons/${lessonId}/revision`}
              IconComponent={Revision}
              data-testid={'link-revision'}
            />
            <LessonLink
              title={t('lessons.details.improvements')}
              link={`/lessons/${lessonId}/improvements`}
              IconComponent={Improvements}
              data-testid={'link-improvements'}
            />
            <LessonLink
              title={t('lessons.details.progress')}
              link={`/lessons/${lessonId}/progress`}
              IconComponent={Progress}
              data-testid={'link-progress'}
            />
          </div>
        </div>

        <LessonPieceError />
        <LessonFooter />
      </LayoutWithHeader>
    </LessonProvider>
  );
}
