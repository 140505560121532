import { Link, useNavigate, useParams } from 'react-router-dom';

import LogoHorizontal from 'src/images/logoHorizontal.svg?react';
import LoginWithGoogleButton from 'src/components/Login/LoginWithGoogleButton';
import LoginWithAppleButton from 'src/components/Login/LoginWithAppleButton';
import Button from 'src/components/common/designSystem/Button';
import BlankLayout from 'src/layouts/BlankLayout';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ChallengeResult from 'src/components/Challenge/ChallengeResult';
import { Trans, useTranslation } from 'react-i18next';

export default function AnonymousChallengeLessonQuizResult() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lessonId, quizId, runId, challengeId } = useParams();
  const redirect = `/challenges/${challengeId}/lessons/${lessonId}/quizzes/${quizId}/runs/${runId}/result`;

  const onLogin = () => {
    navigate(`/onboarding?to=${redirect}`);
  };

  return (
    <BlankLayout
      appBackground={'bg-white'}
      statusBar={{
        color: '#FFFFFF',
        style: Style.Light,
      }}
    >
      <ChallengeResult
        challengeId={Number(challengeId)}
        quizId={Number(quizId)}
        runId={Number(runId)}
      />
      <div
        className={'fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50'}
      />
      <div
        className={
          'absolute overflow-hidden w-11/12 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-2xl'
        }
      >
        <div
          className={
            'bg-gradient-to-b from-paywall-darkest-from to-paywall-darkest-to flex items-center justify-center py-20'
          }
        >
          <LogoHorizontal className={'w-72 h-auto'} />
        </div>
        <div className="flex flex-col w-full justify-center items-center px-4 py-8 gap-8">
          <div className={'text-center text-balance font-bold text-2xl'}>
            {t('challenge.result.signup')}
          </div>
          <div
            className={'flex flex-col gap-2 w-full items-center justify-center'}
          >
            <LoginWithGoogleButton onLogin={onLogin} />
            <LoginWithAppleButton onLogin={onLogin} />
            <Button
              data-testid="btn-login-email"
              onClick={() => {
                navigate(`/login/email?to=${redirect}`);
              }}
            >
              {t('login.withEmail')}
            </Button>
          </div>
          <div
            className={
              'text-center text-sm text-structural-neutral font-bold text-balance w-40'
            }
          >
            <Trans i18nKey="login.signUp.cgu">
              En continuant, j’accepte
              <Link
                to={'/terms-and-conditions'}
                className={'text-structural-darkest font-semibold'}
              >
                les termes et conditions
              </Link>
            </Trans>
          </div>
        </div>
      </div>
    </BlankLayout>
  );
}
