import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateBack = ({ from }: { from?: string } = {}) => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(() => {
    if (from) {
      navigate(from);
      return;
    }
    if (location.state && location.state.from) {
      navigate(location.state.from);
      return;
    }
    if (location.key === 'default') {
      navigate('/');
      return;
    }
    navigate(-1);
  }, [navigate, location.key]);
};

export default useNavigateBack;
