import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useCurrentUser, useUpdateCurrentUser } from 'src/queries/user';
import { usePaywall } from 'src/contexts/PaywallContext';
import PaywallScreenDescription from 'src/components/Paywall/PaywallScreenDescription';
import { useNotification } from 'src/contexts/NotificationContext';

const StartTrialPanel = () => {
  const { t } = useTranslation();
  const { mutateAsync: saveCurrentUser, isLoading: isUpdating } =
    useUpdateCurrentUser();
  const { showError } = useNotification();
  const { isPremium } = usePaywall();
  const { data: user } = useCurrentUser();

  const isOpen = Boolean(
    !isPremium && user && user.onboarding_completed && !user.trial_ends_at,
  );

  useEffect(() => {
    if (isOpen) {
      logAnalyticsEvent('paywall_viewed', {
        type: 'onboarding_screen1_child',
      });
    }
  }, [isOpen]);

  const onContinue = async () => {
    try {
      await saveCurrentUser({
        onboarding_completed: true,
      });
      logAnalyticsEvent('trial_started');
    } catch (error) {
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  return (
    <AutoFloatingPanel
      data-testid={'panel-start-trial'}
      isOpen={isOpen}
      closable={false}
    >
      <PaywallScreenDescription
        onContinue={onContinue}
        isContinuing={isUpdating}
      />
    </AutoFloatingPanel>
  );
};

export default StartTrialPanel;
