import DeleteIcon from 'src/images/delete.svg?react';
import { PhotoStatus, usePhoto } from 'src/components/Photo/PhotoContext';

const DeletePhotoButton = () => {
  const { clearPhoto, status } = usePhoto();

  if (status !== PhotoStatus.PHOTO_READY) {
    return null;
  }

  return (
    <div
      className="flex items-center justify-center rounded-full bg-white h-16 w-16 cursor-pointer"
      onClick={clearPhoto}
      data-testid="btn-clear-photo"
    >
      <DeleteIcon className={'fill-structural-darkest'} />
    </div>
  );
};

export default DeletePhotoButton;
