import Card from 'src/components/common/designSystem/Card';
import StreakDay from './StreakDay';
import useStreakParentCard from 'src/components/Streak/modal/useStreakParentCard';
import { useTranslation } from 'react-i18next';
import CheckBaobabIcon from 'src/images/check-baobab.svg?react';
import { User } from 'src/types/user.types';

const StreakParentCard = ({ child }: { child: User }) => {
  const { t } = useTranslation();
  const { days, isStreakCompleted, streaks } = useStreakParentCard({ child });

  return (
    <Card className={'gap-5 p-6'} data-testid={'card-streak'}>
      <div className={'flex flex-row justify-between items-center w-full'}>
        <div className={'font-bold text-base text-structural-darkest'}>
          {t('settings.users.streaks.title')}
        </div>
        {streaks ? (
          <div className={'flex flex-row items-center gap-2'}>
            <CheckBaobabIcon className={'w-5 h-auto'} />
            <div className={'font-extrabold text-base text-structural-darkest'}>
              {streaks?.length}
            </div>
          </div>
        ) : null}
      </div>
      <div className={'flex flex-row gap-4 justify-between flex-1 w-full'}>
        {days.map((day, index) => (
          <StreakDay
            key={index}
            day={day}
            isCompleted={isStreakCompleted(day)}
          />
        ))}
      </div>
    </Card>
  );
};

export default StreakParentCard;
