import { useParams, Navigate } from 'react-router-dom';

import LessonRevision from 'src/components/LessonDetail/LessonRevision';
import { LessonProvider } from 'src/components/LessonDetail/LessonContext';

export default function LessonRevisionPage() {
  const { lessonId } = useParams<{ lessonId: string }>();
  if (!lessonId) {
    return <Navigate to="404" />;
  }

  const lessonIdNumber = Number(lessonId);
  return (
    <LessonProvider lessonId={lessonIdNumber}>
      <LessonRevision />
    </LessonProvider>
  );
}
