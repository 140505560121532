import { useEffect, useState } from 'react';
import AlwaysScrollToBottom from 'src/components/common/animations/AlwaysScrollToBottom';
import { Progress } from 'antd';
import SkipIcon from 'src/images/skip.svg?react';
import CheckIcon from 'src/images/check.svg?react';

const TextTyping = ({
  text,
  onFinish,
}: {
  text: string;
  onFinish?: () => void;
}) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (index < text.length) {
        setIndex(index + 1);
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [index, onFinish, text]);

  const finishTyping = () => {
    if (index >= text.length) {
      if (onFinish) {
        onFinish();
      }
      return;
    }
    setIndex(text.length);
  };

  const isTypingFinished = index >= text.length;

  return (
    <div
      className={
        'flex-1 flex flex-col items-center justify-between w-full overflow-hidden'
      }
    >
      <div
        className={
          'flex-1 w-full text-left text-xl font-bold py-8 overflow-hidden'
        }
      >
        <div className={'w-full h-full overflow-auto'}>
          {text.slice(0, index)}
          <AlwaysScrollToBottom />
        </div>
      </div>
      <div
        onClick={finishTyping}
        className={'flex'}
        data-testid={isTypingFinished ? 'btn-finish-typing' : 'btn-skip-typing'}
      >
        <Progress
          type="circle"
          strokeColor={'white'}
          percent={(index / text.length) * 100}
          size={80}
          format={() => (
            <div className={'flex items-center justify-center pl-1'}>
              {!isTypingFinished ? (
                <SkipIcon className={'fill-white stroke-white h-5 w-auto'} />
              ) : (
                <CheckIcon className={'fill-white h-5 w-auto'} />
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default TextTyping;
