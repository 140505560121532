import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ParentChildSchoolLevelStep from 'src/components/Onboarding/ParentChildSchoolLevelStep';

const ParentChildSchoolLevel = () => {
  const navigate = useNavigate();
  const { childId } = useParams();
  const onNext = () => {
    navigate(`/child/${childId}/school_level/device`);
  };

  return (
    <LayoutWithHeader
      header={<OnboardingHeader step={4} />}
      statusBar={<StatusBar color="#FAFAFB" style={Style.Light} />}
      className={'bg-structural-lightest'}
    >
      <ParentChildSchoolLevelStep childId={Number(childId)} onFinish={onNext} />
      <Outlet />
    </LayoutWithHeader>
  );
};

export default ParentChildSchoolLevel;
