import { useQuery } from 'react-query';
import axios from 'axios';
import { MilestoneType } from 'src/types/milestone.types';

type Milestones = Record<MilestoneType, number>;

export const useGetMilestones = () => {
  return useQuery<Milestones>({
    queryKey: ['milestones'],
    queryFn: async () => {
      const { data } = await axios.get('/api/user/milestones');
      return data;
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
  });
};
