import { forwardRef, LegacyRef } from 'react';
import { Input as AntdInput, type InputProps, InputRef } from 'antd';
import { type PasswordProps } from 'antd/lib/input';

const Input = (
  { prefix, className, ...props }: InputProps,
  ref: LegacyRef<InputRef>,
) => {
  return (
    <AntdInput
      {...props}
      ref={ref}
      prefix={prefix ? <span className={'mr-1'}>{prefix}</span> : undefined}
      classNames={{
        input:
          'placeholder-structural-neutral placeholder-opacity-100 text-base',
      }}
      className={
        'w-full min-h-12 text-base p-3 bg-white rounded-xl border border-gray-200 flex items-center ' +
        className
      }
    />
  );
};

export const InputPassword = ({
  prefix,
  className,
  ...props
}: PasswordProps) => {
  return (
    <AntdInput.Password
      {...props}
      prefix={prefix ? <span className={'mr-1'}>{prefix}</span> : undefined}
      classNames={{
        input:
          'placeholder-structural-neutral placeholder-opacity-100 text-base',
      }}
      className={
        'w-full min-h-12 text-base p-3 bg-white rounded-xl border border-gray-200 flex items-center ' +
        className
      }
    />
  );
};

export default forwardRef(Input);
