import { Swiper, SwiperRef } from 'antd-mobile';
import { useRef } from 'react';
import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const DiscoverChallengeSwiper = () => {
  const { t } = useTranslation();
  const ref = useRef<SwiperRef>(null);
  const navigate = useNavigate();

  const onFinish = () => {
    navigate('/home/lessons');
  };

  return (
    <Swiper
      allowTouchMove
      ref={ref}
      indicatorProps={{
        style: {
          '--active-dot-color': 'black',
        },
      }}
    >
      <Swiper.Item>
        <div
          className={
            'flex flex-col gap-6 items-center justify-between h-full p-8'
          }
        >
          <div className={'text-7xl'}>💪</div>
          <div className={'font-bold text-2xl text-center text-balance'}>
            {t('challenge.discover.panel.1.title')}
          </div>
          <div className={'text-base mb-3'}>
            {t('challenge.discover.panel.1.description')}
          </div>
          <Button
            type={'primary'}
            data-testid={'btn-next-first'}
            onClick={() => ref.current?.swipeNext()}
          >
            {t('global.next')}
          </Button>
        </div>
      </Swiper.Item>
      <Swiper.Item>
        <div
          className={
            'flex flex-col gap-6 items-start justify-between h-full p-8'
          }
        >
          <div className={'text-7xl'}>🎮</div>
          <div className={'font-bold text-2xl text-balance'}>
            {t('challenge.discover.panel.2.title')}
          </div>
          <div className={'flex flex-col gap-3 mb-3'}>
            <div className={'text-base'}>
              {t('challenge.discover.panel.2.description')}
            </div>
            <div className={'text-base'}>
              {t('challenge.discover.panel.2.description2')}
            </div>
          </div>
          <Button
            type={'primary'}
            data-testid={'btn-next-second'}
            onClick={() => ref.current?.swipeNext()}
          >
            {t('global.next')}
          </Button>
        </div>
      </Swiper.Item>
      <Swiper.Item>
        <div
          className={
            'flex flex-col gap-6 items-start justify-between h-full p-8'
          }
        >
          <div className={'text-7xl'}>🏆</div>
          <div className={'font-bold text-2xl text-balance'}>
            {t('challenge.discover.panel.3.title')}
          </div>
          <div className={'flex flex-col gap-3 mb-3'}>
            <div className={'text-base'}>
              {t('challenge.discover.panel.3.description')}
            </div>
            <div className={'text-base'}>
              {t('challenge.discover.panel.3.description2')}
            </div>
          </div>
          <Button
            type={'primary'}
            data-testid={'btn-choose-quiz'}
            onClick={onFinish}
          >
            {t('challenge.discover.panel.3.button')}
          </Button>
        </div>
      </Swiper.Item>
    </Swiper>
  );
};

export default DiscoverChallengeSwiper;
