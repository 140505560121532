import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { usePaywall } from 'src/contexts/PaywallContext';
import PrimaryCheckIcon from 'src/components/common/PrimaryCheckIcon';
import { useNavigate } from 'react-router-dom';
import { PURCHASES_ERROR_CODE } from '@revenuecat/purchases-capacitor';
import { useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { openWhatsAppSupport } from 'src/modules/support/support';
import DuePricing from 'src/components/Paywall/DuePricing';
import { reportError } from 'src/modules/logs/Sentry';
import AnnualOffer from 'src/components/Paywall/AnnualOffer';
import IconMdiChevronLeft from '~icons/mdi/chevron-left';

const PaywallScreenPay = () => {
  const { t } = useTranslation();
  const { subscribePremiumWithTrial, nbTrialDays } = usePaywall();
  const navigate = useNavigate();
  const [paying, setPaying] = useState(false);
  const { showError } = useNotification();

  const pay = async () => {
    try {
      setPaying(true);
      await subscribePremiumWithTrial();
    } catch (error: any) {
      if (error.code === PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        setPaying(false);
        return;
      }

      reportError('Fail to subscribe premium with trial', error);
      showError({
        error,
        message: t('paywall.error'),
      });
    }
    setPaying(false);
  };

  return (
    <div className={'flex flex-col p-5 gap-6'}>
      <div className={'flex flex-row justify-start items-center'}>
        <div
          className={'flex-1 flex justify-start items-center'}
          onClick={() => navigate(-1)}
        >
          <IconMdiChevronLeft fontSize={30} />
        </div>
      </div>

      <div
        className={
          'font-extrabold text-xl text-structural-darkest text-left text-balance'
        }
      >
        <Trans i18nKey="paywall.pay.title">
          <span
            className={
              'bg-gradient-to-r bg-clip-text text-transparent from-paywall-darkest-from to-paywall-darkest-to'
            }
          >
            Essai gratuit
          </span>{' '}
          de Baobab
        </Trans>
      </div>

      <div className={'flex flex-col gap-4 items-start'}>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PrimaryCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t('paywall.pay.item1', { nbTrialDays })}
          </div>
        </div>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PrimaryCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t('paywall.pay.item2')}
          </div>
        </div>
        <div className={'flex flex-row items-start justify-start gap-3'}>
          <div>
            <PrimaryCheckIcon />
          </div>
          <div className={'text-balance text-base text-structural-darkest'}>
            {t('paywall.pay.item3')}
          </div>
        </div>
      </div>

      <AnnualOffer />
      <DuePricing />

      <div className={'flex flex-col w-full gap-2'}>
        <Button
          data-testid={'btn-pay-trial'}
          type={'primary'}
          onClick={pay}
          loading={paying}
        >
          {t('paywall.pay.button')}
        </Button>
        <Button
          data-testid="btn-contact-us"
          type={'link'}
          onClick={openWhatsAppSupport}
        >
          {t('paywall.pay.contactUs')}
        </Button>
      </div>

      <PaywallCGU />
    </div>
  );
};

export default PaywallScreenPay;
