import StarsIcon from 'src/images/stars.svg?react';
import './QuizStars.css';

const QuizStarsIcon = () => {
  return (
    <div className={'absolute -top-4 -right-3'}>
      <StarsIcon className={'stars'} />
    </div>
  );
};

export default QuizStarsIcon;
