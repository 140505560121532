import { useTranslation } from 'react-i18next';

const QuizNewRecord = () => {
  const { t } = useTranslation();
  return (
    <div
      className={
        'rounded-lg px-2 py-1 bg-structural-darkest text-white font-bold uppercase'
      }
    >
      {t('quiz.result.newRecord')}
    </div>
  );
};

export default QuizNewRecord;
