import { type MouseEventHandler, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from 'src/components/common/designSystem/Avatar';
import { useCurrentUser } from 'src/queries/user';
import UserItemMenu from 'src/components/UserManagement/UserItemMenu';
import { User } from 'src/types/user.types';

interface Props {
  user: User;
  onClick?: MouseEventHandler<HTMLDivElement>;
  icon?: ReactElement | false;
  className?: string;
}

const UserItem = ({ user, icon, className, onClick }: Props) => {
  const { data: currentUser } = useCurrentUser();
  const { t } = useTranslation();
  const isCurrentUser = currentUser?.id === user.id;

  return (
    <div
      className={`p-3 flex items-center justify-between bg-white rounded-xl w-full ${className}`}
      onClick={onClick}
      data-testid={`user-${user.firstname}-${user.lastname ?? ''}`}
    >
      <div className="flex items-center gap-4">
        <Avatar name={user.firstname} />
        {user.firstname && user.firstname.length > 0
          ? user.firstname
          : t(`users.userTypes.${user.user_type}`)}
      </div>
      <div className="flex items-center gap-2">
        <span className="text-2xl">
          {icon ??
            (isCurrentUser && (
              <IconMdiCheck className="text-primary-darker text-xl" />
            ))}
        </span>
        {!isCurrentUser && <UserItemMenu user={user} />}
      </div>
    </div>
  );
};

export default UserItem;
