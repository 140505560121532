import { useTranslation } from 'react-i18next';
import { useNotification } from 'src/contexts/NotificationContext';
import { useUpdateLesson } from 'src/queries/lessons';
import dayjs from 'dayjs';
import DatePicker from 'src/components/common/designSystem/DatePicker';
import { reportError } from 'src/modules/logs/Sentry';

const AddLessonEvaluationDate = ({
  isOpen,
  onClose,
  lessonId,
}: {
  isOpen: boolean;
  onClose: () => void;
  lessonId: number;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const { mutateAsync: updateLesson } = useUpdateLesson();

  const sendEvaluationDate = async (date: Date) => {
    try {
      await updateLesson({
        id: lessonId,
        evaluation_date: dayjs(date).format('YYYY-MM-DD'),
      });
      onClose();
    } catch (error) {
      reportError('Fail to add evaluation date', error);
      showError({
        error,
        message: t('lessons.evaluation.failToUpdate'),
      });
    }
  };

  return (
    <DatePicker
      data-testid={'panel-add-evaluation-date'}
      title={t('lessons.evaluation.add')}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={sendEvaluationDate}
      confirmText={t('global.continue')}
      cancelText={t('onboarding.skip')}
    />
  );
};

export default AddLessonEvaluationDate;
