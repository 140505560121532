import { useSearchParams } from 'react-router-dom';
import BackArrowHeader from 'src/components/Header/BackArrowHeader';
import BlankLayout from 'src/layouts/BlankLayout';
import ResetPasswordSetPassword from 'src/components/Login/ResetPassword/ResetPasswordSetPassword';
import ResetPasswordSendEmail from 'src/components/Login/ResetPassword/ResetPasswordSendEmail';

export default function ResetPassword() {
  const [searchParams] = useSearchParams();

  const oobCode = searchParams.get('oobCode');

  return (
    <BlankLayout>
      <BackArrowHeader />
      {oobCode ? (
        <ResetPasswordSetPassword oobCode={oobCode} />
      ) : (
        <ResetPasswordSendEmail />
      )}
    </BlankLayout>
  );
}
