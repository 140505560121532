import axios, { type AxiosError } from 'axios';
import { QueryClient, type QueryFunctionContext } from 'react-query';

const defaultQueryFn = async ({ queryKey }: QueryFunctionContext) => {
  const { data } = await axios.get(
    `${import.meta.env.VITE_BACKEND_URL}/api/${queryKey[0] as string}${queryKey[1] ? `/${queryKey[1] as string | number}` : ''}`,
  );
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      retry: (failureCount: number, error) => {
        if (
          (error as AxiosError)?.response?.status === 404 ||
          (error as AxiosError)?.response?.status === 401 ||
          (error as AxiosError)?.response?.status === 403
        )
          return false;

        if (failureCount >= 3) return false;

        return true;
      },
    },
  },
});
