import { Form } from 'antd';
import Input from 'src/components/common/designSystem/Input';
import { useTranslation } from 'react-i18next';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const EmailInput = () => {
  const { t } = useTranslation();

  return (
    <Form.Item
      name="email"
      validateTrigger="onBlur"
      className="w-full text-center"
      noStyle
      rules={[
        {
          required: true,
          message: t('global.validation.fieldRequired'),
        },
        {
          pattern: EMAIL_REGEX,
          message: t('login.emailInvalid'),
        },
      ]}
    >
      <Input
        autoComplete="email"
        data-testid="inp-email"
        prefix="✉️"
        placeholder={t('login.emailLabel')}
      />
    </Form.Item>
  );
};

export default EmailInput;
