export default {
  title: 'Saisissez vos identifiants',
  welcome: 'Bienvenue sur Baobab !',
  introduction:
    "Apprendre ses leçons : maintenant c'est super facile et amusant !",
  login: "J'ai déjà un compte",
  signIn: 'Connexion',
  createAccount: 'Je crée mon compte',
  emailLabel: 'Adresse email',
  passwordLabel: 'Mot de passe',
  emailRequired: "Renseignez l'adresse email",
  emailInvalid: 'Adresse email invalide',
  passwordRequired: 'Renseignez le mot de passe',
  emailIsVerified: 'Votre email est vérifié',
  withGoogle: 'Continuer avec Google',
  withApple: 'Continuer avec Apple',
  withEmail: 'Continuer avec mon e-mail',
  resetPassword: {
    goToForgotPassword: "J'ai oublié mon mot de passe",
    title: 'Réinitialiser son mot de passe',
    startResetPassword: 'Réinitialiser mon mot de passe',
    sentEmailSuccess:
      "Un email a été envoyé à l'adresse {{email}} avec un lien pour réinitialiser votre mot de passe.",
    passwordResetSuccess:
      'Le mot de passe a bien été réinitialisé ! Vous pouvez maintenant vous connecter avec ce dernier.',
    resetMyPassword: 'Réinitialiser mon mot de passe',
    confirmed: {
      title: 'Votre mot de passe a bien été modifié',
      description:
        'Vous pouvez fermer cette fenêtre et vous connecter à votre compte sur l’application Baobab.',
    },
  },
  signUp: {
    goToSignup: 'Créer un compte',
    title: `Avant de commencer,
un <u>parent</u> doit créer le compte :`,
    buttonLabel: "S'inscrire",
    emailLabel: 'email du parent',
    firstnameLabel: 'Prenom',
    confirmPasswordLabel: 'Confirmation du mot de passe',
    confirmPasswordRequired: 'Renseignez la confirmation du mot de passe',
    passwordMinCharacters: 'Minimum 8 caractères',
    password1Uppercase: '1 majuscule',
    password1Number: '1 chiffre',
    confirmPasswordInvalid: 'La confirmation est différente du mot de passe',
    cgu: 'En continuant, j’accepte <1>les termes et conditions</1>',
  },
  familyUserSelect: {
    letsGo: "C'est parti !",
    welcomeWhoAreYou: 'Bienvenue sur Baobab 👋\nQui est-ce ?',
  },
  errors: {
    emailAlreadyInUse: 'Un compte existe avec cet email.',
    userNotFound: "Aucun compte n'existe avec cet email. Inscrivez vous !",
    wrongPassword: "L'email ou le mot de passe n'est pas correct.",
    defaultError: "L'authentification a échoué, réessayez ou contactez nous.",
    linkExpired: 'Le lien a expiré. Veuillez réessayer.',
  },
};
