import { useTranslation } from 'react-i18next';
import Milestone from 'src/components/Milestones/Milestone';
import Lessons from 'src/images/milestones/lessons.svg?react';

const MilestoneLessons = ({ count }: { count: number }) => {
  const { t } = useTranslation();
  return (
    <Milestone
      illustration={<Lessons />}
      title={
        count >= 50
          ? t('milestones.lessons.lesson50')
          : count >= 40
            ? t('milestones.lessons.lesson40')
            : count >= 30
              ? t('milestones.lessons.lesson30')
              : count >= 20
                ? t('milestones.lessons.lesson20')
                : count >= 10
                  ? t('milestones.lessons.lesson10')
                  : t('milestones.lessons.lesson5')
      }
    />
  );
};

export default MilestoneLessons;
