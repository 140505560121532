import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import QuizCompleteSentenceCompletion from 'src/components/Quiz/CompleteSentenceQuestion/QuizCompleteSentenceCompletion';
import { CompleteSentenceQuestion } from 'src/types/quiz.types';

const QuizCompleteSentenceCompletions = ({
  completions,
  question,
  onClickCompletion,
}: {
  question: CompleteSentenceQuestion;
  completions: string[];
  onClickCompletion: (index: number) => void;
}) => {
  const { status } = useQuiz();

  return completions.map((answer, index) => (
    <QuizCompleteSentenceCompletion
      key={index}
      answer={answer}
      onClick={
        status === QuestionStatus.WAITING
          ? () => {
              onClickCompletion(index);
            }
          : undefined
      }
      isCorrect={question.correct[index] === answer}
      showResult={
        status !== QuestionStatus.WAITING && status !== QuestionStatus.PAUSED
      }
    />
  ));
};

export default QuizCompleteSentenceCompletions;
