import { useTranslation } from 'react-i18next';

import ProfileMenu from 'src/components/Profile/ProfileMenu';
import BackArrowHeader from 'src/components/Header/BackArrowHeader';
import BlankLayout from 'src/layouts/BlankLayout';

const ChildSettingsPage = () => {
  const { t } = useTranslation();

  return (
    <BlankLayout>
      <div className={'flex flex-col text-structural-darkest gap-2 py-2'}>
        <div className={'flex flex-row items-center'}>
          <BackArrowHeader />
          <div className={'text-2xl font-bold text-structural-darkest'}>
            {t('profile.title')}
          </div>
        </div>
        <div className={'px-6'}>
          <ProfileMenu />
        </div>
      </div>
    </BlankLayout>
  );
};

export default ChildSettingsPage;
