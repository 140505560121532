import { useNavigate, useParams } from 'react-router-dom';
import ParentChildDevice, {
  DeviceType,
} from 'src/components/Onboarding/ParentChildDevice';
import useSwitchToChildOnboarding from 'src/hooks/useSwitchToChildOnboarding';
import Spinner from 'src/components/common/designSystem/Spinner';

const ParentChildDevicePage = () => {
  const { childId } = useParams();
  const navigate = useNavigate();
  const { switchToChildOnboarding, isSwitching } = useSwitchToChildOnboarding({
    childId: Number(childId),
  });

  const onSubmit = async (deviceType: DeviceType) => {
    if (deviceType === DeviceType.other) {
      return navigate(`/child/${childId}/school_level/device/other`);
    }
    await switchToChildOnboarding();
  };

  if (isSwitching) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return <ParentChildDevice childId={Number(childId)} onSubmit={onSubmit} />;
};

export default ParentChildDevicePage;
