export default {
  title: 'Notifications',
  empty: {
    title: 'Une évaluation à venir ?',
    description: 'Révise avec un quiz',
  },
  quiz_ready: {
    title: 'Ta révision est prête !',
    button: 'Démarrer',
  },
  challenge_finished: {
    win: 'Tu as gagné ton duel contre {{opponentFirstName}} !',
    lose: 'Tu as perdu ton duel contre {{opponentFirstName}}...',
    equal: 'Egalité contre {{opponentFirstName}}...',
  },
  referral_reward: {
    title: '{{firstName}} a rejoint Baobab +50 XP',
  },
};
