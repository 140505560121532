import { useTranslation } from 'react-i18next';
import useNavigateBack from 'src/hooks/useNavigateBack';
import { SafeArea } from 'antd-mobile';
import { LeftOutline } from 'antd-mobile-icons';

const OnboardingHeader = ({
  step,
  maxStep = 5,
  hideBack,
  from,
}: {
  step: number;
  maxStep?: number;
  hideBack?: boolean;
  from?: string;
}) => {
  const { t } = useTranslation();
  const goBack = useNavigateBack({ from });

  return (
    <>
      <SafeArea position="top" className="bg-white" />
      <div className={'flex flex-row justify-between items-center px-4 py-2'}>
        {hideBack ? (
          <div />
        ) : (
          <div onClick={goBack}>
            <LeftOutline fontSize={24} data-testid={'btn-back'} />
          </div>
        )}
        <div
          className={
            'font-bold text-base rounded-2xl py-3 px-5 bg-black bg-opacity-10'
          }
        >
          {t('onboarding.steps', { step, maxStep })}
        </div>
        {hideBack ? <div /> : <div className={'w-6'} />}
      </div>
    </>
  );
};

export default OnboardingHeader;
