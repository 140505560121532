import { useRef, useEffect, useState } from 'react';
const Chronometer = ({ isPlaying }: { isPlaying?: boolean }) => {
  const [seconds, setSeconds] = useState<number>(0);
  const interval = useRef<number | null>(null);

  useEffect(() => {
    if (!isPlaying && interval.current) {
      clearInterval(interval.current);
      return;
    }

    interval.current = window.setInterval(() => {
      if (isPlaying) setSeconds(seconds => seconds + 1);
    }, 1000);

    return () => {
      if (interval.current) clearInterval(interval.current);
    };
  }, [isPlaying]);

  return (
    Math.floor(seconds / 60) + ':' + (seconds % 60).toString().padStart(2, '0')
  );
};

export default Chronometer;
