export default {
  http: {
    400: 'Paramètres invalides, veuillez vérifier les informations saisies',
    401: 'Non autorisé',
    403: 'Accès refusé',
    404: "L'élément est introuvable",
    422: 'Échec de la validation, veuillez vérifier les informations saisies',
    500: 'Une erreur est survenue sur le serveur, veuillez nous contacter si le problème persiste',
    503: 'Le service est indisponible, veuillez nous contacter si le problème persiste',
    504: 'Le temps de réponse maximum a été dépassé, veuillez nous contacter si le problème persiste',
    default:
      'Une erreur est survenue, veuillez nous contacter si le problème persiste',
  },
};
