import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import {
  FloatingPanel,
  type FloatingPanelProps,
  type FloatingPanelRef,
  Mask,
  SafeArea,
} from 'antd-mobile';
import { useResizeDetector } from 'react-resize-detector';
import { createPortal } from 'react-dom';

const FLOATING_HEADER_HEIGHT = 28;

export const AutoFloatingPanel = ({
  onClose,
  closable = true,
  'data-testid': dataTestId,
  ...props
}: Omit<FloatingPanelProps, 'anchors'> & {
  isOpen: boolean;
  onClose?: () => void;
  closable?: boolean;
  'data-testid'?: string;
}) => {
  const [anchors, setAnchors] = useState<number[]>([0]);
  const panelRef = useRef<FloatingPanelRef | null>(null);

  const { ref } = useResizeDetector({
    onResize: ({ height }) => {
      if (!height) {
        return;
      }
      const totalHeight = height + FLOATING_HEADER_HEIGHT;
      if (totalHeight > 0 && anchors[1] !== totalHeight) {
        setAnchors([0, totalHeight]);
      }
    },
  });

  useLayoutEffect(() => {
    if (props.isOpen) {
      panelRef.current?.setHeight(anchors[1]);
    } else {
      panelRef.current?.setHeight(0);
    }
  }, [anchors, props.isOpen]);

  const onHeightChange = useCallback(
    (height: number) => {
      if (!closable) {
        return;
      }
      if (onClose && props.isOpen && height <= 0) {
        onClose();
      }
    },
    [closable, onClose, props.isOpen],
  );

  return createPortal(
    <>
      {!closable ? (
        <style>
          .main-nav-bar,.adm-safe-area-position-top {'{ z-index: 1004 }'}
        </style>
      ) : null}
      <Mask
        className={'h-dvh bottom-0 top-auto'}
        visible={props.isOpen}
        onMaskClick={onClose}
      />
      <FloatingPanel
        {...props}
        onHeightChange={onHeightChange}
        anchors={closable ? anchors : [anchors[1] ?? 0]}
        ref={panelRef}
        data-testid={
          dataTestId
            ? `${dataTestId}-${props.isOpen ? 'open' : 'closed'}`
            : undefined
        }
      >
        <div ref={ref} className={'max-h-[calc(100dvh-100px)]'}>
          {props.children}
          <SafeArea position="bottom" />
        </div>
      </FloatingPanel>
    </>,
    document.body,
  );
};
