import { Fragment } from 'react';
import QuizFillBlankAnswer from 'src/components/Quiz/FillBlankQuestion/QuizFillBlankAnswer';

import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import { FillBlankQuestion } from 'src/types/quiz.types';

const QuizFillBlankPhrase = ({
  question,
  splittedQuestion,
  answers,
  onClickHole,
  selectedHole,
}: {
  question: FillBlankQuestion;
  splittedQuestion: string[];
  answers: string[];
  onClickHole: (index: number) => void;
  selectedHole: number;
}) => {
  const { status } = useQuiz();

  return (
    <div
      className={
        'w-full flex items-center text-center justify-center text-balance py-4 px-4 min-h-20'
      }
    >
      <div className={'w-full break-words text-center align-middle'}>
        {splittedQuestion.map((part, index, array) => {
          return (
            <Fragment key={answers[index] ?? index}>
              {part}
              {index !== array.length - 1 && (
                <QuizFillBlankAnswer
                  answer={{
                    value: answers[index],
                  }}
                  isCorrect={answers[index] === question.correct[index]}
                  questionEnded={
                    Boolean(answers[index]) ||
                    (status !== QuestionStatus.WAITING &&
                      status !== QuestionStatus.PAUSED)
                  }
                  onClick={() => onClickHole(index)}
                  isSelected={selectedHole === index}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default QuizFillBlankPhrase;
