import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Spinner from 'src/components/common/designSystem/Spinner';
import axios from 'axios';
import { useNotification } from 'src/contexts/NotificationContext';

export default function FirebaseAuthActions() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { showError } = useNotification();

  const redirect = useCallback(async () => {
    try {
      const invitationRef = searchParams.get('ref');
      if (invitationRef) {
        const { data: code } = await axios.get(
          `/api/invitations/${invitationRef}`,
        );
        navigate({
          pathname: '/login',
          search: createSearchParams({
            onboardingCode: code.code,
          }).toString(),
        });
      } else {
        showError({
          message: t('invitations.notFound'),
          error: t('invitations.notFound'),
        });
        navigate('/');
      }
    } catch (error) {
      showError({ message: t('invitations.notFound'), error });
      navigate('/');
    }
  }, [navigate, showError, t]);

  useEffect(() => {
    redirect();
  }, [redirect]);

  return <Spinner size="xl" className="w-full h-full" />;
}
