import { Trans, useTranslation } from 'react-i18next';
import PrimaryCheckIcon from 'src/components/common/PrimaryCheckIcon';

const AnnualOffer = () => {
  const { t } = useTranslation();

  return (
    <div
      className={
        'w-full items-center bg-gradient-to-r from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-1'
      }
    >
      <div
        className={
          'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-4 gap-2'
        }
      >
        <div className={'flex flex-row gap-4 items-center justify-center'}>
          <PrimaryCheckIcon />
          <div className={'flex flex-col'}>
            <div
              className={
                'font-extrabold text-lg leading-none text-structural-darker'
              }
            >
              {t('paywall.panel.annual.type')}
            </div>
            <div className={'text-base leading-none text-structural-darker'}>
              <Trans i18nKey="paywall.panel.annual.description">
                Soit <b>5,49€</b> par mois
              </Trans>
            </div>
          </div>
        </div>
        <div className={'flex flex-col text-right'}>
          <div
            className={
              'font-extrabold text-lg leading-none text-structural-darker'
            }
          >
            {t('paywall.panel.annual.price')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnualOffer;
