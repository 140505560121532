import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { useNavigate } from 'react-router-dom';
import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';

import { useCurrentUser } from 'src/queries/user';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';

const QuizConfirmQuit = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { onPause, onResume, status, answersStatusHistory } = useQuiz();

  const onQuit = () => {
    logAnalyticsEvent('quit_quiz', {
      nbQuestions: answersStatusHistory.length,
    });
    navigate(-1);
  };

  const handleClose = () => {
    onClose();
    onResume();
  };

  useEffect(() => {
    if (open && status === QuestionStatus.WAITING) {
      onPause();
    }
  }, [onPause, open, status]);

  return (
    <AutoFloatingPanel isOpen={open} onClose={handleClose}>
      <div className={'flex flex-col justify-center gap-5 p-4'}>
        <div className={'text-4xl text-center'}>🥺</div>
        <div className={'text-lg font-bold text-center'}>
          {t('quiz.quit.title', {
            context: currentUser?.family_member_type,
          })}
        </div>
        <div className={'text-base text-center'}>
          {t('quiz.quit.description')}
        </div>
        <div className={'flex flex-col gap-2'}>
          <Button type="primary" className={'!bg-red-darkest'} onClick={onQuit}>
            {t('quiz.quit.quit')}
          </Button>
          <Button type="default" onClick={handleClose}>
            {t('quiz.quit.cancel')}
          </Button>
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default QuizConfirmQuit;
