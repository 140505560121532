import axios from 'axios';
import { useMutation } from 'react-query';

export const useGenerateInvitationLink = () => {
  return useMutation({
    mutationFn: async (userId: number) => {
      const { data: loginLink } = await axios.post('/api/invitations', {
        user_id: userId,
      });
      return loginLink.link as string;
    },
  });
};

export const useGenerateParentInvitationLink = () => {
  return useMutation<{
    link: string;
    isNewParent: boolean;
  }>({
    mutationFn: async () => {
      const { data: loginLink } = await axios.post<{
        link: string;
        original_url: string;
        is_new_parent: boolean;
      }>('/api/invitations/parent');
      return {
        link: loginLink.link,
        isNewParent: loginLink.is_new_parent,
      };
    },
  });
};

export const useGenerateFriendInvitationLink = () => {
  return useMutation({
    mutationFn: async () => {
      const { data: invitationLink } = await axios.post(
        '/api/invitations/friend',
      );
      return invitationLink.link as string;
    },
  });
};
