import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ChildGenderStep from 'src/components/Onboarding/ChildGenderStep';
import { useCurrentUser } from 'src/queries/user';

import { UserType } from 'src/types/user.types';

const OnboardingChildGender = () => {
  const navigate = useNavigate();
  const { data: child } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const onNext = () => {
    navigate(`/onboarding/child/school_level?${searchParams.toString()}`);
  };

  if (!child || !child.id || child.user_type !== UserType.child) {
    return <Navigate to={`/onboarding?${searchParams.toString()}`} replace />;
  }

  return (
    <LayoutWithHeader
      header={
        <OnboardingHeader
          step={2}
          from={`/onboarding/child/firstname?${searchParams.toString()}`}
        />
      }
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ChildGenderStep childId={child.id} isMe onFinish={onNext} />
    </LayoutWithHeader>
  );
};

export default OnboardingChildGender;
