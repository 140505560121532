import QuizXPIcon from 'src/images/quiz/xp.svg?react';
import CounterAnimation from 'src/components/common/animations/Counter';

const QuizXP = ({ xp }: { xp: number }) => {
  return (
    <div className={'flex flex-row items-center justify-center gap-2'}>
      <QuizXPIcon />
      <div className={'text-base font-extrabold text-structural-darkest'}>
        +<CounterAnimation value={xp} />
      </div>
    </div>
  );
};

export default QuizXP;
