const ChallengeResultScore = ({
  grade,
  hasWon,
  firstName,
  initial,
  'data-testid': dataTestid,
}: {
  grade: number;
  hasWon: boolean;
  firstName: string;
  initial: string;
  'data-testid'?: string;
}) => {
  return (
    <div
      data-testid={dataTestid}
      className={'flex flex-col gap-2 justify-center items-center'}
    >
      <div
        className={`rounded-full text-white font-bold text-2xl leading-none flex justify-center items-center w-12 h-12 ${
          hasWon ? 'bg-primary-darkest' : 'bg-structural-darkest'
        }`}
      >
        {initial}
      </div>
      <div>
        <div
          style={{
            height: `${(160 * grade) / 100}px`,
          }}
          className={`${
            hasWon
              ? 'bg-gradient-to-b from-paywall-darkest-from to-paywall-darkest-to'
              : 'bg-structural-darkest'
          } w-24 min-h-10 py-2 rounded-xl flex justify-center items-end text-white font-semibold text-base`}
        >
          {grade}%
        </div>
      </div>
      <div className={'font-semibold text-balance text-base text-center'}>
        {firstName}
      </div>
    </div>
  );
};

export default ChallengeResultScore;
