import { useState } from 'react';
import { usePhoto } from 'src/components/Photo/PhotoContext';
import { SafeArea, Swiper } from 'antd-mobile';
import { LeftOutline } from 'antd-mobile-icons';

const PhotoList = () => {
  const { photos } = usePhoto();
  const [showImages, setShowImages] = useState(false);

  if (!photos.length) {
    return <div className={'w-10'} />;
  }

  const lastPhotoBase64 = photos[photos.length - 1];

  return (
    <>
      <div
        className={
          'border-white border-2 rounded w-10 h-16 flex items-center justify-center relative'
        }
        onClick={() => setShowImages(true)}
        data-testid={'photo-list'}
      >
        <div
          className={
            'absolute -right-3.5 -top-3.5 pt-1 w-7 h-7 bg-white rounded-full flex items-center justify-center font-bold text-structural-darker'
          }
        >
          {photos.length}
        </div>
        <img
          src={lastPhotoBase64}
          alt={'last photo'}
          className={'rounded overflow-hidden'}
        />
      </div>
      {showImages ? (
        <PhotoListPopup onClose={() => setShowImages(false)} />
      ) : null}
    </>
  );
};

const PhotoListPopup = ({ onClose }: { onClose: () => void }) => {
  const { photos } = usePhoto();

  return (
    <div
      className={
        'absolute top-0 left-0 w-full h-full flex flex-col bg-primary-darkest items-center justify-center'
      }
      onClick={onClose}
    >
      <SafeArea position={'top'} />
      <div className={'flex flex-row w-full'}>
        <div className={'p-6 text-white'} onClick={onClose}>
          <LeftOutline color={'white'} fontSize={24} data-testid={'btn-back'} />
        </div>
      </div>
      <div className={'flex-1 flex justify-center items-center'}>
        <Swiper direction="horizontal" indicatorProps={{ color: 'white' }}>
          {photos.map((photo, index) => (
            <Swiper.Item key={index} className={'px-4 mb-6'}>
              <img
                key={index}
                src={photo}
                alt={`photo-${index}`}
                className={'rounded-2xl overflow-hidden'}
              />
            </Swiper.Item>
          ))}
        </Swiper>
      </div>
      <SafeArea position={'bottom'} />
    </div>
  );
};

export default PhotoList;
