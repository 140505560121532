import { type ThemeConfig } from 'antd';
import { projectColors } from 'src/styles/project-styles';

// A custom theme for this app
export const antdTheme: ThemeConfig = {
  token: {
    colorPrimary: projectColors.primary.darkest,
    colorInfo: projectColors.primary.darkest,
    fontFamily:
      "'Gilroy', apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'", // eslint-disable-line max-len
  },
};
