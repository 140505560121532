import QuizCompleteSentenceCompletions from 'src/components/Quiz/CompleteSentenceQuestion/QuizCompleteSentenceCompletions';
import { CompleteSentenceQuestion } from 'src/types/quiz.types';

const QuizCompleteSentencePhrase = ({
  question,
  completions,
  onClickCompletion,
}: {
  question: CompleteSentenceQuestion;
  completions: string[];
  onClickCompletion: (index: number) => void;
}) => {
  let [statementStart, statementEnd] = question.statement.split('___');
  return (
    <div
      className={
        'bg-white w-full rounded-lg border-2 border-primary-lighter flex items-center text-center justify-center text-base font-semibold text-balance py-4 px-4 min-h-20'
      }
    >
      <div
        className={
          'w-full break-words text-left align-middle whitespace-normal'
        }
      >
        {statementStart}
        <QuizCompleteSentenceCompletions
          question={question}
          completions={completions}
          onClickCompletion={onClickCompletion}
        />
        {statementEnd}
      </div>
    </div>
  );
};

export default QuizCompleteSentencePhrase;
