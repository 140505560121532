import { useTranslation } from 'react-i18next';

import QuizXPIcon from 'src/images/quiz/xp.svg?react';

import { useCurrentUser } from 'src/queries/user';
import ProfileButton from 'src/components/Profile/ProfileButton';
import Button from 'src/components/common/designSystem/Button';
import StreakChildCard from 'src/components/Streak/modal/StreakChildCard';
import ChallengeStatsProfileItem from 'src/components/Challenge/ChallengeStatsProfileItem';
import { openSupportMessenger } from 'src/modules/support/support';

const ProfilePage = () => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();

  if (!user) {
    return null;
  }

  return (
    <div className={'flex flex-col text-structural-darkest gap-6'}>
      <div className={'flex flex-row justify-between items-center'}>
        <div className={'flex flex-row items-center gap-2'}>
          <div
            className={
              'w-8 h-8 rounded-full text-white text-xl font-bold flex items-center pt-0.5 justify-center bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to'
            }
          >
            {user.firstname?.[0]}
          </div>
          <div className={'text-2xl font-bold text-structural-darkest'}>
            {t('profile.child.title', { firstName: user.firstname })}
          </div>
        </div>
        <ProfileButton />
      </div>
      <div className={'flex flex-col gap-6 py-4'}>
        <div className={'text-xl font-bold text-structural-darkest'}>
          {t('profile.child.subtitle')}
        </div>

        <div
          className={
            'flex flex-row justify-between items-center rounded-xl bg-structural-lightest p-4'
          }
        >
          <div className={'font-bold text-base'}>{t('profile.child.xp')}</div>
          <div className={'flex flex-row items-center justify-start gap-2'}>
            <QuizXPIcon className={'w-auto h-6'} />
            <div className={'font-extrabold text-base'}>{user.xp}</div>
          </div>
        </div>

        <ChallengeStatsProfileItem />

        <StreakChildCard />

        <div className={'flex flex-col gap-2'}>
          <div className={'text-xl font-bold text-structural-darkest'}>
            {t('profile.child.ask')}
          </div>
          <Button
            data-testid="btn-contact-us"
            type={'primary'}
            onClick={async () => {
              await openSupportMessenger();
            }}
          >
            {t('profile.child.contactUs')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
