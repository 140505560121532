import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import CloseIcon from 'src/images/close.svg?react';

import { Navigate, useNavigate } from 'react-router-dom';

import { useGetQuizRun } from 'src/queries/quiz';
import { useGetChallenge } from 'src/queries/challenge';
import Spinner from 'src/components/common/designSystem/Spinner';
import ChallengeResultScore from 'src/components/Challenge/ChallengeResultScore';
import { useCurrentUser } from 'src/queries/user';
import ChallengeResultTitle from 'src/components/Challenge/ChallengeResultTitle';

const ChallengeResult = ({
  challengeId,
  quizId,
  runId,
}: {
  challengeId: number;
  quizId: number;
  runId: number;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: me } = useCurrentUser();
  const { data: quizRun, isLoading: isLoadingQuizRun } = useGetQuizRun({
    quizId: Number(quizId),
    quizRunId: Number(runId),
  });
  const { data: challenge, isLoading: isLoadingChallenge } =
    useGetChallenge(challengeId);

  const handleRestart = async () => {
    navigate(`/challenges/${challengeId}`, { replace: true });
  };

  if (isLoadingQuizRun || isLoadingChallenge) {
    return (
      <div className={'w-full h-full flex flex-col'}>
        <Spinner className="w-full h-full" />
      </div>
    );
  }

  if (!quizRun || !challenge) {
    return <Navigate to={'/home'} />;
  }

  return (
    <div className={'flex flex-1 justify-between p-4 flex-col gap-2 w-full'}>
      <div>
        <div className={'flex flex-row justify-between items-center mb-8'}>
          <div className={'p-3 text-white'} onClick={() => navigate('home')}>
            <CloseIcon
              className={'fill-structural-darker w-4 h-4'}
              data-testid={'btn-back'}
            />
          </div>
          <div
            className={
              'bg-black bg-opacity-10 text-center text-balance rounded-2xl font-bold text-base px-4 py-2'
            }
          >
            {challenge.quiz.lesson.title}
          </div>
          <div className={'w-10'} />
        </div>
        <ChallengeResultTitle
          firstName={me?.firstname ?? ''}
          grade={quizRun.grade}
          opponentGrade={challenge.max_grade}
        />
      </div>

      <div className={'flex flex-row gap-4 items-end justify-center'}>
        <ChallengeResultScore
          grade={quizRun.grade}
          hasWon={quizRun.grade >= challenge.max_grade}
          initial={me?.firstname?.[0] ?? ''}
          firstName={'Ton score'}
          data-testid={'score-mine'}
        />
        <ChallengeResultScore
          grade={challenge.max_grade}
          hasWon={challenge.max_grade >= quizRun.grade}
          initial={challenge.created_by.firstname?.[0] ?? ''}
          firstName={challenge.created_by.firstname ?? ''}
          data-testid={'score-opponent'}
        />
      </div>
      <Button
        data-testid="btn-restart-quiz"
        type={'primary'}
        className="w-full"
        onClick={handleRestart}
      >
        {t('global.restart')}
      </Button>
    </div>
  );
};

export default ChallengeResult;
