import { useEffect, useState } from 'react';

const CounterAnimation = ({ value }: { value: number }) => {
  const [count, setCount] = useState(0);

  const increment = () => {
    const increment = value / 100;
    const interval = setInterval(() => {
      setCount(prevCount => {
        if (prevCount + increment >= value) {
          clearInterval(interval);
          return value;
        }
        return Math.ceil(prevCount + increment);
      });
    }, 150);
  };

  useEffect(() => {
    increment();
  }, []);

  return (
    <div
      className="inline-block"
      style={{
        transform: `scale(${0.5 + count / 2 / value})`,
      }}
    >
      {count}
    </div>
  );
};

export default CounterAnimation;
