import ChildFirstnameStep from 'src/components/Onboarding/ChildFirstnameStep';
import { Child } from 'src/types/user.types';

const ParentChildFirstnameStep = ({
  child,
  onFinish,
}: {
  child?: Child;
  onFinish: (child: Child) => void;
}) => {
  return <ChildFirstnameStep child={child} onFinish={onFinish} />;
};

export default ParentChildFirstnameStep;
