import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import ChildObjectiveStep from 'src/components/Onboarding/ChildObjectiveStep';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ChildObjectivePanel from 'src/components/Onboarding/ChildObjectivePanel';
import { isNotificationNotDetermined } from 'src/modules/push-notifications/Onesignal';
import AskNotificationPanel from 'src/components/Onboarding/AskNotificationPanel';
import { useCurrentUser, useUpdateCurrentUser } from 'src/queries/user';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useNotification } from 'src/contexts/NotificationContext';
import { usePaywall } from 'src/contexts/PaywallContext';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';

const OnboardingChildObjective = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutateAsync: saveCurrentUser, isLoading: isUpdating } =
    useUpdateCurrentUser();
  const { data: user } = useCurrentUser();
  const [askNotification, setAskNotification] = useState(false);
  const { showError } = useNotification();
  const { isPremium } = usePaywall();
  const [searchParams] = useSearchParams();
  const showParentPanel = searchParams.get('showParentPanel') === 'true';

  const onNext = async () => {
    if (await isNotificationNotDetermined()) {
      return setAskNotification(true);
    }
    await onFinished();
  };
  const onFinished = async () => {
    if (!isPremium && !user?.trial_ends_at) {
      navigate(
        `/onboarding/child/objective/paywall?${searchParams.toString()}`,
      );
      return;
    }

    try {
      await saveCurrentUser({
        onboarding_completed: true,
      });
      logAnalyticsEvent('onboarding_complete');
    } catch (error) {
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  if (isUpdating) {
    return <Spinner size="large" className="w-full h-full" />;
  }

  return (
    <LayoutWithHeader
      header={
        showParentPanel ? (
          <OnboardingHeader step={1} maxStep={1} hideBack />
        ) : (
          <OnboardingHeader
            step={5}
            from={`/onboarding/child/school_level?${searchParams.toString()}`}
          />
        )
      }
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ChildObjectiveStep onFinish={onNext} />
      {showParentPanel ? <ChildObjectivePanel /> : null}
      <AskNotificationPanel
        open={askNotification}
        onClose={() => {
          setAskNotification(false);
          onFinished();
        }}
      />
      <Outlet />
    </LayoutWithHeader>
  );
};

export default OnboardingChildObjective;
