import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import DiscoverChallengeSwiper from 'src/components/Challenge/DiscoverChallengeCard/DiscoverChallengeSwiper';

const DiscoverChallengePanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'panel-discover-challenge'}
    >
      <DiscoverChallengeSwiper />
    </AutoFloatingPanel>
  );
};

export default DiscoverChallengePanel;
