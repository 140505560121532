import Spinner from 'src/components/common/designSystem/Spinner';
import { useGetUsers } from 'src/queries/users';
import { Tab, Tabs } from 'src/components/common/designSystem/Tabs';
import ChildTab from 'src/components/UserManagement/ChildTab';

function UserManagement() {
  const { data: users, isLoading } = useGetUsers();

  if (isLoading) {
    return <Spinner className="w-full" />;
  }

  if (!users?.length) {
    return null;
  }

  const children = users.filter(
    user => user.user_type === 'child' && user.firstname?.length,
  );

  if (!children.length) {
    return null;
  }

  return (
    <Tabs data-testid={'children'}>
      {children.map(user => (
        <Tab key={user.id} title={user.firstname}>
          <ChildTab child={user} />
        </Tab>
      ))}
    </Tabs>
  );
}

export default UserManagement;
