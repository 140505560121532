import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { usePollVote } from 'src/queries/poll';
import RadioList from 'src/components/common/designSystem/RadioList';
import PollResult from 'src/components/Poll/PollResult';
import { Poll } from 'src/types/poll.types';

const PollPanel = ({
  poll,
  isOpen,
  onClose,
}: {
  poll: Poll;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { mutateAsync: sendVote } = usePollVote({ id: poll.id });
  const [selectedAnswer, setSelectedAnswer] = useState<number>();

  const vote = useCallback(async () => {
    if (!selectedAnswer) return;

    await sendVote({ answerId: selectedAnswer });
  }, [selectedAnswer, sendVote]);

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'panel-poll'}
    >
      <div className={'gap-4 p-4 flex flex-col'}>
        <div className={'font-extrabold text-center text-balance text-base'}>
          {poll.question}
        </div>
        <div>
          {poll.user_answer_id ? (
            <PollResult poll={poll} />
          ) : (
            <RadioList
              selected={selectedAnswer}
              onSelect={setSelectedAnswer}
              options={poll.answers.map(answer => ({
                type: 'value',
                value: answer.id,
                label: answer.content,
              }))}
            />
          )}
        </div>
        {poll.user_answer_id ? (
          <Button
            type={'default'}
            data-testid={'btn-close-vote'}
            onClick={onClose}
          >
            {t('poll.close')}
          </Button>
        ) : (
          <Button
            type={'default'}
            data-testid={'btn-vote'}
            disabled={!selectedAnswer}
            onClick={vote}
          >
            {t('poll.vote')}
          </Button>
        )}
      </div>
    </AutoFloatingPanel>
  );
};
export default PollPanel;
