import { useTranslation } from 'react-i18next';
import Milestone from 'src/components/Milestones/Milestone';
import Quiz1 from 'src/images/milestones/quiz1.svg?react';
import Quiz10 from 'src/images/milestones/quiz10.svg?react';
import Quiz20 from 'src/images/milestones/quiz20.svg?react';

const MilestoneQuiz = ({ count }: { count: number }) => {
  const { t } = useTranslation();
  return (
    <Milestone
      illustration={
        count >= 20 ? <Quiz20 /> : count >= 10 ? <Quiz10 /> : <Quiz1 />
      }
      title={
        count >= 20
          ? t('milestones.quizzes.quiz20')
          : count >= 10
            ? t('milestones.quizzes.quiz10')
            : t('milestones.quizzes.quiz1')
      }
    />
  );
};

export default MilestoneQuiz;
