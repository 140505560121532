import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { useNotification } from 'src/contexts/NotificationContext';
import { AuthError, authErrorCodeToMessage } from 'src/modules/firebase';
import { useAuth } from 'src/hooks/useAuth';
import { AuthMode } from 'src/contexts/AuthContext';
import { Capacitor } from '@capacitor/core';
import { reportError } from 'src/modules/logs/Sentry';

const LoginWithAppleButton = ({ onLogin }: { onLogin?: () => void }) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const { setMode } = useAuth();

  if (Capacitor.getPlatform() === 'android') {
    return null;
  }

  const loginWithApple = async () => {
    try {
      setMode(AuthMode.SIGNUP);
      await FirebaseAuthentication.signInWithApple({
        customParameters: [{ key: 'locale', value: 'fr' }],
        mode: 'popup',
      });
      onLogin && onLogin();
    } catch (error) {
      const message = authErrorCodeToMessage(error as AuthError);
      if (message) {
        reportError('Fail to login with Apple', error);
        showError({
          message: message,
          error,
        });
      }
    }
  };

  return (
    <Button
      data-testid={'btn-login-apple'}
      icon={<IconLogosApple />}
      onClick={loginWithApple}
    >
      {t('login.withApple')}
    </Button>
  );
};

export default LoginWithAppleButton;
