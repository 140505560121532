import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'antd-mobile';

import Avatar from 'src/components/common/designSystem/Avatar';
import { useCurrentUser } from 'src/queries/user';
import UserSwitch from 'src/components/Layout/UserSwitch';
import { useGetUsers } from 'src/queries/users';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import DeleteMyAccountItem from 'src/components/Profile/DeleteMyAccountItem';
import BuildInfoMenu from 'src/components/BuildInfo/BuildInfoMenu';
import BuildInfoPanel from 'src/components/BuildInfo/BuildInfoPanel';
import PaywallMenuPanel from 'src/components/Paywall/PaywallMenuPanel';
import useLogout from 'src/hooks/useLogout';

const AvatarHeaderButton = () => {
  const { t } = useTranslation();
  const { data: currentUser } = useCurrentUser();
  const { data: users } = useGetUsers();
  const { logout } = useLogout();

  const [
    {
      isMainPanelOpen,
      isUserSwitchPanelOpen,
      isBuildPanelOpen,
      isPaywallPanelOpen,
    },
    setPanelState,
  ] = useState({
    isMainPanelOpen: false,
    isUserSwitchPanelOpen: false,
    isBuildPanelOpen: false,
    isPaywallPanelOpen: false,
  });
  const onMenuClick = () => {
    setPanelState({
      isMainPanelOpen: true,
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: false,
    });
  };
  const handleOpenUserSwitch = () => {
    setPanelState({
      isMainPanelOpen: false,
      isUserSwitchPanelOpen: true,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: false,
    });
  };
  const closePanel = () => {
    setPanelState({
      isMainPanelOpen: false,
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: false,
    });
  };
  const handleOpenBuildPanel = () => {
    setPanelState({
      isMainPanelOpen: false,
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: true,
      isPaywallPanelOpen: false,
    });
  };
  const handleOpenPaywallPanel = () => {
    setPanelState({
      isMainPanelOpen: false,
      isUserSwitchPanelOpen: false,
      isBuildPanelOpen: false,
      isPaywallPanelOpen: true,
    });
  };

  const switchUserDisabled =
    (users?.filter(user => user.id !== currentUser?.id).length ?? 0) === 0;

  return (
    <>
      <div onClick={onMenuClick} data-testid="btn-avatar">
        <Avatar name={currentUser?.firstname} />
      </div>

      <AutoFloatingPanel isOpen={isMainPanelOpen} onClose={closePanel}>
        <List className={'text-left text-base text-structural-darkest'}>
          <BuildInfoMenu onClick={handleOpenBuildPanel} />
          <List.Item
            data-testid="btn-switch-user"
            arrow={false}
            disabled={switchUserDisabled}
            onClick={handleOpenUserSwitch}
          >
            <span>{t('navbar.menu.changeUser')}</span>
          </List.Item>
          <List.Item
            data-testid="btn-subscription"
            arrow={false}
            onClick={handleOpenPaywallPanel}
          >
            <span>{t('navbar.menu.subscription')}</span>
          </List.Item>
          <DeleteMyAccountItem onClick={closePanel} />
          <List.Item
            arrow={false}
            data-testid="btn-logout"
            onClick={logout}
            className="text-red-darkest"
          >
            <span>{t('navbar.menu.logout')}</span>
          </List.Item>
        </List>
      </AutoFloatingPanel>
      <AutoFloatingPanel isOpen={isUserSwitchPanelOpen} onClose={closePanel}>
        <UserSwitch onSwitch={closePanel} />
      </AutoFloatingPanel>
      <BuildInfoPanel isOpen={isBuildPanelOpen} onClose={closePanel} />
      <PaywallMenuPanel isOpen={isPaywallPanelOpen} onClose={closePanel} />
    </>
  );
};

export default AvatarHeaderButton;
