import { useMemo, useEffect } from 'react';
import { type AxisOptions, Chart } from 'react-charts';
import dayjs from 'dayjs';
import { projectColors } from 'src/styles/project-styles';
import { curveBumpX } from 'd3-shape';
import './LessonProgressGraph.scss';
import { FinishedQuizRun } from 'src/types/quiz.types';

const LessonProgressGraph = ({
  finishedQuizRuns,
}: {
  finishedQuizRuns: FinishedQuizRun[];
}) => {
  const primaryAxis = useMemo(
    (): AxisOptions<FinishedQuizRun> => ({
      getValue: (datum: FinishedQuizRun) => datum.date,
      showGrid: false,
      scaleType: 'band',
      formatters: {
        scale: (value: Date) => dayjs(value).format('DD/MM'),
      },
      shouldNice: true,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): Array<AxisOptions<FinishedQuizRun>> => [
      {
        getValue: datum => datum.grade,
        elementType: 'line',
        max: 100,
        min: 0,
        showDatumElements: true,
        curve: curveBumpX,
        showGrid: false,
      },
    ],
    [],
  );

  useEffect(() => {
    const path = document.querySelector<SVGPathElement>('.Series path');
    if (path) path.setAttribute('pathLength', '1');
  }, []);

  return (
    <div className={'w-full h-full'}>
      <Chart
        options={{
          data: [
            {
              label: 'Grade',
              data: finishedQuizRuns,
              color: projectColors.primary.darkest,
            },
          ],
          tooltip: {
            show: false,
          },
          primaryCursor: {
            show: false,
          },
          secondaryCursor: {
            show: false,
          },
          primaryAxis,
          secondaryAxes,
          getSeriesStyle: () => ({
            stroke: projectColors.primary.darkest,
            strokeWidth: 4,
          }),
          getDatumStyle: () =>
            ({
              strokeWidth: 2,
              stroke: 'black',
              fill: 'white',
              transition: 'none',
              circle: {
                r: 6,
              },
            }) as any,
        }}
      />
    </div>
  );
};

export default LessonProgressGraph;
