import { useTranslation } from 'react-i18next';

import { Poll } from 'src/types/poll.types';

const PollResult = ({ poll }: { poll: Poll }) => {
  const { t } = useTranslation();
  const totalVotes = poll.answers.reduce(
    (acc, answer) => acc + answer.votes_count,
    0,
  );

  return (
    <div>
      <div className="flex-1 flex flex-col gap-2 justify-center w-full">
        {poll.answers.map(answer => {
          const percent = Math.round((answer.votes_count / totalVotes) * 100);
          return (
            <div
              key={answer.id}
              className={
                'relative p-4 overflow-hidden flex items-center justify-between ' +
                'text-base rounded-xl w-full bg-white border border-primary-lighter ' +
                `${poll.user_answer_id === answer.id ? 'font-bold text-primary-darkest' : ''}`
              }
            >
              <div
                style={{ width: `${percent}%` }}
                className={'absolute top-0 bottom-0 left-0 bg-primary-lighter'}
              />
              <div className={'relative'}>{answer.content}</div>
              <span className="relative font-semibold">{percent}%</span>
            </div>
          );
        })}
        <div className={'font-semibold text-sm'}>
          {t('poll.votes', { count: totalVotes })}
        </div>
      </div>
    </div>
  );
};

export default PollResult;
