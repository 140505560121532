import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const PaywallCGU = () => {
  return (
    <div className={'text-xs text-balance text-center text-structural-darkest'}>
      <Trans i18nKey={'paywall.pay.footer'}>
        Annulez à tout moment. L’abonnement se renouvelle automatiquement. En
        vous abonnant, vous acceptez notre{' '}
        <Link
          data-testid={'link-confidentialite'}
          to={'/confidentialite'}
          className={'font-bold text-structural-darkest'}
        >
          politique de confidentialité
        </Link>{' '}
        et nos
        <Link
          data-testid={'link-terms-and-conditions'}
          to={'/terms-and-conditions'}
          className={'font-bold text-structural-darkest'}
        >
          conditions générales d’utilisation.
        </Link>
      </Trans>
    </div>
  );
};

export default PaywallCGU;
