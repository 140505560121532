import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import Header from 'src/components/Header/Header';

export default function ConfidentialitePage() {
  return (
    <LayoutWithHeader header={<Header backArrow />}>
      <div className="p-4">
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              <b>
                POLITIQUE DE CONFIDENTIALITE <br />
                Application Baobab <br />
              </b>
            </p>
            <p>
              <br />
              <b>
                1 - PROPRIETE ET RESPONSABILITE EDITORIALE <br />
              </b>{' '}
              <br />
              Cette Politique de Confidentialit&#233; r&#233;git la collecte et
              le traitement de vos Donn&#233;es Personnelles <br />
              dans le cadre de la mise &#224; disposition de l&#8217;Application
              par Nos soins. <br /> <br />
              Nous sommes responsables de traitement. <br /> <br />
              Dans le cadre de la mise &#224; disposition de
              l&#8217;Application, Nous pouvons collecter et traiter Vos <br />
              Donn&#233;es Personnelles. <br /> <br />
              Nous avons impl&#233;ment&#233; des r&#232;gles et proc&#233;dures
              strictes pour les prot&#233;ger. <br /> <br />
              Vous &#234;tes parfaitement inform&#233; que les Donn&#233;es
              Personnelles soumises et collect&#233;es feront <br />
              l&#8217;objet d&#8217;un traitement automatis&#233;. <br /> <br />
              <b>
                2 - DEFINITIONS <br />
              </b>{' '}
              <br />
              Dans le cadre de cette Politique de Confidentialit&#233;, les
              d&#233;finitions suivantes s&#8217;appliqueront &#224; <br />
              tous les termes d&#233;butant par une majuscule, au singulier et
              au pluriel : <br /> <br />
            </p>
            <p>
              &#9679; &#171; <b>Application</b> &#187; : l&#8217;application
              Baobab que Nous &#233;ditons. Elle peut &#234;tre utilis&#233;e{' '}
              <br />
              sur divers terminaux mobiles. <br />
            </p>
            <p>
              &#9679; &#171; <b>Compte </b>&#187; : Votre compte sur
              l&#8217;Application, accessible par Votre identifiant <br />
              ainsi que Votre mot de passe, et contenant Vos informations
              personnelles ainsi <br />
              que Votre historique. <br />
            </p>
            <p>
              &#9679; &#171; <b>Conditions G&#233;n&#233;rales </b>&#187; ou
              &#171;<b> CG </b>&#187; : conditions g&#233;n&#233;rales de
              l&#8217;Application, <br />
              accessibles au sein de l&#8217;Application. <br />
            </p>
            <p>
              &#9679; &#171; <b>Donn&#233;es Personnelles</b> &#187; : les
              donn&#233;es personnelles que Nous collectons <br />
              et traitons. <br />
            </p>
            <p>
              &#9679; &#171; <b>Nous</b> &#187;, &#171; <b>Nos</b> &#187;,
              &#171; <b>Notre</b> &#187; : fait r&#233;f&#233;rence &#224; la
              soci&#233;t&#233; BAOBAB LABS, <br />
              soci&#233;t&#233; par actions simplifi&#233;e au capital social de
              1500 &#8364;, dont le si&#232;ge social <br />
              est situ&#233; au 1 Impasse des Amandiers 71700 TOURNUS,
              immatricul&#233;e au <br />
              Registre du Commerce et des Soci&#233;t&#233;s de M&#226;con sous
              le num&#233;ro 985 354 <br />
              158. <br />
            </p>
            <p>
              &#9679; &#171; <b>Partie</b> &#187; : Baobab Labs<b> </b>et/ou
              Vous, pris individuellement et/ou collectivement. <br />
              &#9679; &#171;
              <b>Politique de Confidentialit&#233; </b>&#187; : le pr&#233;sent
              document. <br />
              &#9679; &#171; <b>Vous</b> &#187;, &#171;
              <b>Vos</b> &#187;, &#171; <b>Votre</b> : fait r&#233;f&#233;rence
              &#224; vous, utilisateur de l&#8217;Application. <br />
            </p>
            <p>
              <br />
              <b>
                3 - ETENDUE ET MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITE{' '}
                <br />
              </b>
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Cette Politique de Confidentialit&#233; r&#233;gule la collecte,
              le traitement et la divulgation de Vos <br />
              Donn&#233;es Personnelles par Nos soins dans le cadre de la mise
              &#224; disposition et de l&#8217;utilisation <br />
              de l&#8217;Application. Elle d&#233;taille &#233;galement les
              modalit&#233;s d&#8217;exercice de Vos droits sur les Donn&#233;es{' '}
              <br />
              Personnelles. <br /> <br />
              Vous devez prendre connaissance de l&#8217;int&#233;gralit&#233;
              de la Politique de Confidentialit&#233; et <br />
              l&#8217;accepter sans r&#233;serve. <br /> <br />
              Cette acceptation sera exprim&#233;e en validant le bouton
              d&#8217;acceptation de la Politique de <br />
              Confidentialit&#233; lors de l&#8217;inscription au sein de
              l&#8217;Application. <br /> <br />
              Nous Nous r&#233;servons le droit de modifier &#224; tout moment
              la Politique de Confidentialit&#233; en <br />
              publiant une nouvelle version sur l&#8217;Application, en Vous le
              notifiant par courriel ou par une <br />
              notification &#224; Votre connexion suivante. <br /> <br />
              En l&#8217;absence d&#8217;acceptation de la Politique de
              Confidentialit&#233; modifi&#233;e, Vous ne pourrez pas <br />
              continuer &#224; utiliser l&#8217;Application et pourrez demander
              la suppression de Votre Compte. <br /> <br />
              La Politique de Confidentialit&#233; applicable est la
              derni&#232;re que Vous avez accept&#233;. <br /> <br />
              Si Vous &#234;tes un mineur de moins de 15 ans, le consentement de
              Votre parent ou du tuteur <br />
              l&#233;gal doit &#234;tre obtenu en plus de Votre consentement,
              &#224; chaque fois qu&#8217;un consentement est <br />
              n&#233;cessaire. <br /> <br />
              L&#8217;Application<b> </b>peut contenir des liens vers un site
              tiers. Celui-ci peut avoir une politique de <br />
              confidentialit&#233; diff&#233;rente et Nous n&#8217;engagerons
              pas Notre responsabilit&#233; pour celles-ci. <br />
              <br />
              <b>
                4 - COMMENT LES DONNEES SONT-ELLES COLLECTEES ? <br />
              </b>{' '}
              <br />
              Nous pouvons collecter Vos Donn&#233;es Personnelles de
              diff&#233;rentes mani&#232;res : <br />
            </p>
            <p>
              &#8226; Lors de Votre inscription &#224; l&#8217;Application.{' '}
              <br />
              &#8226; Lors de Votre utilisation de l&#8217;Application. <br />
              &#8226; Lorsque Vous Nous contactez, pour quelque raison que ce
              soit. <br />
            </p>
            <p>
              <br />
              <b>
                5 &#8211; QUELLES SONT LES DONNEES PERSONNELLES COLLECTEES ET
                QUELLES <br />
              </b>
            </p>
            <p>
              <b>
                FINALITES ? <br />
              </b>{' '}
              <br />
              Nous collectons et traitons les Donn&#233;es Personnelles pour
              ex&#233;cuter Nos obligations <br />
              contractuelles envers Vous, et notamment de : <br />
            </p>
            <p>
              &#8226; Vous fournir un Compte ; <br />
              &#8226; Mettre &#224; disposition l&#8217;Application ; <br />
              &#8226; Afficher des contenus qui correspondent &#224; Vos
              besoins, notamment en permettant le <br />
            </p>
            <p>
              traitement de vos contenus par un mod&#232;le d&#8217;intelligence
              artificielle ; <br />
              &#8226; &#202;tre en mesure de Vous proposer l&#8217;Application
              sans erreur technique, de mani&#232;re <br />
            </p>
            <p>
              s&#233;curis&#233;e, et de d&#233;tecter et corriger toute erreur.{' '}
              <br />
              &#8226; &#201;laborer des statistiques d&#8217;utilisation. <br />
            </p>
            <p></p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Elles sont ad&#233;quates, pertinentes et limit&#233;es &#224; ce
              qui est n&#233;cessaire au regard des finalit&#233;s <br />
              pour lesquelles elles sont trait&#233;es. <br /> <br />
              Nous pouvons aussi collecter les Donn&#233;es Personnelles pour
              Notre int&#233;r&#234;t l&#233;gitime, et <br />
              notamment : <br />
            </p>
            <p>
              &#8226; D&#8217;am&#233;liorer Notre Application. <br /> <br />
              Sur accord sp&#233;cifique de Votre part, ces Donn&#233;es
              Personnelles peuvent &#233;galement &#234;tre <br />
              utilis&#233;es pour : <br />
            </p>
            <p>
              &#8226; Envoi d&#8217;informations sur Nos activit&#233;s ; <br />
              &#8226; Envoi d&#8217;offres marketing publicitaires relatives
              &#224; Nos services, par email et sur tout <br />
            </p>
            <p>
              autre support. <br /> <br />
              Vous aurez toutefois la possibilit&#233;, &#224; tout moment, de
              refuser de fournir certaines de ces <br />
              Donn&#233;es Personnelles au sein des r&#233;glages de
              l&#8217;Application, &#233;tant entendu que ceci peut <br />
              avoir un impact sur le bon fonctionnement de l&#8217;Application
              &#8211; certaines Donn&#233;es Personnelles <br />
              sont n&#233;cessaires pour continuer &#224; l'utiliser. <br />{' '}
              <br />
              Nous ne collectons aucune donn&#233;e sensible. Sont
              consid&#233;r&#233;es comme sensibles les donn&#233;es <br />
              suivantes : l&#8217;origine raciale ou ethnique, les opinions
              politiques, les croyances religieuses ou <br />
              philosophiques, l&#8217;adh&#233;sion &#224; un syndicat, les
              donn&#233;es relatives &#224; la sant&#233; ou &#224;
              l&#8217;orientation <br />
              sexuelle. Si de telles informations Nous &#233;taient d&#8217;une
              mani&#232;re ou d&#8217;une autre communiqu&#233;es, <br />
              elles seront supprim&#233;es d&#232;s prise de connaissance de
              cette situation. <br /> <br />
              Nous pouvons<b> </b>collecter et traiter les Donn&#233;es
              Personnelles suivantes, si elles ont &#233;t&#233; fournies <br />
              : <br />
            </p>
            <p>
              &#8226; Si le Compte est cr&#233;&#233; par un parent pour son
              enfant : <br />o Le pr&#233;nom du parent ; <br />o L&#8217;email
              du parent (en cas de cr&#233;ation de compte avec un email et un
              mot de <br />
            </p>
            <p>
              passe, via Apple login ou via Google login) ; <br />
              &#167; En cas d&#8217;utilisation par l&#8217;enfant du Compte de
              son parent, celui-ci peut <br />
            </p>
            <p>
              cr&#233;er un utilisateur au sein du Compte de son parent. <br />
              &#167; En cas d&#8217;utilisation par l&#8217;enfant de son propre
              Compte, le parent lui <br />
            </p>
            <p>
              transmet un lien d&#8217;invitation ou lui fait scanner QR code.{' '}
              <br />o Le pr&#233;nom de l&#8217;enfant ; <br />o Le sexe de
              l&#8217;enfant (facultatif) ; <br />o Le coll&#232;ge de
              l&#8217;enfant (facultatif) ; <br />o Le niveau scolaire de
              l&#8217;enfant ; <br />o Enregistrement audio de l&#8217;enfant
              lisant ses cours ; <br />o Photographies des cours de
              l&#8217;enfant (si elles incluent des Donn&#233;es <br />
            </p>
            <p>
              Personnelles) ; <br />o Echanges avec le support. <br />
            </p>
            <p>
              &#8226; Si le Compte est cr&#233;&#233; par un enfant de plus de
              15 ans : <br />o Le pr&#233;nom de l&#8217;enfant ; <br />o
              L&#8217;email de l&#8217;enfant (en cas de cr&#233;ation de compte
              avec un email et un mot de <br />
            </p>
            <p>
              passe, via Apple login ou via Google login) ; <br />o Le sexe de
              l&#8217;enfant (facultatif) ;{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              o Le coll&#232;ge de l&#8217;enfant (facultatif) ; <br />o Le
              niveau scolaire de l&#8217;enfant ; <br />o Enregistrement audio
              de l&#8217;enfant lisant ses cours ; <br />o Photographies des
              cours de l&#8217;enfant (si elles incluent des Donn&#233;es <br />
            </p>
            <p>
              Personnelles) ; <br />o Echanges avec le support. <br />
            </p>
            <p>
              <br />
              Ces Donn&#233;es Personnelles sont n&#233;cessaires pour permettre
              Votre acc&#232;s &#224; l&#8217;Application <br />
              (acc&#233;der &#224; Votre Compte), assurer son bon
              fonctionnement, pour Vous fournir une exp&#233;rience <br />
              personnalis&#233;e (profil unique), pour fournir un support &#224;
              l&#8217;utilisation de l&#8217;Application, pour faire <br />
              des statistiques d&#8217;usage de l&#8217;Application, et Vous
              notifier tout changement relatif &#224;
              <br />
              l&#8217;Application. <br /> <br />
              <b>
                6 - LIEU DE STOCKAGE DES DONNEES <br />
              </b>{' '}
              <br />
              D&#232;s que possible, les Donn&#233;es Personnelles
              collect&#233;es sont stock&#233;es au sein de l&#8217;Espace{' '}
              <br />
              &#201;conomique Europ&#233;en, par un prestataire qui applique les
              standards de l&#8217;industrie en mati&#232;re <br />
              de s&#233;curit&#233; informatique. <br /> <br />
              Il n&#8217;est toutefois pas exclu que certaines Donn&#233;es
              Personnelles collect&#233;es puissent faire <br />
              l&#8217;objet d&#8217;un transfert hors de l&#8217;Union
              Europ&#233;enne par nos sous-traitants ult&#233;rieurs. <br />{' '}
              <br />
              Ceux-ci, le cas &#233;ch&#233;ant, se sont engag&#233;s
              contractuellement envers Nous &#224; respecter des <br />
              r&#232;gles au moins &#233;quivalentes &#224; celles applicables
              au sein de l&#8217;Union Europ&#233;enne, notamment <br />
              par la signature des clauses contractuelles types de la Commission
              Europ&#233;enne ou &#224; <br />
              n&#8217;effectuer ce transfert que dans des pays
              b&#233;n&#233;ficiant d&#8217;une d&#233;cision
              d&#8217;ad&#233;quation. <br /> <br />
              <b>
                7 - DIVULGATION DES DONNEES PERSONNELLES ET SOUS-TRAITANCE{' '}
                <br />
              </b>{' '}
              <br />
              Les Donn&#233;es Personnelles ne seront jamais c&#233;d&#233;es
              &#224; un tiers sans accord pr&#233;alable de Votre <br />
              part. <br /> <br />
              Nous pourrons toutefois transmettre les Donn&#233;es Personnelles
              &#224; un tiers si Nous sommes <br />
              tenus de le faire en r&#233;ponse &#224; une obligation
              l&#233;gale ou &#224; l&#8217;entit&#233; survivante &#224; toute
              op&#233;ration <br />
              de fusion, cession ou regroupement &#224; laquelle Nous
              participons, ou &#224; l&#8217;acqu&#233;reur de tout ou <br />
              une partie substantielle de Nos actions ou de Notre fonds de
              commerce. <br /> <br />
              Nous pouvons faire appel aux prestataires suivants, qui peuvent
              avoir acc&#232;s aux Donn&#233;es <br />
              Personnelles, ce que Vous acceptez : <br /> <br />
            </p>
            <p>
              &#8226; Prestataire d&#8217;h&#233;bergement de
              l&#8217;Application (concerne toutes les Donn&#233;es <br />
              Personnelles fournies) : <br />
            </p>
            <p>
              o Google Cloud Platform : un datacenter localis&#233; au sein de
              l&#8217;Espace <br />
              Economique Europ&#233;en a &#233;t&#233; choisi. <br />
            </p>
            <p>
              &#8226; API IA : <br />o OpenAI (cours de l&#8217;enfant en
              photographie ou version audio, et niveau scolaire <br />
            </p>
            <p>
              de l&#8217;enfant) : les Donn&#233;es Personnelles peuvent
              &#234;tre transf&#233;r&#233;es hors de <br />
              l&#8217;Espace Economique Europ&#233;en. Nous avons toutefois
              conclu un contrat avec <br />
              OpenAI interdisant la r&#233;utilisation des donn&#233;es &#224;
              des fins d&#8217;entra&#238;nement du{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              mod&#232;le, et pr&#233;voyant une suppression de ces donn&#233;es
              dans un d&#233;lai <br />
              raisonnable. <br />
            </p>
            <p>
              o Mistral (cours de l&#8217;enfant en photographie ou version
              audio, et niveau scolaire <br />
              de l&#8217;enfant) : les Donn&#233;es Personnelles sont
              h&#233;berg&#233;es au sein de l&#8217;Espace <br />
              Economique Europ&#233;enn. Nous avons conclu un contrat avec
              Mistral interdisant <br />
              la r&#233;utilisation des donn&#233;es &#224; des fins
              d&#8217;entra&#238;nement du mod&#232;le, et pr&#233;voyant <br />
              une suppression de ces donn&#233;es dans un d&#233;lai
              raisonnable. <br />
            </p>
            <p>
              o Anthropic (cours de l&#8217;enfant en photographie ou version
              audio, et niveau <br />
              scolaire de l&#8217;enfant) : les Donn&#233;es Personnelles
              peuvent &#234;tre transf&#233;r&#233;es hors <br />
              de l&#8217;Espace Economique Europ&#233;en. Nous avons toutefois
              conclu un contrat <br />
              avec Anthropic interdisant la r&#233;utilisation des donn&#233;es
              &#224; des fins <br />
              d&#8217;entra&#238;nement du mod&#232;le, et pr&#233;voyant une
              suppression de ces donn&#233;es dans <br />
              un d&#233;lai raisonnable. <br />
            </p>
            <p>
              &#8226; Prestataire de visualisation des donn&#233;es : <br />o
              Amplitude, Inc (sexe de l&#8217;enfant et son niveau scolaire) :
              les Donn&#233;es <br />
            </p>
            <p>
              Personnelles peuvent &#234;tre transf&#233;r&#233;es hors de
              l&#8217;Espace Economique <br />
              Europ&#233;en. <br />
            </p>
            <p>
              o OneSignal, Inc (mail de l&#8217;utilisateur) : un datacenter
              localis&#233; au sein de l&#8217;Espace <br />
              Economique Europ&#233;en a &#233;t&#233; choisi. <br />
            </p>
            <p>
              <br />
              Ces sous-traitant se sont contractuellement engag&#233;s envers
              Nous &#224; respecter leurs <br />
              obligations l&#233;gales et r&#233;glementaires en mati&#232;re de
              donn&#233;es personnelles. <br /> <br />
              Nous pouvons modifier la liste de sous-traitants &#224; tout
              moment, en mettant &#224; jour Notre <br />
              Politique de Confidentialit&#233;. <br /> <br />
              Les Donn&#233;es Personnelles peuvent &#233;galement &#234;tre
              transmises &#224; toute autorit&#233; administrative <br />
              en faisant la demande, ou en application de la loi ou sur
              d&#233;cision de justice. <br /> <br />
              <b>
                8 - SECURITE DES DONNEES PERSONNELLES <br />
              </b>{' '}
              <br />
              Nous nous assurons que les Donn&#233;es Personnelles sont
              stock&#233;es dans des conditions <br />
              respectant les standards de l&#8217;industrie. <br /> <br />
              Nous Nous engageons notamment &#224; respecter la
              r&#233;glementation applicable &#224; la s&#233;curisation <br />
              des Donn&#233;es Personnelles. <br /> <br />
              Nous nous assurons &#224; ce que Nos employ&#233;s et prestataires
              charg&#233;s de traiter les Donn&#233;es <br />
              Personnelles soient soumis &#224; une obligation de
              confidentialit&#233; appropri&#233;e et soient form&#233;s au{' '}
              <br />
              traitement des Donn&#233;es Personnelles. <br /> <br />
              En cas d&#8217;atteinte aux Donn&#233;es Personnelles, Nous vous
              en informerons dans les meilleurs <br />
              d&#233;lais. <br /> <br />
              Nous stockerons les Donn&#233;es Personnelles pendant la
              dur&#233;e de validit&#233; de Votre Compte, <br />
              sauf si elles ont &#233;t&#233; anonymis&#233;es, auquel cas elles
              pourront &#234;tre conserv&#233;es sans limite de <br />
              dur&#233;e. De m&#234;me, si le Compte n&#8217;est plus actif
              depuis trois (3) ans, ou en cas de suppression <br />
              du Compte par Vos soins, les Donn&#233;es Personnelles seront
              supprim&#233;es automatiquement, <br />
              sauf si elles ont &#233;t&#233; anonymis&#233;es. <br />
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Dans ce cas, Nous supprimerons toutes les Donn&#233;es
              Personnelles, &#224; l&#8217;exception de celles qui <br />
              sont strictement n&#233;cessaires au respect de ses obligations
              l&#233;gales, ainsi que celles qui ont <br />
              &#233;t&#233; anonymis&#233;es. <br />
              <br />
              <b>
                9 &#8211; COOKIES ET TRACEURS <br />
              </b>{' '}
              <br />
              <b>Qu&#8217;est-ce qu&#8217;un cookie ?</b> <br /> <br />
              Un cookie est un fichier texte susceptible d&#8217;&#234;tre
              d&#233;pos&#233; dans un terminal lors de la <br />
              consultation d&#8217;un service en ligne avec un logiciel de
              navigation. Un fichier cookie permet <br />
              notamment &#224; son &#233;metteur, pendant sa dur&#233;e de
              validit&#233;, de reconna&#238;tre le terminal concern&#233;{' '}
              <br />
              &#224; chaque fois que ce terminal acc&#232;de &#224; un contenu
              num&#233;rique comportant des cookies du <br />
              m&#234;me &#233;metteur. <br />
              <b>
                {' '}
                <br />
                &#192; quoi servent les cookies et traceurs &#233;mis sur Notre
                site ?
              </b>{' '}
              <br /> <br />
              Les cookies que Nous &#233;mettons Nous permettent : <br />
            </p>
            <p>
              &#8226; D'&#233;tablir des statistiques et volumes de
              fr&#233;quentation et d'utilisation des divers <br />
              &#233;l&#233;ments composant Notre site. <br />
            </p>
            <p>
              &#8226; D'adapter la pr&#233;sentation de notre site aux
              pr&#233;f&#233;rences d'affichage de Votre terminal. <br />
              &#8226; De m&#233;moriser des informations relatives &#224; un
              formulaire que vous avez rempli sur <br />
            </p>
            <p>
              Notre site. <br />
              &#8226; De Vous permettre d'acc&#233;der &#224; des espaces
              r&#233;serv&#233;s et personnels de Notre site. <br />
            </p>
            <p>
              <b>
                {' '}
                <br />
              </b>
              Les cookies sont les suivants : <br /> <br />
            </p>
            <p>
              <b>
                Emetteur Nom du cookie Type de Cookie <br />
              </b>
            </p>
            <p>
              {' '}
              Meta Meta Pixel Cookie de suivi <br />
            </p>
            <p>
              Google Firebase Authentification <br />
              Token <br />
            </p>
            <p>
              Bearer token (token <br />
              d&#8217;identification) <br />
            </p>
            <p>
              <b>
                {' '}
                <br />
                Comment pouvez-vous contr&#244;ler les cookies utilis&#233;s ?
              </b>{' '}
              <br /> <br />
              Il est possible d&#8217;emp&#234;cher l&#8217;installation de
              cookies de la mani&#232;re suivante : <br />
            </p>
            <p>
              &#9679; En les refusant sur la banni&#232;re &#224; cet effet.{' '}
              <br />
              &#9679; En les supprimant via l&#8217;option idoine du navigateur.{' '}
              <br />
            </p>
            <p>
              &#9632; Google Chrome : Menu Outil / Param&#232;tres / Afficher
              les param&#232;tres <br />
              avanc&#233;s / Param&#232;tres de Contenu / Autoriser le stockage
              des donn&#233;es <br />
              locales. <br />
            </p>
            <p>
              &#9632; Edge :
              https://support.microsoft.com/fr-fr/help/17442/windows-internet-
              <br />
              explorer-delete-manage-cookies <br />
            </p>
            <p>
              &#9632; Mozilla Firefox :
              https://support.mozilla.org/fr/kb/activer-desactiver-
              <br />
              cookies-preferences <br />
            </p>
            <p>
              &#9632; Safari :
              https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              <br />
              Seuls les cookies techniques ne peuvent pas &#234;tre
              refus&#233;s, ceux-ci &#233;tant n&#233;cessaires au bon <br />
              fonctionnement du Site. <br /> <br />
              Vous avez la possibilit&#233; d&#8217;accepter certains cookies et
              d&#8217;en refuser d&#8217;autres, de tous les <br />
              accepter, ou de tous les refuser, &#233;tant entendu que cela peut
              perturber le fonctionnement du <br />
              site s&#8217;il s&#8217;agit de cookies fonctionnels. <br />{' '}
              <br />
              <b>
                10 &#8211; VOS DROITS <br />
              </b>{' '}
              <br />
              Conform&#233;ment &#224; la Loi Informatique et Libert&#233;s et
              au RGPD, Vous disposez des droits <br />
              suivants pour toutes les Donn&#233;es Personnelles que Vous Nous
              avez transmises : <br />
            </p>
            <p>
              &#9679; Droit d&#8217;acc&#232;s, <br />
              &#9679; Droit de rectification et de modification, <br />
              &#9679; Droit de limiter le traitement, <br />
              &#9679; Droit d&#8217;opposition, <br />
              &#9679; Droit de portabilit&#233;. <br />
            </p>
            <p>
              <br />
              Il est possible d&#8217;exercer ces droits en Nous contactant
              &#224; l&#8217;adresse de contact suivante : <br />
              reclamation@baobab-labs.com <br /> <br />
              Conform&#233;ment &#224; Nos obligations l&#233;gales, Nous Vous
              r&#233;pondrons dans un d&#233;lai maximal d&#8217;un <br />
              (1) mois &#224; compter de la r&#233;ception de la demande. <br />{' '}
              <br />
              <b>
                11 - LOI APPLICABLE ET LITIGES <br />
              </b>{' '}
              <br />
              La loi applicable aux pr&#233;sentes est la loi fran&#231;aise.{' '}
              <br /> <br />
              La Politique de Confidentialit&#233; est r&#233;dig&#233;e en
              fran&#231;ais, et la version fran&#231;aise fera foi m&#234;me{' '}
              <br />
              en cas de traduction dans une autre langue. <br /> <br />
              Vous pouvez soumettre &#224; la CNIL tout litige relatif &#224;
              l&#8217;ex&#233;cution ou l&#8217;interpr&#233;tation de la <br />
              Politique de Confidentialit&#233;, non r&#233;solu &#224;
              l&#8217;amiable entre les Parties. <br /> <br />
              Il pourra &#233;galement &#234;tre soumis au tribunal
              comp&#233;tent du ressort de Notre soci&#233;t&#233;, y compris{' '}
              <br />
              en mati&#232;re de r&#233;f&#233;r&#233;, d&#8217;un appel en
              garantie ou d&#8217;une pluralit&#233; de d&#233;fendeurs, et quel
              que soit <br />
              Votre pays d&#8217;origine.{' '}
            </p>
          </div>
        </div>
      </div>
    </LayoutWithHeader>
  );
}
