export default {
  reviews: 'Mes révisions',
  welcome: '👋 Bienvenue dans Baobab !',
  welcomeDescription1:
    'Ajoute tes lecons à réviser dans Baobab en lisant à haute voix ou en prenant ton cours en photo.',
  welcomeDescription2:
    'Notre magicien 🧙‍♂️ te préparera des notes et des questions personnalisées sur ton cours !',
  newThingsInSchool: "Du nouveau à l'école aujourd'hui ?",
  newThingsInSchoolDescription:
    'Rajoute vite tes nouvelles leçons pour avoir tes quiz personnalisés !',
  addLesson: {
    chooseASubject: 'Quelle est la matière ?',
  },
  completionCard: {
    almostThere: 'Tu y es preque !',
    startNow: 'Commence ton parcours d’apprentissage maintenant !',
    createLesson: 'Ajoute une leçon à réviser !',
    description:
      'Lis-moi ta leçon pour que je puisse t’interroger dessus. Pour aller plus vite, tu peux enregistrer tes chapitres par petits bouts !',
    continueLessonCreation: 'Continuer ma première leçon',
    lastStep: 'Plus qu’une étape',
    answerQuiz: 'Réponds à ton premier quiz maintenant !',
    startQuiz: 'Lancer le quiz !',
    waitingQuiz: 'Quiz en cours',
  },
  streak: {
    description1: 'Bien joué ! Reviens chaque jour pour cumuler des flammes !',
    description2:
      "Deuxième jour de révision d'affilée !\nTu es sur la bonne voie !",
    description3:
      "Tu es sur une bonne série !\nTroisième jour de révision d'affilée !",
    description4: 'Tu y es presque !\nLa constance est la clé du succès !',
    description5:
      'Cinquième jour consécutif !\nTu fais des progrès impressionnants !',
    description6: 'Plus qu’un jour pour\ncompléter ta semaine ! ',
    description7:
      'Une semaine complète de révision !\nTa détermination est admirable !',
    description: 'Bien joué ! Reviens chaque jour pour cumuler des flammes !',
    continue: 'Continuer ma série',
    descriptionDetails: 'Reviens chaque jour pour cumuler des flammes !',
  },
};
