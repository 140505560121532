import QuizQuestionHeader from 'src/components/Quiz/QuizQuestionHeader';
import FillIcon from 'src/images/quiz/fill.svg?react';
import { CompleteSentenceQuestion } from 'src/types/quiz.types';

const QuizCompleteSentenceQuestionHeader = ({
  question,
}: {
  question: CompleteSentenceQuestion;
}) => {
  return <QuizQuestionHeader icon={<FillIcon />} text={question.guideline} />;
};

export default QuizCompleteSentenceQuestionHeader;
