import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import LessonContainer from 'src/components/LessonDetail/LessonContainer';
import LessonEditTitle from 'src/components/LessonDetail/LessonEditTitle';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';

export default function LessonRenamePage() {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { t } = useTranslation();

  if (!lessonId) return <Navigate to="404" />;

  const lessonIdNumber = Number(lessonId);

  return (
    <LessonContainer
      lessonId={lessonIdNumber}
      footer={
        <Footer>
          <Button
            data-testid="btn-save-lesson-title"
            form="lessonTitleForm"
            type="primary"
            htmlType="submit"
          >
            {t('global.save')}
          </Button>
        </Footer>
      }
    >
      <LessonEditTitle />
    </LessonContainer>
  );
}
