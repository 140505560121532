import BlankWhiteLayout from 'src/layouts/BlankWhiteLayout';
import { useTranslation } from 'react-i18next';
import useNavigateBack from 'src/hooks/useNavigateBack';
import LessonFooter from 'src/components/LessonDetail/LessonFooter';
import { LeftOutline } from 'antd-mobile-icons';

const LessonEmpty = () => {
  const { t } = useTranslation();
  const goBack = useNavigateBack();

  return (
    <BlankWhiteLayout>
      <div className={'flex flex-col justify-between flex-1 py-4 px-1'}>
        <div>
          <div className={'p-3'} onClick={goBack}>
            <LeftOutline fontSize={24} data-testid={'btn-back'} />
          </div>
        </div>
        <div
          className={
            'px-3 flex h-full w-full flex-1 text-center text-balance justify-center items-center font-bold text-2xl'
          }
        >
          {t('lessons.empty.title')}
        </div>
        <LessonFooter />
      </div>
    </BlankWhiteLayout>
  );
};

export default LessonEmpty;
