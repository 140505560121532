import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import ParentChildFirstnameStep from 'src/components/Onboarding/ParentChildFirstnameStep';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import { Child } from 'src/types/user.types';
import AskNotificationPanel from 'src/components/Onboarding/AskNotificationPanel';
import { useRef, useState } from 'react';

const OnboardingParentChildFirstname = ({
  existingChild,
}: {
  existingChild?: Child;
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isAskNotificationOpen, setIsAskNotificationOpen] = useState(false);
  const child = useRef<Child | undefined>(existingChild);

  const onNext = (createdChild: Child) => {
    setIsAskNotificationOpen(true);
    child.current = createdChild;
  };
  const onCloseAskNotification = () => {
    setIsAskNotificationOpen(false);
    if (!child.current) {
      return;
    }
    navigate(
      `/onboarding/parent/child/${child.current.id}/gender?${searchParams.toString()}`,
    );
  };

  return (
    <LayoutWithHeader
      header={
        <OnboardingHeader
          step={2}
          from={`/onboarding/parent/firstname?${searchParams.toString()}`}
        />
      }
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ParentChildFirstnameStep child={existingChild} onFinish={onNext} />
      <AskNotificationPanel
        open={isAskNotificationOpen}
        onClose={onCloseAskNotification}
      />
    </LayoutWithHeader>
  );
};

export default OnboardingParentChildFirstname;
