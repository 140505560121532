import {
  PACKAGE_TYPE,
  PRODUCT_TYPE,
  Purchases,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import {
  ANNUAL_ENTITLEMENT_ID,
  ANNUAL_OFFERING_ID,
} from 'src/modules/purchase/purchase.constants';

export const fakePackage: PurchasesPackage = {
  identifier: ANNUAL_ENTITLEMENT_ID,
  packageType: PACKAGE_TYPE.MONTHLY,
  product: {
    title: 'Premium',
    description: 'Premium subscription',
    price: 0.99,
    pricePerMonth: 0.99,
    pricePerWeek: 0.99,
    pricePerMonthString: '$0.99',
    pricePerYear: 0.99,
    pricePerWeekString: '$0.99',
    pricePerYearString: '$0.99',
    currencyCode: 'USD',
    identifier: 'premium_trial',
    introPrice: null,
    productType: PRODUCT_TYPE.AUTO_RENEWABLE_SUBSCRIPTION,
    discounts: null,
    productCategory: null,
    priceString: '$0.99',
    defaultOption: null,
    subscriptionOptions: null,
    subscriptionPeriod: null,
    presentedOfferingContext: null,
    presentedOfferingIdentifier: null,
  },
  presentedOfferingContext: {
    offeringIdentifier: ANNUAL_OFFERING_ID,
    targetingContext: null,
    placementIdentifier: null,
  },
  offeringIdentifier: ANNUAL_OFFERING_ID,
};

export const fakeSubscribePremiumWithTrial = async () => {
  return await Purchases.purchasePackage({ aPackage: fakePackage });
};

export const fakeSubscribePremiumWithoutTrial = async () => {
  return await Purchases.purchasePackage({ aPackage: fakePackage });
};
