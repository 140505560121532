import { useVoiceRecorder } from 'src/components/VoiceRecorder/VoiceRecorderContext';
import DeleteIcon from 'src/images/delete.svg?react';

const ClearVoiceRecordButton = () => {
  const {
    isHandlingBlob,
    controls: { isPausedRecording, clearCanvas, isProcessingRecordedAudio },
  } = useVoiceRecorder();

  if (!isPausedRecording || isHandlingBlob || isProcessingRecordedAudio) {
    return null;
  }

  return (
    <div
      className="flex items-center justify-center bg-white rounded-full text-structural-darkest text-2xl h-14 w-14 cursor-pointer"
      onClick={clearCanvas}
    >
      <DeleteIcon className={'fill-structural-darkest'} />
    </div>
  );
};

export default ClearVoiceRecordButton;
