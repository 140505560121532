import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { SafeArea } from 'antd-mobile';
import Header from 'src/components/Header/Header';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';

const LayoutWithHeader = ({
  children,
  header,
  className,
  statusBar = <StatusBar color="#FFFFFF" style={Style.Light} />,
}: PropsWithChildren & {
  header?: ReactNode;
  className?: string;
  statusBar?: ReactElement<typeof StatusBar>;
}) => {
  return (
    <div className={'w-full h-full max-h-dvh bg-white flex flex-col'}>
      {statusBar}
      {header ?? <Header />}
      <div className={'flex-1 min-w-0 min-h-0 relative'}>
        <div
          className={
            'h-full w-full overflow-hidden overflow-y-auto flex flex-col py-4 px-3 ' +
            className
          }
        >
          {children}
          <SafeArea position="bottom" />
        </div>
      </div>
    </div>
  );
};

export default LayoutWithHeader;
