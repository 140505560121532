import ShareIcon from 'src/images/share.svg?react';
import { useTranslation } from 'react-i18next';
import { User } from 'src/types/user.types';
import InvitationPanel from 'src/components/Invitation/InvitationPanel';
import { useState } from 'react';

const InviteChildMenuItem = ({ child }: { child: User }) => {
  const { t } = useTranslation();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const inviteChild = () => {
    setIsInviteModalOpen(true);
  };

  return (
    <>
      <div
        onClick={inviteChild}
        data-testid={`btn-invite-child-${child.firstname}`}
        className={
          'flex items-center justify-center gap-2 font-semibold px-2 py-1 text-base'
        }
      >
        <ShareIcon className={'fill-white w-4 h-4'} />
        <span>
          {t('invitations.child.button', { firstName: child.firstname })}
        </span>
      </div>
      <InvitationPanel
        childId={child.id!}
        isOpen={isInviteModalOpen}
        onClose={() => {
          setIsInviteModalOpen(false);
        }}
      />
    </>
  );
};

export default InviteChildMenuItem;
