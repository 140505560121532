import { useTranslation } from 'react-i18next';
import Card from 'src/components/common/designSystem/Card';

const LessonWithoutQuizCard = () => {
  const { t } = useTranslation();

  return (
    <div className={'h-full flex justify-center items-center'}>
      <Card className={'text-center gap-2 p-6'}>
        <div className={'text-5xl'}>🤨</div>
        <div>
          <div className={'font-extrabold text-base'}>
            {t('lessons.improvements.noQuiz.title')}
          </div>
          <div className={'text-base text-balance'}>
            {t('lessons.improvements.noQuiz.description')}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default LessonWithoutQuizCard;
