import { registerPlugin } from '@capacitor/core';

export interface BranchIOMetadataPlugin {
  setRequestMetadata(options: {
    metadataKey: string;
    metadataValue: string;
  }): Promise<void>;
}

export default registerPlugin<BranchIOMetadataPlugin>('BranchIOMetadata');
