import SendIcon from 'src/images/send.svg?react';
import { useTranslation } from 'react-i18next';
import { openSupportMessenger } from 'src/modules/support/support';

const ContactUsMenuItem = () => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="btn-contact-us"
      onClick={openSupportMessenger}
      className={
        'flex items-center justify-center gap-2 font-semibold px-2 py-1 text-base'
      }
    >
      <SendIcon className={'fill-structural-darkest w-4 h-4'} />
      <span>{t('paywall.pay.contactUs')}</span>
    </div>
  );
};

export default ContactUsMenuItem;
