import { ReactNode } from 'react';
import MilestoneAnimation from 'src/components/Milestones/MilestoneAnimation';

const Milestone = ({
  title,
  illustration,
}: {
  title: string;
  illustration: ReactNode;
}) => {
  return (
    <div className={'flex flex-col gap-4 w-full justify-center items-center'}>
      <div className={'relative'}>
        <MilestoneAnimation />
        <div>{illustration}</div>
      </div>
      <div className={'text-white h1 whitespace-pre-wrap text-center'}>
        {title}
      </div>
    </div>
  );
};

export default Milestone;
