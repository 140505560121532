import { PropsWithChildren } from 'react';
import 'src/styles/components/common/Spinner.scss';
import { projectColors } from 'src/styles/project-styles';

interface SpinnerProps extends PropsWithChildren {
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl';
  type?: 'primary' | 'warning' | 'white';
  className?: string;
}

const Spinner = ({
  size = 'medium',
  type = 'primary',
  className,
}: SpinnerProps) => {
  const sizeToPixel = {
    xs: '24px',
    small: '34px',
    medium: '44px',
    large: '60px',
    xl: '80px',
  };
  const sizeToLinePixel = {
    xs: '2px',
    small: '4px',
    medium: '4px',
    large: '4px',
    xl: '6px',
  };

  const colorType = {
    primary: projectColors.primary.darkest,
    white: '#fff',
    warning: projectColors.orange,
  };

  const spinnerSizeInPixel = sizeToPixel[size];
  const spinnerLineSizeInPixel = sizeToLinePixel[size];
  const spinnerColor = colorType[type];

  const styleVariables = {
    ['--spinner-size' as any]: spinnerSizeInPixel,
    ['--spinner-line' as any]: spinnerLineSizeInPixel,
    ['--spinner-color' as any]: spinnerColor,
  };

  return (
    <div
      className={`flex justify-center items-center ${className}`}
      style={styleVariables}
    >
      <div className="spinner-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Spinner;
