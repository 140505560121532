import { useTranslation } from 'react-i18next';
import { useUpdateCurrentUser } from 'src/queries/user';
import { useNotification } from 'src/contexts/NotificationContext';
import PaywallScreenDescription from 'src/components/Paywall/PaywallScreenDescription';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFlags } from 'src/contexts/FlagsContext';

const OnboardingChildPaywallDescription = () => {
  const { t } = useTranslation();
  const { mutateAsync: saveCurrentUser, isLoading: isUpdating } =
    useUpdateCurrentUser();
  const { showError } = useNotification();
  const navigate = useNavigate();
  const { child_trial_store_paywall } = useFlags();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    logAnalyticsEvent('paywall_viewed', {
      type: 'onboarding_screen1_child',
    });
  }, []);

  const onContinue = async () => {
    if (child_trial_store_paywall) {
      navigate(
        `/onboarding/child/objective/paywall/pay?${searchParams.toString()}`,
      );
      return;
    }

    try {
      await saveCurrentUser({
        onboarding_completed: true,
      });
      logAnalyticsEvent('trial_started');
      logAnalyticsEvent('onboarding_complete');
    } catch (error) {
      showError({
        error,
        message: t('error.http.default'),
      });
    }
  };

  return (
    <PaywallScreenDescription
      onContinue={onContinue}
      isContinuing={isUpdating}
    />
  );
};

export default OnboardingChildPaywallDescription;
