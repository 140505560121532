import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useAuth } from 'src/hooks/useAuth';
import { addUserProperty } from 'src/modules/analytics/Amplitude';
import dayjs from 'dayjs';
import { CreatedUser, User } from 'src/types/user.types';

export const useCurrentUser = () => {
  const { isLoggedIn, logout } = useAuth();
  return useQuery<CreatedUser>({
    queryKey: ['user'],
    enabled: isLoggedIn,
    refetchOnWindowFocus: true,
    onError: async () => {
      await logout();
    },
  });
};

export const useUpdateCurrentUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: Partial<User> | User): Promise<User> => {
      const { data } = await axios.put('/api/user', user);
      return data;
    },
    onSuccess: (data, updatedProperties) => {
      queryClient.setQueryData(['user'], data);

      if (updatedProperties.date_of_birth) {
        addUserProperty('yob', dayjs(updatedProperties.date_of_birth).year());
      }
      if (updatedProperties.school_level) {
        addUserProperty('grade_level', updatedProperties.school_level);
      }
      if (updatedProperties.school_id) {
        addUserProperty('school_id', updatedProperties.school_id);
      }
    },
  });
};
