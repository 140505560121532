import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { Capacitor } from '@capacitor/core';
import Button from 'src/components/common/designSystem/Button';

const APP_STORE_LINK = 'https://apps.apple.com/app/baobab-labs/id6503043086';
const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.baobab.app';

const ShouldUpdateNativeAppPanel = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();

  return (
    <AutoFloatingPanel isOpen={open} closable={false}>
      <div className={'flex flex-col justify-center gap-2 p-4'}>
        <div className={'text-4xl text-center'}>🚀</div>
        <div className={'text-lg font-bold text-center'}>
          {t('updater.panel.title')}
        </div>
        <div className={'text-base text-center'}>
          {t('updater.panel.description')}
        </div>
        <UpdateNativeAppButton />
      </div>
    </AutoFloatingPanel>
  );
};

const UpdateNativeAppButton = () => {
  const { t } = useTranslation();

  if (Capacitor.getPlatform() === 'ios') {
    return (
      <Button
        className={'w-full'}
        type={'primary'}
        data-testid={'btn-update'}
        onClick={() => {
          window.open(APP_STORE_LINK, '_blank');
        }}
      >
        {t('updater.panel.downloadAppStore')}
      </Button>
    );
  }

  if (Capacitor.getPlatform() === 'android') {
    return (
      <Button
        className={'w-full'}
        type={'primary'}
        data-testid={'btn-update'}
        onClick={() => {
          window.open(PLAY_STORE_LINK, '_blank');
        }}
      >
        {t('updater.panel.downloadPlayStore')}
      </Button>
    );
  }

  return null;
};

export default ShouldUpdateNativeAppPanel;
