import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';

import { useNavigate, useParams } from 'react-router-dom';
import Button from 'src/components/common/designSystem/Button';

const LessonOtherAudioPiecePanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lessonId } = useParams();

  const addAnotherAudio = () => {
    navigate(`/lessons/${lessonId}/pieces/audio`, { replace: true });
  };
  const skip = () => {
    onClose();
  };

  return (
    <AutoFloatingPanel isOpen={isOpen} closable={false}>
      <div
        className={
          'flex flex-col gap-4 p-8 items-center justify-center text-structural-darkest text-center'
        }
      >
        <div className={'text-5xl'}>🎙️</div>
        <div className={'text-xl font-bold text-balance'}>
          {t('lessons.audio.another.title')}
        </div>
        <div className={'text-base text-balance'}>
          {t('lessons.audio.another.description')}
        </div>
        <div className={'flex flex-col gap-2 w-full'}>
          <Button
            onClick={addAnotherAudio}
            data-testid={'btn-add-another-audio'}
            type={'primary'}
          >
            {t('global.add')}
          </Button>
          <Button
            data-testid={'btn-skip-another-audio'}
            onClick={skip}
            type={'default'}
          >
            {t('global.skip')}
          </Button>
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default LessonOtherAudioPiecePanel;
