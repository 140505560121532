import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import Header from 'src/components/Header/Header';

export default function TermsAndConsitionsPage() {
  return (
    <LayoutWithHeader header={<Header backArrow />}>
      <div className="p-4">
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              <b>
                Conditions G&#233;n&#233;rales <br />
                Baobab <br />
              </b>
            </p>
            <p>
              {' '}
              <br />
              Nous &#233;ditons l&#8217;application Baobab, disponible sur
              l&#8217;Apple App Store et le Google Play Store. <br />
              Les pr&#233;sentes CG d&#233;finissent les conditions et les
              modalit&#233;s d&#8217;utilisation de l&#8217;Application, ainsi
              que <br />
              les modalit&#233;s d&#8217;Abonnement &#224; celle-ci. <br />
              Elles sont conclues entre Vous et Nous. <br />
              Les CG sont compl&#233;t&#233;es par la Politique de
              Confidentialit&#233;. <br /> <br />
              <b>
                1 - D&#233;finitions <br />
              </b>
              Dans la suite des pr&#233;sentes CG et de tout autre document
              contractuel qui s&#8217;y rattache, les termes <br />
              dont la premi&#232;re lettre figure en majuscule auront la
              signification suivante au singulier et au pluriel : <br />
            </p>
            <p>
              &#9679; &#171; <b>Abonnement</b> &#187;, &#171;{' '}
              <b>Abonner &#187;</b> : Votre abonnement &#224;
              l&#8217;Application. <br />
              &#9679; &#171; <b>Application</b> &#187; : l&#8217;application
              Baobab, que Nous &#233;ditons. Elle fournit des services
              d&#8217;aides aux <br />
            </p>
            <p>
              devoirs assist&#233;s par intelligence artificielle. Elle peut
              &#234;tre utilis&#233;e sur divers terminaux <br />
              mobiles. <br />
            </p>
            <p>
              &#9679; &#171; <b>CG</b> &#187; : les pr&#233;sentes conditions
              g&#233;n&#233;rales, accessibles au sein de l&#8217;Application.{' '}
              <br />
              &#9679; &#171; <b>Commande</b> &#187; : toute commande
              d&#8217;Abonnement par Vos soins. <br />
              &#9679; &#171; <b>Compte</b> &#187; : Votre compte sur
              l&#8217;Application, accessible par Votre identifiant ainsi que
              Votre <br />
            </p>
            <p>
              mot de passe, et contenant Vos informations personnelles ainsi que
              Votre historique. <br />
              &#9679; &#171; <b>Donn&#233;es</b> <b>Personnelles</b> &#187; :
              les donn&#233;es personnelles que Nous collectons et traitons.{' '}
              <br />
              &#9679; &#171; <b>Nous</b> &#187;, &#171; <b>Nos</b> &#187;,
              &#171; <b>Notre</b> &#187; : fait r&#233;f&#233;rence &#224; la
              soci&#233;t&#233; BAOBAB LABS, soci&#233;t&#233; par actions{' '}
              <br />
            </p>
            <p>
              simplifi&#233;e au capital social de 1500 &#8364;, dont le
              si&#232;ge social est situ&#233; au 1 Impasse des <br />
              Amandiers 71700 TOURNUS, immatricul&#233;e au Registre du Commerce
              et des Soci&#233;t&#233;s de <br />
              Macon sous le num&#233;ro 985 354 158. <br />
            </p>
            <p>
              &#9679; &#171; <b>Partie</b> &#187; : Vous et Nous, pris
              individuellement et/ou collectivement. <br />
              &#9679; &#171; <b>Politique de Confidentialit&#233;</b> &#187; :
              la politique de confidentialit&#233;, qui d&#233;taille les
              conditions <br />
            </p>
            <p>
              applicables &#224; la collecte et au traitement des Donn&#233;es
              Personnelles, accessible au sein de <br />
              l&#8217;Application. <br />
            </p>
            <p>
              &#9679; &#171; <b>Vous</b> &#187;, &#171; <b>Votre</b> &#187;,
              &#171; <b>Vos</b> &#187; : fait r&#233;f&#233;rence &#224; vous,
              utilisateur de l&#8217;Application. <br /> <br />
              <b>
                2 - Acceptation et modification des CG <br />
              </b>{' '}
              <br />
              <b>
                2.1 - Cadre des CG <br />
              </b>
              Les CG s&#8217;appliquent &#224; Votre utilisation de
              l&#8217;Application et &#224; toute Commande pass&#233;e au sein
              de celle-
              <br />
              ci.{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Nous nous r&#233;servons le droit de modifier &#224; tout moment
              les CG, en Vous le notifiant par mail ou <br />
              notification &#224; Votre connexion suivante &#224;
              l&#8217;Application. <br />
              Vous devrez accepter les CG modifi&#233;es pour pouvoir continuer
              &#224; utiliser l&#8217;Application. A d&#233;faut, votre <br />
              acc&#232;s &#224; l&#8217;Application sera supprim&#233; &#224; la
              date de renouvellement de Votre Abonnement, qui ne sera <br />
              pas renouvel&#233;. <br />
              Les CG applicables &#224; l&#8217;Application sont les
              derni&#232;res que Vous avez accept&#233;es. <br />
              Les CG sont applicables pour toute la dur&#233;e
              d&#8217;utilisation de l&#8217;Application par Vos soins dans le
              cadre <br />
              de Votre Abonnement, &#224; compter de leur date
              d&#8217;acceptation. <br />
              Nos informations l&#233;gales concernant l&#8217;Application sont
              fournies au sein des CG et au sein de <br />
              l&#8217;Application. <br /> <br />
              <b>
                2.2 - Acceptation des CG <br />
              </b>
              Pour Vous inscrire &#224; l&#8217;Application et vous Abonner,
              Vous devrez lire et accepter les CG. <br />
              Cette acceptation sera exprim&#233;e en validant le bouton
              d&#8217;acceptation des CG lors de l&#8217;inscription au <br />
              sein de l&#8217;Application. Ceci sera r&#233;put&#233; avoir la
              m&#234;me valeur qu&#8217;une signature manuscrite. <br /> <br />
              <b>
                2.3 - Capacit&#233; juridique <br />
              </b>
              Pour Vous inscrire &#224; l&#8217;Application, vous devez
              &#234;tre &#226;g&#233; d&#8217;au moins 15 ans. <br />
              L&#8217;inscription peut &#234;tre effectu&#233;e par un parent
              pour son enfant, mineur, ou par le mineur de plus de <br />
              15 ans. <br />
              Si Vous &#234;tes un mineur de moins de 15 ans, le consentement de
              Votre parent ou du tuteur l&#233;gal doit <br />
              &#234;tre fourni en plus de Votre accord, pour pouvoir utiliser
              l&#8217;Application et y passer une Commande. A <br />
              d&#233;faut, aucune Commande ne sera possible. <br /> <br />
              <b>
                2.4 - Fin de fourniture de l&#8217;Application <br />
              </b>
              Nous pouvons cesser de fournir l&#8217;Application &#224; tout
              moment. <br />
              Votre Compte sera alors supprim&#233;, sans que Nous
              n&#8217;engagions Notre responsabilit&#233;. <br />
              Si Vous avez un Abonnement, le Compte sera supprim&#233; &#224; la
              fin de l&#8217;Abonnement, ou &#224; d&#233;faut, Vous <br />
              recevrez un remboursement au pro-rata de la p&#233;riode
              pay&#233;e d&#8217;avance au cours de laquelle <br />
              l&#8217;Application n&#8217;est plus disponible. <br /> <br />
              <b>
                3 - Application <br />
              </b>
              L&#8217;Application permet notamment de g&#233;n&#233;rer des
              quizz ou des fiches de r&#233;vision, au moyen d&#8217;un <br />
              mod&#232;le d&#8217;intelligence artificielle, sur la base de
              cours photographi&#233;s ou d&#8217;enregistrements audios en{' '}
              <br />
              cours lus par l&#8217;enfant.{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              L&#8217;Application peut &#234;tre utilis&#233;e soit par
              l&#8217;enfant sur le terminal du parent, soit par l&#8217;enfant{' '}
              <br />
              directement via son propre Compte, celui-ci pouvant &#234;tre
              li&#233; au Compte de son parent. Dans ce <br />
              dernier cas, le parent peut &#234;tre notifi&#233; lors de
              certains usages de l&#8217;Application par l&#8217;enfant. <br />
              Nous pouvons modifier ou supprimer ces fonctionnalit&#233;s &#224;
              tout moment. L&#8217;acc&#232;s &#224; l&#8217;Application peut{' '}
              <br />
              &#224; tout moment &#234;tre restreint pour permettre la
              maintenance, la r&#233;paration ou la mise &#224; jour de <br />
              l&#8217;Application. <br /> <br />
              <b>
                4 - Installation de l&#8217;Application <br />
              </b>
              Vous installez l&#8217;Application sur Vos propres mat&#233;riels.{' '}
              <br />
              Vous devez disposer d&#8217;un acc&#232;s &#224; internet. <br />
              Vous &#234;tes responsable de Vous assurer que Votre mat&#233;riel
              est en bon &#233;tat de fonctionnement et <br />
              correspond aux pr&#233;requis de l&#8217;Application, indiqu&#233;
              sur l&#8217;Apple App Store ou le Google Play Store. <br />
              Vous supportez l&#8217;int&#233;gralit&#233; des frais
              d&#8217;&#233;quipement (mat&#233;riel, acc&#232;s &#224; une
              ligne t&#233;l&#233;phonique et &#224; <br />
              internet, etc) afin de disposer des pr&#233;requis permettant de
              faire fonctionner l&#8217;Application. Nous ne <br />
              nous engageons qu&#8217;&#224; fournir l&#8217;Application et non
              les &#233;quipements n&#233;cessaires aux pr&#233;requis, ce que{' '}
              <br />
              Vous acceptez. <br />
              Nous ne serons pas responsables si Votre mat&#233;riel ne permet
              pas de faire fonctionner l&#8217;Application. <br />
              Nous ne fournissons aucune assistance ni garantie
              d&#8217;installation et d&#8217;utilisation. <br /> <br />
              <b>
                5 - Contact <br />
              </b>
              Vous pouvez Nous contacter &#224; l&#8217;adresse suivante :
              reclamation@baobab-labs.com <br /> <br />
              <b>
                6 - Commande <br />
              </b>{' '}
              <br />
              <b>
                6.1 - Mode de Commande <br />
              </b>
              Vous pouvez vous Abonner en passant une Commande au sein de
              l&#8217;Application, via le syst&#232;me <br />
              d&#8217;achat int&#233;gr&#233;s &#224; celle-ci. Ce syst&#232;me
              utilise les syst&#232;mes d&#8217;achat pr&#233;vus au sein de
              l&#8217;Apple App <br />
              Store et du Google Play Store. <br /> <br />
              <b>
                6.2 - &#201;tape de la Commande <br />
              </b>
              Les &#233;tapes de la Commande sont les suivantes : <br />
            </p>
            <p>
              &#9679; Cr&#233;ation d&#8217;un Compte par Vos soins s&#8217;il
              s&#8217;agit d&#8217;une premi&#232;re Commande ou connexion
              &#224; <br />
              votre Compte s&#8217;il existe d&#233;j&#224; ; <br />
            </p>
            <p>
              &#9679; Choix de l&#8217;Abonnement que Vous souhaitez Commander ;{' '}
              <br />
              &#9679; Fourniture de Vos informations d&#8217;identification ;{' '}
              <br />
              &#9679; Choix du mode de paiement de la Commande et fourniture des
              informations de facturation ; <br />
              &#9679; Visualisation du r&#233;sum&#233; de la Commande et de
              l&#8217;Abonnement pour validation finale ; <br />
              &#9679; Prise de connaissance et validation des CG ;{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              &#9679; Validation de la Commande, incluant une mention que la
              Commande implique une <br />
              obligation de payer ; <br />
            </p>
            <p>
              &#9679; Envoi d&#8217;un mail de confirmation de Commande, &#224;
              r&#233;ception du paiement, reprenant <br />
              l&#8217;ensemble de la Commande. <br />
            </p>
            <p>
              {' '}
              <br />
              La Commande est constitu&#233;e &#224; r&#233;ception du paiement.
              L&#8217;Abonnement d&#233;butera &#224; cette date. <br /> <br />
              Les coordonn&#233;es et informations fournies devront &#234;tre
              &#224; jour et r&#233;elles. <br />
              <b>
                {' '}
                <br />
                6.3 - Erreur dans la Commande <br />
              </b>
              Nous ne saurons en aucun cas &#234;tre responsables d&#8217;erreur
              de choix lors de la Commande, d&#8217;erreurs <br />
              de saisie lors de la Commande, ou de toute autre information
              fournie qui serait erron&#233;e, ni de leurs <br />
              &#233;ventuelles cons&#233;quences. <br />
              <b>
                {' '}
                <br />
                6.4 - Refus de la Commande <br />
              </b>
              Nous nous r&#233;servons le droit de refuser toute Commande pour
              motif l&#233;gitime, notamment en cas de <br />
              probl&#232;mes de paiement, de Commandes anormalement
              &#233;lev&#233;es par rapport aux Commandes <br />
              habituellement pass&#233;es par le Client ou de Commandes
              pass&#233;es de mauvaise foi. <br /> <br />
              <b>
                7 &#8211; Droit de la consommation <br />
              </b>{' '}
              <br />
              Conform&#233;ment &#224; l&#8217;Article L221-28 13&#176; du Code
              de la Consommation, Vous ne disposez d&#8217;aucun droit <br />
              de r&#233;tractation dans la mesure o&#249; Nous fournissons des
              contenus num&#233;riques non fournis sur un <br />
              support mat&#233;riel dont l'ex&#233;cution a commenc&#233;
              apr&#232;s accord pr&#233;alable expr&#232;s du consommateur et{' '}
              <br />
              renoncement expr&#232;s &#224; son droit de r&#233;tractation.{' '}
              <br /> <br />
              <b>
                8 - Abonnement <br />
              </b>{' '}
              <br />
              L&#8217;acc&#232;s &#224; l&#8217;Application est soumis &#224;
              abonnement mensuel ou annuel. <br /> <br />
              Les formules d&#8217;Abonnement sont souscrites pour la dur&#233;e
              d&#8217;Abonnement pr&#233;vue lors de la <br />
              Commande, renouvelable tacitement pour la m&#234;me dur&#233;e.
              Vous pouvez d&#233;sactiver le <br />
              renouvellement de l&#8217;Abonnement &#224; tout moment, sur Votre
              Compte. Il prendra fin au terme de la <br />
              p&#233;riode en cours. <br /> <br />
              L&#8217;Abonnement peut d&#233;buter par une p&#233;riode
              d&#8217;essai. Dans ce cas, sa dur&#233;e sera indiqu&#233;e lors
              de la <br />
              Commande. A son terme, l&#8217;Abonnement sera automatiquement
              renouvel&#233; pour une fr&#233;quence <br />
              mensuelle. Vous aurez la possibilit&#233; de d&#233;sactiver cela
              sur votre Compte. <br /> <br />
              L&#8217;Abonnement est d&#251; en d&#233;but de p&#233;riode, pour
              la p&#233;riode &#224; &#233;choir. <br /> <br />
              La date de d&#233;but de p&#233;riode est celle de d&#233;but de
              la p&#233;riode initiale de l&#8217;Abonnement. <br />{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Si le renouvellement de l&#8217;Abonnement est activ&#233;, en cas
              d&#8217;impossibilit&#233; de pr&#233;lever le prix de <br />
              l&#8217;Abonnement &#224; date de renouvellement, celui-ci sera
              arr&#234;t&#233; imm&#233;diatement d&#232;s le premier &#233;chec{' '}
              <br />
              de pr&#233;l&#232;vement. <br /> <br />
              <b>
                9 &#8211; Prix et paiement <br />
              </b>{' '}
              <br />
              <b>
                9.1 - Prix <br />
              </b>
              Sauf mention &#233;crite contraire, les prix propos&#233;s sont
              des prix entendus en euros toutes taxes <br />
              comprises (TTC), incluant la TVA applicable au jour de la
              Commande. <br /> <br />
              Nous nous r&#233;servons le droit de r&#233;percuter tout
              changement du taux de TVA sur le prix de <br />
              l&#8217;Abonnement, y compris au moment du renouvellement. <br />{' '}
              <br />
              Hors changement de taux de TVA, Nous nous r&#233;servons
              &#233;galement le droit de modifier nos prix &#224; <br />
              tout moment. N&#233;anmoins, dans ce cas, le prix modifi&#233;
              s&#8217;appliquera uniquement au renouvellement <br />
              de l&#8217;Abonnement et Vous aurez toujours la possibilit&#233;
              de ne pas le renouveler. <br /> <br />
              <b>
                9.2 - Paiement <br />
              </b>
              Vous pouvez effectuer son r&#232;glement par carte bancaire, ou
              par tout autre moyen propos&#233; au sein <br />
              de l&#8217;Application. Vous garantissez &#234;tre titulaire du
              moyen de paiement utilis&#233; aux fins de paiement <br />
              de la Commande et que vous disposez de fonds suffisants pour
              couvrir int&#233;gralement le r&#232;glement <br />
              de la Commande. <br /> <br />
              Le paiement sera d&#233;bit&#233; au moment de la confirmation de
              la Commande, et &#224; la date de chaque <br />
              renouvellement de l&#8217;Abonnement. <br /> <br />
              En cas d&#8217;une impossibilit&#233; de d&#233;biter ou
              d&#8217;encaisser les sommes dues en r&#232;glement de la <br />
              Commande, pour quelque raison que ce soit, la Commande pourra
              &#234;tre annul&#233;e, ou le <br />
              renouvellement de l&#8217;Abonnement refus&#233;. <br /> <br />
              Les paiements par carte bancaire se font au moyen de transactions
              s&#233;curis&#233;es fournies par un <br />
              prestataire de paiement agissant selon les r&#232;gles de
              l&#8217;art. <br /> <br />
              Le paiement est effectu&#233; directement entre les mains de la
              banque ou du prestataire de paiement <br />
              recevant Votre paiement. <br /> <br />
              <b>
                9.3 - Retard de paiement <br />
              </b>
              Tout r&#232;glement tardif peut donner lieu &#224; la facturation
              de p&#233;nalit&#233;s de retard sans qu&#8217;aucune mise <br />
              en demeure pr&#233;alable ne soit n&#233;cessaire. Le taux de ces
              p&#233;nalit&#233;s est &#233;gal &#224; trois fois le taux <br />
              d&#8217;int&#233;r&#234;t l&#233;gal et est applicable d&#232;s le
              premier jour de retard. Une somme de quarante (40) euros <br />
              pourra &#234;tre &#233;galement factur&#233;e au titre des frais
              de recouvrement. <br /> <br />
              La facturation d&#8217;int&#233;r&#234;t de retard et des frais
              administratifs de recouvrement ne Nous interdisent <br />
              pas, &#224; notre discr&#233;tion, de mettre en &#339;uvre une
              proc&#233;dure en justice afin d&#8217;obtenir des dommages <br />
              et int&#233;r&#234;ts compl&#233;mentaires.{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              {' '}
              <br />
              <b>
                10 - Obligations des Parties <br />
              </b>{' '}
              <br />
              <b>
                10.1 &#8211; Nos obligations <br />
              </b>
              Nous ferons Nos meilleurs efforts pour que l&#8217;Application
              fonctionne conform&#233;ment &#224; ses <br />
              sp&#233;cifications, sans souscrire une quelconque obligation de
              r&#233;sultat. <br />
              Nous ne nous engageons pas &#224; : <br />
            </p>
            <p>
              &#9679; Une quelconque obligation de r&#233;sultat dans le cadre
              de la fourniture de l&#8217;Application. <br />
              &#9679; Ce que l&#8217;Application soit exempte de bugs ou soit
              disponible en permanence. <br />
              &#9679; La pertinence des informations fournies au sein de
              l&#8217;Application. <br />
              &#9679; Une quelconque fr&#233;quence de maintenance ou mise
              &#224; jour de l&#8217;Application. <br />
              &#9679; Une quelconque qualit&#233; de service pour
              l&#8217;Application. <br />
              &#9679; Un quelconque niveau de service et de disponibilit&#233;
              pour l&#8217;Application. <br />
              &#9679; Une quelconque dur&#233;e de support de
              l&#8217;Application. <br />
            </p>
            <p>
              Nous ferons uniquement Nos meilleurs efforts pour que
              l&#8217;Application soit op&#233;rationnelle de mani&#232;re{' '}
              <br />
              s&#233;curis&#233;e, au titre d&#8217;une obligation de moyens.
              Nous ne garantissons toutefois pas une s&#233;curit&#233; <br />
              absolue de l&#8217;Application. <br />
              Nous pouvons suspendre, modifier ou retirer l&#8217;Application et
              cesser son support &#224; tout moment, <br />
              sans pr&#233;avis ni notification, sans que Notre
              responsabilit&#233; ne puisse &#234;tre engag&#233;e. <br />{' '}
              <br />
              <b>
                10.2 - Vos obligations <br />
              </b>
              Vous pouvez utiliser l&#8217;Application conform&#233;ment aux
              pr&#233;sentes CG, &#224; l&#8217;exclusion de tout autre droit.{' '}
              <br />
              Vous devez respecter les pr&#233;sentes CG lors de
              l&#8217;utilisation de l&#8217;Application. <br /> <br />
              <b>
                11 - Compte <br />
              </b>{' '}
              <br />
              <b>
                11.1 - G&#233;n&#233;ralit&#233;s <br />
              </b>
              Vous pouvez disposer d&#8217;un Compte pour utiliser
              l&#8217;Application. <br />
              Vous ne pouvez cr&#233;er qu&#8217;un seul Compte. Si le Compte
              est cr&#233;&#233; par un parent, l&#8217;enfant peut disposer{' '}
              <br />
              d&#8217;un sous-Compte pour utiliser l&#8217;Application. <br />{' '}
              <br />
              <b>
                11.2 - Identifiant et mot de passe <br />
              </b>
              Vous proc&#233;derez &#224; la cr&#233;ation d&#8217;un Compte
              lorsque Vous Vous inscrirez &#224; l&#8217;Application. <br />
              Vous cr&#233;erez un identifiant et un mot de passe, sauf si vous
              utilisez un autre moyen de connexion tel <br />
              qu&#8217;Apple ou Google logins.{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Si vous cr&#233;ez un mot de passe, Vous devez Vous assurer que le
              mot de passe est suffisamment fort <br />
              et s&#233;curis&#233;. <br />
              Ce Compte sera utilis&#233; pour acc&#233;der &#224; et utiliser
              l&#8217;Application. <br />
              Nous ne serons pas responsables en cas d&#8217;acc&#232;s au
              Compte par un tiers, notamment par une attaque <br />
              de force brute. <br /> <br />
              <b>
                11.3 - Utilisation du Compte <br />
              </b>
              Vous &#234;tes responsable de la s&#233;curit&#233; et de
              l&#8217;usage des identifiant et mot de passe de Votre Compte.{' '}
              <br />
              Vous devez notamment Vous assurer que ceux-ci sont utilis&#233;s
              en conformit&#233; avec les pr&#233;sentes CG. <br />
              Nous ne serons pas responsables en cas d&#8217;utilisation
              non-autoris&#233;e du Compte par un tiers, et ne <br />
              serons pas responsable en cas de dommage caus&#233; par une telle
              utilisation. <br />
              Si Vous Vous apercevez que Votre Compte est compromis ou
              utilis&#233; sans autorisation, ou toute autre <br />
              faille de s&#233;curit&#233; li&#233;e au Compte, Vous devez Nous
              en informer dans les plus brefs d&#233;lais. <br />
              Nous ne serons pas responsables s&#8217;il Vous est impossible
              d&#8217;acc&#233;der &#224; l&#8217;Application. <br />
              Nous pouvons toutefois, &#224; tout moment, fermer le Compte sans
              avoir &#224; Vous pr&#233;venir, en cas de <br />
              violation des stipulations des pr&#233;sentes CG, sans
              qu&#8217;aucune indemnisation ne puisse &#234;tre demand&#233;e{' '}
              <br />
              par Vos soins. Notre responsabilit&#233; ne pourra pas &#234;tre
              recherch&#233;e &#224; ce titre. Toutefois, Nous <br />
              pourrons engager Votre responsabilit&#233;, si Vous n&#8217;avez
              pas respect&#233; les pr&#233;sentes CG. <br /> <br />
              <b>
                11.4 - S&#233;curit&#233; <br />
              </b>
              Nous faisons nos meilleurs efforts pour nous assurer de
              l&#8217;utilisation s&#233;curis&#233;e du Site. Toutefois, face{' '}
              <br />
              &#224; la nature d&#8217;Internet, Nous ne pouvons garantir une
              s&#233;curit&#233; absolue de l&#8217;Application et n&#8217;est
              tenue <br />
              que d&#8217;une obligation de moyen. <br />
              En parall&#232;le, Vous Vous engagez &#224; ne pas agir de la
              mani&#232;re suivante, sans que cette liste soit <br />
              limitative : <br />
            </p>
            <p>
              &#9679; Agir d&#8217;une mani&#232;re qui pourrait compromettre le
              fonctionnement de l&#8217;Application, <br />
              &#9679; Reproduire l&#8217;Application, de quelque mani&#232;re
              que ce soit, par tout moyen et sous toute <br />
            </p>
            <p>
              forme, <br />
              &#9679; Tenter d&#8217;acc&#233;der au Compte d&#8217;un tiers,{' '}
              <br />
              &#9679; Tenter d&#8217;acc&#233;der aux serveurs (hors usage
              normal du service) ou ordinateurs de Notre <br />
            </p>
            <p>
              r&#233;seau, <br />
              &#9679; Tenter de t&#233;l&#233;verser des virus, chevaux de
              Troie, ou tout autre programme invasif ou ill&#233;gal <br />
            </p>
            <p>
              sur nos serveurs, <br />
              &#9679; Extraire des donn&#233;es ou du code informatique de
              l&#8217;Application sans autorisation &#233;crite <br />
            </p>
            <p>
              pr&#233;alable de Notre part, notamment adapter, modifier,
              traduire, transcrire, arranger, <br />
              compiler, d&#233;compiler, assembler, d&#233;sassembler,
              transcoder tout ou partie de l&#8217;Application.{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              &#9679; Utiliser l&#8217;Application d&#8217;une mani&#232;re
              ill&#233;gale ou en violation de ces CG ou de toute loi <br />
              applicable. <br />
            </p>
            <p>
              {' '}
              <br />
              <b>
                11.5 - Donn&#233;es du Compte <br />
              </b>
              Vous Vous engagez &#224; fournir des informations r&#233;elles et
              &#224; jour sur Votre Compte, celles-ci &#233;tant <br />
              utilis&#233;es au sein de l&#8217;Application. <br />
              Conform&#233;ment &#224; l&#8217;Article &#171; Donn&#233;es
              Personnelles &#187; et &#224; la Politique de
              Confidentialit&#233;, Vous pouvez <br />
              &#233;galement demander la suppression de Votre Compte. Dans ce
              cas, Vos Donn&#233;es Personnelles <br />
              seront supprim&#233;es par Nos soins, sauf si celles-ci sont
              n&#233;cessaires au respect de ses obligations <br />
              l&#233;gales et r&#233;glementaires par Nos soins, ou si elles ont
              &#233;t&#233; anonymis&#233;es. <br /> <br />
              <b>
                12 - Propri&#233;t&#233; Intellectuelle <br />
              </b>{' '}
              <br />
              <b>
                12.1 &#8211; Propri&#233;t&#233; intellectuelle sur
                l&#8217;Application <br />
              </b>
              L&#8217;Application, ainsi que tout &#233;l&#233;ment de celle-ci,
              en ce compris les textes, images, vid&#233;os, noms de <br />
              domaine, marques, dessins, mod&#232;les, brevets, logiciels, bases
              de donn&#233;es, sont : <br />
            </p>
            <p>
              &#9679; Notre propri&#233;t&#233;, ou ; <br />
              &#9679; le cas &#233;ch&#233;ant, la propri&#233;t&#233;
              d&#8217;un tiers Nous ayant fourni une licence
              d&#8217;utilisation. <br />
            </p>
            <p>
              {' '}
              <br />
              Ces &#233;l&#233;ments sont prot&#233;g&#233;s dans le monde
              entier. <br />
              Les pr&#233;sentes CG ne Vous accordent aucun droit de
              propri&#233;t&#233; intellectuelle. Vous ne pouvez pas <br />
              reproduire, repr&#233;senter, adapter, exploiter ou extraire aucun
              de ces &#233;l&#233;ments, de mani&#232;re partielle <br />
              ou totale, sans l&#8217;accord pr&#233;alable &#233;crit de Notre
              part. <br />
              Nous Vous autorisons, dans le cadre de l&#8217;Abonnement auquel
              Vous aurez souscrit, &#224; t&#233;l&#233;charger, <br />
              installer et utiliser l&#8217;Application sur Votre mat&#233;riel
              compatible, dans le monde entier, pour la dur&#233;e <br />
              d&#8217;installation de l&#8217;Application, et uniquement pour
              l&#8217;ex&#233;cution de l&#8217;Application. Vous pouvez
              acc&#233;der <br />
              &#224; et utiliser Votre Compte dans les m&#234;mes conditions.{' '}
              <br />
              Ces usages sont autoris&#233;s &#224; des fins personnelles.{' '}
              <br />
              Aucun autre usage de l&#8217;Application n&#8217;est
              autoris&#233;. <br />
              Toute reproduction partielle ou totale de ces &#233;l&#233;ments
              pourra constituer une contrefa&#231;on. Nous <br />
              nous r&#233;servons le droit d&#8217;exercer toute action qui
              serait n&#233;cessaire &#224; faire valoir ses droits et &#224;{' '}
              <br />
              r&#233;parer son pr&#233;judice. <br /> <br />
              <b>
                12.2 &#8211; Contenus envoy&#233;s sur l&#8217;Application{' '}
                <br />
              </b>
              Les contenus (textes, photographies, ou tout autre
              &#233;l&#233;ment) mis en ligne par Vos soins au sein de <br />
              l&#8217;Application sont Votre propri&#233;t&#233;.{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Vous garantissez disposer de tous les droits pour utiliser ces
              contenus avec l&#8217;Application. <br />
              Vous Nous autorisez &#224; utiliser ces contenus afin de permettre
              leur traitement par l&#8217;Application, y <br />
              compris via des sous-traitants, notamment pour l&#8217;utilisation
              de mod&#232;les d&#8217;intelligence artificielle <br />
              pour g&#233;n&#233;rer du contenu d&#233;riv&#233; de ces
              contenus, pour Votre usage au sein de l&#8217;Application. Aucun{' '}
              <br />
              autre usage de ces contenus ne sera effectu&#233;. <br />
              Il est &#233;galement rappel&#233; que tout &#233;l&#233;ment
              envoy&#233; sur l&#8217;Application peut faire l&#8217;objet
              d&#8217;une <br />
              mod&#233;ration a posteriori, avec ou sans signalement
              pr&#233;alable, de mani&#232;re discr&#233;tionnaire par Nos{' '}
              <br />
              soins. <br />
              Tout contenu envoy&#233; sur l&#8217;Application doit &#234;tre
              conforme au droit fran&#231;ais, et ne pas porter atteinte <br />
              &#224; un tiers. <br />
              Vous Nous garantissez contre toute action engag&#233;e par un
              tiers au titre de la violation d&#8217;un <br />
              quelconque droit du fait de la mise en ligne et de
              l&#8217;utilisation des contenus ou tout autre &#233;l&#233;ment{' '}
              <br />
              fournis par Vos soins. Cette garantie inclut en particulier, sans
              s&#8217;y limiter, les actions en mati&#232;re de <br />
              contrefa&#231;on, de concurrence d&#233;loyale ou parasitisme.{' '}
              <br /> <br />
              <b>
                13 - Donn&#233;es Personnelles <br />
              </b>
              La fourniture de l&#8217;Application implique la collecte de
              Donn&#233;es Personnelles par Nos soins, d&#8217;une <br />
              mani&#232;re conforme &#224; la l&#233;gislation et
              r&#233;glementation applicable. <br />
              Vous &#234;tes parfaitement inform&#233; que les Donn&#233;es
              Personnelles que Vous Nous soumettez <br />
              directement ou indirectement feront l&#8217;objet d&#8217;un
              traitement automatis&#233;. <br />
              Vous devez accepter Notre Politique de Confidentialit&#233;
              lorsque Vous Vous inscrivez sur l&#8217;Application <br />
              afin de pouvoir l&#8217;utiliser. <br />
              Notre Politique de Confidentialit&#233; peut &#234;tre
              consult&#233;e au sein de l&#8217;Application. <br />
              <b>
                {' '}
                <br />
                14 - Responsabilit&#233; <br />
              </b>
              Notre responsabilit&#233; est strictement limit&#233;e &#224; la
              prestation de fourniture technique de l&#8217;Application, <br />
              et &#224; son bon fonctionnement, au titre d&#8217;une obligation
              de moyens. <br />
              Les informations fournies au sein de l&#8217;Application ne le
              sont qu&#8217;&#224; titre purement informatif, sans <br />
              obligation de r&#233;sultat. Vous devez effectuer les
              v&#233;rifications d&#8217;usage avant toute utilisation de ces{' '}
              <br />
              informations. <br />
              Nous ne fournissons aucune autre prestation. <br />
              Nous n&#8217;engageons pas notre responsabilit&#233; au titre de
              la mise &#224; disposition, de la disponibilit&#233;, de la <br />
              maintenance et de l&#8217;utilisation de l&#8217;Application.
              Aucune garantie expresse ou implicite n&#8217;est fournie <br />
              au titre de la qualit&#233; de l&#8217;Application, de sa
              disponibilit&#233;, les informations qui y figurent, et leur{' '}
              <br />
              ad&#233;quation &#224; Vos besoins. <br />
              Vous &#234;tes inform&#233; que l&#8217;utilisation de
              l&#8217;Application est effectu&#233;e sous Votre propre
              responsabilit&#233;.{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              Nous ne serons pas non plus responsables des dommages caus&#233;s
              par des logiciels malveillants, des <br />
              virus ou par une quelconque inexactitude ou omission
              d'informations, sauf si les dommages <br />
              d&#233;coulent d'un acte d&#233;lib&#233;r&#233; ou d'une grave
              n&#233;gligence de Notre part. <br />
              Ces limitations de responsabilit&#233;s sont applicables dans la
              limite maximale permise par la loi <br />
              applicable. <br />
              <b>
                {' '}
                <br />
                15 - Clauses g&#233;n&#233;rales <br />
              </b>{' '}
              <br />
              <b>
                15.1 - M&#233;diation <br />
              </b>{' '}
              <br />
              Conform&#233;ment aux dispositions des articles L612-1 et suivants
              du Code de la consommation, en tant <br />
              que consommateur, Vous avez la possibilit&#233;, en cas de litige
              avec Nous, de recourir gratuitement au <br />
              service de m&#233;diation La Soci&#233;t&#233; M&#233;diation
              Professionnelle, si aucune r&#233;ponse n&#8217;a &#233;t&#233;
              apport&#233;e &#224; <br />
              Votre demande aupr&#232;s de Notre service client apr&#232;s un
              d&#233;lai raisonnable d&#8217;un (1) mois: <br />
            </p>
            <p>
              &#9679; par voie &#233;lectronique :
              http://www.mediateur-consommation-smp.fr/ <br />
              &#9679; ou par voie postale :
              <i>24 rue Albert de Mun - 33000 Bordeaux</i> <br />
            </p>
            <p>
              {' '}
              <br />
              Vous restez libre d&#8217;accepter ou de refuser le recours &#224;
              la m&#233;diation et, en cas de recours &#224; la <br />
              m&#233;diation, chaque Partie est libre d&#8217;accepter ou de
              refuser la solution propos&#233;e par le m&#233;diateur. <br />{' '}
              <br />
              <b>
                15.2 - Respect de la l&#233;gislation <br />
              </b>
              Nous fournissons l&#8217;Application dans le respect de la
              r&#233;glementation fran&#231;aise. <br />
              Vous devez prendre connaissance des lois locales et nationales
              afin de s&#8217;assurer que Vous pouvez <br />
              utiliser l&#8217;Application. <br /> <br />
              <b>
                15.3 - Int&#233;gralit&#233; de l&#8217;accord <br />
              </b>
              Les pr&#233;sentes CG pr&#233;valent sur toute autre stipulation
              potentiellement applicable &#224; la relation entre <br />
              Vous et Nous. Les pr&#233;sentes CG annulent et remplacent tout
              engagement ant&#233;rieur des Parties <br />
              relatif &#224; l&#8217;objet et constituent
              l'int&#233;gralit&#233; de l&#8217;accord entre les Parties eu
              &#233;gard &#224; l&#8217;objet des <br />
              pr&#233;sentes. <br /> <br />
              <b>
                15.4 - Survie de certaines stipulations <br />
              </b>
              La fin des pr&#233;sentes CG, pour quelque cause que ce soit, ne
              saurait valoir terme pour les clauses <br />
              dont la nature ou le contenu n&#233;cessite leur maintien. <br />{' '}
              <br />
              <b>
                15.5 - Non-validit&#233; partielle <br />
              </b>
              Si une ou plusieurs stipulations des pr&#233;sentes CG sont tenues
              pour non valides ou d&#233;clar&#233;es comme <br />
              telles en application d&#8217;une loi, d&#8217;un r&#232;glement
              ou &#224; la suite d&#8217;une d&#233;cision d&#233;finitive
              d&#8217;une juridiction{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              comp&#233;tente, elles seront r&#233;put&#233;es non-&#233;crites
              et les autres stipulations garderont toute leur force et <br />
              leur port&#233;e. <br /> <br />
              <b>
                15.6 - Non-renonciation et tol&#233;rance <br />
              </b>
              Le fait pour Nous de ne pas Nous pr&#233;valoir aupr&#232;s de
              Vous d&#8217;un manquement &#224; l&#8217;une quelconque <br />
              des obligations vis&#233;es au sein des pr&#233;sentes CG ne
              saurait &#234;tre interpr&#233;t&#233; pour l&#8217;avenir comme
              une <br />
              renonciation &#224; l&#8217;obligation en cause et n'a pas pour
              effet de Vous accorder des droits acquis. <br />
              Un d&#233;faut ou un retard dans l&#8217;exercice d&#8217;un de
              Nos droits ne peut &#234;tre interpr&#233;t&#233; comme une <br />
              renonciation &#224; ce droit. <br /> <br />
              <b>
                15.7 - Ind&#233;pendance <br />
              </b>
              Aucune des Parties ne peut prendre un engagement au nom et/ou pour
              le compte de l&#8217;autre. En <br />
              outre, chacune des Parties demeure seule responsable de ses actes,
              all&#233;gations, engagements, <br />
              service, produits et personnels. <br /> <br />
              <b>
                15.8 - Preuve <br />
              </b>
              Les Parties acceptent que l&#8217;ensemble des communications
              &#233;lectroniques, notamment courriel, fasse <br />
              preuve entre elles. <br />
              Vous reconnaissez notamment la valeur de preuve de Nos
              syst&#232;mes d'enregistrement automatique, <br />
              et notamment de l&#8217;Application, sauf pour Vous d'apporter une
              preuve contraire, Vous renoncez &#224; les <br />
              contester en cas de litige. <br /> <br />
              <b>
                15.9 - Force majeure <br />
              </b>
              Dans le cadre de l&#8217;ex&#233;cution des pr&#233;sentes CG,
              Nous ne serons pas tenus pour responsables si le <br />
              service ne fonctionne plus en raison de tout &#233;v&#233;nement
              de force majeure. <br />
              Pour les besoins des pr&#233;sentes CG, la force majeure
              s&#8217;entend comme tout &#233;v&#232;nement impr&#233;visible,{' '}
              <br />
              irr&#233;sistible et ext&#233;rieur aux Parties au sens du droit
              et de la jurisprudence fran&#231;aise. <br />
              En cas de survenance d&#8217;un cas de force majeure, Nous ferons
              Nos meilleurs efforts pour permettre <br />
              le fonctionnement de l&#8217;Application. <br />
              Si la force majeure subsiste, Nous pourrons mettre fin &#224; la
              fourniture de l&#8217;Application, sans pr&#233;avis et <br />
              sans engager Notre responsabilit&#233;. <br /> <br />
              <b>
                15.10 - Juridiction comp&#233;tente <br />
              </b>
              Tout litige relatif &#224; l&#8217;ex&#233;cution ou
              l&#8217;interpr&#233;tation des pr&#233;sentes CG, non r&#233;solu
              &#224; l&#8217;amiable entre les <br />
              Parties, sera soumis au Tribunal comp&#233;tent du si&#232;ge de
              Notre entreprise, y compris en mati&#232;re de{' '}
            </p>
          </div>
        </div>
        <div style={{ pageBreakBefore: 'always', pageBreakAfter: 'always' }}>
          <div>
            <p>
              r&#233;f&#233;r&#233;, d&#8217;un appel en garantie ou d&#8217;une
              pluralit&#233; de d&#233;fendeurs, et quel que soit Votre pays{' '}
              <br />
              d&#8217;origine. <br />{' '}
            </p>
          </div>
        </div>
      </div>
    </LayoutWithHeader>
  );
}
