import ParentChildOtherDevice from 'src/components/Onboarding/ParentChildOtherDevice';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const ParentChildOtherDevicePage = () => {
  const { childId } = useParams();
  const navigate = useNavigate();

  const onFinish = async () => {
    navigate(`/`);
  };

  return (
    <>
      <ParentChildOtherDevice childId={Number(childId)} onFinish={onFinish} />
      <Outlet />
    </>
  );
};

export default ParentChildOtherDevicePage;
