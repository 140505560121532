import { useTranslation } from 'react-i18next';
import { type PropsWithTestId } from 'src/types';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { Calendar } from 'antd';
import 'src/components/common/designSystem/DatePicker.scss';
import Button from 'src/components/common/designSystem/Button';
import { useState } from 'react';
import dayjs from 'dayjs';

const DatePicker = ({
  isOpen,
  title,
  onClose,
  value,
  onConfirm,
  'data-testid': dataTestId,
  confirmText,
  cancelText,
}: {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  onConfirm: (date: Date) => void;
  value?: Date;
  confirmText?: string;
  cancelText?: string;
} & PropsWithTestId) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(dayjs(value));

  return (
    <AutoFloatingPanel
      isOpen={Boolean(isOpen)}
      onClose={onClose}
      data-testid={dataTestId}
    >
      <div className={'p-4 flex flex-col justify-center items-center gap-4'}>
        {title ? (
          <div className={'text-base text-center text-balance font-bold'}>
            {title}
          </div>
        ) : null}
        <Calendar
          fullscreen={false}
          onSelect={date => {
            setSelectedDate(dayjs(date));
          }}
          value={selectedDate}
          headerRender={config => {
            return (
              <div className={'text-xl font-bold capitalize mb-4 mt-2'}>
                {config.value.format('MMMM YYYY')}
              </div>
            );
          }}
        />
        <div className={'flex flex-col gap-1 w-full'}>
          <Button
            disabled={!selectedDate}
            data-testid={'btn-confirm'}
            onClick={() => {
              onConfirm(selectedDate.toDate());
            }}
            type={'primary'}
          >
            {confirmText ?? t('global.add')}
          </Button>
          <Button type={'default'} data-testid={'btn-skip'} onClick={onClose}>
            {cancelText ?? t('global.askLater')}
          </Button>
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default DatePicker;
