export default {
  title: 'Tableau de bord',
  users: {
    title: 'Membres de la famille',
    addUser: 'Ajouter un enfant',
    firstname: 'Prénom',
    dateOfBirth: 'Année de naissance',
    schoolLevel: 'Classe',
    familyMemberType: 'Genre',
    failToAddChild: "Impossible d'ajouter {{firstname}}",
    failToAddSchool: "Impossible d'enregistrer l'école",
    switch: "Changer d'utilisateur",
    lessons: {
      title: 'Révisions en cours',
      empty: {
        title: 'Aucune révision en cours',
        button: 'Inviter mon enfant',
      },
    },
    streaks: {
      title: 'Jours de révision',
    },
  },
};
