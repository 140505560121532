import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ClockIcon from 'src/images/clock.svg?react';

export default function LessonCardEvaluationDate({
  evaluationDate,
}: {
  evaluationDate: string;
}) {
  const { t } = useTranslation();

  return (
    <div
      className={
        'flex flex-row items-center justify-center p-2 rounded-lg gap-1 bg-primary-lightest bg-opacity-20'
      }
    >
      <ClockIcon className={'w-4 h-4'} />
      <div className={'font-bold text-base leading-none'}>
        {dayjs(evaluationDate).isBefore()
          ? t('lessons.evaluation.daysInPassed', {
              days: Math.abs(dayjs(evaluationDate).diff(dayjs(), 'days')),
            })
          : t('lessons.evaluation.daysIn', {
              days: dayjs(evaluationDate).diff(dayjs(), 'days') + 1,
            })}
      </div>
    </div>
  );
}
