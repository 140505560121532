import QuizStarsIcon from 'src/components/Quiz/QuizStarsIcon';
import PrimaryCheckIcon from 'src/components/common/PrimaryCheckIcon';
import QuizAnswerWrongIcon from 'src/components/Quiz/QuizAnswerWrongIcon';

const QuizAnswer = ({
  isSelected,
  isCorrect,
  showCorrection,
  onClick,
  id,
}: {
  showCorrection: boolean;
  onClick?: () => void;
  isSelected: boolean;
  isCorrect: boolean;
  id: string;
}) => {
  const getAnswerColor = () => {
    if (showCorrection) {
      if (isCorrect) {
        return 'bg-gradient-to-r';
      } else if (isSelected) {
        return 'bg-red-darkest';
      }
      return '';
    }
    if (isSelected) {
      return 'bg-gradient-to-r';
    }
    return '';
  };

  const getLetterByIndex = () => {
    if (showCorrection) {
      if (isCorrect) {
        return <PrimaryCheckIcon />;
      }
      if (isSelected) {
        return <QuizAnswerWrongIcon />;
      }
    }
    return '';
  };

  return (
    <div
      data-testid={`answer-${id}`}
      onClick={onClick}
      className={`w-full items-center from-paywall-darkest-from to-paywall-darkest-to rounded-xl p-0.5 ${getAnswerColor()}`}
    >
      <div
        className={`rounded-[0.625rem] flex bg-gray-100 items-center relative text-base text-balance gap-4 text-structural-darker font-extrabold text-left justify-between cursor-pointer px-4 h-12`}
      >
        <div
          className={'overflow-ellipsis line-clamp-2 w-full overflow-hidden'}
        >
          {id}
        </div>
        <div>{getLetterByIndex()}</div>
        {isCorrect && isSelected && showCorrection ? <QuizStarsIcon /> : null}
      </div>
    </div>
  );
};

export default QuizAnswer;
