import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import Milestone from 'src/components/Milestones/Milestone';
import Sciences from 'src/images/milestones/sciences.svg?react';
import Geography from 'src/images/milestones/geography.svg?react';
import History from 'src/images/milestones/history.svg?react';
import Mathematics from 'src/images/milestones/mathematics.svg?react';

import { MilestoneType } from 'src/types/milestone.types';

type AllowedMilestoneType =
  | MilestoneType.sciences
  | MilestoneType.mathematics
  | MilestoneType.geography
  | MilestoneType.history;

const infos: Record<
  AllowedMilestoneType,
  {
    illustration: ReactNode;
    title: string;
  }
> = {
  [MilestoneType.sciences]: {
    illustration: <Sciences />,
    title: 'milestones.lessons.sciences',
  },
  [MilestoneType.mathematics]: {
    illustration: <Mathematics />,
    title: 'milestones.lessons.mathematics',
  },
  [MilestoneType.geography]: {
    illustration: <Geography />,
    title: 'milestones.lessons.geography',
  },
  [MilestoneType.history]: {
    illustration: <History />,
    title: 'milestones.lessons.history',
  },
};

const MilestoneSubject = ({ type }: { type: AllowedMilestoneType }) => {
  const { t } = useTranslation();
  const { illustration, title } = infos[type];

  return <Milestone illustration={illustration} title={t(title)} />;
};

export default MilestoneSubject;
