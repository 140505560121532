import * as React from "react";
const SvgMathematics = (props) => /* @__PURE__ */ React.createElement("svg", { width: 126, height: 148, viewBox: "0 0 126 148", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_780_28019)" }, /* @__PURE__ */ React.createElement("path", { d: "M117.662 125.29L47.9327 147.431C41.6407 149.427 34.9157 145.945 32.9203 139.652L0.560603 37.7263C-1.43481 31.433 2.04655 24.7065 8.33849 22.7106L78.0677 0.56073C84.3596 -1.43514 91.0846 2.04701 93.08 8.34037L125.44 110.274C127.435 116.567 123.954 123.294 117.662 125.29Z", fill: "#EA9CBB" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M78.0678 0.56073L74.043 1.83469C80.3349 -0.161179 87.0599 3.32097 89.0553 9.61433L121.415 111.548C123.41 117.841 119.929 124.568 113.637 126.564L117.662 125.29C123.954 123.294 127.435 116.567 125.44 110.274L93.0801 8.34037C91.0847 2.04701 84.3597 -1.43514 78.0678 0.56073Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
} }, /* @__PURE__ */ React.createElement("path", { d: "M81.9406 15.5834L13.828 37.208C11.9016 37.8196 10.8355 39.8775 11.447 41.8044L16.893 58.9659C17.5045 60.8928 19.5619 61.9591 21.4884 61.3474L89.601 39.7229C91.5275 39.1113 92.5935 37.0534 91.982 35.1265L86.536 17.965C85.9245 16.0381 83.8671 14.9718 81.9406 15.5834Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M35.8932 66.4591L24.3038 70.1385C23.1059 70.5188 22.443 71.7985 22.8232 72.9966L26.5019 84.5887C26.8821 85.7869 28.1614 86.4499 29.3593 86.0696L40.9488 82.3901C42.1467 82.0098 42.8095 80.7302 42.4293 79.532L38.7507 67.9399C38.3705 66.7418 37.0911 66.0788 35.8932 66.4591Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M36.5545 66.3568C36.6733 66.5351 36.7837 66.7305 36.8517 66.9513L40.5283 78.5358C40.9104 79.7334 40.2481 81.0158 39.0424 81.398L27.4605 85.0755C27.2397 85.1434 27.0274 85.1689 26.8066 85.1689C27.3586 85.9758 28.386 86.3749 29.371 86.0607L40.9529 82.3832C42.1501 82.001 42.8124 80.7185 42.4388 79.521L38.7622 67.9365C38.448 66.9513 37.5309 66.3398 36.5545 66.3568Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M54.7194 60.4825L43.1299 64.162C41.932 64.5423 41.2692 65.8219 41.6494 67.0201L45.328 78.6122C45.7083 79.8103 46.9876 80.4733 48.1855 80.093L59.775 76.4136C60.9729 76.0333 61.6357 74.7537 61.2555 73.5555L57.5769 61.9634C57.1966 60.7652 55.9173 60.1022 54.7194 60.4825Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M55.3787 60.3773C55.4976 60.5557 55.6079 60.751 55.6759 60.9718L59.3525 72.5563C59.7346 73.7539 59.0723 75.0363 57.8666 75.4185L46.2847 79.096C46.0639 79.1639 45.8431 79.1894 45.6309 79.1894C46.1828 79.9963 47.2102 80.3954 48.1952 80.0812L59.7771 76.4037C60.9743 76.0215 61.6367 74.7391 61.263 73.5415L57.5864 61.957C57.2722 60.9718 56.3552 60.3603 55.3787 60.3773Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M73.5456 54.505L61.9561 58.1844C60.7582 58.5647 60.0953 59.8444 60.4756 61.0425L64.1542 72.6346C64.5344 73.8328 65.8137 74.4958 67.0116 74.1155L78.6011 70.436C79.799 70.0557 80.4619 68.7761 80.0817 67.5779L76.403 55.9858C76.0228 54.7877 74.7435 54.1247 73.5456 54.505Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M74.2049 54.3988C74.3237 54.5771 74.4341 54.7725 74.502 54.9933L78.1787 66.5778C78.5608 67.7753 77.8985 69.0578 76.6928 69.44L65.1108 73.1175C64.8901 73.1854 64.6778 73.2109 64.457 73.2109C65.009 74.0177 66.0364 74.4169 67.0214 74.1027L78.6033 70.4252C79.8005 70.043 80.4628 68.7605 80.0892 67.563L76.4125 55.9785C76.0984 54.9933 75.1813 54.3818 74.2049 54.3988Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M92.3718 48.5284L80.7823 52.2079C79.5844 52.5882 78.9215 53.8678 79.3017 55.066L82.9804 66.6581C83.3606 67.8562 84.6399 68.5192 85.8378 68.1389L97.4273 64.4595C98.6252 64.0792 99.2881 62.7996 98.9078 61.6014L95.2292 50.0093C94.849 48.8111 93.5697 48.1481 92.3718 48.5284Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M93.0291 48.4281C93.1479 48.6064 93.2583 48.8018 93.3263 49.0226L97.0029 60.6071C97.385 61.8046 96.7227 63.0871 95.517 63.4693L83.9351 67.1468C83.7143 67.2147 83.502 67.2402 83.2812 67.2402C83.8332 68.047 84.8606 68.4462 85.8456 68.132L97.4275 64.4545C98.6247 64.0723 99.287 62.7898 98.9134 61.5923L95.2368 50.0078C94.9226 49.0226 94.0056 48.4111 93.0291 48.4281Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M42.287 86.5709L30.6982 90.2524C29.5003 90.6329 28.8377 91.9127 29.2181 93.1108L32.8988 104.702C33.2792 105.9 34.5587 106.563 35.7565 106.183L47.3453 102.501C48.5432 102.121 49.2058 100.841 48.8254 99.6428L45.1447 88.0513C44.7643 86.8532 43.4848 86.1904 42.287 86.5709Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M42.9392 86.4769C43.0581 86.6553 43.1685 86.8506 43.2364 87.0714L46.9131 98.656C47.2952 99.8535 46.6329 101.136 45.4271 101.518L33.8452 105.196C33.6245 105.264 33.4037 105.289 33.1914 105.289C33.7433 106.096 34.7708 106.495 35.7557 106.181L47.3376 102.503C48.5349 102.121 49.1972 100.839 48.8236 99.6411L45.1469 88.0566C44.8328 87.0714 43.9157 86.4599 42.9392 86.4769Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M61.1003 80.6065L49.5108 84.286C48.3129 84.6663 47.65 85.9459 48.0303 87.1441L51.7089 98.7362C52.0891 99.9344 53.3684 100.597 54.5663 100.217L66.1558 96.5376C67.3537 96.1573 68.0166 94.8777 67.6364 93.6795L63.9577 82.0874C63.5775 80.8892 62.2982 80.2262 61.1003 80.6065Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M61.7654 80.4974C61.8843 80.6758 61.9947 80.8711 62.0626 81.0919L65.7393 92.6765C66.1214 93.874 65.4591 95.1564 64.2533 95.5386L52.6714 99.2161C52.4506 99.2841 52.2383 99.3095 52.0176 99.3095C52.5695 100.116 53.5969 100.516 54.5819 100.201L66.1638 96.5238C67.3611 96.1416 68.0234 94.8592 67.6498 93.6617L63.9731 82.0771C63.6589 81.0919 62.7419 80.4804 61.7654 80.4974Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M79.9265 74.6212L68.337 78.3006C67.1391 78.681 66.4762 79.9606 66.8564 81.1587L70.5351 92.7508C70.9153 93.949 72.1946 94.612 73.3925 94.2317L84.982 90.5522C86.1799 90.1719 86.8428 88.8923 86.4625 87.6942L82.7839 76.102C82.4037 74.9039 81.1243 74.2409 79.9265 74.6212Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M80.5896 74.5179C80.7085 74.6963 80.8189 74.8916 80.8868 75.1124L84.5635 86.697C84.9456 87.8945 84.2833 89.1769 83.0775 89.5591L71.4956 93.2366C71.2748 93.3046 71.0626 93.33 70.8418 93.33C71.3937 94.1369 72.4211 94.5361 73.4061 94.2218L84.988 90.5443C86.1853 90.1621 86.8476 88.8797 86.474 87.6822L82.7973 76.0976C82.4831 75.1124 81.5661 74.5009 80.5896 74.5179Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M98.7507 68.6446L87.1612 72.3241C85.9633 72.7044 85.3004 73.984 85.6806 75.1822L89.3593 86.7743C89.7395 87.9725 91.0188 88.6355 92.2167 88.2551L103.806 84.5757C105.004 84.1954 105.667 82.9158 105.287 81.7176L101.608 70.1255C101.228 68.9273 99.9486 68.2643 98.7507 68.6446Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M99.4158 68.5394C99.5347 68.7178 99.6451 68.9131 99.713 69.1339L103.39 80.7185C103.772 81.916 103.109 83.1984 101.904 83.5806L90.3218 87.2581C90.101 87.326 89.8887 87.3515 89.668 87.3515C90.2199 88.1584 91.2473 88.5575 92.2323 88.2433L103.814 84.5658C105.011 84.1836 105.674 82.9012 105.3 81.7036L101.623 70.1191C101.309 69.1339 100.392 68.5224 99.4158 68.5394Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M48.6608 106.691L37.0713 110.37C35.8734 110.75 35.2106 112.03 35.5908 113.228L39.2694 124.82C39.6497 126.018 40.929 126.681 42.1269 126.301L53.7164 122.622C54.9143 122.241 55.5771 120.962 55.1969 119.763L51.5183 108.171C51.138 106.973 49.8587 106.31 48.6608 106.691Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M49.326 106.597C49.4448 106.775 49.5552 106.971 49.6231 107.192L53.2998 118.776C53.6819 119.974 53.0196 121.256 51.8139 121.638L40.2319 125.316C40.0112 125.384 39.7904 125.409 39.5781 125.409C40.13 126.216 41.1575 126.615 42.1424 126.301L53.7244 122.623C54.9216 122.241 55.5839 120.959 55.2103 119.761L51.5336 108.177C51.2195 107.192 50.3024 106.58 49.326 106.597Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M86.3143 94.7399L57.292 103.954C56.0941 104.334 55.4313 105.614 55.8115 106.812L59.4901 118.404C59.8704 119.602 61.1497 120.265 62.3476 119.885L91.3699 110.671C92.5678 110.291 93.2306 109.011 92.8504 107.813L89.1718 96.2207C88.7916 95.0226 87.5122 94.3596 86.3143 94.7399Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M86.9751 94.638C87.0939 94.8164 87.2043 95.0117 87.2722 95.2326L90.9489 106.817C91.331 108.015 90.6687 109.297 89.463 109.679L60.4487 118.894C60.228 118.962 60.0157 118.988 59.7949 118.988C60.3468 119.794 61.3743 120.194 62.3592 119.879L91.3735 110.664C92.5707 110.282 93.233 109 92.8594 107.802L89.1828 96.2177C88.8686 95.2326 87.9515 94.6211 86.9751 94.638Z", fill: "#EA9CBB" })), /* @__PURE__ */ React.createElement("path", { d: "M105.149 88.7657L93.5596 92.4452C92.3617 92.8255 91.6989 94.1051 92.0791 95.3033L95.7577 106.895C96.1379 108.094 97.4173 108.757 98.6152 108.376L110.205 104.697C111.403 104.316 112.065 103.037 111.685 101.839L108.007 90.2466C107.626 89.0484 106.347 88.3854 105.149 88.7657Z", fill: "white" }), /* @__PURE__ */ React.createElement("g", { style: {
  mixBlendMode: "multiply"
}, opacity: 0.29 }, /* @__PURE__ */ React.createElement("path", { d: "M105.799 88.6595C105.917 88.8379 106.028 89.0332 106.096 89.254L109.772 100.839C110.155 102.036 109.492 103.319 108.287 103.701L96.7046 107.378C96.4838 107.446 96.2716 107.472 96.0508 107.472C96.6027 108.278 97.6301 108.678 98.6151 108.363L110.197 104.686C111.394 104.304 112.057 103.021 111.683 101.824L108.006 90.2392C107.692 89.254 106.775 88.6425 105.799 88.6595Z", fill: "#EA9CBB" }))), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_780_28019" }, /* @__PURE__ */ React.createElement("rect", { width: 126, height: 148, fill: "white" }))));
export default SvgMathematics;
