import { Button as AntdButton, type ButtonProps } from 'antd';
import { useRef } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';

const TrackedButton = ({ onClick, ...props }: ButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <AntdButton
      ref={ref}
      onClick={event => {
        if (onClick) {
          onClick(event);
        }
        logAnalyticsEvent('button_click', {
          buttonName: props['data-testid'],
          buttonTextContent: ref.current?.textContent,
        });
      }}
      {...props}
    />
  );
};

export default TrackedButton;
