import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNavigate } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import ParentChildFirstnameStep from 'src/components/Onboarding/ParentChildFirstnameStep';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import { Child } from 'src/types/user.types';

const ParentChildFirstname = () => {
  const navigate = useNavigate();
  const onNext = (child: Child) => {
    navigate(`/child/${child.id}/gender`);
  };

  return (
    <LayoutWithHeader
      header={<OnboardingHeader step={2} />}
      statusBar={<StatusBar color="#FAFAFB" style={Style.Light} />}
      className={'bg-structural-lightest'}
    >
      <ParentChildFirstnameStep onFinish={onNext} />
    </LayoutWithHeader>
  );
};

export default ParentChildFirstname;
