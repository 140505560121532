import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import { lessonIsProcessing } from 'src/utils/lessonsUtils';
import Footer from 'src/components/common/designSystem/Footer';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { DotLoading } from 'antd-mobile';
import AddContentButton from 'src/components/LessonDetail/AddContentButton';
import { useNavigate } from 'react-router-dom';

const LessonFooter = () => {
  const { lesson } = useLesson();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isProcessing = lessonIsProcessing(lesson);

  const handleQuizClick = async () => {
    if (!lesson.latest_quiz) {
      return;
    }
    navigate(`/lessons/${lesson.id}/quizzes/${lesson.latest_quiz.id}`);
  };
  const goBack = () => {
    navigate(-1);
  };

  if (isProcessing) {
    return (
      <Footer>
        <div
          className={
            'text-primary-darker h-10 flex justify-center items-center'
          }
        >
          <DotLoading color="primary" />
          {t('lessons.status.processing')}
        </div>
        <Button size="large" type="primary" onClick={goBack}>
          {t('global.back')}
        </Button>
      </Footer>
    );
  }

  return (
    <Footer>
      <AddContentButton />
      {lesson.lesson_pieces.length ? (
        <Button
          data-testid="btn-start-quiz"
          size="large"
          type="primary"
          disabled={!lesson.latest_quiz}
          onClick={handleQuizClick}
        >
          {t('lessons.startAQuiz')}
        </Button>
      ) : null}
    </Footer>
  );
};

export default LessonFooter;
