import { useMemo } from 'react';
import { shuffle } from 'remeda';
import QuizCompleteSentenceAnswer from 'src/components/Quiz/CompleteSentenceQuestion/QuizCompleteSentenceAnswer';
import { QuestionStatus, useQuiz } from 'src/components/Quiz/QuizContext';
import { CompleteSentenceQuestion } from 'src/types/quiz.types';

const QuizCompleteSentenceAnswers = ({
  completions,
  question,
  onClickAnswer,
}: {
  question: CompleteSentenceQuestion;
  completions: string[];
  onClickAnswer: (answer: string) => void;
}) => {
  const { status } = useQuiz();
  const answers = useMemo(() => {
    return shuffle([...question.correct, ...question.incorrect]);
  }, [question.correct, question.incorrect]);

  return (
    <div
      className={'flex flex-row flex-wrap gap-3 justify-center items-center'}
    >
      {answers.map((answer, index) => (
        <QuizCompleteSentenceAnswer
          key={index}
          answer={answer}
          completions={completions}
          onClick={
            status === QuestionStatus.WAITING
              ? () => {
                  onClickAnswer(answer);
                }
              : undefined
          }
        />
      ))}
    </div>
  );
};

export default QuizCompleteSentenceAnswers;
