import { List } from 'antd-mobile';
import CustomAvatar from 'src/components/common/designSystem/Avatar';
import { useCurrentUser } from 'src/queries/user';
import { useGetUsers } from 'src/queries/users';
import { familyMemberEmoji } from 'src/utils/usersUtils';
import { useSwitchUser } from 'src/queries/sessions';
import { User } from 'src/types/user.types';

function UserSwitch({ onSwitch }: { onSwitch: () => void }) {
  const { data: currentUser } = useCurrentUser();
  const { data: userList } = useGetUsers();
  const { mutateAsync: switchUser, isLoading } = useSwitchUser();
  const userFiltered = userList?.filter(
    user => user.id !== currentUser?.id && user.firstname?.length,
  );
  const onClickSwitchUser = async (user: User) => {
    if (!user.id) return;
    await switchUser(user.id);
    onSwitch();
  };

  return (
    <List>
      {userFiltered?.map(user => (
        <List.Item
          key={user.id}
          arrow={false}
          disabled={isLoading}
          data-testid={`user-switch-${user.firstname}-${user.lastname ?? ''}`}
          onClick={() => {
            onClickSwitchUser(user);
          }}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <CustomAvatar name={user.firstname} />
              {user.firstname}
            </div>
            <span className="text-2xl">
              {familyMemberEmoji(user.family_member_type)}
            </span>
          </div>
        </List.Item>
      ))}
    </List>
  );
}

export default UserSwitch;
