import { useMutation } from 'react-query';
import axios from 'axios';
import { AppReview } from 'src/types/app_review.types';

export const useAddAppReview = () => {
  return useMutation({
    mutationFn: async ({
      rate,
      comment,
    }: {
      rate: number;
      comment?: string;
    }) => {
      const { data } = await axios.post<AppReview>(`/api/app_reviews`, {
        rate,
        comment,
      });
      return data;
    },
  });
};

export const useUpdateAppReview = () => {
  return useMutation({
    mutationFn: async ({
      id,
      rate,
      comment,
    }: {
      id: number;
      rate?: number;
      comment?: string;
    }) => {
      const { data } = await axios.put<AppReview>(`/api/app_reviews/${id}`, {
        rate,
        comment,
      });
      return data;
    },
  });
};
