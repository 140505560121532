import type { Dayjs } from 'dayjs';

import { Streak } from 'src/types/streak.types';

export const isStreakCompleted = (day: Dayjs, existingStreaks: Streak[]) => {
  const streakDate = day.format('YYYY-MM-DD');
  return Boolean(
    existingStreaks.find(streak => streak.streakDate === streakDate),
  );
};
