import { Swiper, SwiperRef } from 'antd-mobile';
import { useRef } from 'react';
import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import LogoMonkey from 'src/images/logo-monkey.svg?react';

const OnboardingFirstLessonSwiper = ({
  onFinish,
}: {
  onFinish: () => void;
}) => {
  const { t } = useTranslation();
  const ref = useRef<SwiperRef>(null);

  return (
    <Swiper
      allowTouchMove
      ref={ref}
      indicatorProps={{
        style: {
          '--active-dot-color': 'black',
        },
      }}
    >
      <Swiper.Item>
        <div
          className={
            'flex flex-col gap-6 items-center justify-between h-full p-8'
          }
        >
          <div className={'w-full flex items-center justify-center'}>
            <LogoMonkey className="h-24 w-auto" />
          </div>
          <div className={'font-bold text-2xl text-center text-balance'}>
            {t('onboarding.firstLesson.1.title')}
          </div>
          <div className={'text-base mb-3'}>
            {t('onboarding.firstLesson.1.description')}
          </div>
          <Button
            type={'primary'}
            data-testid={'btn-next-first'}
            onClick={() => ref.current?.swipeNext()}
          >
            {t('global.next')}
          </Button>
        </div>
      </Swiper.Item>
      <Swiper.Item>
        <div
          className={
            'flex flex-col gap-6 items-start justify-between h-full p-8'
          }
        >
          <div className={'text-7xl'}>🎙</div>
          <div className={'font-bold text-2xl text-balance'}>
            {t('onboarding.firstLesson.2.title')}
          </div>
          <div className={'flex flex-col gap-3 mb-3'}>
            <div className={'text-base'}>
              {t('onboarding.firstLesson.2.description')}
            </div>
            <div className={'text-base'}>
              {t('onboarding.firstLesson.2.description2')}
            </div>
          </div>
          <Button
            type={'primary'}
            data-testid={'btn-next-second'}
            onClick={() => ref.current?.swipeNext()}
          >
            {t('global.next')}
          </Button>
        </div>
      </Swiper.Item>
      <Swiper.Item>
        <div
          className={
            'flex flex-col gap-6 items-start justify-between h-full p-8'
          }
        >
          <div className={'text-7xl'}>🧙</div>
          <div className={'font-bold text-2xl text-balance'}>
            {t('onboarding.firstLesson.3.title')}
          </div>
          <div className={'flex flex-col gap-3 mb-3'}>
            <div className={'text-base'}>
              {t('onboarding.firstLesson.3.description')}
            </div>
            <div className={'text-base'}>
              {t('onboarding.firstLesson.3.description2')}
            </div>
          </div>
          <Button
            type={'primary'}
            data-testid={'btn-next-third'}
            onClick={() => ref.current?.swipeNext()}
          >
            {t('global.next')}
          </Button>
        </div>
      </Swiper.Item>
      <Swiper.Item>
        <div
          className={
            'flex flex-col gap-6 items-start justify-between h-full p-8'
          }
        >
          <div className={'text-7xl'}>🙌</div>
          <div className={'font-bold text-2xl text-balance'}>
            {t('onboarding.firstLesson.4.title')}
          </div>
          <div className={'flex flex-col gap-3 mb-3'}>
            <div className={'text-base'}>
              {t('onboarding.firstLesson.4.description')}
            </div>
            <div className={'text-base'}>
              {t('onboarding.firstLesson.4.description2')}
            </div>
          </div>
          <Button
            type={'primary'}
            data-testid={'btn-lets-go'}
            onClick={onFinish}
          >
            {t('global.letsGo')}
          </Button>
        </div>
      </Swiper.Item>
    </Swiper>
  );
};

export default OnboardingFirstLessonSwiper;
