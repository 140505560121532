import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLessonPiece } from 'src/queries/lesson_pieces';
import { LeftOutline } from 'antd-mobile-icons';
import Spinner from 'src/components/common/designSystem/Spinner';
import TextTyping from 'src/components/common/animations/TextTyping';

import { useGetLesson } from 'src/queries/lessons';
import { getSubjectInfo } from 'src/utils/subject';
import LessonPieceErrorPanel from 'src/components/LessonDetail/LessonPieceErrorPanel';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import AddLessonEvaluationDate from 'src/components/AddLesson/AddLessonEvaluationDate';
import LessonOtherAudioPiecePanel from 'src/components/Piece/LessonOtherAudioPiecePanel';

const LessonPieceProcessing = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pieceId, lessonId } = useParams();
  const { data: lesson } = useGetLesson(Number(lessonId));
  const { data: lessonPiece, isError } = useGetLessonPiece(
    Number(lessonId),
    Number(pieceId),
  );
  const [showEvaluationDate, setShowEvaluationDate] = useState(false);
  const [showEndingPanel, setShowEndingPanel] = useState(false);
  const typingFinished = useRef(false);

  const hasTranscription = lessonPiece?.raw_text_content
    ? lessonPiece.raw_text_content.length > 0
    : false;
  const hasEvaluationDate = Boolean(lesson?.evaluation_date);

  useEffect(() => {
    if (isError) {
      navigate(`/home`, { replace: true });
    }
  }, [isError]);

  const onFinishTyping = () => {
    typingFinished.current = true;
    setTimeout(() => {
      setShowEndingPanel(true);
    }, 200);
  };

  const onCloseEndingPanel = () => {
    setShowEndingPanel(false);
    if (!hasEvaluationDate && typingFinished.current) {
      setShowEvaluationDate(true);
    } else {
      navigate(`/home`, { replace: true });
    }
  };

  const onCloseEvaluationDate = () => {
    setShowEvaluationDate(false);
    navigate(`/home`, { replace: true });
  };

  const lessonSubject = lesson
    ? getSubjectInfo(lesson.subject_matter)
    : undefined;

  useEffect(() => {
    if (!showEvaluationDate && lessonPiece?.post_process_failed) {
      setTimeout(() => {
        navigate(`/home`, { replace: true });
      }, 5000);
    }
  }, [lessonPiece?.post_process_failed, navigate, showEvaluationDate]);

  return (
    <BlankPrimaryLayout>
      <div className="w-full h-full flex flex-col justify-between text-white p-8">
        <div className={'flex flex-row items-center justify-between'}>
          <LeftOutline
            fontSize={24}
            data-testid={'btn-back'}
            onClick={() => navigate(-1)}
          />
          <div className={'text-center'}>
            <h1 className="text-base font-semibold capitalize">
              {lesson?.title}
            </h1>
            {lessonSubject ? (
              <div className="text-xs">{t(lessonSubject.labelKey)}</div>
            ) : null}
          </div>
          <div className={'w-6'} />
        </div>
        <div
          className={
            'flex flex-col items-center justify-end flex-1 overflow-hidden'
          }
        >
          {hasTranscription ? (
            <TextTyping
              onFinish={onFinishTyping}
              text={lessonPiece?.raw_text_content ?? ''}
            />
          ) : (
            <Spinner size="xl" type={'white'} />
          )}
        </div>
      </div>
      <LessonOtherAudioPiecePanel
        isOpen={showEndingPanel}
        onClose={onCloseEndingPanel}
      />
      <AddLessonEvaluationDate
        isOpen={showEvaluationDate}
        onClose={onCloseEvaluationDate}
        lessonId={Number(lessonId)}
      />
      {lesson && lessonPiece ? (
        <LessonPieceErrorPanel
          isOpen={Boolean(lessonPiece.post_process_failed)}
          subject={lesson.subject_matter}
          onClose={() => navigate(`/home`, { replace: true })}
        />
      ) : null}
    </BlankPrimaryLayout>
  );
};

export default LessonPieceProcessing;
