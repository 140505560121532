import { ReactNode } from 'react';
import { SafeArea } from 'antd-mobile';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';

const BlankLayout = ({
  children,
  appBackground = 'bg-[#FAFAFA]',
  appBackgroundFooter,
  statusBar = {
    color: '#FAFAFA',
    style: Style.Light,
  },
}: {
  children: ReactNode;
  appBackground?: string;
  appBackgroundFooter?: string;
  statusBar?: {
    color: string;
    style: Style;
  };
}) => {
  return (
    <div
      className={`w-full h-full max-h-dvh flex flex-col relative ${appBackground} overflow-auto`}
    >
      <StatusBar color={statusBar.color} style={statusBar.style} />
      <SafeArea
        position="top"
        style={{
          backgroundColor: statusBar.color,
        }}
      />
      {children}
      <SafeArea
        position="bottom"
        className={appBackgroundFooter ?? appBackground}
      />
    </div>
  );
};

export default BlankLayout;
