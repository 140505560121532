import { useCallback, useEffect, useRef } from 'react';
import Spinner from 'src/components/common/designSystem/Spinner';
import { useAuth } from 'src/hooks/useAuth';

import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';

const LogoutChallengePage = () => {
  const hasInitAnonymousUser = useRef(false);
  const { isLoggedIn, createAnonymousUser } = useAuth();

  const initAnonymousUser = useCallback(async () => {
    if (hasInitAnonymousUser.current || isLoggedIn) {
      return;
    }
    hasInitAnonymousUser.current = true;
    await createAnonymousUser();
  }, [createAnonymousUser, isLoggedIn]);

  useEffect(() => {
    initAnonymousUser();
  }, [initAnonymousUser]);

  if (!isLoggedIn) {
    return (
      <BlankPrimaryLayout>
        <div
          className={'w-full h-full flex flex-col justify-center items-center'}
        >
          <Spinner className="w-full h-full" />
        </div>
      </BlankPrimaryLayout>
    );
  }

  return null;
};

export default LogoutChallengePage;
