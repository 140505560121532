import BlankLayout from 'src/layouts/BlankLayout';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ChallengeResult from 'src/components/Challenge/ChallengeResult';
import { useParams } from 'react-router-dom';

export default function ChallengeLessonQuizResult() {
  const { quizId, runId, challengeId } = useParams();
  return (
    <BlankLayout
      appBackground={'bg-white'}
      statusBar={{
        color: '#FFFFFF',
        style: Style.Light,
      }}
    >
      <ChallengeResult
        challengeId={Number(challengeId)}
        quizId={Number(quizId)}
        runId={Number(runId)}
      />
    </BlankLayout>
  );
}
