import('dayjs/locale/fr');
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Auto import locales
const resources: Record<string, any> = {};

Object.entries(import.meta.glob('../locales/**/*.js', { eager: true })).forEach(
  ([key, value]: [string, any]) => {
    const locale = key.slice(11, 13);
    const fileKey = key.slice(14, -3);
    resources[locale] = {
      translation: {
        ...(resources[locale]?.translation || {}),
        [fileKey]: value.default,
      },
    };
    return [key.slice(14, -9), value.default];
  },
);

export const initTranslation = async () => {
  dayjs.locale('fr');
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(localizedFormat);

  await i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: 'fr', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export default i18n;
