import { useTranslation } from 'react-i18next';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import AppstoreIcon from 'src/images/appstore.png';
import PlaystoreIcon from 'src/images/playstore.png';
import Logo from 'src/images/logoHorizontal.svg?react';

const InstallApp = () => {
  const { t } = useTranslation();

  return (
    <BlankPrimaryLayout>
      <div
        className={
          'flex flex-1 flex-col items-center justify-between text-white text-balance text-center p-8'
        }
      >
        <div />
        <div className={'flex flex-col gap-12 items-center'}>
          <div>
            <Logo className={'max-w-full max-h-full w-auto h-9'} />
          </div>
          <div className={'font-bold text-xl'}>
            {t('pwa.install.description')}
          </div>
        </div>
        <div className={'flex flex-col gap-5'}>
          <div className={'flex flex-row gap-4 items-center justify-center'}>
            <div className={'border-white border-t-[1px] flex-1'} />
            <div className={'text-balance font-extrabold'}>
              {t('pwa.install.title')}
            </div>
            <div className={'border-white border-t-[1px] flex-1'} />
          </div>
          <div className={'flex flex-row gap-3 items-center justify-center'}>
            <a
              href={'https://baobab.app.link/ho2iKlo53Kb'}
              target={'_blank'}
              rel="noreferrer"
            >
              <img src={AppstoreIcon} alt={'Download app store'} />
            </a>
            <a
              href={'https://baobab.app.link/ho2iKlo53Kb'}
              target={'_blank'}
              rel="noreferrer"
            >
              <img src={PlaystoreIcon} alt={'Download play store'} />
            </a>
          </div>
        </div>
      </div>
    </BlankPrimaryLayout>
  );
};

export default InstallApp;
