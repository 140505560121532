export default {
  panel: {
    ask: {
      title: 'Ton avis est super important !',
      description1: 'Tu as déjà fait plein de quiz, c’est incroyable !',
      description2: 'Tu donnes quelle note à Baobab ?',
      error: 'Une erreur est survenue, veuillez réessayer.',
    },
    positive: {
      title: 'Merci pour ton avis !',
      description:
        'Aide d’autres enfants à découvrir Baobab en laissant un avis !',
      button: 'Laisser un avis',
    },
    negative: {
      title: 'Merci pour ton avis !',
      description: 'Comment nous pouvons nous améliorer ?',
      placeholder: 'Dis-nous tout !',
      button: 'Laisser un avis',
    },
  },
};
