export default {
  next: 'Suivant',
  skip: 'Passer cette étape',
  steps: 'Étape {{step}} sur {{maxStep}}',
  who: {
    title: 'Toc toc, qui est là ? 👀',
    parent: 'Un parent',
    child: 'Un enfant',
    panel: {
      title: 'Bienvenue sur Baobab',
      description:
        'Pour te connecter, ton parent doit t’inviter sur l’application depuis son compte !',
    },
  },
  parent: {
    firstname: {
      title: 'Quel est votre prénom ?',
      label: 'Mon prénom',
      fail: 'Impossible de mettre à jour votre prénom',
      panel: {
        title: 'Bienvenue {{firstname}}',
        description:
          'Nous allons vous poser quelques questions sur votre enfant \n' +
          '(Vous pourrez en ajouter d’autres plus tard)\n' +
          '\n' +
          'Ces informations restent confidentielles et permettront d’optimiser son expérience.',
      },
    },
    child: {
      firstname: {
        title: 'Quel est le prénom de votre enfant ?',
        label: 'Son prénom',
        fail: 'Impossible de mettre à jour le prénom de votre enfant',
      },
      year: {
        title: 'Précisez son année de naissance',
        label: 'A A A A',
        invalid: 'Année de naissance invalide',
        min: 'Désolé, votre enfant doit avoir au moins 10 ans pour s’inscrire',
        max: 'Désolé, votre enfant doit avoir moins de 16 ans pour s’inscrire',
        fail: 'Impossible de mettre à jour l’année de naissance de votre enfant',
      },
      gender: {
        title: 'Votre enfant est...',
        girl: '👧 Une fille',
        boy: '👦 Un garçon',
        other: '👀 Ne pas le communiquer',
        fail: 'Impossible de mettre à jour le genre de votre enfant',
      },
      school_level: {
        title: 'Quelle est sa classe ?',
        fail: 'Impossible de mettre à jour la classe de votre enfant',
      },
      school: {
        title: 'Sélectionnez son collège en saisissant le nom de la ville',
        inputPlaceholder: 'Ville du collège',
        students_one: 'Élève',
        students_other: 'Élèves',
      },
      device: {
        title: 'Sur quel appareil {{firstname}} utilisera Baobab ?',
        same: 'Sur ce smartphone',
        other: 'Sur un autre smartphone',
        share: {
          copied: 'Lien de partage copié',
          title: 'Invite ton enfant',
          description:
            'J’ai créé un compte pour toi sur l’application Baobab ! Connecte-toi en utilisant ce lien',
          error: 'Une erreur est survenue, veuillez réessayer',
        },
      },
    },
    notification: {
      title: 'Activer les notifications',
      description: 'Pour rester informé.e de la progression de votre enfant !',
      card: {
        title: 'Votre enfant progresse !',
        now: 'maintenant',
        description: 'Il a réalisé un score parfait sur sa leçon !',
      },
      button: 'Activer mes notifications',
    },
  },
  child: {
    firstname: {
      title: 'Quel est ton prénom ?',
      label: 'Ton prénom',
      fail: 'Impossible de mettre à jour ton prénom',
    },
    year: {
      title: 'Précise ton année de naissance',
      label: 'A A A A',
      invalid: 'Année de naissance invalide',
      min: 'Désolé, tu dois avoir au moins 10 ans pour s’inscrire',
      max: 'Désolé, tu dois avoir moins de 16 ans pour s’inscrire',
      fail: 'Impossible de mettre à jour ton année de naissance',
    },
    gender: {
      title: 'Tu es ?',
      girl: '👧 Une fille',
      boy: '👦 Un garçon',
      other: '👀 Ne pas le communiquer',
      fail: 'Impossible de mettre à jour ton genre',
    },
    school_level: {
      title: 'Quelle est ta classe ?',
      fail: 'Impossible de mettre à jour ta classe',
    },
    school: {
      title: 'Sélectionne ton collège en saisissant le nom de la ville',
      inputPlaceholder: 'Ville du collège',
      students_one: 'Élève',
      students_other: 'Élèves',
    },
    objective: {
      title: 'Quel est ton objectif avec Baobab ?',
      otherInputPlaceholder: 'Peux-tu préciser ?',
      panel: {
        title: "C'est au tour de {{firstname}}",
        description:
          'Les prochaines étapes doivent être complétées par votre enfant',
        button: 'J’ai compris !',
      },
    },
    notification: {
      title: '🛎 Active tes notifications',
      description: 'Cela te permettra d’atteindre tous tes objectifs !',
      card: {
        title: 'Rejoins moi sur Baobab',
        now: 'maintenant',
        description: 'À ton tour : Émilie te propose un duel ! 💪',
      },
      button: 'Activer mes notifications',
    },
  },
  firstLesson: {
    1: {
      title: 'C’est (presque) magique ! 🪄',
      description: 'Je t’explique... 😉',
    },
    2: {
      title: 'Un contrôle ? Vite, ajoute ta leçon !',
      description: 'Ouvre ton cahier et lis-nous ta leçon',
      description2:
        'Tu peux le faire en plusieurs petites parties, tranquille.',
    },
    3: {
      title: 'Abracadabra !',
      description:
        'Baobab te crée un quiz personnalisé pour que tu puisses réviser !',
      description2: 'En un clin d’oeil 😉',
    },
    4: {
      title: 'Deviens une légende !',
      description:
        'Enchaîne les révisions, gagne des XP et révise comme un boss.',
      description2: 'C’est fun et efficace !',
    },
  },
};
