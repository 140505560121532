import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Spinner from 'src/components/common/designSystem/Spinner';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import QuizProvider from 'src/components/Quiz/QuizContext';
import Quiz from 'src/components/Quiz/Quiz';
import QuizHeader from 'src/components/Quiz/QuizHeader';
import { useNotification } from 'src/contexts/NotificationContext';
import QuizBackButton from 'src/components/Quiz/QuizBackButton';

import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import { useFinishQuiz, useGetQuizQuestion } from 'src/queries/quiz';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import AuthentificatedHeader from 'src/components/Header/AuthentificatedHeader';
import { useGetChallenge } from 'src/queries/challenge';
import { reportError } from 'src/modules/logs/Sentry';

export default function ChallengeLessonQuiz() {
  const navigate = useNavigate();
  const { lessonId, quizId, runId, challengeId } = useParams();
  const { showError } = useNotification();
  const { t } = useTranslation();

  const { data: questions, isLoading: isLoadingQuestions } = useGetQuizQuestion(
    Number(quizId),
  );
  const { data: challenge, isLoading: isLoadingChallenge } = useGetChallenge(
    Number(challengeId),
  );

  const { mutateAsync: finishQuizQuery, isLoading: isFinishingQuiz } =
    useFinishQuiz();

  const onQuizEnd = async () => {
    if (!lessonId || !runId) {
      return;
    }
    try {
      await finishQuizQuery({
        quizId: Number(quizId),
        quizRunId: Number(runId),
        lessonId: Number(lessonId),
      });
      logAnalyticsEvent('quiz_finished', {
        lessonId,
        quizRunId: runId,
      });
      navigate(
        `/challenges/${challengeId}/lessons/${lessonId}/quizzes/${quizId}/runs/${runId}/result`,
        { replace: true },
      );
    } catch (error) {
      reportError('Fail to finish quiz challenge', error);
      showError({
        error,
        message: t('quiz.failToUpdate'),
      });
    }
  };

  useEffect(() => {
    if (questions && !questions.length) {
      showError({
        message: t('quiz.noQuestions'),
        error: undefined,
      });
    }
  }, [questions, showError, t]);

  if (isFinishingQuiz || isLoadingQuestions || isLoadingChallenge)
    return (
      <LayoutWithHeader header={<AuthentificatedHeader backArrow />}>
        <Spinner className="w-full h-full" />
      </LayoutWithHeader>
    );

  if (!lessonId || !questions || !challenge || !questions.length || !runId) {
    return <Navigate to="404" />;
  }

  return (
    <QuizProvider
      lesson={challenge.quiz.lesson}
      quizID={Number(quizId)}
      quizRunID={Number(runId)}
      onQuizEnd={onQuizEnd}
      questionsList={questions}
    >
      <BlankPrimaryLayout appBackgroundFooter={'bg-white'}>
        <div
          className={
            'w-full flex-1 flex flex-col justify-between overflow-hidden'
          }
        >
          <QuizHeader back={<QuizBackButton />} />
          <Quiz />
        </div>
      </BlankPrimaryLayout>
    </QuizProvider>
  );
}
