import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Button from 'src/components/common/designSystem/Button';
import { useCurrentUser } from 'src/queries/user';

const ChildObjectivePanel = () => {
  const { data: me } = useCurrentUser();
  const { t } = useTranslation();
  const [openChildPanel, setOpenChildPanel] = useState(false);

  useEffect(() => {
    setOpenChildPanel(true);
  }, []);

  return (
    <AutoFloatingPanel
      isOpen={openChildPanel}
      onClose={() => setOpenChildPanel(false)}
    >
      <div className={'flex flex-col justify-center gap-2 p-4'}>
        <div className={'text-4xl text-center'}>👋</div>
        <div className={'text-lg font-bold text-center'}>
          {t('onboarding.child.objective.panel.title', {
            firstname: me?.firstname,
          })}
        </div>
        <div className={'text-base text-center'}>
          {t('onboarding.child.objective.panel.description')}
        </div>
        <Button
          className={'w-full mt-8'}
          type={'primary'}
          data-testid={'btn-understand'}
          onClick={() => setOpenChildPanel(false)}
        >
          {t('onboarding.child.objective.panel.button')}
        </Button>
      </div>
    </AutoFloatingPanel>
  );
};

export default ChildObjectivePanel;
