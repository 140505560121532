export default {
  title: 'Paramètres',
  switchUser: 'Changer d’utilisateur',
  subscription: 'Abonnement',
  buildVersion: 'Version de l’application',
  contactUs: 'Nous contacter',
  logout: 'Déconnexion',
  deleteAccount: 'Supprimer mon compte',
  child: {
    title: 'Salut {{firstName}} !',
    subtitle: 'Ta performance',
    xp: 'XP',
    challenges: 'Duels gagnés',
    ask: 'Une question ? Une remarque ?',
    contactUs: 'Nous contacter',
    streaks: {
      title: 'Série de révisions',
    },
  },
};
