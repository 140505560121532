const LessonSmartbit = ({
  type,
  content,
}: {
  type: string;
  content: string;
}) => {
  return (
    <div
      className={
        'px-4 flex flex-1 flex-col gap-24 items-center justify-center text-white'
      }
    >
      <div
        className={
          'bg-white px-4 py-2 rounded-lg bg-opacity-20 uppercase text-base font-bold'
        }
      >
        {type}
      </div>
      <div className={'font-bold text-3xl text-center text-balance'}>
        {content}
      </div>
    </div>
  );
};

export default LessonSmartbit;
