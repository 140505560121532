import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { useGetUsers } from 'src/queries/users';
import Spinner from 'src/components/common/designSystem/Spinner';
import AddChildMenuItem from 'src/components/Parent/AddChildMenuItem';
import InviteChildMenuItem from 'src/components/Parent/InviteChildMenuItem';
import ContactUsMenuItem from 'src/components/Parent/ContactUsMenuItem';

const FourreToutPanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { data: users, isLoading } = useGetUsers();

  const children =
    users?.filter(
      user => user.user_type === 'child' && user.firstname?.length,
    ) ?? [];

  return (
    <AutoFloatingPanel
      data-testid={'panel-fourre-tout-parent'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className={'flex flex-col gap-4 justify-start items-start p-4'}>
        {isLoading ? (
          <Spinner className="w-full" />
        ) : (
          children.map(child => (
            <InviteChildMenuItem key={child.id} child={child} />
          ))
        )}
        <AddChildMenuItem />
        <ContactUsMenuItem />
      </div>
    </AutoFloatingPanel>
  );
};

export default FourreToutPanel;
