import { ReactNode } from 'react';
import { NavBar, SafeArea } from 'antd-mobile';
import { useNavigate } from 'react-router-dom';
import LogoHorizontal from 'src/images/logoHorizontal.svg?react';
import { LeftOutline } from 'antd-mobile-icons';

import useNavigateBack from 'src/hooks/useNavigateBack';

export interface HeaderProps {
  title?: ReactNode;
  subtitle?: string;
  backArrow?: boolean;
  onBack?: () => void;
  backArrowPathname?: string;
  right?: ReactNode;
}

const Header = ({
  title,
  subtitle,
  backArrow,
  onBack,
  backArrowPathname,
  right,
}: HeaderProps) => {
  const navigate = useNavigate();
  const goBack = useNavigateBack();

  const onBackClick = () => {
    if (backArrowPathname) {
      navigate(backArrowPathname);
      return;
    }
    goBack();
  };

  const renderSubtitle = () => {
    if (!subtitle) {
      return null;
    }
    return <div className="text-xs text-structural-darker">{subtitle}</div>;
  };

  const renderTitle = () => {
    if (!title) {
      return <LogoHorizontal className="w-auto h-6" />;
    }
    return (
      <>
        <h1 className="text-base font-semibold text-structural-darker capitalize">
          {title}
        </h1>
        {renderSubtitle()}
      </>
    );
  };

  return (
    <>
      <SafeArea position="top" className="bg-white" />
      <NavBar
        backArrow={false}
        data-testid="nav-bar"
        back={
          backArrow ? (
            <LeftOutline fontSize={24} data-testid={'btn-back'} />
          ) : null
        }
        onBack={onBack ?? onBackClick}
        right={right}
      >
        <div className="w-full flex flex-col items-center justify-center">
          {renderTitle()}
        </div>
      </NavBar>
    </>
  );
};

export default Header;
