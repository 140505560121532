import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { useEffect, useState } from 'react';
import { useGetLessons } from 'src/queries/lessons';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/common/designSystem/Button';
import {
  hasSeenContactSupportPanel,
  setHasSeenContactSupportPanel,
} from 'src/utils/support';
import { openSupportMessenger } from 'src/modules/support/support';

const ContactSupportPanel = () => {
  const { data: lessonsData } = useGetLessons();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const onClose = () => {
    setHasSeenContactSupportPanel();
    setIsOpen(false);
  };

  useEffect(() => {
    if (!lessonsData || hasSeenContactSupportPanel()) {
      return;
    }
    const allLessons = lessonsData.pages.map(page => page.items).flat();
    const hasLessonWithPiece = allLessons.some(
      lesson => lesson.lesson_pieces.length > 0,
    );
    if (!hasLessonWithPiece && allLessons.length > 1) {
      setIsOpen(true);
    }
  }, [lessonsData]);

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'panel-support'}
    >
      <div className={'flex items-start flex-col gap-5 p-6 text-left'}>
        <div className={'text-4xl'}>😊</div>
        <div className={'font-bold text-balance text-2xl'}>
          {t('support.panel.title')}
        </div>
        <div className={'text-balance text-base mb-2'}>
          {t('support.panel.description')}
        </div>
        <div className={'flex flex-col gap-2 w-full'}>
          <Button
            data-testid="btn-contact-us"
            type={'primary'}
            onClick={async () => {
              await openSupportMessenger();
              onClose();
            }}
          >
            {t('support.panel.button')}
          </Button>
          <Button data-testid="btn-not-now" type={'default'} onClick={onClose}>
            {t('support.panel.close')}
          </Button>
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default ContactSupportPanel;
