import { useTranslation } from 'react-i18next';
import Milestone from 'src/components/Milestones/Milestone';
import Answers from 'src/images/milestones/answers.svg?react';

const MilestoneQuizAnswers = ({ count }: { count: number }) => {
  const { t } = useTranslation();
  return (
    <Milestone
      illustration={<Answers />}
      title={
        count >= 200
          ? t('milestones.quiz_answers.answers200')
          : count >= 150
            ? t('milestones.quiz_answers.answers150')
            : count >= 100
              ? t('milestones.quiz_answers.answers100')
              : count >= 50
                ? t('milestones.quiz_answers.answers50')
                : t('milestones.quiz_answers.answers10')
      }
    />
  );
};

export default MilestoneQuizAnswers;
