import { User } from 'src/types/user.types';
import { reportError } from 'src/modules/logs/Sentry';
import { Intercom } from '@capacitor-community/intercom';

export const initIntercomSupport = async () => {};

export const initUserToIntercomSupport = async (user?: User) => {
  if (!user) {
    try {
      await Intercom.logout();
    } catch (error) {
      reportError('Error while logging out from Intercom', error);
    }
    return;
  }

  try {
    await Intercom.registerIdentifiedUser({
      userId: String(user.id),
      email: user.email,
    });
    await Intercom.updateUser({
      name: user.firstname,
      customAttributes: {
        family_id: user.family_id,
        user_type: user.user_type,
      },
    });
  } catch (error) {
    reportError('Error while init user in Intercom', error);
  }
};

export const openIntercomMessenger = async () => {
  try {
    await Intercom.displayMessenger();
  } catch (error) {
    reportError('Error while opening Intercom', error);
  }
};
