import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { type Duration } from 'dayjs/plugin/duration';

const TimeCounter = ({ endTime }: { endTime: string }) => {
  const [timeLeft, setTimeLeft] = useState<Duration>(
    dayjs.duration(new Date(endTime).getTime() - Date.now()),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const endDate = new Date(endTime).getTime();
      setTimeLeft(dayjs.duration(endDate - Date.now()));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [endTime]);

  return timeLeft.format(timeLeft.days() > 0 ? 'DD:HH:mm:ss' : 'HH:mm:ss');
};

export default TimeCounter;
