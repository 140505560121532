import dayjs, { type Dayjs } from 'dayjs';
const DATE_FORMAT = 'YYYY-MM-DD';

export const getDaysCenterOnDay = (day: Dayjs): Dayjs[] =>
  get7PreviousDays(day.add(3, 'day'));
export const get7PreviousDays = (day: Dayjs): Dayjs[] => [
  day.subtract(6, 'day'),
  day.subtract(5, 'day'),
  day.subtract(4, 'day'),
  day.subtract(3, 'day'),
  day.subtract(2, 'day'),
  day.subtract(1, 'day'),
  day,
];

export const formatChildBirthDateFromYear = (year: number) => {
  return dayjs(new Date(year, 6, 1)).format(DATE_FORMAT);
};
