const QuizCompleteSentenceCompletion = ({
  answer,
  onClick,
  isCorrect,
  showResult,
}: {
  answer: string;
  isCorrect?: boolean;
  showResult?: boolean;
  onClick?: () => void;
}) => {
  const getClassname = () => {
    let color = 'red';
    if (!showResult) {
      color = 'primary';
    } else if (isCorrect) {
      color = 'green';
    }

    return `border-${color}-darkest text-${color}-darkest`;
  };

  return (
    <span
      onClick={onClick}
      className={`ml-1 my-1 inline-block align-middle rounded-lg text-center px-2 border-dashed border-2 ${getClassname()}`}
    >
      {answer}
    </span>
  );
};

export default QuizCompleteSentenceCompletion;
