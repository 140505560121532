import { Navigate, useSearchParams } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

const RedirectOnLogin = () => {
  const { isAnonymous, isLoggedIn } = useAuth();
  const [searchParams] = useSearchParams();
  const to = searchParams.get('from') || searchParams.get('to');

  if (!isLoggedIn || isAnonymous) {
    return null;
  }

  if (to) {
    return <Navigate to={to} replace />;
  }

  return null;
};

export default RedirectOnLogin;
