import { LeftOutline } from 'antd-mobile-icons';
import useNavigateBack from 'src/hooks/useNavigateBack';

const BackArrowHeader = () => {
  const goBack = useNavigateBack();

  return (
    <div className={'flex flex-row justify-between items-center p-6'}>
      <div onClick={goBack}>
        <LeftOutline fontSize={24} data-testid={'btn-back'} />
      </div>
    </div>
  );
};

export default BackArrowHeader;
