import { ReactNode } from 'react';
import { useQuiz } from 'src/components/Quiz/QuizContext';

const QuizQuestionHeader = ({
  icon,
  text,
}: {
  icon: ReactNode;
  text: string;
}) => {
  const { currentQuestionIndex, totalQuestions } = useQuiz();
  return (
    <div className={'w-full flex flex-row items-start justify-between gap-2'}>
      <div className={'flex flex-row items-start gap-2'}>
        <div>
          <div
            className={
              'rounded-full bg-primary-light fill-primary-darkest w-5 h-5 flex items-center justify-center'
            }
          >
            {icon}
          </div>
        </div>
        <div
          className={
            'text-primary-darkest font-extrabold text-xl text-balance text-left -mt-0.5'
          }
        >
          {text}
        </div>
      </div>
      <div
        className={
          'text-structural-darkest font-semibold flex-nowrap no-wrap text-nowrap'
        }
      >
        {currentQuestionIndex + 1} / {totalQuestions}
      </div>
    </div>
  );
};

export default QuizQuestionHeader;
