import { WordPuzzleQuestion } from 'src/types/quiz.types';
import QuizStatus from 'src/components/Quiz/QuizStatus';

const QuizWordPuzzleQuestionHeader = ({
  question,
}: {
  question: WordPuzzleQuestion;
}) => {
  return (
    <div
      className={
        'px-8 py-2 w-full flex flex-1 flex-col gap-2 justify-between items-center text-white'
      }
    >
      <div />
      <div
        className={
          'w-full flex flex-col text-center justify-center text-2xl font-bold text-balance py-4 px-2 overflow-auto'
        }
      >
        <span className={'text-base font-normal'}>Donnez la traduction de</span>
        <span>"{question.question}"</span>
      </div>
      <QuizStatus />
    </div>
  );
};

export default QuizWordPuzzleQuestionHeader;
