import QuizStatus from 'src/components/Quiz/QuizStatus';
import QuizFillBlankPhrase from 'src/components/Quiz/FillBlankQuestion/QuizFillBlankPhrase';
import { FillBlankQuestion } from 'src/types/quiz.types';

const QuizFillBlankQuestionHeader = ({
  question,
  onClickHole,
  answers,
  splittedQuestion,
  selectedHole,
}: {
  question: FillBlankQuestion;
  onClickHole: (index: number) => void;
  answers: string[];
  splittedQuestion: string[];
  selectedHole: number;
}) => {
  return (
    <div
      className={
        'px-8 py-2 w-full flex flex-1 flex-col gap-2 justify-between items-center overflow-hidden text-white'
      }
    >
      <div />
      <div
        className={
          'w-full flex text-center justify-center text-2xl font-bold text-balance py-4 px-2 overflow-auto'
        }
      >
        <QuizFillBlankPhrase
          question={question}
          onClickHole={onClickHole}
          answers={answers}
          splittedQuestion={splittedQuestion}
          selectedHole={selectedHole}
        />
      </div>
      <QuizStatus />
    </div>
  );
};

export default QuizFillBlankQuestionHeader;
