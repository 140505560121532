import ChildSchoolLevelStep from 'src/components/Onboarding/ChildSchoolLevelStep';

const ParentChildSchoolLevelStep = ({
  childId,
  onFinish,
}: {
  childId: number;
  onFinish: () => void;
}) => {
  return <ChildSchoolLevelStep childId={childId} onFinish={onFinish} />;
};

export default ParentChildSchoolLevelStep;
