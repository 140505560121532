import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import CheckIcon from 'src/images/check.svg?react';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { useNotification } from 'src/contexts/NotificationContext';
import { useNotificationShown } from 'src/queries/notifications';

const InAppReferralRewardNotification = ({
  notification,
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.REFERRAL_REWARD;
  };
  onRead: () => Promise<void>;
}) => {
  const notificationShownRef = useRef<number>();
  const queryClient = useQueryClient();
  const params = notification.params;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { show, close } = useNotification();
  const { mutateAsync: notificationShown } = useNotificationShown();
  const id = `referral-reward-${params.user_id}`;

  useEffect(() => {
    queryClient.invalidateQueries(['user']);
  }, [queryClient]);

  const onStart = useCallback(async () => {
    await onRead();
    close(id);
  }, [close, navigate, onRead, id]);

  const showNotification = useCallback(async () => {
    if (
      notificationShownRef.current === notification.id ||
      notification.read_at ||
      notification.shown_at
    ) {
      return;
    }
    notificationShownRef.current = notification.id;
    show({
      message: (
        <div className={'flex flex-row items-center justify-between w-full'}>
          <span className={'text-white font-bold text-base text-balance'}>
            {t('notifications.referral_reward.title', {
              firstName: params.user_firstname,
            })}
          </span>
          <CheckIcon className={'fill-green-300 w-5 h-auto'} />
        </div>
      ),
      duration: 5,
      onClick: onStart,
      placement: 'bottom',
      closable: false,
      props: {
        'data-testid': `toast-referral-reward-${params.user_id}`,
      },
      key: id,
    });
    await notificationShown(notification.id);
  }, [
    notification.id,
    notification.read_at,
    notification.shown_at,
    notificationShown,
    onStart,
    params.user_id,
    id,
    show,
    t,
  ]);

  useEffect(() => {
    showNotification();
  }, [showNotification]);

  return null;
};

export default InAppReferralRewardNotification;
