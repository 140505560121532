import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';

const LessonDetailProcessing = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full flex flex-col items-center justify-center text-center">
      <Spinner size="xl" />
      <div className={'mt-4 text-balance'}>{t('lessons.processing')}</div>
    </div>
  );
};

export default LessonDetailProcessing;
