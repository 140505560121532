import { ReactNode } from 'react';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import { projectColors } from 'src/styles/project-styles';
import BlankLayout from 'src/layouts/BlankLayout';
import BackgroundPattern from 'src/components/common/BackgroundPattern';

const BlankPrimaryLayout = ({
  children,
  appBackgroundFooter,
}: {
  children: ReactNode;
  appBackgroundFooter?: string;
}) => {
  return (
    <BlankLayout
      appBackground={
        'bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to'
      }
      statusBar={{
        color: projectColors.paywall.darkest.to,
        style: Style.Dark,
      }}
      appBackgroundFooter={appBackgroundFooter ?? 'transparent'}
    >
      <div
        className={
          'flex z-10 flex-col items-center justify-between w-full h-full'
        }
      >
        {children}
      </div>
      <BackgroundPattern />
    </BlankLayout>
  );
};

export default BlankPrimaryLayout;
