export default {
  schoolLevel6: '6ème',
  schoolLevel5: '5ème',
  schoolLevel4: '4ème',
  schoolLevel3: '3ème',
  schoolLevelOther: 'Autre',
  userTypes: {
    parent: 'Compte parent',
    child: 'Compte enfant',
  },
  family: {
    mother: 'Maman',
    father: 'Papa',
    boy: 'Garçon',
    girl: 'Fille',
    other: 'N/A',
    otherLabel: 'Ne pas le communiquer',
  },
  objectives: {
    better_organisation: '💪 Améliorer mon organisation',
    better_grades: '🎉 Réussir mes évaluations',
    saving_time: '⌛️ Gagner du temps',
    first_place: '🥇 Être 1er.e de ma classe',
    other: '💬 Autre',
  },
  deleteMyAccount: {
    title: 'Es-tu sûr.e de vouloir supprimer ton compte ?',
    description: 'Si tu supprimes ton compte, tu perdras toutes tes leçons',
    confirm: 'Supprimer',
  },
  logout: {
    title: 'Es-tu sûr.e de vouloir te déconnecter ?',
    confirm: 'Déconnexion',
  },
};
