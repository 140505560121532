import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Capacitor } from '@capacitor/core';
import dayjs from 'dayjs';
import pageViewTrackingEnrichment from 'src/utils/PageViewTrackingEnrichment';
import { User, UserType } from 'src/types/user.types';
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import { reportError } from 'src/modules/logs/Sentry';
import { Device } from '@capacitor/device';

type AnalyticsEvents =
  | 'add_child_button_clicked'
  | 'quiz_started'
  | 'quiz_finished'
  | 'quiz_answered'
  | 'lesson_created'
  | 'lesson_piece_created'
  | 'invite_user'
  | 'signup'
  | 'signin'
  | 'onboarding_complete'
  | 'paywall_viewed'
  | 'trial_started'
  | 'trial_ended'
  | 'add_lesson-content'
  | 'start_quiz'
  | 'complete_quiz'
  | 'quit_quiz'
  | 'challenge_friend'
  | 'challenge_accepted'
  | 'button_click'
  | 'card_viral_viewed'
  | 'viral_invite_sent'
  | 'card_review_viewed'
  | 'card_challenge_tuto_viewed'
  | 'star-review-0'
  | 'star-review-1'
  | 'star-review-2'
  | 'star-review-3'
  | 'star-review-4'
  | 'star-review-5'
  | 'button_store_review_clicked'
  | 'button_negative_review_clicked';

const BRANCH_IO_EVENTS: AnalyticsEvents[] = [
  'signup',
  'onboarding_complete',
  'trial_started',
];

export async function logAnalyticsEvent(
  eventName: AnalyticsEvents,
  eventParams?: Record<string, any>,
): Promise<void> {
  if (import.meta.env.VITE_CI) {
    return;
  }

  amplitude.track(eventName, eventParams);

  if (BRANCH_IO_EVENTS.includes(eventName)) {
    try {
      await BranchDeepLinks.sendBranchEvent({
        eventName,
        metaData: eventParams ?? {},
      });
    } catch (e) {
      reportError('Error sending Branch event', e);
    }
  }
}

export const initAnalytics = async () => {
  if (import.meta.env.VITE_CI) {
    return;
  }

  amplitude.add(pageViewTrackingEnrichment());
  if (Capacitor.isNativePlatform()) {
    const currentBuild = await CapacitorUpdater.current();
    amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
      minIdLength: 1,
      appVersion: currentBuild.bundle.version,
      autocapture: true,
    });
    const sessionReplayTracking = sessionReplayPlugin({
      sampleRate: 1,
    });
    amplitude.add(sessionReplayTracking);

    try {
      const info = await Device.getInfo();
      amplitude.setGroup('native_version', currentBuild.native);
      amplitude.setGroup('device_platform', info.platform);
      amplitude.setGroup('device_os_version', info.osVersion);
      amplitude.setGroup('device_manufacturer', info.manufacturer);
      amplitude.setGroup('device_model', info.model);
      amplitude.setGroup('device_os_name', info.operatingSystem);
    } catch (e) {
      console.warn('Error setting device info', e);
    }
  } else {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
      minIdLength: 1,
      autocapture: true,
    });
  }
};

export const identifyUser = (user?: User) => {
  if (import.meta.env.VITE_CI) {
    return;
  }

  if (!user || !user.id) {
    return;
  }

  amplitude.setUserId(String(user.id));
  addUserProperty('familyId', String(user.family_id));
  addUserProperty('userType', user.user_type);
  addUserProperty('yob', dayjs(user.date_of_birth).year());
  addUserProperty('grade_level', user.school_level);
  addUserProperty('school_id', user.school_id);
  addUserProperty('objectives', user.objectives);
  if (user.user_type === UserType.child) {
    addUserProperty('gender', user.family_member_type);
  }
};

export const addUserProperty = (key: string, value: any) => {
  if (import.meta.env.VITE_CI) {
    return;
  }

  amplitude.setGroup(key, value);
};

export const getAnalyticsDeviceId = () => {
  if (import.meta.env.VITE_CI) {
    return undefined;
  }

  return amplitude.getDeviceId();
};
