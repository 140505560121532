import { useCallback, useEffect, useRef, useState } from 'react';
import {
  useGetUnreadNotifications,
  useReadNotification,
} from 'src/queries/notifications';
import InAppLessonPieceProcessFailedNotifications from 'src/components/Notifications/InAppNotifications/InAppLessonPieceProcessFailedNotification';
import InAppQuizReadyNotification from 'src/components/Notifications/InAppNotifications/InAppQuizReadyNotification';
import {
  IN_APP_NOTIFICATIONS,
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import InAppReferralRewardNotification from 'src/components/Notifications/InAppNotifications/InAppReferralRewardNotification';
import InAppAskAppReviewNotifications from 'src/components/Notifications/InAppNotifications/InAppAskAppReviewNotification';
import InAppAskOutAppNotificationOptinNotification from 'src/components/Notifications/InAppNotifications/InAppAskOutAppNotificationOptinNotification';
import InAppMilestoneReachedNotification from 'src/components/Notifications/InAppNotifications/InAppMilestoneReachedNotification';

const InAppNotifications = () => {
  const { data: notifications, refetch: refetchUnreadNotifications } =
    useGetUnreadNotifications({
      refetchInterval: 5000,
    });
  const { mutateAsync: readNotification } = useReadNotification();
  const [currentNotification, setCurrentNotification] =
    useState<InAppNotification>();
  const readNotifications = useRef(new Set<number>());

  useEffect(() => {
    if (
      currentNotification ||
      !notifications ||
      !notifications.pages.length ||
      !notifications.pages[0].items.length
    ) {
      return;
    }
    const unreadNotification = notifications.pages[0].items.find(
      notification =>
        IN_APP_NOTIFICATIONS.includes(notification.type) &&
        !notification.shown_at &&
        !readNotifications.current.has(notification.id),
    );
    setCurrentNotification(unreadNotification);
  }, [currentNotification, notifications]);

  useEffect(() => {
    if (currentNotification) {
      if (
        notifications &&
        notifications.pages.length &&
        notifications.pages[0].items.find(
          notification =>
            !notification.shown_at &&
            notification.id === currentNotification.id,
        )
      ) {
        return;
      }
      setCurrentNotification(undefined);
    }
  }, [currentNotification, notifications]);

  const handleNotificationRead = useCallback(async () => {
    if (
      currentNotification &&
      !readNotifications.current.has(currentNotification.id)
    ) {
      readNotifications.current.add(currentNotification.id);
      await readNotification(currentNotification.id);
      await refetchUnreadNotifications();
    }
  }, [currentNotification, readNotification, refetchUnreadNotifications]);

  if (!currentNotification) {
    return null;
  }

  switch (currentNotification.type) {
    case InAppNotificationType.LESSON_PIECE_PROCESS_FAIL:
      return (
        <InAppLessonPieceProcessFailedNotifications
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    case InAppNotificationType.QUIZ_READY:
      return (
        <InAppQuizReadyNotification
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    case InAppNotificationType.REFERRAL_REWARD:
      return (
        <InAppReferralRewardNotification
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    case InAppNotificationType.ASK_APP_REVIEW:
      return (
        <InAppAskAppReviewNotifications
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    case InAppNotificationType.ASK_NOTIFICATION_OPTIN:
      return (
        <InAppAskOutAppNotificationOptinNotification
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    case InAppNotificationType.MILESTONE_REACHED:
      return (
        <InAppMilestoneReachedNotification
          notification={currentNotification}
          onRead={handleNotificationRead}
        />
      );
    default:
      return null;
  }
};

export default InAppNotifications;
