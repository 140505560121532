import { useQuery } from 'react-query';
import axios from 'axios';
import { Streak } from 'src/types/streak.types';

export const useGetStreaks = () => {
  return useQuery<Streak[]>({
    queryKey: ['streaks'],
    queryFn: async () => {
      const { data } = await axios.get<Array<Omit<Streak, 'streak_date'>>>(
        '/api/user/daily_streaks',
      );
      return data.map(streak => ({
        ...streak,
        streakDate: streak.created_at.split('T')[0],
      }));
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
  });
};

export const useGetChildStreaks = ({ childId }: { childId: number }) => {
  return useQuery<Streak[]>({
    queryKey: ['streaks', { childId }],
    queryFn: async () => {
      const { data } = await axios.get<Array<Omit<Streak, 'streak_date'>>>(
        `/api/users/${childId}/streaks`,
      );
      return data.map(streak => ({
        ...streak,
        streakDate: streak.created_at.split('T')[0],
      }));
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
  });
};
