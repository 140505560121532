const MilestoneAnimation = () => {
  return (
    <>
      <div
        className={
          'absolute -z-10 top-0 bottom-0 animate-pulse -translate-x-10 -translate-y-4 left-0 right-0 m-auto'
        }
      >
        <div className={'luminaire on'} />
      </div>
      <div
        style={{ animationDelay: '1s' }}
        className={
          'absolute -z-10 rotate-180 animate-pulse -translate-y-4 top-0 bottom-0 left-0 right-0 m-auto'
        }
      >
        <div
          className={'luminaire on'}
          style={{ animationDirection: 'reverse' }}
        />
      </div>
      <div
        style={{ animationDelay: '1.5s' }}
        className={
          'absolute -z-10 -rotate-90 animate-pulse translate-x-5 translate-y-5 top-0 bottom-0 left-0 right-0 m-auto'
        }
      >
        <div className={'luminaire on'} />
      </div>
    </>
  );
};

export default MilestoneAnimation;
