import QuizXPIcon from 'src/images/quiz/xp.svg?react';

import { Lesson } from 'src/types/lesson.types';

const LessonCardXP = ({ lesson }: { lesson: Lesson }) => {
  if (!lesson.latest_quiz) {
    return null;
  }

  return (
    <div className={'flex flex-row items-center justify-center gap-1'}>
      <QuizXPIcon className={'h-5 w-auto'} />
      <div className={'text-base relative font-extrabold text-white'}>
        <div>+{lesson.latest_quiz?.questions?.length} XP</div>
      </div>
    </div>
  );
};

export default LessonCardXP;
