import { useVoiceRecorder } from 'src/components/VoiceRecorder/VoiceRecorderContext';
import PauseIcon from 'src/images/pause.svg?react';

const PauseVoiceRecordButton = () => {
  const {
    controls: { isPausedRecording, isRecordingInProgress, togglePauseResume },
  } = useVoiceRecorder();

  if (!isRecordingInProgress || isPausedRecording) {
    return null;
  }

  return (
    <div
      className="flex items-center justify-center bg-white rounded-full text-structural-darkest text-2xl h-14 w-14 cursor-pointer"
      onClick={togglePauseResume}
      data-testid="btn-pause-mic"
    >
      <PauseIcon className={'fill-structural-darkest'} />
    </div>
  );
};

export default PauseVoiceRecordButton;
