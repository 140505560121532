import { KeepAwake } from '@capacitor-community/keep-awake';

export const keepAwake = async () => {
  if (!(await isSupported())) {
    return;
  }
  await KeepAwake.keepAwake();
};

export const allowSleep = async () => {
  if (!(await isSupported())) {
    return;
  }
  await KeepAwake.allowSleep();
};

const isSupported = async () => {
  const result = await KeepAwake.isSupported();
  return result.isSupported;
};
