import { useCallback, useMemo, useState } from 'react';
import { useGetStreaks } from 'src/queries/streak';
import dayjs, { type Dayjs } from 'dayjs';
import { get7PreviousDays } from 'src/utils/date';
import { isStreakCompleted as isStreakCompletedUtil } from 'src/utils/streak';

const maxDay = dayjs().add(3, 'day');

const useStreaksDetails = () => {
  const { data: streaks } = useGetStreaks();
  const [currentLastDay, setCurrentLastDay] = useState<Dayjs>(maxDay);

  const days = useMemo(() => {
    return get7PreviousDays(currentLastDay);
  }, [currentLastDay]);
  const previousWeek = useCallback(() => {
    setCurrentLastDay(prev =>
      prev.locale('fr').startOf('week').subtract(1, 'day'),
    );
  }, []);
  const nextWeek = useCallback(() => {
    setCurrentLastDay(prev => {
      const newLastDay = prev.locale('fr').endOf('week').add(1, 'week');
      if (newLastDay.isAfter(dayjs())) return maxDay;
      return newLastDay;
    });
  }, []);
  const isStreakCompleted = useCallback(
    (day: Dayjs) => {
      if (!streaks) return false;
      return isStreakCompletedUtil(day, streaks);
    },
    [streaks],
  );

  return {
    previousWeek,
    nextWeek,
    days,
    isStreakCompleted,
    currentLastDay,
    nextWeekAvailable: currentLastDay.isBefore(maxDay),
  };
};

export default useStreaksDetails;
