import Spinner from 'src/components/common/designSystem/Spinner';
import SendIcon from 'src/images/send.svg?react';
import { useVoiceRecorder } from 'src/components/VoiceRecorder/VoiceRecorderContext';

const SendVoiceRecordButton = () => {
  const {
    stopRecord,
    isHandlingBlob,
    controls: { isPausedRecording, isProcessingRecordedAudio },
  } = useVoiceRecorder();

  if (!isPausedRecording && !isHandlingBlob && !isProcessingRecordedAudio) {
    return null;
  }

  if (isHandlingBlob) {
    return <Spinner type={'white'} />;
  }

  return (
    <div
      className="flex items-center justify-center bg-white rounded-full text-structural-darkest text-2xl h-14 w-14 cursor-pointer"
      onClick={stopRecord}
      data-testid="btn-send-record"
    >
      <SendIcon className={'fill-structural-darkest'} />
    </div>
  );
};

export default SendVoiceRecordButton;
