import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useGetLesson, useStartLessonAnalysis } from 'src/queries/lessons';
import Spinner from 'src/components/common/designSystem/Spinner';
import { PieceType, useCreateLessonPiece } from 'src/queries/lesson_pieces';
import { LeftOutline } from 'antd-mobile-icons';
import VoiceRecorderContextProvider from 'src/components/VoiceRecorder/VoiceRecorderContext';
import LessonDetailVoiceRecorder from 'src/components/VoiceRecorder/LessonDetailVoiceRecorder';
import BlankPrimaryLayout from 'src/layouts/BlankPrimaryLayout';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import useNavigateBack from 'src/hooks/useNavigateBack';

const LessonAddAudio = () => {
  const { lessonId } = useParams();
  const navigate = useNavigate();
  const { data: lesson, isLoading } = useGetLesson(lessonId);
  const { t } = useTranslation();
  const { mutateAsync: createLessonPiece } = useCreateLessonPiece({
    type: PieceType.VOICE,
  });
  const { mutateAsync: startAnalysis } = useStartLessonAnalysis();
  const goBack = useNavigateBack();

  const handleBack = () => {
    goBack();
  };

  const handleCreatePiece = async (
    file: Blob,
    duration: number,
    uri?: string,
  ) => {
    if (!lesson || !lesson.id) {
      return;
    }

    const piece = await createLessonPiece({
      lessonId: lesson.id,
      title: t('lessons.lessonPieceNumbered', {
        number: lesson.lesson_pieces.length + 1,
      }),
      file,
      uri,
    });
    await startAnalysis(lesson.id);
    logAnalyticsEvent('add_lesson-content', {
      lessonId: lesson.id,
      lessonPieceId: piece.id,
      lessonSubject: lesson.subject_matter,
      lessonTitle: lesson.title,
      recordingDuration: duration,
    });
    navigate(`/lessons/${lesson.id}/pieces/${piece.id}/processing`, {
      replace: true,
    });
  };

  if (isLoading) {
    return <Spinner className="w-full h-full" />;
  }

  if (!lesson) {
    return <Navigate to="404" />;
  }

  return (
    <VoiceRecorderContextProvider onRecordReady={handleCreatePiece}>
      <BlankPrimaryLayout>
        <div className="w-full h-full flex flex-col justify-between text-white p-8">
          <div className={'flex flex-row items-center justify-between'}>
            <LeftOutline
              fontSize={24}
              data-testid={'btn-back'}
              onClick={handleBack}
            />
            <div className={'font-extrabold text-base'}>
              {t('lessons.audio.title')}
            </div>
            <div className={'w-6'} />
          </div>
          <div>
            <LessonDetailVoiceRecorder />
          </div>
        </div>
      </BlankPrimaryLayout>
    </VoiceRecorderContextProvider>
  );
};

export default LessonAddAudio;
