import AddIcon from 'src/images/add.svg?react';
import { useNavigate } from 'react-router-dom';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useTranslation } from 'react-i18next';

const AddChildMenuItem = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = async () => {
    logAnalyticsEvent('add_child_button_clicked');
    navigate(`/child/firstname`);
  };

  return (
    <div
      onClick={onClick}
      data-testid={'btn-add-child'}
      className={
        'flex items-center justify-center gap-2 font-semibold px-2 py-1 text-base'
      }
    >
      <AddIcon className={'fill-white w-4 h-4'} />
      <span>{t('settings.users.addUser')}</span>
    </div>
  );
};

export default AddChildMenuItem;
