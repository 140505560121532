import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CapacitorShake } from '@capgo/capacitor-shake';
import type { PluginListenerHandle } from '@capacitor/core';
import { createBugReport, reportError } from 'src/modules/logs/Sentry';
import { debounce } from 'ts-debounce';
import { AutoFloatingPanel } from '../common/AutoFloatingPanel';
import Button from 'src/components/common/designSystem/Button';
import Input from 'src/components/common/designSystem/Input';

const DebugShakeListener = () => {
  const handler = useRef<PluginListenerHandle>();
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [bugDescription, setBugDescription] = useState<string>();

  const listener = useCallback(
    debounce(
      async () => {
        setPanelOpen(true);
      },
      2000,
      { isImmediate: true },
    ),
    [],
  );

  const sendBug = async () => {
    if (!bugDescription) {
      return;
    }
    await createBugReport(bugDescription);
    setPanelOpen(false);
  };

  useEffect(() => {
    if (import.meta.env.MODE !== 'staging') {
      return;
    }

    if (handler.current) {
      return;
    }

    CapacitorShake.addListener('shake', listener)
      .then(_handler => {
        handler.current = _handler;
      })
      .catch(err => reportError('Could not add shake listener', err));

    return () => {
      handler.current?.remove();
      handler.current = undefined;
    };
  }, [listener]);

  return (
    <AutoFloatingPanel isOpen={isPanelOpen} onClose={() => setPanelOpen(false)}>
      <div className={'p-4 flex flex-col gap-4'}>
        <Input
          placeholder={'Description du bug'}
          value={bugDescription}
          onChange={(e: FormEvent<HTMLInputElement>) => {
            setBugDescription(e.currentTarget.value);
          }}
        />
        <Button type={'primary'} disabled={!bugDescription} onClick={sendBug}>
          Envoyer le bug
        </Button>
      </div>
    </AutoFloatingPanel>
  );
};

export default DebugShakeListener;
