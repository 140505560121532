import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Spinner from 'src/components/common/designSystem/Spinner';

import { useNavigate } from 'react-router-dom';

const LessonPieceProcessingPanel = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation();
  const timer = useRef<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (!isOpen) {
      return;
    }
    timer.current = window.setTimeout(() => {
      navigate(`/home`, { replace: true });
    }, 5000);
  }, [navigate, isOpen]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  return (
    <AutoFloatingPanel isOpen={isOpen} closable={false}>
      <div
        className={
          'flex flex-col gap-4 p-8 items-center justify-center text-structural-darkest text-center'
        }
      >
        <div className={'text-5xl'}>📚</div>
        <div className={'text-xl font-bold text-balance'}>
          {t('lessons.piece.processing.title')}
        </div>
        <div className={'text-base text-balance'}>
          {t('lessons.piece.processing.description')}
        </div>
        <Spinner size={'xl'} className="w-full h-full" />
      </div>
    </AutoFloatingPanel>
  );
};

export default LessonPieceProcessingPanel;
