import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { StatusBar as CapacitorStatusBar } from '@capacitor/status-bar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';

const StatusBar = ({ color, style }: { color: string; style: Style }) => {
  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      CapacitorStatusBar.setOverlaysWebView({
        overlay: false,
      });
      CapacitorStatusBar.setBackgroundColor({
        color,
      });
      CapacitorStatusBar.setStyle({ style });
    }
  }, [color, style]);

  return null;
};

export default StatusBar;
