import ChildGenderStep from 'src/components/Onboarding/ChildGenderStep';

const ParentChildGenderStep = ({
  childId,
  onFinish,
}: {
  childId: number;
  onFinish: () => void;
}) => {
  return <ChildGenderStep childId={childId} onFinish={onFinish} />;
};

export default ParentChildGenderStep;
