// Import the functions you need from the SDKs you need
import { initializeApp, type FirebaseOptions } from 'firebase/app';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import i18n from 'i18next';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = JSON.parse(
  import.meta.env.VITE_FIREBASE_CONFIG as string,
) as FirebaseOptions;

// Initialize Firebase for JS
initializeApp(firebaseConfig);

export type AuthError = {
  code?: string;
  message: string;
};

export const authErrorCodeToMessage = ({
  code,
  message,
}: AuthError): string | undefined => {
  if (
    message === 'The user canceled the sign-in flow.' ||
    message ===
      'L’opération n’a pas pu s’achever. (com.apple.AuthenticationServices.AuthorizationError erreur 1001.)'
  ) {
    code = 'user-cancelled';
  }
  switch (code) {
    case 'auth/email-already-in-use':
    case 'email-already-in-use':
      return i18n.t('login.errors.emailAlreadyInUse');
    case 'auth/user-not-found':
    case 'user-not-found':
      return i18n.t('login.errors.userNotFound');
    case 'auth/wrong-password':
    case 'wrong-password':
    case 'auth/invalid-login-credentials':
    case 'invalid-login-credentials':
      return i18n.t('login.errors.wrongPassword');
    case 'auth/invalid-action-code':
    case 'invalid-action-code':
      return i18n.t('login.errors.linkExpired');
    case 'auth/redirect-cancelled-by-user':
    case 'redirect-cancelled-by-user':
    case 'auth/user-cancelled':
    case 'user-cancelled':
      return undefined;
    default:
      return i18n.t('login.errors.defaultError');
  }
};

FirebaseAuthentication.setLanguageCode({ languageCode: 'fr' });

export const getCurrentUser = async () => {
  const result = await FirebaseAuthentication.getCurrentUser();
  return result.user;
};

export const getCurrentUserIdToken = async () => {
  const currentUser = await getCurrentUser();
  if (!currentUser) {
    return;
  }
  return (await FirebaseAuthentication.getIdToken()).token;
};

if (import.meta.env.MODE === 'development') {
  await FirebaseAuthentication.useEmulator({
    // eslint-disable-line react-hooks/rules-of-hooks
    host: import.meta.env.VITE_FIREBASE_EMULATOR_HOST,
    port: import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_PORT || 9099,
    scheme: 'http',
  });
  // under the hood, this is only the web version that is used, look at
  // https://github.com/capawesome-team/capacitor-firebase/blob/main/packages/storage/src/index.ts
  const firebaseStorage = getStorage();
  connectStorageEmulator(
    firebaseStorage,
    import.meta.env.VITE_FIREBASE_EMULATOR_HOST as string,
    import.meta.env.VITE_FIREBASE_STORAGE_EMULATOR_PORT || 9199,
  );
}
