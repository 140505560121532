import { useTranslation } from 'react-i18next';
import { List } from 'antd-mobile';

const BuildInfoMenu = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <List.Item data-testid="btn-build-info" arrow={false} onClick={onClick}>
        <span>{t('navbar.menu.buildInfo')}</span>
      </List.Item>
    </>
  );
};

export default BuildInfoMenu;
