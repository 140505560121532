const StreakCounter = ({ streaksCount }: { streaksCount: number }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="65"
      viewBox={'0 0 40 80'}
      className={
        'absolute bottom-0 overflow-visible -right-2 text-8xl font-extrabold text-white'
      }
    >
      <text
        x="0"
        y="76"
        style={{
          strokeWidth: 5,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
        className={'stroke-black fill-white'}
      >
        {streaksCount}
      </text>
    </svg>
  );
};

export default StreakCounter;
