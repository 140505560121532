import { useEffect, useState } from 'react';
import LessonPieceErrorPanel from 'src/components/LessonDetail/LessonPieceErrorPanel';
import { useQueryClient } from 'react-query';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';

const InAppLessonPieceProcessFailedNotifications = ({
  notification,
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.LESSON_PIECE_PROCESS_FAIL;
  };
  onRead: () => void;
}) => {
  const queryClient = useQueryClient();
  const params = notification.params;
  const [isOpened, setIsOpened] = useState(true);

  useEffect(() => {
    queryClient.invalidateQueries(['lesson_pieces', params.lesson_piece_id]);
    queryClient.invalidateQueries(['lessons']);
    queryClient.invalidateQueries(['lessons', params.lesson_id]);
  }, [params.lesson_id, params.lesson_piece_id, queryClient]);

  const onClose = () => {
    setIsOpened(false);
    onRead();
  };

  return (
    <LessonPieceErrorPanel
      isOpen={isOpened}
      onClose={onClose}
      subject={params.lesson_subject}
    />
  );
};

export default InAppLessonPieceProcessFailedNotifications;
