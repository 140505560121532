import { Capacitor } from '@capacitor/core';
import TestFlightPlugin from '../../plugins/TestFlightPlugin';

export const isTestFlight = async () => {
  if (!Capacitor.isNativePlatform() || Capacitor.getPlatform() !== 'ios') {
    return false;
  }

  try {
    const { value: isTestFlight } =
      await TestFlightPlugin.isSimulatorOrTestFlight({ value: '' });
    return isTestFlight;
  } catch (error) {
    return false;
  }
};
