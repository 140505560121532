import {
  hasDoneOnboardingFirstLesson,
  setHasDoneOnboardingFirstLesson,
} from 'src/utils/onboarding';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import { useEffect, useState } from 'react';
import OnboardingFirstLessonSwiper from 'src/components/Onboarding/FirstLesson/OnboardingFirstLessonSwiper';

const OnboardingFirstLesson = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (hasDoneOnboardingFirstLesson()) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);

  const onClose = () => {
    setIsOpen(false);
    setHasDoneOnboardingFirstLesson();
  };

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'panel-first-lesson'}
    >
      <OnboardingFirstLessonSwiper onFinish={onClose} />
    </AutoFloatingPanel>
  );
};

export default OnboardingFirstLesson;
