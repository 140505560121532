import { useTranslation } from 'react-i18next';

import { SmartbitContent } from 'src/types/lesson.types';

const LessonDetailSmartbit = ({
  smartbits,
  newSmartbitPayload,
}:
  | { newSmartbitPayload: false; smartbits: string[] }
  | { newSmartbitPayload: true; smartbits: SmartbitContent[] }) => {
  const { t } = useTranslation();

  if (!newSmartbitPayload)
    return (
      <ul>
        {smartbits.map((content, i) => (
          <li className="list-disc" key={i}>
            {content}
          </li>
        ))}
      </ul>
    );

  return (
    <>
      {smartbits.map((content, i) => (
        <div className="flex flex-col gap-1 mb-3" key={i}>
          <div className="font-semibold">
            {t('lessons.smartbit.objective')} {content.objective}
          </div>
          <div className="underline">
            {t('lessons.smartbit.reviewQuestions')}
          </div>
          <div className="flex flex-col gap-1 italic">
            {content.review_questions.map((reviewQuestion, idx) => (
              <div key={idx}>{reviewQuestion.question}</div>
            ))}
          </div>
          <ul>
            {Object.entries(content.details).map((detail, idx) => {
              if (!Array.isArray(detail[1])) {
                return null;
              }
              return (
                <li className="list-disc list-inside" key={idx}>
                  {detail[0]}:
                  <ul>
                    {detail[1].map((el, idx2) => (
                      <li className="list-[square] list-inside ml-4" key={idx2}>
                        {el}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </>
  );
};

export default LessonDetailSmartbit;
