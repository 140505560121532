import { emojiBlast } from 'emoji-blast';

export const blast = (
  input: string,
  {
    x,
    y,
  }: {
    x: number;
    y: number;
  } = {
    x: Math.random() * innerWidth,
    y: Math.random() * innerHeight,
  },
) => {
  const sparkles = () => {
    const velocityCoords = [
      [0, -15],
      [0, 15],
      [-15, 0],
      [15, 0],
      [10, 10],
      [-10, -10],
      [10, -10],
      [-10, 10],
    ];
    for (const [xCoordinate, yCoordinate] of velocityCoords) {
      emojiBlast({
        className: 'sparkles',
        emojiCount: 1,
        emojis: ['✨'],
        physics: {
          fontSize: 20,
          gravity: 0,
          initialVelocities: {
            rotation: 0,
            x: xCoordinate,
            y: yCoordinate,
          },
          rotation: 0,
          rotationDeceleration: 0,
        },
        position: {
          x,
          y,
        },
      });
    }
  };
  const blast = () => {
    const coordDiff = [
      [0, -150],
      [0, 150],
      [-150, 0],
      [150, 0],
      [106, 106],
      [-106, -106],
      [106, -106],
      [-106, 106],
    ];
    for (const [xCoordinateDiff, yCoordinateDiff] of coordDiff) {
      emojiBlast({
        emojis: [input],
        physics: {
          gravity: 0.2,
          fontSize: { max: 96, min: 14 },
          initialVelocities: {
            rotation: 0,
            x: { max: 10, min: -10 },
            y: { max: 15, min: -15 },
          },
        },
        position: {
          x: x + xCoordinateDiff,
          y: y + yCoordinateDiff,
        },
        emojiCount: 3,
        uniqueness: 1,
      });
    }
  };
  const removeSparkles = () => {
    const elements = document.getElementsByClassName('sparkles');
    while (elements.length > 0) {
      elements[0].parentNode?.removeChild(elements[0]);
    }
  };
  sparkles();
  setTimeout(removeSparkles, 630);
  setTimeout(blast, 630);
};
