import { useCurrentUser } from 'src/queries/user';
import BackgroundPattern from 'src/components/common/BackgroundPattern';
import BaoWink from 'src/images/bao/bao-wink.svg?react';
import { Trans, useTranslation } from 'react-i18next';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useEffect, useState } from 'react';
import DiscoverChallengePanel from 'src/components/Challenge/DiscoverChallengeCard/DiscoverChallengePanel';

const DiscoverChallengeCard = () => {
  const { data: user } = useCurrentUser();
  const { t } = useTranslation();
  const [showPanel, setShowPanel] = useState(false);

  useEffect(() => {
    if (user?.show_invite_card) {
      logAnalyticsEvent('card_challenge_tuto_viewed');
    }
  }, [user?.show_invite_card]);

  const discover = () => {
    setShowPanel(true);
  };

  if (!user?.show_invite_card) {
    return null;
  }

  return (
    <>
      <div
        onClick={discover}
        data-testid={'card-discover-challenge'}
        className={
          'bg-gradient-to-b from-[#B358DC] to-[#9B5FFF] cursor-pointer relative rounded-3xl overflow-hidden text-white'
        }
      >
        <BackgroundPattern />
        <div className={'z-10 flex flex-row justify-between items-center'}>
          <div
            className={'flex flex-1 flex-col pl-6 gap-2 justify-between py-6'}
          >
            <div className={'flex flex-col gap-1'}>
              <div className={'font-bold text-2xl'}>
                {t('challenge.discover.card.title')}
              </div>
              <div className={'font-bold text-xl'}>
                <Trans i18nKey={'challenge.discover.card.description'}>
                  Entre dans l’arène des <b>défis</b> !
                </Trans>
              </div>
            </div>
            <div className={'flex'}>
              <div
                className={
                  'bg-white w-auto rounded-full px-4 py-1 font-semibold text-structural-darkest'
                }
              >
                {t('challenge.discover.card.button')}
              </div>
            </div>
          </div>
          <BaoWink className={'h-40'} />
        </div>
      </div>

      <DiscoverChallengePanel
        isOpen={showPanel}
        onClose={() => setShowPanel(false)}
      />
    </>
  );
};

export default DiscoverChallengeCard;
