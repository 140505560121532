import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ButtonProps } from 'antd';

import Input from 'src/components/common/designSystem/Input';

import Button from 'src/components/common/designSystem/Button';
import { LeftOutline } from 'antd-mobile-icons';

import { LessonSubject } from 'src/types/lesson.types';

export default function AddLessonTitle({
  onBack,
  onSubmitTitle,
  isLoading,
  subject,
}: {
  onBack?: () => void;
  onSubmitTitle: (title: string) => Promise<void>;
  isLoading?: boolean;
  subject: LessonSubject;
} & ButtonProps) {
  const { t } = useTranslation();
  const [title, setTitle] = useState<string>('');

  return (
    <div className={'flex flex-col gap-6'}>
      <div className={'flex flex-row justify-between items-center w-full'}>
        {onBack ? (
          <LeftOutline
            fontSize={16}
            data-testid={'btn-back'}
            onClick={onBack}
          />
        ) : (
          <div className={'w-4'} />
        )}
        <div className={'text-base font-semibold'}>
          {t('lessons.new.title.title')}
        </div>
        <div className={'w-4'} />
      </div>
      <Input
        data-testid="inp-lesson-title"
        placeholder={t(`lessons.new.title.placeholder.${subject}`)}
        prefix="📖"
        value={title}
        onChange={(e: FormEvent<HTMLInputElement>) => {
          setTitle(e.currentTarget.value);
        }}
      />
      {title.length ? (
        <Button
          loading={isLoading}
          data-testid="btn-create-lesson"
          type="primary"
          onClick={() => onSubmitTitle(title)}
        >
          {t('lessons.new.create')}
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
}
