import { useTranslation } from 'react-i18next';
import { List, Modal } from 'antd-mobile';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteLesson } from 'src/queries/lessons';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { useNavigate } from 'react-router-dom';
import { useNotification } from 'src/contexts/NotificationContext';

const LessonDeleteMenuItem = () => {
  const { t } = useTranslation();
  const { lesson } = useLesson();
  const navigate = useNavigate();
  const { showError, showSuccess } = useNotification();

  const { mutateAsync: deleteLesson, isLoading } = useDeleteLesson();

  const onConfirmDelete = async () => {
    try {
      await deleteLesson(lesson.id!);
      showSuccess({
        message: t('lessons.delete.success'),
      });
      navigate('/');
    } catch (error) {
      showError({
        error,
        message: t('lessons.delete.error'),
      });
    }
  };
  const onDelete = async () => {
    Modal.clear();
    Modal.show({
      closeOnMaskClick: true,
      title: t('lessons.delete.title'),
      content: (
        <div className={'text-center'}>{t('lessons.delete.description')}</div>
      ),
      closeOnAction: true,
      onAction: ({ key }) => {
        if (key === 'confirm') onConfirmDelete();
      },
      actions: [
        {
          key: 'confirm',
          text: (
            <span data-testid={'btn-confirm-delete-lesson'}>
              {t('lessons.menu.delete')}
            </span>
          ),
          danger: true,
        },
        {
          key: 'cancel',
          text: t('global.cancel'),
          className: 'text-structural-darkest',
        },
      ],
    });
  };

  return (
    <>
      <List.Item
        arrow={false}
        data-testid="btn-delete-lesson"
        onClick={onDelete}
        disabled={isLoading}
        className="text-red-neutral hover:text-red-neutral"
      >
        <div className="flex gap-2 items-center">
          <DeleteOutlined />
          <span>{t('lessons.menu.delete')}</span>
        </div>
      </List.Item>
    </>
  );
};

export default LessonDeleteMenuItem;
