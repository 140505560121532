import StreakCard from 'src/components/Streak/modal/StreakCard';
import MilestoneQuiz from 'src/components/Milestones/MilestoneQuiz';
import MilestoneLessons from 'src/components/Milestones/MilestoneLessons';
import MilestoneQuizAnswers from './MilestoneQuizAnswers';
import MilestoneSubject from './MilestoneSubject';
import { MilestoneType } from 'src/types/milestone.types';

const MilestoneCard = ({
  type,
  count,
}: {
  type: MilestoneType;
  count: number;
}) => {
  if (type === MilestoneType.daily_streaks) {
    return <StreakCard count={count} />;
  }
  if (type === MilestoneType.quizzes) {
    return <MilestoneQuiz count={count} />;
  }
  if (type === MilestoneType.lessons) {
    return <MilestoneLessons count={count} />;
  }
  if (type === MilestoneType.quiz_answers) {
    return <MilestoneQuizAnswers count={count} />;
  }

  return <MilestoneSubject type={type} />;
};

export default MilestoneCard;
