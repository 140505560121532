import { useGetChallengesStats } from 'src/queries/challenge';
import { useTranslation } from 'react-i18next';
import Spinner from 'src/components/common/designSystem/Spinner';

const ChallengeStatsProfileItem = () => {
  const { data: stats, isLoading } = useGetChallengesStats();
  const { t } = useTranslation();

  return (
    <div
      className={
        'flex flex-row justify-between items-center rounded-xl bg-structural-lightest p-4'
      }
      data-testid={'card-challenges'}
    >
      <div className={'font-bold text-base'}>
        {t('profile.child.challenges')}
      </div>
      {isLoading ? (
        <Spinner size={'small'} />
      ) : (
        <div className={'font-extrabold text-base'}>
          {stats?.total_wins ?? 0}
        </div>
      )}
    </div>
  );
};

export default ChallengeStatsProfileItem;
