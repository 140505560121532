import { useLesson } from 'src/components/LessonDetail/LessonContext';
import LessonTitleForm from 'src/components/LessonDetail/LessonTitleForm';
import { useUpdateLesson } from 'src/queries/lessons';
import { useNavigate } from 'react-router-dom';

const LessonEditTitle = () => {
  const { lesson } = useLesson();
  const navigate = useNavigate();
  const { mutateAsync: updateLesson } = useUpdateLesson();

  const onSubmit = async (title: string) => {
    await updateLesson({ id: lesson.id, title });
    navigate(-1);
  };

  return (
    <LessonTitleForm
      onSubmit={onSubmit}
      title={lesson.title}
      subject={lesson.subject_matter}
    />
  );
};

export default LessonEditTitle;
