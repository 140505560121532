import SendIcon from 'src/images/send.svg?react';
import Spinner from 'src/components/common/designSystem/Spinner';
import { PhotoStatus, usePhoto } from 'src/components/Photo/PhotoContext';

const SendPhotoButton = () => {
  const { status, sendPhoto } = usePhoto();

  if (
    status !== PhotoStatus.PHOTO_READY &&
    status !== PhotoStatus.SENDING_PHOTO
  ) {
    return null;
  }

  if (status === PhotoStatus.SENDING_PHOTO) {
    return <Spinner type={'white'} size={'large'} />;
  }

  return (
    <div
      className="flex items-center justify-center rounded-full bg-white h-16 w-16 cursor-pointer"
      onClick={sendPhoto}
      data-testid="btn-send-photo"
    >
      <SendIcon className={'fill-structural-darkest'} />
    </div>
  );
};

export default SendPhotoButton;
