import { ReactNode } from 'react';
import { NavBar, SafeArea } from 'antd-mobile';

import Logo from 'src/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import AvatarHeaderButton from './AvatarHeaderButton';

import { LeftOutline } from 'antd-mobile-icons';
import useNavigateBack from 'src/hooks/useNavigateBack';

export interface HeaderProps {
  title?: ReactNode;
  subtitle?: ReactNode;
  backArrow?: boolean;
  backArrowPathname?: string;
  onBack?: () => void;
}

const HeaderLogo = () => {
  const navigate = useNavigate();
  return (
    <img
      src={Logo}
      alt="Baobab logo"
      className="h-8 w-8 min-w-8 rounded-full"
      onClick={() => {
        navigate('/home');
      }}
    />
  );
};

export default function AuthentificatedHeader({
  title,
  subtitle,
  backArrow,
  backArrowPathname,
  onBack,
}: HeaderProps) {
  const navigate = useNavigate();
  const goBack = useNavigateBack();

  const onBackClick = () => {
    if (onBack) {
      return onBack();
    }
    if (backArrowPathname) {
      navigate(backArrowPathname);
      return;
    }
    goBack();
  };

  return (
    <>
      <SafeArea position="top" className="bg-white" />
      <NavBar
        data-testid="nav-bar"
        className="main-nav-bar"
        back={
          backArrow ? (
            <LeftOutline fontSize={24} data-testid={'btn-back'} />
          ) : null
        }
        left={!backArrow && <HeaderLogo />}
        right={
          <div className="flex justify-end">
            <AvatarHeaderButton />
          </div>
        }
        backArrow={false}
        onBack={onBackClick}
      >
        <h1 className="text-base truncate font-semibold text-structural-darker capitalize">
          {title}
        </h1>
        {subtitle ? (
          <div className="text-xs truncate text-structural-darker">
            {subtitle}
          </div>
        ) : null}
      </NavBar>
    </>
  );
}
