import { PropsWithChildren } from 'react';
import { type PropsWithTestId } from 'src/types';

const Card = ({
  children,
  className,
  'data-testid': dataTestId,
}: PropsWithChildren & PropsWithTestId & { className?: string }) => {
  return (
    <div
      data-testid={dataTestId}
      className={
        'p-4 rounded-3xl bg-white flex flex-col justify-center items-center ' +
        className
      }
    >
      {children}
    </div>
  );
};

export default Card;
