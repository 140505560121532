import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from 'src/hooks/useAuth';

import Spinner from 'src/components/common/designSystem/Spinner';
import Divider from 'src/components/common/designSystem/Divider';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import LoginWithGoogleButton from 'src/components/Login/LoginWithGoogleButton';
import LoginWithAppleButton from 'src/components/Login/LoginWithAppleButton';
import Button from 'src/components/common/designSystem/Button';
import BackArrowHeader from 'src/components/Header/BackArrowHeader';
import BlankLayout from 'src/layouts/BlankLayout';

function Login() {
  const { t } = useTranslation();
  const { setIsLoggedIn } = useAuth();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const onboardingCode = searchParams.get('onboardingCode');

  useEffect(() => {
    if (onboardingCode) {
      // Generated link from onboarding for first child
      FirebaseAuthentication.signInWithCustomToken({
        token: onboardingCode,
      })
        .then(async result => {
          if (result.user) {
            navigate('/onboarding/child/objective');
            return;
          }
          setSearchParams({});
        })
        .catch(() => {
          // If any error, the code must be wrong or expired, so remove loading state
          setSearchParams({});
        });
    }
  }, [navigate, onboardingCode, setIsLoggedIn, setSearchParams]);

  if (onboardingCode) {
    return <Spinner className="w-full h-full" size="large" />;
  }

  return (
    <BlankLayout>
      <BackArrowHeader />
      <div className="flex-1 bg-transparent flex flex-col justify-center items-center px-4 gap-4">
        <LoginWithGoogleButton />
        <LoginWithAppleButton />
        <Divider>{t('global.or')}</Divider>
        <Button
          data-testid="btn-login-email"
          onClick={() => {
            navigate('/login/email');
          }}
        >
          {t('login.withEmail')}
        </Button>
      </div>
    </BlankLayout>
  );
}

export default Login;
