export default {
  menu: {
    logout: 'Déconnexion',
    delete: 'Supprimer mon compte',
    settings: 'Paramètres',
    changeUser: "Changer d'utilisateur",
    buildInfo: 'Version de l’application',
    subscription: 'Abonnement',
  },
};
