import {
  getInLocalStorageByKey,
  setInLocalStorage,
} from 'src/utils/storageManager';
import { LocalStorageKey } from 'src/utils/constants';
import { getLocalStorageUserID } from 'src/utils/usersUtils';

export const hasSeenContactSupportPanel = (): boolean => {
  const userId = getLocalStorageUserID();
  if (!userId) {
    return false;
  }
  const localStorageHasSeenContactSupportPanel = getInLocalStorageByKey(
    LocalStorageKey.HAS_SEEN_CONTACT_SUPPORT_PANEL,
  );
  return Boolean(
    localStorageHasSeenContactSupportPanel &&
      localStorageHasSeenContactSupportPanel[userId],
  );
};

export const setHasSeenContactSupportPanel = () => {
  const userId = getLocalStorageUserID();
  if (!userId) {
    return;
  }
  const localStorageHasSeenContactSupportPanel = getInLocalStorageByKey(
    LocalStorageKey.HAS_SEEN_CONTACT_SUPPORT_PANEL,
  );
  if (
    localStorageHasSeenContactSupportPanel &&
    localStorageHasSeenContactSupportPanel[userId]
  ) {
    return;
  }
  setInLocalStorage(LocalStorageKey.HAS_SEEN_CONTACT_SUPPORT_PANEL, {
    ...(localStorageHasSeenContactSupportPanel || {}),
    [userId]: true,
  });
};
