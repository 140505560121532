import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useNotification } from 'src/contexts/NotificationContext';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { authErrorCodeToMessage } from 'src/modules/firebase';
import type { AuthError } from 'firebase/auth';
import { Form } from 'antd';
import Footer from 'src/components/common/designSystem/Footer';
import Button from 'src/components/common/designSystem/Button';
import EmailInput from 'src/components/Login/EmailInput';
import { reportError } from 'src/modules/logs/Sentry';

const ResetPasswordSendEmail = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchParamEmail = searchParams.get('email');

  const [resettingPassword, setResettingPassword] = useState(false);
  const navigate = useNavigate();
  const { showSuccess, showError } = useNotification();

  const sendResetPasswordEmail = async ({ email }: { email: string }) => {
    setResettingPassword(true);
    try {
      await FirebaseAuthentication.sendPasswordResetEmail({
        email,
      });
      showSuccess({
        message: t('login.resetPassword.sentEmailSuccess', {
          email,
        }),
      });
    } catch (error) {
      reportError('Fail to send reset password email', error);
      showError({
        message: authErrorCodeToMessage(error as AuthError),
        error,
      });
    } finally {
      navigate('/');
      setResettingPassword(false);
    }
  };

  return (
    <div className="flex flex-col items-center h-full">
      <div className={'font-extrabold text-base text-balance text-center'}>
        {t('login.resetPassword.title')}
      </div>
      <Form
        name="resetPasswordForm"
        onFinish={sendResetPasswordEmail}
        className="flex-1 bg-transparent w-full flex flex-col justify-center items-center px-4 gap-4"
        disabled={resettingPassword}
        validateTrigger="onSubmit"
        layout="vertical"
        initialValues={{ email: searchParamEmail }}
      >
        <EmailInput />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="resetPasswordForm"
          type="primary"
          htmlType="submit"
          loading={resettingPassword}
        >
          {t('login.resetPassword.startResetPassword')}
        </Button>
      </Footer>
    </div>
  );
};

export default ResetPasswordSendEmail;
