import { useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';
import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import AuthentificatedHeader from 'src/components/Header/AuthentificatedHeader';
import Title from 'src/components/common/designSystem/Title';
import { useGetLesson } from 'src/queries/lessons';
import { getSubjectInfo } from 'src/utils/subject';
import Button from 'src/components/common/designSystem/Button';
import Spinner from 'src/components/common/designSystem/Spinner';

const AddPieces = () => {
  const { lessonId } = useParams();
  const { t } = useTranslation();
  const { data: lesson, isLoading } = useGetLesson(lessonId);

  if (isLoading) return <Spinner className="w-full h-full" />;

  if (!lesson) return <Navigate to="404" />;

  const lessonSubject = getSubjectInfo(lesson.subject_matter);

  return (
    <LayoutWithHeader
      header={
        <AuthentificatedHeader
          backArrow
          title={lesson.title}
          subtitle={t(lessonSubject.labelKey)}
        />
      }
    >
      <div className="flex flex-col justify-between items-center h-full">
        <Title>{t('lessons.newPiece.titlePieceType')}</Title>
        <div className={'w-full flex flex-col gap-4'}>
          <Link className={'w-full'} to={`/lessons/${lessonId}/pieces/photo`}>
            <Button
              className={
                'w-full text-left border-none bg-white p-0 pl-3 pr-3 h-12 text-lg whitespace-pre'
              }
            >
              {t('lessons.newPiece.titlePieceTypePhoto')}
            </Button>
          </Link>
          <Link className={'w-full'} to={`/lessons/${lessonId}/pieces/audio`}>
            <Button
              className={
                'w-full text-left border-none bg-white p-0 pl-3 pr-3 h-12 text-lg whitespace-pre'
              }
            >
              {t('lessons.newPiece.titlePieceTypeAudio')}
            </Button>
          </Link>
        </div>
        <div />
      </div>
    </LayoutWithHeader>
  );
};

export default AddPieces;
