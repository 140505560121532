import Button from 'src/components/common/designSystem/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Textarea from 'src/components/common/designSystem/Textarea';

const ReviewComment = ({
  comment: initialComment,
  onComment,
  isLoading,
}: {
  comment?: string;
  onComment: (comment: string) => void;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState(initialComment ?? '');

  return (
    <>
      <Textarea
        value={comment}
        data-testid={'inp-comment'}
        onChange={ev => {
          setComment(ev.target.value);
        }}
        placeholder={t('app_reviews.panel.negative.placeholder')}
      />
      <Button
        type={'primary'}
        data-testid={'btn-comment'}
        loading={isLoading}
        onClick={() => {
          onComment(comment);
        }}
      >
        {t('app_reviews.panel.negative.button')}
      </Button>
    </>
  );
};

export default ReviewComment;
