import { PropsWithChildren, ReactNode } from 'react';

import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import Header from 'src/components/Header/Header';
import LessonFooter from 'src/components/LessonDetail/LessonFooter';
import {
  LessonProvider,
  useLesson,
} from 'src/components/LessonDetail/LessonContext';
import LessonHeaderMenu from 'src/components/LessonDetail/LessonHeaderMenu';
import { useTranslation } from 'react-i18next';
import { getSubjectInfo } from 'src/utils/subject';

const LessonContainer = ({
  lessonId,
  title,
  children,
  footer,
}: PropsWithChildren<{
  lessonId: number;
  title?: string;
  footer?: ReactNode;
}>) => {
  return (
    <LessonProvider lessonId={lessonId}>
      <LayoutWithHeader header={<LessonContainerHeader title={title} />}>
        {children}
        {footer ?? <LessonFooter />}
      </LayoutWithHeader>
    </LessonProvider>
  );
};

export const LessonContainerHeader = ({ title }: { title?: string }) => {
  const { t } = useTranslation();
  const { lesson } = useLesson();
  const lessonSubject = getSubjectInfo(lesson.subject_matter);

  return (
    <Header
      title={title ?? lesson.title}
      subtitle={title ? lesson.title : t(lessonSubject.labelKey)}
      right={<LessonHeaderMenu lessonId={lesson.id!} />}
      backArrow
    />
  );
};

export default LessonContainer;
