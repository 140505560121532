import { useTranslation } from 'react-i18next';
import Card from 'src/components/common/designSystem/Card';
import StreakDay from './StreakDay';
import Flame from 'src/images/Flame.svg?react';
import StreakCounter from './StreakCounter';
import useStreakCard from 'src/components/Streak/modal/useStreakCard';

const StreakCard = ({ count }: { count: number }) => {
  const { t } = useTranslation();
  const { days, isStreakCompleted } = useStreakCard();

  return (
    <Card className={'gap-3'}>
      <div className={'h-4'}>
        <div className={'-mt-20 relative'}>
          <Flame className={'h-24 w-auto'} />
          <StreakCounter streaksCount={count} />
        </div>
      </div>
      <div
        className={
          'flex flex-row gap-4 border-b-primary-lighter border-b-2 pb-3'
        }
      >
        {days.map((day, index) => (
          <StreakDay
            key={index}
            day={day}
            isCompleted={isStreakCompleted(day)}
          />
        ))}
      </div>
      <div className={'text-center text-balance'}>
        {t(`home.streak.description${count}`, {
          defaultValue: t('home.streak.description'),
        })}
      </div>
    </Card>
  );
};

export default StreakCard;
