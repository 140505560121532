import { forwardRef, LegacyRef } from 'react';
import { Input as AntdInput, InputRef } from 'antd';
import { type TextAreaProps } from 'antd/lib/input';

const Textarea = (
  { className, ...props }: TextAreaProps,
  ref: LegacyRef<InputRef>,
) => {
  return (
    <AntdInput.TextArea
      {...props}
      ref={ref}
      classNames={{
        textarea:
          'placeholder-structural-neutral placeholder-opacity-100 text-base',
      }}
      className={
        'w-full min-h-12 text-base p-3 bg-white rounded-xl border border-gray-200 flex items-center ' +
        className
      }
    />
  );
};

export default forwardRef(Textarea);
