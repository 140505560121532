import { EnrichmentPlugin } from '@amplitude/analytics-types';

/**
 * This is an example plugin that enriches events with event_type "Page View" by adding
 * more event_properties on top of what @amplitude/analytics-browser provides out of the box
 *
 * @returns EnrichmentPlugin
 */
export const pageViewTrackingEnrichment = (): EnrichmentPlugin => {
  return {
    name: 'page-view-tracking-enrichment',
    type: 'enrichment',
    setup: async () => undefined,
    execute: async event => {
      event.event_properties = {
        ...event.event_properties,
        page_id: getRoutePath(),
      };
      return event;
    },
  };
};

const getRoutePath = (): string => {
  const url = new URL(window.location.href);
  return url.pathname.replace(new RegExp('/\\d+/', 'g'), '/:id/');
};

export default pageViewTrackingEnrichment;
