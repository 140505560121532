import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Capacitor } from '@capacitor/core';
import { isTestFlight } from 'src/modules/ios';
import { queryClient } from 'src/queries/base';
import { User } from 'src/types/user.types';

const CONSOLE_SENTRY_LOG = '[Sentry] Error:';
class SentryError extends Error {
  constructor(
    message: string,
    public error: any,
  ) {
    super(message);
    this.error = error;
  }
}

export const initSentry = () => {
  Sentry.init(
    {
      dsn: 'https://58a93481b17ea401d177a22918387a09@o4506868795768832.ingest.us.sentry.io/4506868798259200',
      dist: process.env.npm_package_version,
      integrations: [
        SentryReact.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        SentryReact.replayIntegration({
          networkDetailAllowUrls: ['/api'],
          maskAllText: false,
          blockAllMedia: false,
        }),
        SentryReact.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      ignoreErrors: [
        /Request failed with status code 40[014]/,
        'Failed to fetch dynamically imported module',
        'Abort due to cancellation of share.',
        'AbortError: Share canceled',
        'Request timeout after',
        '[Sentry] Error:',
      ],
      beforeSend(event, hint) {
        const exception = event.exception;
        const isFromAmplitude = exception?.values?.find(exception => {
          return !!exception.stacktrace?.frames?.find(frame =>
            frame.module?.match('@amplitude'),
          );
        });
        if (isFromAmplitude) {
          return null;
        }

        if (
          event.logger === 'console' &&
          event.extra &&
          event.extra.arguments &&
          Array.isArray(event.extra.arguments) &&
          event.extra.arguments[0] === CONSOLE_SENTRY_LOG
        ) {
          return null;
        }

        if (hint.originalException instanceof SentryError) {
          event.fingerprint = ['{{ default }}', hint.originalException.message];
        }

        return event;
      },
      // Percentage of sessions to be saved. Set as 0 because we have clarity
      replaysSessionSampleRate: 1,
      // percentage of sessions to be saved when there is an error. Here 100% to have it on sentry
      replaysOnErrorSampleRate: 1,
      tracesSampleRate: 1,
      environment: import.meta.env.MODE,
    },
    SentryReact.init,
  );
  (async () => {
    Sentry.setTag(
      'app_version',
      (await CapacitorUpdater.current()).bundle.version || 'web',
    );
  })();
};

export const createBugReport = async (message: string) => {
  const currentBuild = await CapacitorUpdater.current();
  const user = queryClient.getQueryData<User>('user');
  reportError(`[BUG REPORT] ${message}`, {
    version: currentBuild.bundle.version,
    versionNative: currentBuild.native,
    platform: Capacitor.getPlatform(),
    isTestFlight: await isTestFlight(),
    userId: user?.id,
    familyId: user?.family_id,
    baseUrl: import.meta.env.VITE_BACKEND_URL,
    environment: import.meta.env.MODE,
  });
};

export const reportError = (message: string, error?: any) => {
  console.error(CONSOLE_SENTRY_LOG, message, error);
  Sentry.captureException(new SentryError(message, error), error);
};
