import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';
import ChildYearStep from 'src/components/Onboarding/ChildYearStep';
import { useCurrentUser } from 'src/queries/user';
import { Child, UserType } from 'src/types/user.types';

const OnboardingChildYear = () => {
  const navigate = useNavigate();
  const { data: child } = useCurrentUser();
  const [searchParams] = useSearchParams();
  const onNext = () => {
    navigate(`/onboarding/child/gender?${searchParams.toString()}`);
  };

  if (!child || child.user_type !== UserType.child) {
    return <Navigate to={`/onboarding?${searchParams.toString()}`} replace />;
  }

  return (
    <LayoutWithHeader
      header={<OnboardingHeader step={2} />}
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ChildYearStep child={child as Child} isMe onFinish={onNext} />
    </LayoutWithHeader>
  );
};

export default OnboardingChildYear;
