import ParentFirstnameStep from 'src/components/Onboarding/ParentFirstnameStep';

import LayoutWithHeader from 'src/layouts/LayoutWithHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OnboardingHeader from 'src/components/Onboarding/OnboardingHeader';
import StatusBar from 'src/components/common/StatusBar';
import { Style } from '@capacitor/status-bar/dist/esm/definitions';

const OnboardingParentFirstname = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onNext = () => {
    navigate(`/onboarding/parent/child/firstname?${searchParams.toString()}`);
  };

  return (
    <LayoutWithHeader
      header={<OnboardingHeader step={1} />}
      statusBar={<StatusBar color="#FFFFFF" style={Style.Light} />}
      className={'bg-white'}
    >
      <ParentFirstnameStep onFinish={onNext} />
    </LayoutWithHeader>
  );
};

export default OnboardingParentFirstname;
