import {
  createContext,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Capacitor } from '@capacitor/core';
import { Filesystem } from '@capacitor/filesystem';
import { Directory } from '@capacitor/filesystem/dist/esm/definitions';
import { useNotification } from 'src/contexts/NotificationContext';
import convertBase64ToBlob from 'src/utils/base64ToBlob';
import { CameraPreview } from '@capgo/camera-preview';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { cropBase64Image } from 'src/utils/cropImage';
import { reportError } from 'src/modules/logs/Sentry';
import CameraDisabledPanel from 'src/components/Piece/Photo/CameraDisabledPanel';

export enum PhotoStatus {
  NONE = 'none',
  CAMERA_READY = 'camera_ready',
  TAKING_PHOTO = 'taking_photo',
  PHOTO_READY = 'photo_ready',
  SENDING_PHOTO = 'sending_photo',
  PHOTO_SENT = 'photo_sent',
}

export const PhotoContext = createContext<
  | {
      takePhoto: () => Promise<void>;
      openGallery: () => Promise<void>;
      sendPhoto: () => Promise<void>;
      clearPhoto: () => void;
      previewRef: RefObject<HTMLDivElement>;
      status: PhotoStatus;
      currentPhoto?: string;
      photos: string[];
    }
  | undefined
>(undefined);

const PhotoContextProvider = ({
  children,
  onPhotoReady,
}: PropsWithChildren<{
  onPhotoReady: (blob: Blob, uri?: string) => Promise<void>;
}>) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const [isCameraDenied, setIsCameraDenied] = useState(false);
  const [photoStatus, setPhotoStatus] = useState<{
    status: PhotoStatus;
    currentPhoto?: string;
    photos: string[];
  }>({
    status: PhotoStatus.NONE,
    photos: [],
  });
  const previewRef = useRef<HTMLDivElement>(null);

  const sendPhoto = async () => {
    if (
      !photoStatus.currentPhoto ||
      photoStatus.status !== PhotoStatus.PHOTO_READY
    ) {
      return;
    }

    setPhotoStatus(prev => ({
      ...prev,
      status: PhotoStatus.SENDING_PHOTO,
    }));

    try {
      const recordedBlob = convertBase64ToBlob(photoStatus.currentPhoto);

      if (Capacitor.isNativePlatform()) {
        await Filesystem.requestPermissions();
        const tmpFile = await Filesystem.writeFile({
          path: 'photo.jpg',
          data: photoStatus.currentPhoto,
          directory: Directory.Cache,
          recursive: true,
        });
        await onPhotoReady(recordedBlob, tmpFile.uri);
      } else {
        await onPhotoReady(recordedBlob);
      }
      setPhotoStatus({
        status: PhotoStatus.PHOTO_SENT,
        currentPhoto: photoStatus.currentPhoto,
        photos: [...photoStatus.photos, photoStatus.currentPhoto],
      });
    } catch (e) {
      reportError('Fail to send photo', e);
      showError({
        message: t('lessons.photo.error'),
        error: e,
      });
      setPhotoStatus(prevState => ({
        ...prevState,
        status: PhotoStatus.PHOTO_READY,
      }));
    }
  };

  const clearPhoto = async () => {
    setPhotoStatus(prevState => ({
      ...prevState,
      status: PhotoStatus.CAMERA_READY,
      currentPhoto: undefined,
    }));
  };

  const takePhoto = async () => {
    if (
      photoStatus.status !== PhotoStatus.CAMERA_READY ||
      !previewRef.current
    ) {
      return;
    }

    setPhotoStatus(prev => ({
      ...prev,
      status: PhotoStatus.TAKING_PHOTO,
    }));
    try {
      const rect = Capacitor.isNativePlatform()
        ? previewRef.current.getBoundingClientRect()
        : previewRef.current
            .getElementsByTagName('video')[0]
            .getBoundingClientRect();
      const { width, height } = rect;

      const photo = await CameraPreview.capture({
        format: 'jpeg',
      });
      const croppedPhoto = await cropBase64Image(
        `data:image/jpeg;base64,${photo.value}`,
        0,
        0,
        width,
        height,
      );
      setPhotoStatus(prev => ({
        ...prev,
        status: PhotoStatus.PHOTO_READY,
        currentPhoto: croppedPhoto,
      }));
    } catch (e) {
      setPhotoStatus(prev => ({
        ...prev,
        status: PhotoStatus.CAMERA_READY,
      }));
      reportError('Fail to take photo', e);
      showError({
        message: t('lessons.photo.error'),
        error: e,
      });
    }
  };

  const openGallery = async () => {
    try {
      const image = await Camera.getPhoto({
        resultType: CameraResultType.Base64,
        source: CameraSource.Photos,
        quality: 100,
      });
      setPhotoStatus(prev => ({
        ...prev,
        status: PhotoStatus.PHOTO_READY,
        currentPhoto: `data:image/${image.format};base64,${image.base64String}`,
      }));
    } catch (error) {
      if (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        error.message === 'User cancelled photos app'
      ) {
        return;
      }

      reportError('Fail to open gallery', error);
      showError({
        message: t('lessons.photo.error'),
        error,
      });
    }
  };

  const checkPermissions = async () => {
    const permissionStatus = await Camera.requestPermissions();
    const isDenied = permissionStatus.camera === 'denied';
    setIsCameraDenied(isDenied);
    return !isDenied;
  };

  const startCamera = useCallback(async () => {
    if (!previewRef.current) {
      return;
    }

    try {
      if (!Capacitor.isNativePlatform()) {
        await CameraPreview.start({
          parent: 'camera-preview',
          position: 'rear',
          className: 'max-w-full max-h-full rounded-xl overflow-hidden',
          disableAudio: true,
        });
      } else {
        if (!(await checkPermissions())) {
          return;
        }

        const rect = previewRef.current.getBoundingClientRect();
        const { width, height, x, y } = rect;
        const isIOS = Capacitor.getPlatform() === 'ios';

        await CameraPreview.start({
          parent: 'camera-preview',
          position: 'rear',
          className: 'max-w-full max-h-full rounded-xl overflow-hidden',
          disableAudio: true,
          toBack: true,
          width,
          height,
          x: isIOS ? x * (window.devicePixelRatio / 2) : x,
          y: isIOS ? y * (window.devicePixelRatio / 2) : y,
        });
      }

      setPhotoStatus(prev => {
        if (prev.status === PhotoStatus.NONE) {
          return {
            ...prev,
            status: PhotoStatus.CAMERA_READY,
          };
        }
        return prev;
      });
    } catch (error) {
      reportError('Fail to start camera', error);
      showError({
        message: t('lessons.photo.error'),
        error,
      });

      setPhotoStatus(prev => ({
        ...prev,
        status: PhotoStatus.NONE,
      }));
    }
  }, [previewRef, showError, t]);

  const stopCamera = useCallback(async () => {
    try {
      await CameraPreview.stop();
    } catch (e) {}
  }, []);

  useEffect(() => {
    startCamera();
    return () => {
      stopCamera();
    };
  }, [startCamera]);

  return (
    <PhotoContext.Provider
      value={{
        status: photoStatus.status,
        currentPhoto: photoStatus.currentPhoto,
        photos: photoStatus.photos,
        takePhoto,
        sendPhoto,
        clearPhoto,
        openGallery,
        previewRef,
      }}
    >
      {children}
      <CameraDisabledPanel isOpen={isCameraDenied} />
    </PhotoContext.Provider>
  );
};

export const usePhoto = () => {
  const photoContext = useContext(PhotoContext);
  if (!photoContext) {
    throw new Error('usePhoto must be used within a PhotoContext');
  }
  return photoContext;
};

export default PhotoContextProvider;
