import axios from 'axios';
import { useQuery } from 'react-query';
import { Challenge } from 'src/types/challenge.types';

export const useGetChallenge = (challengeId: number) => {
  return useQuery<Challenge>(['challenge', challengeId], () =>
    axios.get(`/api/challenges/${challengeId}`).then(res => res.data),
  );
};

export const useGetChallengesStats = () => {
  return useQuery<{ total_challenges: number; total_wins: number }>(
    'challenges_stats',
    () => axios.get('/api/challenges/stats').then(res => res.data),
  );
};
