import {
  FirebaseStorage,
  UploadFileOptions,
} from '@capacitor-firebase/storage';

export const uploadFile = async (options: UploadFileOptions) => {
  return new Promise<void>((resolve, reject) => {
    FirebaseStorage.uploadFile(options, async (event, error) => {
      if (error) {
        reject(error);
      } else if (event?.completed) {
        const type = options.blob?.type;
        if (type) {
          await FirebaseStorage.updateMetadata({
            path: options.path,
            metadata: {
              contentType: type,
              customMetadata: { contentType: type },
            },
          });
        }
        resolve();
      }
    });
  });
};
