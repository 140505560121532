import { useVoiceRecorder } from 'src/components/VoiceRecorder/VoiceRecorderContext';
import MicroIcon from 'src/images/micro.svg?react';

const StartVoiceRecordButton = () => {
  const {
    controls: {
      isRecordingInProgress,
      isProcessingRecordedAudio,
      isAvailableRecordedAudio,
      startRecording,
    },
  } = useVoiceRecorder();

  const recordingOrFinished =
    isRecordingInProgress ||
    isProcessingRecordedAudio ||
    isAvailableRecordedAudio;

  if (recordingOrFinished) {
    return null;
  }

  return (
    <div
      className="flex items-center justify-center bg-white rounded-full text-structural-darkest text-2xl h-14 w-14 cursor-pointer"
      onClick={startRecording}
      data-testid="btn-record-mic"
    >
      <MicroIcon className={'fill-structural-darkest'} />
    </div>
  );
};

export default StartVoiceRecordButton;
