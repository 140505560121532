import { useTranslation } from 'react-i18next';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import PayButton from 'src/components/Paywall/PayButton';
import Button from 'src/components/common/designSystem/Button';
import { useEffect } from 'react';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import AskParentButton from 'src/components/Paywall/AskParentButton';
import PaywallCGU from 'src/components/Paywall/PaywallCGU';
import { useCurrentUser } from 'src/queries/user';
import { UserType } from 'src/types/user.types';
import { openWhatsAppSupport } from 'src/modules/support/support';
import AnnualOffer from 'src/components/Paywall/AnnualOffer';
import ParentSellArguments from 'src/components/Paywall/ParentSellArguments';

const PaywallPanel = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (isOpen) {
      logAnalyticsEvent('paywall_viewed', {
        type: 'blocking_trial_ended',
      });
      logAnalyticsEvent('trial_ended');
    }
  }, [isOpen]);

  return (
    <AutoFloatingPanel
      data-testid={'panel-paywall-locked'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div
        className={'flex flex-col items-start justify-center py-4 px-4 gap-4'}
      >
        <div
          className={'flex-1 flex justify-start items-center'}
          onClick={onClose}
          data-testid={'btn-skip-paywall'}
        >
          <IconMdiChevronLeft fontSize={30} />
        </div>

        <div
          className={
            'font-extrabold text-lg text-structural-darker text-left text-balance'
          }
        >
          {t(
            user?.user_type === UserType.child
              ? 'paywall.locked.title.child'
              : 'paywall.locked.title.parent',
          )}
        </div>

        <ParentSellArguments />
        <AnnualOffer />

        <div
          className={'flex flex-col items-center justify-center gap-2 w-full'}
        >
          <AskParentButton />
          <PayButton>{t('paywall.locked.button')}</PayButton>
          <Button
            data-testid="btn-contact-us"
            type={'link'}
            onClick={openWhatsAppSupport}
          >
            {t('paywall.pay.contactUs')}
          </Button>
          <PaywallCGU />
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default PaywallPanel;
