import { useTranslation } from 'react-i18next';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';

const ReferralRewardNotificationCard = ({
  notification,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.REFERRAL_REWARD;
  };
}) => {
  const { t } = useTranslation();
  const params = notification.params;

  return (
    <div
      data-testid={`notification-referral-reward-${params.user_id}`}
      className={'flex flex-row items-center bg-white rounded-xl p-4 gap-4'}
    >
      <div
        className={`w-12 h-12 text-white font-bold text-lg rounded-2xl flex items-center justify-center bg-structural-darkest`}
      >
        {params.user_firstname[0]}
      </div>
      <div className={'flex flex-1 flex-col w-full'}>
        <div
          className={'text-base font-bold text-balance text-structural-darkest'}
        >
          {t('notifications.referral_reward.title', {
            firstName: params.user_firstname,
          })}
        </div>
      </div>
    </div>
  );
};

export default ReferralRewardNotificationCard;
