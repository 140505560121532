import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsList from 'src/components/Notifications/NotificationsList';
import { useReadAllNotifications } from 'src/queries/notifications';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { mutateAsync: readAllNotifications } = useReadAllNotifications();

  useEffect(() => {
    readAllNotifications();
  }, [readAllNotifications]);

  return (
    <div className={'flex flex-col text-structural-darkest'}>
      <div className={'text-2xl font-bold text-structural-darkest my-4'}>
        {t('notifications.title')}
      </div>
      <NotificationsList />
    </div>
  );
};

export default NotificationsPage;
