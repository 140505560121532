import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { initOneSignal } from 'src/modules/push-notifications/Onesignal';

export const initPushNotifications = async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  await PushNotifications.register();
  initOneSignal();

  await PushNotifications.addListener('registrationError', err => {
    console.error('Push Notifications Registration error: ', err.error);
  });
};

export const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
};

export const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
};
