import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetPoll } from 'src/queries/poll';
import Card from 'src/components/common/designSystem/Card';
import Title from 'src/components/common/designSystem/Title';
import Button from 'src/components/common/designSystem/Button';
import Cartable from 'src/images/cartable.svg?react';
import TimeCounter from 'src/components/common/TimeCounter';
import PollPanel from 'src/components/Poll/PollPanel';

const PollCard = () => {
  const { data: poll } = useGetPoll();
  const { t } = useTranslation();
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  if (!poll) return null;

  return (
    <>
      <Card data-testid={'card-poll'} className={'mt-3'}>
        <div className={'flex flex-row justify-between items-center w-full'}>
          <div className={'w-20'} />
          <Cartable />
          <div className={'w-20'}>
            {poll.expired_at ? (
              <div
                className={
                  'border rounded-lg border-structural-neutral pt-0.5 w-full text-center text-structural-neutral'
                }
              >
                <TimeCounter endTime={poll.expired_at} />
              </div>
            ) : null}
          </div>
        </div>
        <Title>{poll.question}</Title>
        <Button
          type={'default'}
          data-testid={'btn-poll'}
          onClick={() => {
            setIsPanelOpen(true);
          }}
        >
          {t(poll.user_answer_id ? 'poll.see_results' : 'poll.vote')}
        </Button>
      </Card>
      <PollPanel
        poll={poll}
        isOpen={isPanelOpen}
        onClose={() => {
          setIsPanelOpen(false);
        }}
      />
    </>
  );
};
export default PollCard;
