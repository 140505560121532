import CloseIcon from 'src/images/close.svg?react';
import { useNavigate } from 'react-router-dom';

const QuizResultHeader = ({ title }: { title?: string }) => {
  const navigate = useNavigate();

  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className={'w-full px-4 pt-4'}>
        <div
          className={
            'flex flex-row h-16 justify-between items-center gap-4 w-full'
          }
        >
          <div className={'p-3'} onClick={onBackClick}>
            <CloseIcon
              className={'fill-structural-darkest w-4 h-4'}
              data-testid={'btn-back'}
            />
          </div>
          <div
            className={
              'flex flex-col py-3 px-4 bg-structural-darkest bg-opacity-10 rounded-2xl justify-center items-center flex-shrink overflow-hidden text-structural-darkest'
            }
          >
            <div
              className={
                'text-base font-semibold overflow-ellipsis whitespace-nowrap overflow-hidden w-full'
              }
            >
              {title}
            </div>
          </div>
          <div className={'w-10'} />
        </div>
      </div>
    </>
  );
};

export default QuizResultHeader;
