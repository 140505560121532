import { SafeArea } from 'antd-mobile';
import MenuIcon from 'src/images/menu.svg?react';
import { useState } from 'react';
import FourreToutPanel from 'src/components/Parent/FourreToutPanel';

const FourreToutButton = () => {
  const [panelOpened, setPanelOpened] = useState(false);

  const onClick = () => {
    setPanelOpened(true);
  };

  const onClose = () => {
    setPanelOpened(false);
  };

  return (
    <>
      <div
        className={'fixed bottom-4 right-4'}
        data-testid={'btn-fourre-tout-parent'}
      >
        <div
          onClick={onClick}
          className={
            'flex items-center justify-center rounded-full w-16 h-16 bg-structural-darkest'
          }
        >
          <MenuIcon className={'fill-white w-6 h-auto'} />
        </div>
        <SafeArea position={'bottom'} />
      </div>
      <FourreToutPanel isOpen={panelOpened} onClose={onClose} />
    </>
  );
};

export default FourreToutButton;
