import { LessonSubject } from 'src/types/lesson.types';
import { MilestoneType } from 'src/types/milestone.types';

export enum InAppNotificationType {
  QUIZ_READY = 'QUIZ_READY',
  LESSON_PIECE_PROCESS_FAIL = 'LESSON_PIECE_PROCESS_FAIL',
  CHALLENGE_FINISHED = 'CHALLENGE_FINISHED',
  REFERRAL_REWARD = 'REFERRAL_REWARD',
  ASK_APP_REVIEW = 'ASK_APP_REVIEW',
  ASK_NOTIFICATION_OPTIN = 'ASK_NOTIFICATION_OPTIN',
  MILESTONE_REACHED = 'MILESTONE_REACHED',
}

export type QuizReadyInAppNotification = {
  type: InAppNotificationType.QUIZ_READY;
  params: {
    lesson_id: number;
    lesson_title: string;
    lesson_subject: LessonSubject;
    lesson_piece_id: number;
    quiz_id: number;
  };
};
export type LessonPieceProcessFailInAppNotification = {
  type: InAppNotificationType.LESSON_PIECE_PROCESS_FAIL;
  params: {
    lesson_id: number;
    lesson_title: string;
    lesson_subject: LessonSubject;
    lesson_piece_id: number;
  };
};
export type ChallengeFinishedInAppNotification = {
  type: InAppNotificationType.CHALLENGE_FINISHED;
  params: {
    lesson_id: number;
    lesson_subject: LessonSubject;
    lesson_title: string;
    challenge_id: number;
    created_by: number;
    opponent_id: number;
    opponent_firstname: string;
    opponent_score: number;
    my_score: number;
  };
};
export type ReferralRewardInAppNotification = {
  type: InAppNotificationType.REFERRAL_REWARD;
  params: {
    user_id: number;
    user_firstname: string;
  };
};
export type AskAppReviewInAppNotification = {
  type: InAppNotificationType.ASK_APP_REVIEW;
  params: {};
};
export type AskNotificationOptinInAppNotification = {
  type: InAppNotificationType.ASK_NOTIFICATION_OPTIN;
  params: {};
};
export type MilestoneReachedInAppNotification = {
  type: InAppNotificationType.MILESTONE_REACHED;
  params: {
    milestone_key: MilestoneType;
    count: number;
  };
};

export type InAppNotification = {
  id: number;
  recipient_id: number;
  read_at: string;
  shown_at: string;
  created_at: string;
  updated_at: string;
  disabled: boolean;
} & (
  | QuizReadyInAppNotification
  | LessonPieceProcessFailInAppNotification
  | ChallengeFinishedInAppNotification
  | ReferralRewardInAppNotification
  | AskAppReviewInAppNotification
  | AskNotificationOptinInAppNotification
  | MilestoneReachedInAppNotification
);

export const IN_APP_NOTIFICATIONS: InAppNotificationType[] = [
  InAppNotificationType.LESSON_PIECE_PROCESS_FAIL,
  InAppNotificationType.QUIZ_READY,
  InAppNotificationType.REFERRAL_REWARD,
  InAppNotificationType.ASK_APP_REVIEW,
  InAppNotificationType.ASK_NOTIFICATION_OPTIN,
  InAppNotificationType.MILESTONE_REACHED,
];
export const NOTIFICATIONS_SHOWN_IN_LIST: InAppNotificationType[] = [
  InAppNotificationType.QUIZ_READY,
  InAppNotificationType.CHALLENGE_FINISHED,
  InAppNotificationType.REFERRAL_REWARD,
];
