import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateCurrentUser } from 'src/queries/user';
import { useNotification } from 'src/contexts/NotificationContext';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';
import { useNavigate } from 'react-router-dom';
import { reportError } from 'src/modules/logs/Sentry';

const useEndOnboardingParent = () => {
  const { t } = useTranslation();
  const {
    mutateAsync: updateCurrentUser,
    isLoading: finishingParentOnboarding,
  } = useUpdateCurrentUser();
  const { showError } = useNotification();
  const navigate = useNavigate();

  const endOnboarding = useCallback(async () => {
    try {
      await updateCurrentUser({
        onboarding_completed: true,
      });
      navigate('/home', {
        replace: true,
        state: { onboardingCompleted: true },
      });
      logAnalyticsEvent('onboarding_complete');
    } catch (error) {
      reportError('Fail to complete parent onboarding', error);
      showError({
        message: t('error.http.default'),
        error,
      });
    }
  }, [updateCurrentUser, showError, t]);

  return { endOnboarding, isEnding: finishingParentOnboarding };
};

export default useEndOnboardingParent;
