import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import Button from 'src/components/common/designSystem/Button';
import { openNativeAppSettings } from 'src/modules/native-app-settings';
import { useTranslation } from 'react-i18next';
import useNavigateBack from 'src/hooks/useNavigateBack';

const MicrophoneDisabledPanel = ({ isOpen }: { isOpen: boolean }) => {
  const goBack = useNavigateBack();
  const { t } = useTranslation();

  return (
    <AutoFloatingPanel isOpen={isOpen} onClose={goBack}>
      <div
        className={
          'flex flex-col gap-4 p-8 items-start justify-center text-structural-darkest text-left'
        }
      >
        <div className={'text-5xl'}>🎙️</div>
        <div className={'text-xl font-bold text-balance'}>
          {t('microphone.panel.title')}
        </div>
        <div className={'text-base text-balance'}>
          {t('microphone.panel.description')}
        </div>
        <div className={'flex flex-col gap-2 w-full'}>
          <Button
            onClick={openNativeAppSettings}
            data-testid={'btn-open-settings'}
            type={'primary'}
          >
            {t('microphone.panel.button')}
          </Button>
          <Button onClick={goBack} data-testid={'btn-go-back'} type={'default'}>
            {t('global.back')}
          </Button>
        </div>
      </div>
    </AutoFloatingPanel>
  );
};

export default MicrophoneDisabledPanel;
