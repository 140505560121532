// @ts-ignore
export const MONTHLY_OFFERING_ID = 'default';
// @ts-ignore
export const MONTHLY_OFFERING_ID_WITHOUT_TRIAL = 'without_trial';
export const ANNUAL_OFFERING_ID = 'premium_annual';
export const ANNUAL_OFFERING_ID_WITHOUT_TRIAL = 'premium_annual_without_trial';

export const MONTHLY_ENTITLEMENT_ID = 'premium';
export const MONTHLY_ENTITLEMENT_ID_WITHOUT_TRIAL = 'premium_without_trial';
export const ANNUAL_ENTITLEMENT_ID = 'premium_annual';
export const ANNUAL_ENTITLEMENT_ID_WITHOUT_TRIAL =
  'premium_annual_without_trial';
