import LessonCard from 'src/components/LessonsLists/LessonCard';
import { useGetLessons } from 'src/queries/lessons';
import CreateFirstLessonCompletionCard from 'src/components/CompletionCard/CreateFirstLesson';
import SubjectBoxes from 'src/components/Home/SubjectsBoxes';
import { Lesson } from 'src/types/lesson.types';
import { useEffect } from 'react';
import { setHasDoneOnboardingFirstLesson } from 'src/utils/onboarding';

export default function LessonsList() {
  const { data: lessonsData, isLoading: lessonsLoading } = useGetLessons();

  if (lessonsLoading) {
    return null;
  }

  if (!lessonsData) {
    return <CreateFirstLessonCompletionCard />;
  }

  const allLessons = lessonsData.pages.map(page => page.items).flat();

  if (!allLessons.length) {
    return <CreateFirstLessonCompletionCard />;
  }

  return <Lessons lessons={allLessons} />;
}

const Lessons = ({ lessons }: { lessons: Lesson[] }) => {
  useEffect(() => {
    if (lessons.length) {
      setHasDoneOnboardingFirstLesson();
    }
  }, [lessons.length]);

  const now = Date.now();
  const lessonOrderByEvaluation = lessons
    .filter(
      lesson =>
        !lesson.evaluation_date ||
        new Date(lesson.evaluation_date).getTime() > now,
    )
    .sort((lessonA, lessonB) => {
      if (!lessonA.evaluation_date) return 1;
      if (!lessonB.evaluation_date) return -1;
      return (
        new Date(lessonA.evaluation_date).getTime() -
        new Date(lessonB.evaluation_date).getTime()
      );
    });
  const passedLessonsOrderByEvaluation = lessons
    .filter(
      lesson =>
        lesson.evaluation_date &&
        new Date(lesson.evaluation_date).getTime() <= now,
    )
    .sort((lessonA, lessonB) => {
      return (
        new Date(lessonB.evaluation_date!).getTime() -
        new Date(lessonA.evaluation_date!).getTime()
      );
    });

  return (
    <div className="flex flex-col gap-5">
      {lessonOrderByEvaluation.map(lesson => (
        <LessonCard lesson={lesson} key={lesson.id} />
      ))}
      <SubjectBoxes />
      {passedLessonsOrderByEvaluation.map(lesson => (
        <LessonCard lesson={lesson} key={lesson.id} />
      ))}
    </div>
  );
};
