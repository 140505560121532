import { useEffect, useState } from 'react';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import {
  shouldShowLessonPieceError,
  addLocalStorageLessonPieceError,
} from 'src/utils/lessonPiece';
import LessonPieceErrorPanel from 'src/components/LessonDetail/LessonPieceErrorPanel';

const LessonPieceError = () => {
  const { lesson } = useLesson();
  const [openErrorPanel, setOpenErrorPanel] = useState(false);

  useEffect(() => {
    for (const lessonPiece of lesson.lesson_pieces) {
      if (!lessonPiece.id) {
        continue;
      }
      if (
        lessonPiece.post_process_failed &&
        shouldShowLessonPieceError(lessonPiece.id as number)
      ) {
        setOpenErrorPanel(true);
        return;
      }
    }
  }, [lesson.lesson_pieces]);

  const onClose = () => {
    for (const lessonPiece of lesson.lesson_pieces) {
      if (!lessonPiece.id) {
        continue;
      }
      if (
        lessonPiece.post_process_failed &&
        shouldShowLessonPieceError(lessonPiece.id)
      ) {
        addLocalStorageLessonPieceError(lessonPiece.id);
      }
    }
    setOpenErrorPanel(false);
  };

  return (
    <LessonPieceErrorPanel
      isOpen={openErrorPanel}
      onClose={onClose}
      subject={lesson.subject_matter}
    />
  );
};

export default LessonPieceError;
