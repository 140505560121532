import { useCurrentUser } from 'src/queries/user';
import QuizXPIcon from 'src/images/quiz/xp.svg?react';
import { useEffect, useState } from 'react';
import {
  getLocalStorageXPWon,
  resetLocalStorageXPWon,
} from 'src/utils/usersUtils';
import './XPHeaderCounter.scss';

const XPHeaderCounter = () => {
  const { data: user } = useCurrentUser();
  const [newXP, setNewXP] = useState(0);

  useEffect(() => {
    if (user) {
      setNewXP(getLocalStorageXPWon());
      setTimeout(() => {
        resetLocalStorageXPWon();
        setNewXP(0);
      }, 1000);
    }
  }, [user]);

  if (!user) {
    return null;
  }

  const xp = user?.xp || 0;
  return (
    <div
      className={
        'flex flex-row overflow-visible items-center justify-center min-w-16 gap-1 bg-structural-light bg-opacity-20 rounded-3xl px-2 py-1.5'
      }
      data-testid={'xp-header-counter'}
    >
      <QuizXPIcon className={'h-5 w-auto'} />
      <div
        className={'text-base relative font-extrabold text-structural-darkest'}
      >
        <div>{xp}</div>
        {newXP > 0 ? (
          <div
            className={
              'absolute NewXP -left-1 top-0 text-base font-extrabold text-primary-darkest'
            }
          >
            +{newXP}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default XPHeaderCounter;
