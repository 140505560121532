import { useTranslation } from 'react-i18next';
import { Modal } from 'antd-mobile';

import { useAuth } from 'src/hooks/useAuth';

const useLogout = () => {
  const { t } = useTranslation();
  const { logout: authLogout } = useAuth();

  const logout = async () => {
    Modal.clear();
    Modal.show({
      closeOnMaskClick: true,
      'data-testid': 'modal-logout-account',
      title: t('users.logout.title'),
      closeOnAction: true,
      onAction: ({ key }) => {
        if (key === 'confirm') {
          authLogout();
          Modal.clear();
        }
      },
      actions: [
        {
          key: 'confirm',
          text: (
            <span data-testid={'btn-confirm-logout-account'}>
              {t('users.logout.confirm')}
            </span>
          ),
          danger: true,
        },
        {
          key: 'cancel',
          text: t('global.cancel'),
          className: 'text-structural-darkest',
        },
      ],
    });
  };

  return { logout };
};

export default useLogout;
