import { PropsWithChildren, ReactNode } from 'react';
import { SafeArea } from 'antd-mobile';

const TransparentModal = ({
  children,
  onClose,
  isOpen,
  footer,
  'data-testid': dataTestId,
}: PropsWithChildren & {
  onClose: () => void;
  isOpen: boolean;
  footer?: ReactNode;
  'data-testid'?: string;
}) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      data-testid={dataTestId}
      className={
        'absolute slideUp top-0 left-0 bottom-0 right-0 bg-opacity-80 bg-gray-800 z-[1010] px-2 py-4'
      }
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div />
        {children}
        <div>
          {footer}
          <SafeArea position={'bottom'} />
        </div>
      </div>
    </div>
  );
};

export default TransparentModal;
