import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { copyDeep } from 'src/utils/helper-functions';
import { User } from 'src/types/user.types';

export const useGetUsers = () => {
  return useQuery<User[]>({
    queryKey: ['users'],
    refetchOnMount: 'always',
  });
};

export const useCreateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      user: { id?: undefined } & Partial<User>,
    ): Promise<User> => {
      const { data } = await axios.post('/api/users', user);
      return data;
    },
    onSuccess: data => {
      const usersData = queryClient.getQueryData<User[]>(['users']);
      if (!usersData) return;

      const newUsersData = copyDeep(usersData);
      newUsersData.push(data);
      queryClient.setQueryData(['users'], newUsersData);
    },
  });
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: Partial<User>): Promise<User> => {
      const { data } = await axios.put(`/api/users/${user.id!}`, user);
      return data;
    },
    onSuccess: data => {
      const usersData = queryClient.getQueryData<User[]>('users');
      if (!usersData) return;

      const newUsersData = copyDeep(usersData);
      const index = newUsersData.findIndex(user => user.id === data.id);
      newUsersData[index] = data;
      queryClient.setQueryData(['users'], newUsersData);
    },
  });
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (): Promise<void> => {
      const { data } = await axios.delete('/api/user');
      return data;
    },
    onSuccess: () => {
      queryClient.setQueryData(['user'], undefined);
      queryClient.removeQueries();
      queryClient.clear();
    },
  });
};
