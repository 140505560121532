import { FC, type SVGProps } from 'react';
import { Link } from 'react-router-dom';

const LessonLink = ({
  link,
  title,
  IconComponent,
  'data-testid': dataTestId,
}: {
  link: string;
  title: string;
  IconComponent: FC<SVGProps<SVGSVGElement>>;
  'data-testid'?: string;
}) => {
  return (
    <Link to={link} data-testid={dataTestId}>
      <div
        className={
          'p-4 bg-white w-full rounded-l flex flex-row gap-3 items-center'
        }
      >
        <div className={'w-10 h-10'}>
          <IconComponent className={'w-auto h-auto max-h-full max-w-full'} />
        </div>
        <div
          className={'font-bold flex-grow text-base text-structural-darkest'}
        >
          {title}
        </div>
        <div className={'text-2xl text-primary-darkest'}>{'>'}</div>
      </div>
    </Link>
  );
};

export default LessonLink;
