import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router-dom';

import LessonContainer from 'src/components/LessonDetail/LessonContainer';
import LessonImprovements from 'src/components/LessonDetail/LessonImprovements/LessonImprovements';

export default function LessonImprovementsPage() {
  const { t } = useTranslation();
  const { lessonId } = useParams<{ lessonId: string }>();

  if (!lessonId) return <Navigate to="404" />;

  const lessonIdNumber = Number(lessonId);
  return (
    <LessonContainer
      title={t('lessons.details.improvements')}
      lessonId={lessonIdNumber}
    >
      <LessonImprovements />
    </LessonContainer>
  );
}
