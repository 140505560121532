import {
  getInLocalStorageByKey,
  setInLocalStorage,
} from 'src/utils/storageManager';
import { LocalStorageKey } from 'src/utils/constants';
import { UserFamilyMemberType } from 'src/types/user.types';

export const familyMemberEmoji = (
  familyMemberType?: UserFamilyMemberType,
): string => {
  if (!familyMemberType) {
    return '🥰';
  }
  switch (familyMemberType) {
    case UserFamilyMemberType.girl:
      return '👧';
    case UserFamilyMemberType.boy:
      return '👦';
    case UserFamilyMemberType.father:
      return '👨';
    case UserFamilyMemberType.mother:
      return '👩';
    default:
      return '🥰';
  }
};

export const updateLocalStorageUserID = (userId: number | null) => {
  setInLocalStorage(LocalStorageKey.FAMILY_USER_ID, userId);
};
export const getLocalStorageUserID = (): number | null => {
  return getInLocalStorageByKey(LocalStorageKey.FAMILY_USER_ID);
};

export const addLocalStorageXPWon = (xpWon: number) => {
  setInLocalStorage(LocalStorageKey.XP_WON, getLocalStorageXPWon() + xpWon);
};
export const getLocalStorageXPWon = (): number => {
  return getInLocalStorageByKey(LocalStorageKey.XP_WON) || 0;
};
export const resetLocalStorageXPWon = () => {
  setInLocalStorage(LocalStorageKey.XP_WON, 0);
};
