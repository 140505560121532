import PhotoIcon from 'src/images/photo.svg?react';
import { PhotoStatus, usePhoto } from 'src/components/Photo/PhotoContext';

const TakePhotoButton = () => {
  const { status, takePhoto } = usePhoto();

  if (
    status === PhotoStatus.NONE ||
    status === PhotoStatus.TAKING_PHOTO ||
    status !== PhotoStatus.CAMERA_READY
  ) {
    return null;
  }

  return (
    <div
      className={`rounded-full bg-black bg-opacity-20 p-2.5 text-structural-darkest h-20 w-20 cursor-pointer`}
      onClick={takePhoto}
      data-testid="btn-capture-photo"
    >
      <div
        className={
          'flex items-center justify-center bg-white h-full w-full rounded-full'
        }
      >
        <PhotoIcon className={'fill-structural-darkest'} />
      </div>
    </div>
  );
};

export default TakePhotoButton;
