export const cropBase64Image = async (
  base64: string,
  x: number,
  y: number,
  width: number,
  height: number,
): Promise<string> => {
  const image = new Image();
  image.src = base64;
  await new Promise(resolve => {
    image.onload = resolve;
  });

  const canvas = document.createElement('canvas');
  const scaleWidth = image.width / width;
  const scaleHeight = image.height / height;
  const scale = Math.min(scaleWidth, scaleHeight);
  width *= scale;
  height *= scale;

  y = (image.height - height) / 2;
  x = (image.width - width) / 2;

  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Could not get 2d context');
  }

  ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
  return canvas.toDataURL();
};
