import Star from 'src/images/quiz/star.png';
import QuizXP from 'src/components/Quiz/QuizXP';
import Button from 'src/components/common/designSystem/Button';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from 'src/queries/user';
import { MouseEvent } from 'react';
import { blast } from 'src/components/common/animations/Blast';

const LessonSmartbitsEndScreen = ({ total }: { total: number }) => {
  const { lesson } = useLesson();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: currentUser } = useCurrentUser();

  const handleStartQuiz = async (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    navigate(`/lessons/${lesson.id}/quizzes/${lesson.latest_quiz?.id}`, {
      replace: true,
    });
  };

  return (
    <div
      className={
        'px-8 pt-24 flex flex-1 flex-col gap-24 items-center justify-center'
      }
      data-testid="lesson-smartbits-end-screen"
    >
      <div
        className={
          'text-center flex flex-1 flex-col items-center justify-between w-full py-4'
        }
        onClick={(event: MouseEvent<HTMLDivElement>) => {
          blast('👑', {
            x: event.clientX,
            y: event.clientY,
          });
        }}
      >
        <div className={'relative'}>
          <img src={Star} className={'w-32 h-32'} alt={'Star'} />
        </div>
        <div className={'flex flex-col justify-center items-center gap-4'}>
          <div className={'text-3xl font-bold text-center'}>
            {t('quiz.result.greatWork', { name: currentUser?.firstname })}
          </div>
          <div className={'text-balance text-base text-center'}>
            {t('lessons.smartbit.result.description')}
          </div>
        </div>
        <div className={'flex flex-col gap-4 w-full'}>
          <div
            className={
              'w-full items-center bg-gradient-to-r from-paywall-darkest-from/40 to-paywall-darkest-to/40 rounded-xl p-1'
            }
          >
            <div
              className={
                'flex flex-row justify-between w-full h-full items-center bg-white rounded-lg p-4 px-6'
              }
            >
              <div className={'text-base font-bold uppercase'}>
                {t('quiz.result.xp')}
              </div>
              <QuizXP xp={total} />
            </div>
          </div>
        </div>
      </div>
      <div className={'flex flex-col gap-2 w-full py-4'}>
        <Button
          type={'primary'}
          data-testid="btn-start-quiz"
          onClick={handleStartQuiz}
        >
          {t('quiz.result.restart')}
        </Button>
      </div>
    </div>
  );
};

export default LessonSmartbitsEndScreen;
