import { useTranslation } from 'react-i18next';
import { useGetLessons } from 'src/queries/lessons';
import LessonCardSmall from 'src/components/LessonsLists/LessonCardSmall';
import Spinner from 'src/components/common/designSystem/Spinner';
import { LessonSubject } from 'src/types/lesson.types';

const EmptyLessonsList = () => {
  const { t } = useTranslation();
  return (
    <div
      className={
        'flex h-full w-full flex-1 text-center text-balance justify-center items-center font-bold text-2xl'
      }
    >
      {t('lessons.empty.title')}
    </div>
  );
};

export default function LessonsSubjectList({
  subject,
}: {
  subject?: LessonSubject;
}) {
  const { data: lessonsData, isLoading: lessonsLoading } = useGetLessons(
    subject
      ? {
          subjectMatters: [subject],
        }
      : {
          toRevise: true,
        },
  );

  if (lessonsLoading) {
    return (
      <div className={'flex h-full w-full flex-1 justify-center items-center'}>
        <Spinner />
      </div>
    );
  }

  if (!lessonsData) {
    return <EmptyLessonsList />;
  }

  const allLessons = lessonsData.pages.map(page => page.items).flat();

  if (!allLessons.length) {
    return <EmptyLessonsList />;
  }

  const lessonOrderByEvaluation = allLessons.sort((lessonA, lessonB) => {
    if (!lessonA.evaluation_date) return 1;
    if (!lessonB.evaluation_date) return -1;
    return (
      new Date(lessonA.evaluation_date).getTime() -
      new Date(lessonB.evaluation_date).getTime()
    );
  });

  return (
    <div className="flex flex-col gap-5 py-5">
      {lessonOrderByEvaluation.map(lesson => (
        <LessonCardSmall lesson={lesson} key={lesson.id} />
      ))}
    </div>
  );
}
