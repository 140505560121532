import axios from 'axios';
import {
  type Location,
  type NavigateFunction,
  createSearchParams,
} from 'react-router-dom';
import { getCurrentUserIdToken } from 'src/modules/firebase';

export const publicPages = [
  '/',
  '/login',
  '/signup',
  '/reset_password',
  '/auth/action',
  '/invitations',
  '/terms-and-conditions',
  '/confidentialite',
];
export const useOnUnauthorizedError =
  (
    navigate: NavigateFunction,
    location: Location,
    setIsLoggedIn: (value: boolean) => void,
  ) =>
  (error: any): Promise<any> | undefined => {
    if (error) {
      // Ignore cancelled requests
      if (axios.isCancel(error)) return;
      // Redirect the 401 to the log-in page
      if (error.response?.status === 401) {
        setIsLoggedIn(false);
        if (!publicPages.includes(location.pathname))
          navigate({
            pathname: '/',
            search: createSearchParams({ from: location.pathname }).toString(),
          });
      }
    }

    return Promise.reject(error);
  };

export const initAxios = () => {
  axios.defaults.baseURL = String(import.meta.env.VITE_BACKEND_URL);
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common.Accept = 'application/json';
  axios.defaults.paramsSerializer = {
    indexes: null,
  };

  axios.interceptors.request.use(async function (config) {
    const url = config.url;
    config.headers['Access-Control-Allow-Origin'] = '*';
    if (
      !url ||
      (!url.startsWith(import.meta.env.VITE_BACKEND_URL) &&
        !url.startsWith('/api'))
    ) {
      return config;
    }

    const token = await getCurrentUserIdToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
};
