// @ts-nocheck

import { useEffect } from 'react';

const TikTokPixel = () => {
  useEffect(() => {
    if (import.meta.env.MODE !== 'production') {
      return;
    }

    !(function (w, d, t) {
      w.TiktokAnalyticsObject = t;
      const ttq = (w[t] = w[t] || []);
      ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie',
        'holdConsent',
        'revokeConsent',
        'grantConsent',
      ];
      ttq.setAndDefer = function (t, e) {
        t[e] = function () {
          t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      for (let i = 0; i < ttq.methods.length; i++) {
        ttq.setAndDefer(ttq, ttq.methods[i]);
      }
      ttq.instance = function (t) {
        let e = ttq._i[t] || [];
        let n = 0;
        for (; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
        return e;
      };
      ttq.load = function (e, n) {
        const r = 'https://analytics.tiktok.com/i18n/pixel/events.js';
        ttq._i = ttq._i || {};
        ttq._i[e] = [];
        ttq._i[e]._u = r;
        ttq._t = ttq._t || {};
        ttq._t[e] = +new Date();
        ttq._o = ttq._o || {};
        ttq._o[e] = n || {};
        n = document.createElement('script');
        n.type = 'text/javascript';
        n.async = !0;
        n.src = r + '?sdkid=' + e + '&lib=' + t;
        e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(n, e);
      };

      ttq.load('CSB6M3RC77U8VDFFIQJ0');
      ttq.page();
    })(window, document, 'ttq');
  }, []);

  return null;
};

export default TikTokPixel;
