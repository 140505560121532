import { useTranslation } from 'react-i18next';
import BackgroundPattern from 'src/components/common/BackgroundPattern';
import BaoWink from 'src/images/bao/bao-wink.svg?react';
import Button from 'src/components/common/designSystem/Button';
import { useState } from 'react';
import InvitationPanel from 'src/components/Invitation/InvitationPanel';
import { User } from 'src/types/user.types';

const LessonsParentEmpty = ({ child }: { child: User }) => {
  const { t } = useTranslation();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const inviteChild = () => {
    setIsInviteModalOpen(true);
  };

  return (
    <>
      <div
        data-testid={'card-empty-lessons'}
        className={
          'flex flex-col relative items-center overflow-hidden rounded-2xl bg-gradient-to-t from-paywall-darkest-from to-paywall-darkest-to text-white'
        }
      >
        <div
          className={
            'flex flex-col py-8 gap-2 items-center ' +
            'bg-gradient-to-b from-50% from-[#00000000] to-[#000000bb] w-full'
          }
        >
          <BackgroundPattern />
          <BaoWink className={'w-36 h-auto'} />
          <div className={'flex flex-col gap-6 px-8 items-start'}>
            <div className={'font-bold text-3xl'}>
              {t('settings.users.lessons.empty.title')}
            </div>
            <Button
              type={'default'}
              data-testid={'btn-invite'}
              onClick={inviteChild}
            >
              {t('settings.users.lessons.empty.button')}
            </Button>
          </div>
        </div>
      </div>
      <InvitationPanel
        childId={child.id!}
        isOpen={isInviteModalOpen}
        onClose={() => {
          setIsInviteModalOpen(false);
        }}
      />
    </>
  );
};

export default LessonsParentEmpty;
