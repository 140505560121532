import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLesson } from 'src/components/LessonDetail/LessonContext';
import Spinner from 'src/components/common/designSystem/Spinner';
import LessonProgressGraph from 'src/components/LessonDetail/LessonProgressGraph';
import Card from 'src/components/common/designSystem/Card';
import Graph from 'src/images/graph.svg?react';
import { firstBy, sumBy } from 'remeda';
import dayjs from 'dayjs';
import LessonWithoutQuizCard from 'src/components/LessonDetail/LessonWithoutQuizCard';
import { useGetQuizRuns } from 'src/queries/quiz/runs';
import { FinishedQuizRun, QuizRun } from 'src/types/quiz.types';

const LessonProgress = () => {
  const { t } = useTranslation();
  const { lesson } = useLesson();
  const lessonId = lesson.id!;
  const { isLoading, data: quizRunsPages } = useGetQuizRuns(lessonId);

  const finishedQuizRuns = useMemo(() => {
    if (!quizRunsPages?.pages.length) return undefined;

    const finishedQuizRuns = quizRunsPages.pages
      .flatMap(page => page.items)
      .filter(quizRun => quizRun.finished_at) as Array<
      Omit<QuizRun, 'finished_at'> & {
        finished_at: string;
      }
    >;

    return finishedQuizRuns
      .map(finishedQuizRun => ({
        date: new Date(finishedQuizRun.finished_at),
        grade: finishedQuizRun.grade,
        id: finishedQuizRun.id,
      }))
      .sort((a, b) => a.date.getTime() - b.date.getTime()) as FinishedQuizRun[];
  }, [quizRunsPages]);

  if (isLoading) return <Spinner size={'xl'} className="w-full h-full" />;

  if (!finishedQuizRuns?.length) return <LessonWithoutQuizCard />;

  const bestQuizRun = firstBy(finishedQuizRuns, [item => item.grade, 'desc'])!;
  const averageGrade =
    sumBy(finishedQuizRuns, item => item.grade) / finishedQuizRuns.length;

  const minDate = finishedQuizRuns[0].date;
  const maxDate = finishedQuizRuns[finishedQuizRuns.length - 1].date;

  return (
    <Card>
      <div className={'flex flex-col gap-9 w-full'}>
        <div
          className={'flex flex-row w-full gap-2 justify-start items-center'}
        >
          <div className={'w-4 h-5 flex justify-center items-center pb-1'}>
            <Graph className={'w-auto h-auto max-h-full max-w-full'} />
          </div>
          <div className={'text-base font-extrabold'}>Ton score</div>
        </div>
        <div className={'w-full flex flex-row'}>
          <div className={'gap-1 flex flex-col flex-1'}>
            <div className={'text-xs uppercase'}>
              {t('lessons.progress.bestScore')}
            </div>
            <div className={'text-lg font-bold'}>
              {Math.round(bestQuizRun.grade)}%
            </div>
            <div className={'text-xs'}>
              {dayjs(bestQuizRun.date).locale('fr').format('DD MMMM YYYY')}
            </div>
          </div>
          <div className={'gap-1 flex flex-col flex-1'}>
            <div className={'text-xs uppercase'}>
              {t('lessons.progress.average')}
            </div>
            <div className={'text-lg font-bold'}>
              {Math.round(averageGrade)}%
            </div>
            <div className={'text-xs'}>
              {dayjs(minDate).locale('fr').format('DD MMMM YYYY')}
              {' - '}
              {dayjs(maxDate).locale('fr').format('DD MMMM YYYY')}
            </div>
          </div>
        </div>
        <div className={'w-full h-52'}>
          <LessonProgressGraph finishedQuizRuns={finishedQuizRuns} />
        </div>
      </div>
    </Card>
  );
};

export default LessonProgress;
