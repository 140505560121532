import { Suspense } from 'react';
import axios from 'axios';

import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'src/hooks/useAuth';
import { useOnUnauthorizedError } from 'src/modules/axios';
import Spinner from 'src/components/common/designSystem/Spinner';
import { AppRouter } from 'src/components/AppRouter';
import FlagsContext from 'src/contexts/FlagsContext';
import AppUrlListener from 'src/components/AppUrlListener';
import ShouldUpdateNativeAppPanel from 'src/components/Updater/ShouldUpdateNativeAppPanel';
import PaywallProvider from 'src/contexts/PaywallContext';

export default function App({
  shouldUpdateNativeApp,
}: {
  shouldUpdateNativeApp: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoggedIn } = useAuth();
  axios.interceptors.response.use(
    response => response,
    useOnUnauthorizedError(navigate, location, setIsLoggedIn),
  );

  return (
    <Suspense fallback={<Spinner size="xl" className="w-full h-full" />}>
      <FlagsContext
        defaults={{
          child_trial_store_paywall: false,
        }}
      >
        <PaywallProvider>
          <AppRouter />
          <AppUrlListener />
          <ShouldUpdateNativeAppPanel open={shouldUpdateNativeApp} />
        </PaywallProvider>
      </FlagsContext>
    </Suspense>
  );
}
