import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { type ButtonProps } from 'antd';
import Button from 'src/components/common/designSystem/Button';

import AddLessonModal from './AddLessonModal';
import { usePaywall } from 'src/contexts/PaywallContext';
import { Lesson } from 'src/types/lesson.types';

export default function AddLessonButton({
  className,
  children,
  onLessonCreated,
  ...buttonProps
}: {
  className?: string;
  onLessonCreated?: (lesson: Lesson) => Promise<void>;
} & ButtonProps) {
  const { t } = useTranslation();
  const { isOutOfTrial, showPaywall } = usePaywall();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    if (isOutOfTrial) {
      showPaywall();
      return;
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Button
        data-testid="btn-add-lesson"
        type="default"
        onClick={handleClickOpen}
        {...buttonProps}
      >
        {children ?? t('global.add')}
      </Button>
      <AddLessonModal
        isOpen={open}
        onClose={handleClose}
        onLessonCreated={onLessonCreated}
      />
    </Fragment>
  );
}
