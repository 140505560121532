import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import Button from 'src/components/common/designSystem/Button';
import Input from 'src/components/common/designSystem/Input';
import { useUpdateUser } from 'src/queries/users';
import { useNotification } from 'src/contexts/NotificationContext';
import OnboardingTitle from 'src/components/Onboarding/OnboardingTitle';
import dayjs from 'dayjs';
import Footer from 'src/components/common/designSystem/Footer';
import { formatChildBirthDateFromYear } from 'src/utils/date';
import { Child } from 'src/types/user.types';
import { reportError } from 'src/modules/logs/Sentry';

const ChildYearStep = ({
  child,
  onFinish,
  isMe,
}: {
  child: Child;
  onFinish: () => void;
  isMe?: boolean;
}) => {
  const { t } = useTranslation();
  const { showError } = useNotification();
  const tNamespace = isMe
    ? 'onboarding.child.year'
    : 'onboarding.parent.child.year';

  const { mutateAsync: updateChild, isLoading: isUpdating } = useUpdateUser();

  const saveYear = async ({ year }: { year: number }) => {
    try {
      await updateChild({
        id: child.id,
        date_of_birth: formatChildBirthDateFromYear(year),
      });
      onFinish();
    } catch (error) {
      reportError('Fail to update child year', error);
      showError({
        error,
        message: t(`${tNamespace}.fail`),
      });
    }
  };

  return (
    <>
      <Form
        name="childYearForm"
        onFinish={saveYear}
        className="w-full bg-transparent flex-1 flex flex-col justify-between gap-3"
        disabled={isUpdating}
        initialValues={{
          year: child.date_of_birth
            ? dayjs(child.date_of_birth).year()
            : undefined,
        }}
      >
        <OnboardingTitle title={t(`${tNamespace}.title`)} />
        <Form.Item
          name="year"
          validateTrigger="onBlur"
          className="w-full text-center"
          rules={[
            {
              required: true,
              message: t('global.validation.fieldRequired'),
            },
            {
              pattern: /^[0-9]{4}$/,
              message: t(`${tNamespace}.invalid`),
            },
            {
              transform: value => parseInt(value, 10),
              min: new Date().getFullYear() - 17,
              message: t(`${tNamespace}.max`),
            },
            {
              transform: value => parseInt(value, 10),
              max: new Date().getFullYear() - 8,
              message: t(`${tNamespace}.min`),
            },
          ]}
        >
          <Input
            data-testid="inp-year"
            inputMode="numeric"
            pattern="[0-9]*"
            type="number"
            className="!border-none !bg-transparent !shadow-none text-center !text-2xl font-bold"
            placeholder={t(`${tNamespace}.label`)}
          />
        </Form.Item>
        <div />
      </Form>
      <Footer>
        <Button
          data-testid="btn-next"
          form="childYearForm"
          type="primary"
          htmlType="submit"
          disabled={isUpdating}
          loading={isUpdating}
          className="w-full"
        >
          {t('onboarding.next')}
        </Button>
      </Footer>
    </>
  );
};

export default ChildYearStep;
