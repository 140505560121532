import { useTranslation } from 'react-i18next';
import Title from 'src/components/common/designSystem/Title';
import { Form } from 'antd';
import Input from 'src/components/common/designSystem/Input';

import { LessonSubject } from 'src/types/lesson.types';

const LessonTitleForm = ({
  onSubmit,
  title,
  subject,
}: {
  onSubmit: (title: string) => void;
  title?: string;
  subject: LessonSubject;
}) => {
  const { t } = useTranslation();

  const onFinish = (values: { title: string }) => {
    onSubmit(values.title);
  };

  return (
    <div className="h-full w-full flex flex-col items-center justify-between">
      <Title>{t('lessons.new.title.title')}</Title>
      <Form
        name="lessonTitleForm"
        requiredMark="optional"
        className="w-full"
        onFinish={onFinish}
        initialValues={{
          title,
        }}
      >
        <Form.Item<string>
          name="title"
          rules={[{ required: true, message: t('lessons.new.title.required') }]}
        >
          <Input
            data-testid="inp-lesson-title"
            placeholder={t(`lessons.new.title.placeholder.${subject}`)}
            prefix="📖"
          />
        </Form.Item>
      </Form>
      <div />
    </div>
  );
};

export default LessonTitleForm;
