import { useTranslation } from 'react-i18next';
import { Modal } from 'antd-mobile';

import { useAuth } from 'src/hooks/useAuth';

const useDeleteMyAccount = () => {
  const { t } = useTranslation();
  const { deleteAccount } = useAuth();

  const deleteMyAccount = async () => {
    Modal.clear();
    Modal.show({
      closeOnMaskClick: true,
      title: t('users.deleteMyAccount.title'),
      content: (
        <div className={'text-center'}>
          {t('users.deleteMyAccount.description')}
        </div>
      ),
      closeOnAction: true,
      onAction: ({ key }) => {
        if (key === 'confirm') {
          deleteAccount();
        }
      },
      actions: [
        {
          key: 'confirm',
          text: (
            <span data-testid={'btn-confirm-delete-account'}>
              {t('users.deleteMyAccount.confirm')}
            </span>
          ),
          danger: true,
        },
        {
          key: 'cancel',
          text: t('global.cancel'),
          className: 'text-structural-darkest',
        },
      ],
    });
  };

  return { deleteMyAccount };
};

export default useDeleteMyAccount;
