import { useEffect, useState } from 'react';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import AskNotificationPanel from 'src/components/Onboarding/AskNotificationPanel';
import { isNotificationNotDetermined } from 'src/modules/push-notifications/Onesignal';

const InAppAskOutAppNotificationOptinNotification = ({
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.ASK_NOTIFICATION_OPTIN;
  };
  onRead: () => void;
}) => {
  const [isOpen, setIsOpened] = useState(true);
  const onClose = () => {
    setIsOpened(false);
    onRead();
  };

  const init = async () => {
    if (await isNotificationNotDetermined()) {
      setIsOpened(true);
    } else {
      onRead();
    }
  };

  useEffect(() => {
    init();
  }, []);

  return <AskNotificationPanel open={isOpen} onClose={onClose} />;
};

export default InAppAskOutAppNotificationOptinNotification;
