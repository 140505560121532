import { useEffect, useState } from 'react';
import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import { AutoFloatingPanel } from 'src/components/common/AutoFloatingPanel';
import BaoHello from 'src/images/bao/bao-hello.svg?react';
import { useTranslation } from 'react-i18next';
import StarReviewInput, {
  Rate,
} from 'src/components/common/StarReview/StarReviewInput';
import { useAddAppReview, useUpdateAppReview } from 'src/queries/app_reviews';
import { useNotification } from 'src/contexts/NotificationContext';
import { AppReview } from 'src/types/app_review.types';
import Button from 'src/components/common/designSystem/Button';
import { InAppReview } from '@capacitor-community/in-app-review';
import ReviewComment from 'src/components/common/StarReview/ReviewComment';
import { reportError } from 'src/modules/logs/Sentry';
import { logAnalyticsEvent } from 'src/modules/analytics/Amplitude';

const InAppAskAppReviewNotifications = ({
  onRead,
}: {
  notification: InAppNotification & {
    type: InAppNotificationType.ASK_APP_REVIEW;
  };
  onRead: () => void;
}) => {
  const [isOpen, setIsOpened] = useState(true);
  const { t } = useTranslation();
  const { mutateAsync: addReview, isLoading: isLoadingAdd } = useAddAppReview();
  const { mutateAsync: updateReview, isLoading: isLoadingUpdate } =
    useUpdateAppReview();
  const { showError } = useNotification();
  const [review, setReview] = useState<AppReview>();

  useEffect(() => {
    logAnalyticsEvent('card_review_viewed');
  }, []);

  const onReview = async ({
    rate,
    comment,
  }: {
    rate?: Rate;
    comment?: string;
  }) => {
    try {
      if (review) {
        setReview(await updateReview({ id: review.id, rate, comment }));
        return;
      }
      if (!rate) {
        return;
      }
      setReview(await addReview({ rate, comment }));
    } catch (error) {
      reportError('Fail to update app review', error);
      showError({
        error,
        message: t('app_reviews.panel.ask.error'),
      });
    }
  };

  const onClose = () => {
    setIsOpened(false);
    onRead();
  };

  const isLoading = isLoadingAdd || isLoadingUpdate;

  return (
    <AutoFloatingPanel
      isOpen={isOpen}
      onClose={onClose}
      data-testid={'panel-app-review'}
    >
      <div className={'flex items-start flex-col gap-5 p-6 text-left'}>
        {!review ? <AskReview /> : null}
        <StarReviewInput
          onChange={rate => {
            onReview({ rate });
            logAnalyticsEvent(`star-review-${rate}`);
          }}
          value={0}
          isLoading={isLoading}
        />
        {review ? (
          review.rate >= 4 ? (
            <PositiveReview onFinish={onClose} />
          ) : (
            <NegativeReview
              onComment={async (comment: string) => {
                await onReview({ comment });
                onClose();
              }}
              review={review}
              isLoading={isLoading}
            />
          )
        ) : null}
      </div>
    </AutoFloatingPanel>
  );
};

const AskReview = () => {
  const { t } = useTranslation();

  return (
    <>
      <BaoHello className={'h-32 w-auto'} />
      <div className={'font-bold text-balance text-2xl'}>
        {t('app_reviews.panel.ask.title')}
      </div>
      <div className={'flex flex-col gap-2 text-balance text-base'}>
        <div>{t('app_reviews.panel.ask.description1')}</div>
        <div>{t('app_reviews.panel.ask.description2')}</div>
      </div>
    </>
  );
};
const PositiveReview = ({ onFinish }: { onFinish: () => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={'font-bold text-balance text-2xl'}>
        {t('app_reviews.panel.positive.title')}
      </div>
      <div className={'flex flex-col gap-2 text-balance text-base'}>
        {t('app_reviews.panel.positive.description')}
      </div>
      <Button
        type={'primary'}
        data-testid={'btn-review'}
        onClick={async () => {
          await InAppReview.requestReview();
          await logAnalyticsEvent('button_store_review_clicked');
          onFinish();
        }}
      >
        {t('app_reviews.panel.positive.button')}
      </Button>
    </>
  );
};
const NegativeReview = ({
  review,
  onComment,
  isLoading,
}: {
  review: AppReview;
  onComment: (comment: string) => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  const comment = async (comment: string) => {
    onComment(comment);
    await logAnalyticsEvent('button_negative_review_clicked');
  };

  return (
    <>
      <div className={'font-bold text-balance text-2xl'}>
        {t('app_reviews.panel.negative.title')}
      </div>
      <div className={'flex flex-col gap-2 text-balance text-base'}>
        {t('app_reviews.panel.negative.description')}
      </div>
      <ReviewComment
        onComment={comment}
        comment={review.comment}
        isLoading={isLoading}
      />
    </>
  );
};

export default InAppAskAppReviewNotifications;
