import { useTranslation } from 'react-i18next';

const ChallengeResultTitle = ({
  firstName,
  grade,
  opponentGrade,
}: {
  firstName: string;
  grade: number;
  opponentGrade: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className={'flex flex-col gap-2 justify-center items-center'}>
      <div className={'font-bold text-3xl text-center text-balance'}>
        {t(
          grade === opponentGrade
            ? 'challenge.result.title.equal'
            : grade > opponentGrade
              ? 'challenge.result.title.success'
              : 'challenge.result.title.failure',
          { firstName },
        )}
      </div>
      <div className={'text-base font-semibold text-center text-balance'}>
        {t(
          grade === opponentGrade
            ? 'challenge.result.subtitle.equal'
            : grade > opponentGrade
              ? 'challenge.result.subtitle.success'
              : 'challenge.result.subtitle.failure',
        )}
      </div>
    </div>
  );
};

export default ChallengeResultTitle;
