import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { LessonSmartbit } from 'src/types/lesson.types';

export const useGetLessonSmartbits = (lessonId?: number | string) =>
  useQuery<LessonSmartbit[]>({
    queryKey: ['lesson_smartbits', Number(lessonId)],
    queryFn: async ({ queryKey }) => {
      const { data } = await axios.get(
        `/api/lessons/${queryKey[1] as number}/lesson_smartbits`,
      );
      return data;
    },
    enabled: !!lessonId,
    refetchOnMount: 'always',
  });

export const useSeenLessonSmartbits = (lessonId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.post<number>(
        `/api/lessons/${lessonId}/lesson_smartbits/seen`,
      );
      return data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['user']);
    },
  });
};
