export default function CustomAvatar({ name = '#' }: { name?: string }) {
  const getBackgroundColorForName = (): string => {
    const colors = [
      '#0097A5',
      '#1E99EB',
      '#F4B627',
      '#E6449C',
      '#26A167',
      '#EA665B',
    ];

    let hash = 0;
    for (let i = 0, len = name?.length; i < len; i += 1) {
      hash = (hash << 5) - hash + name.charCodeAt(i);
      hash |= 0;
    }
    return colors[Math.abs(hash) % colors.length];
  };

  return (
    <div
      style={{
        backgroundColor: getBackgroundColorForName(),
      }}
      className="text-white h-8 w-8 rounded-full flex items-center justify-center text-lg"
    >
      <div style={{ lineHeight: '1rem', height: 13 }}>
        {name && name.length > 0 ? name.charAt(0) : '#'}
      </div>
    </div>
  );
}
