import {
  InAppNotification,
  InAppNotificationType,
} from 'src/types/notification.types';
import QuizReadyNotificationCard from 'src/components/Notifications/NotificationsList/NotificationCard/QuizReadyNotificationCard';
import ChallengeFinishedNotificationCard from 'src/components/Notifications/NotificationsList/NotificationCard/ChallengeFinishedNotificationCard';
import ReferralRewardNotificationCard from 'src/components/Notifications/NotificationsList/NotificationCard/ReferralRewardNotificationCard';

const NotificationCard = ({
  notification,
}: {
  notification: InAppNotification;
}) => {
  switch (notification.type) {
    case InAppNotificationType.QUIZ_READY:
      return <QuizReadyNotificationCard notification={notification} />;
    case InAppNotificationType.CHALLENGE_FINISHED:
      return <ChallengeFinishedNotificationCard notification={notification} />;
    case InAppNotificationType.REFERRAL_REWARD:
      return <ReferralRewardNotificationCard notification={notification} />;
    default:
      return null;
  }
};

export default NotificationCard;
