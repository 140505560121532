import { useState } from 'react';
import Star from 'src/components/common/StarReview/Star';

export type Rate = 0 | 1 | 2 | 3 | 4 | 5;
const rates: Rate[] = [1, 2, 3, 4, 5];

const StarReviewInput = ({
  value,
  onChange,
  isLoading,
}: {
  value: Rate;
  onChange: (value: Rate) => void;
  isLoading?: boolean;
}) => {
  const [rate, setRate] = useState(value);

  return (
    <div className={'flex flex-row gap-4'} data-testid={'inp-stars'}>
      {rates.map(star => (
        <Star
          key={star}
          style={{
            animationDelay: `${(star - 1) * 50}ms`,
          }}
          className={isLoading ? 'animate-bounce' : ''}
          isStarred={star <= rate}
          onClick={() => {
            setRate(star);
            onChange(star);
          }}
        />
      ))}
    </div>
  );
};

export default StarReviewInput;
