import useNavigateBack from 'src/hooks/useNavigateBack';
import CloseIcon from 'src/images/close.svg?react';

const LessonSmartbitsIndicator = ({
  total,
  current,
}: {
  total: number;
  current: number;
}) => {
  const goBack = useNavigateBack();
  const isLast = current === total - 1;

  return (
    <div className={'absolute w-full z-20 top-4 flex flex-col gap-2'}>
      <div className={'flex flex-row justify-center items-center gap-1 px-4'}>
        {Array.from({ length: total }, (_, index) => (
          <div
            key={index}
            data-testid={`swiper-indicator-${index}`}
            className={`flex-1 h-1 bg-white rounded-full ${
              index <= current ? '' : 'bg-opacity-50'
            }`}
          />
        ))}
      </div>
      <div className={'p-4 pointer-events-auto'} onClick={goBack}>
        <CloseIcon
          className={`${isLast ? 'fill-structural-darkest' : 'fill-white'} w-6 h-auto`}
          data-testid={'btn-back'}
        />
      </div>
    </div>
  );
};

export default LessonSmartbitsIndicator;
